<template>
  <div>
    <div>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="11" sm="5">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Choose a Year"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              reactive
              show-current
              ref="picker"
              v-model="date"
              type="year"
              @input="submityear()"
              min="2015"
              max="2022"
              no-title
            >
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </div>
    <v-container>
      <v-card>
        <v-toolbar color="blue lighter-1" dark dense>
          <v-toolbar-title>Yearly Cases Report</v-toolbar-title>
        </v-toolbar>
        <v-data-table
          :headers="tableHeaders"
          :items="tableData"
          item-key="_id"
          :loading="loading"
          loading-text="Loading... Please wait"
          class="elevation-1"
          hide-default-footer
          dense
        >
          <!-- eslint-disable-next-line -->
          <template v-slot:item.total_amount="{ item }"> RS. {{ item.total_amount }} </template>
        </v-data-table>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-toolbar color="blue lighter-1" dark dense>
          <v-toolbar-title>Yearly Cases Report</v-toolbar-title>
        </v-toolbar>
        <BarChart :item="yearly_report_chartData"></BarChart>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-toolbar color="blue lighter-1" dark dense>
          <v-toolbar-title>Yearly Collection</v-toolbar-title>
        </v-toolbar>
        <BarChart :item="yearly_collection_chartData"></BarChart>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import BarChart from "@/components/work_order/dashboard/bar_chat.vue";
export default {
  components: {
    BarChart,
  },
  name: "Dashboard",
  data: () => ({
    date: new Date().format().substr(0, 4),
    min: "2018",
    max: "2020",
    menu: false,
    loading: false,
    tableHeaders: [
      { text: "Months", value: "months" },
      { text: "Assigned", value: "total_assigned" },
      { text: "Cancelled", value: "total_cancelled" },
      { text: "Finished", value: "total_finished" },
      { text: "Pending", value: "total_pending" },
      { text: "Amount Collected", value: "total_amount" },
    ],
    tableData: [
      {
        months: "October",
        total_assigned: "40",
        total_cancelled: "3",
        total_finished: "35",
        total_pending: "2",
        total_amount: "10000",
      },
      {
        months: "November",
        total_assigned: "40",
        total_cancelled: "3",
        total_finished: "35",
        total_pending: "2",
        total_amount: "10000",
      },
      {
        months: "December",
        total_assigned: "40",
        total_cancelled: "3",
        total_finished: "35",
        total_pending: "2",
        total_amount: "10000",
      },
      {
        months: "January",
        total_assigned: "40",
        total_cancelled: "3",
        total_finished: "35",
        total_pending: "2",
        total_amount: "10000",
      },
      {
        months: "February",
        total_assigned: "40",
        total_cancelled: "3",
        total_finished: "35",
        total_pending: "2",
        total_amount: "10000",
      },
    ],

    yearly_collection_chartData: {
      labels: ["October", "November", "December", "January", "February"],
      datasets: [
        {
          label: "Total Amount",
          type: "bar",
          stack: "Total Amount",
          backgroundColor: "#9999ff",
          data: [30000, 31000, 32000, 33000, 34000],
        },
        {
          label: "Manager Handover",
          type: "bar",
          stack: "Manager Handover",
          backgroundColor: "#b3ffb3",
          data: [20000, 21000, 22000, 23000, 24000],
        },
      ],
    },

    yearly_report_chartData: {
      labels: ["October", "November", "December", "January", "February"],
      datasets: [
        {
          label: "Total",
          type: "bar",
          stack: "Total",
          backgroundColor: "#9999ff",
          data: [30, 31, 32, 33, 34],
        },
        {
          label: "Fineshed",
          type: "bar",
          stack: "Fineshed",
          backgroundColor: "#b3ffb3",
          data: [20, 21, 22, 23, 24],
        },
        {
          label: "cancelled",
          type: "bar",
          stack: "cancelled",
          backgroundColor: " #cccccc",
          data: [5, 2, 6, 9, 5],
        },
        {
          label: "Pending",
          type: "bar",
          stack: "Pending",
          backgroundColor: "#ffa500",
          data: [5, 2, 6, 9, 5],
        },
      ],
    },
  }),
  watch: {
    menu(val) {
      val && this.$nextTick(() => (this.$refs.picker = "year"));
    },
  },
  mounted: function () {},
  methods: {
    submityear: function () {
      let data = this.date;
      let year = data.substring(0, 4);
      this.date = year;
      this.menu = false;
    },
  },
};
</script>
