<template>
  <div>
    <v-toolbar dense flat>
      <v-text-field
        class="ml-5"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        @keyup.native="search_details()"
      ></v-text-field>
      <v-btn icon @click="add_technician()">
        <v-icon>mdi-account-plus-outline</v-icon>
      </v-btn>
    </v-toolbar>
    <v-data-iterator
      :items="tableData"
      :options.sync="options"
      :loading="loading"
      :server-items-length="total_technician_length"
      :footer-props="footerProps"
      class="ma-1"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:default="props">
        <v-row v-for="(item, i) in props.items" :key="i">
          <v-card class="ma-1" width="-webkit-fill-available">
            <v-card-title>
              {{ item.name }}
            </v-card-title>
            <v-card-subtitle>
              Mobile: {{ item.mobile }} <br />
              Role: {{ item.role_name }} <br />
              Department: {{ item.department_name }} <br />
              Id Card:
              <v-chip color="primary" outlined x-small @click="download_id(item.id_card_location, item.photo_id_card)">
                {{ check_id(item.photo_id_card) }}
              </v-chip>
            </v-card-subtitle>
            <v-card-actions>
              <v-icon small class="mr-4" @click="editItem(item)"> mdi-pencil </v-icon>
              <v-icon small @click="deleteItem(item)" v-if="check_manager()"> mdi-delete </v-icon>
              <v-spacer></v-spacer>
              <v-btn icon @click="toggle(i)">
                <v-icon>{{ show[i] ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
              </v-btn>
            </v-card-actions>
            <v-expand-transition>
              <div v-show="show[i]">
                <v-divider></v-divider>
                <v-card-text>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Pincode</th>
                          <th class="text-left">Area</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="thing in item.allocated_areas" :key="thing._id">
                          <td>{{ thing.pincode }}</td>
                          <td>{{ thing.area }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-row>
      </template>
    </v-data-iterator>
    <div>
      <v-container v-if="type === 'add_technician_dialog'">
        <add_user v-bind:show="show_add" v-bind:item="item" @close_add_technician="close_add_screen()"></add_user>
      </v-container>
    </div>
    <!-- 
      Delete Dialog   
    -->

    <div>
      <v-dialog v-model="deleteDialog">
        <v-card>
          <v-card-title class="text-h6 orange lighter-4 white--text"> Do you want to delete this record? </v-card-title>
          <v-card-text>
            {{ delete_summary }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="orange darken-1" text @click="deleteDialog = false"> Cancel </v-btn>
            <v-btn :loading="loading2" :disabled="loading2" color="orange darken-1" text @click="doDelete()"> Yes </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- 
      Message Dialog   
   -->
    <div>
      <v-snackbar v-model="snackbar" :timeout="2000">
        {{ snackbar_text }}
      </v-snackbar>
      <v-overlay :value="overlay" :z-index="2000">
        <v-progress-circular absolute size="64"></v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>
<script>
//import FetchData from "@/js/database/fetch_data";
import fileDownload from "js-file-download";
import axios from "axios";
import PostgresDB from "@/js/database/postgres_db";
import Util from "@/js/Util";
import add_user from "@/components/users/add_user.vue";
import settings from "@/js/database/settings";
export default {
  components: { add_user },
  data() {
    return {
      show: [],
      loading: false,
      loading2: false,
      search: "",
      expanded: [],
      singleExpand: false,
      technicianDetails: [],
      tableHeaders: [
        { text: "Name", align: "start", sortable: true, value: "name" },
        { text: "Mobile", value: "mobile" },
        { text: "Role", value: "role_name" },
        { text: "Department", value: "department_name" },
        { text: "Id Card", value: "photo_id_card" },
        { text: "Actions", value: "actions", sortable: false },
        { text: "", value: "data-table-expand" },
      ],
      tableData: [],
      type: "",
      item: {},
      deleteDialog: false,
      delete_item: null,
      delete_summary: "",
      snackbar_text: "",
      snackbar: false,
      overlay: false,
      options: {
        itemsPerPage: "50",
      },
      offsetvalue: "",
      footerProps: {
        "items-per-page-options": [50, 70, 100, -1],
        "items-per-page-text": "Technician per page",
      },
      total_technician_length: Number(""),
    };
  },
  watch: {
    options: {
      handler() {
        this.load();
      },
      deep: true,
    },
  },
  methods: {
    toggle: function (index) {
      let val = this.show[index];
      val = !val;
      this.$set(this.show, index, val);
    },
    load: async function () {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      if (page == 1) {
        this.offsetvalue = 0;
      } else {
        let pageval = page - 1;
        this.offsetvalue = pageval * itemsPerPage;
      }
      (this.technicianDetails = []), (this.tableData = []);
      this.technicianDetails = await PostgresDB.getAllUsers(itemsPerPage, this.offsetvalue, this.search);
      if (this.technicianDetails.status == 206 || this.technicianDetails.status == 200) {
        let list = this.technicianDetails.data;

        for (let i = 0; i < list.length; i++) {
          let item = list[i];
          let alloc_areas = [];
          if (item.technician_allocated_areas[0]) {
            //console.log(item.technician_allocated_areas[0].allocated_areas);
            let str = item.technician_allocated_areas[0].allocated_areas;
            alloc_areas = JSON.parse(str);
          }
          let id_card = "";
          if (item.photo_id_card) {
            id_card = item.photo_id_card;
            if (id_card.indexOf("/") > -1) {
              id_card = id_card.substring(id_card.lastIndexOf("/") + 1);
            }
          }
          let result = item;
          result.password = null;
          result._id = item.emp_id;
          result.name = item.first_name + " " + item.last_name;
          result.role_id = item.role_master.role_id;
          result.role_name = item.role_master.role_name;
          result.department_id = item.department_list.department_id;
          result.department_name = item.department_list.department_name;
          result.allocated_areas = alloc_areas;
          result.id_card_location = item.photo_id_card;
          result.photo_id_card = id_card;
          //console.log(result);
          this.tableData.push(result);
        }
      }
      //this.tableData = this.technicianDetails.data;
      var tableDataHeaders = this.technicianDetails.headers;
      var contant_range = tableDataHeaders["content-range"];
      var total_technician = Util.contentRangesplitMulti(contant_range);
      this.total_technician_length = Number(total_technician[2]);
      //console.log(this.technicianDetails)
      this.loading = false;
    },
    close_add_screen: function () {
      this.show_add = false;
      this.type = "";
      this.load();
    },
    add_technician: function () {
      this.item = {};
      this.type = "add_technician_dialog";
      this.show_add = true;
    },
    search_details: Util.debounce(function () {
      this.load();
    }, 500),
    deleteItem: function (item) {
      this.delete_summary = item.name + " " + item.role_name + " Mobile:" + item.mobile;
      this.delete_item = item;
      this.deleteDialog = true;
    },
    doDelete: async function () {
      this.loading2 = true;
      let emp_id = this.delete_item.emp_id + "";
      console.log("deleting id:" + emp_id);
      let result = await PostgresDB.deleteUser(emp_id);
      if (result.status == 200 || result.status == 204) {
        this.loading2 = false;
        this.deleteDialog = false;
        this.snackbar_text = 'Deleted Successfully."';
        this.snackbar = true;
        Util.callThisAfter(this.load, 1000);
      } else {
        this.loading2 = false;
        this.deleteDialog = false;
        this.snackbar_text = "Error Deleting Record:" + result;
        this.snackbar = true;
      }
    },
    editItem: function (item) {
      this.item = item;
      this.type = "add_technician_dialog";
      this.show_add = true;
    },
    download_id: function (id_loc, name) {
      let item = id_loc + "";
      let dArray = [];
      if (item.indexOf(" | ") > -1) {
        dArray = item.split(" | ");
      } else {
        dArray[0] = "homecollection";
        dArray[1] = item;
      }
      let jwt_token = Util.getFromSession("pg_admin");
      let data = {
        bucket_name: dArray[0],
        key: dArray[1],
        jwt_token: jwt_token,
      };
      axios({
        method: "post",
        responseType: "blob",
        url: settings.node_url + "/s3/get_file_v2",
        data: data,
      })
        .then(async function (response) {
          //console.log(response.data);
          fileDownload(response.data, name);
        })
        .catch(function (error) {
          console.log("error:" + error);
        });
    },
    check_id: function (str) {
      if (str) {
        return str;
      } else {
        return "No ID Card";
      }
    },
    check_manager: function () {
      let mobile = Util.getFromSession("logged_in_mobile");
      if (mobile == "9841541542") {
        return true;
      }
      return false;
    },
  },
};
</script>
<style scoped></style>
