import axios from "axios";
import settings from "./settings";
import Util from "@/js/Util";
import moment from "moment";

let postgres_db = {
  client: null,
  token: "",
  setup: async function () {
    if (!postgres_db.token) {
      postgres_db.token = Util.getFromSession("pg_admin");
    }
    let headers = {};
    if (postgres_db.token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + postgres_db.token,
        Prefer: "count=estimated , resolution=merge-duplicates , return=representation",
      };
    } else {
      headers = {
        "Content-Type": "application/json",
        Prefer: "count=estimated , resolution=merge-duplicates , return=representation",
      };
    }
    postgres_db.client = axios.create({
      baseURL: settings.rdbms_db_url,
      withCredentials: true,
      headers: headers,
    });
  },
// to test in local
  // let postgres_db = {
  //   client: null,
  //   token: "",
  //   setup: async function () {
     
  //     postgres_db.client = axios.create({
  //       baseURL: settings.rdbms_db_url,
  //       withCredentials: true,
       
  //     });
  //   },
  refreshToken: async function () {
    postgres_db.setup();
    let result = "";
    try {
      result = await postgres_db.client.post("/rpc/refresh_token_v2").catch(function (error) {
        console.log("error:" + error);
        return JSON.stringify(error);
      });
      if (result.status == 200) {
        if (result.data.token) {
          postgres_db.token = result.data.token;
          Util.setSession("pg_admin", postgres_db.token);
          let calendar = moment();
          calendar.add(10, "hours");
          let exp_time = calendar.format("YYYY-MM-DD HH:mm:ss");
          Util.setSession("exp_time", exp_time);
          postgres_db.setup();
        }
        result = "OK";
      }
    } catch (e) {
      console.log("in refresh token:" + e);
    }
    return result;
  },
  app_login: async function () {
    let result = "";
    try {
      let app = { app_name: settings.app_user, password: settings.app_pass };
      result = await postgres_db.client.post("/rpc/app_login", app).catch(function (error) {
        console.log("error:" + error);
        return JSON.stringify(error);
      });
      console.log("in app login:" + result.status + " " + JSON.stringify(result.data));
      if (result.status == 200) {
        Util.setSession("APP_VERSION", result.data.app_version);
        return result.status;
      }
    } catch (e) {
      console.log("in app login catch:" + e);
    }
    sessionStorage.clear();
    return "NEW_APP";
  },
  login: async function (mobile, password) {
    if (postgres_db.client == null) {
      postgres_db.setup();
    }
    let user = { mobile: mobile, password: password, department_id: 64 };
    let result = "";
    try {
      result = await postgres_db.client.post("/rpc/login_v4", user).catch(function (error) {
        //result = await axios.post("/v3/rpc/login_v4", user).catch(function (error) {
        console.log("error:" + error);
        return JSON.stringify(error);
      });
    
      console.log("in login:" + result.status + " " + JSON.stringify(result.data));
      if (result.status == 200) {
        sessionStorage.clear();
        let f_date = Util.formatDate(result.headers.date);
        Util.setSession("server_time", f_date);
        if (result.data.token) {
          postgres_db.token = result.data.token;
          Util.setSession("pg_admin", postgres_db.token);
          let calendar = moment();
          calendar.add(10, "hours");
          let exp_time = calendar.format("YYYY-MM-DD HH:mm:ss");
          Util.setSession("exp_time", exp_time);
          postgres_db.setup();
        }
        let app_login = await postgres_db.app_login();
        if (app_login == "NEW_APP") {
          return "NEW_APP";
        }

  
        let tenant_id = result.data.tenant_id;
        let first_name = result.data.first;
        let last_name = result.data.last;
        let name = first_name + " " + last_name;
        let emp_id = result.data.emp_id;
        let user = await postgres_db.getUserDetails(emp_id);
        Util.setSession("department_name", user.department_name);
        Util.setSession("department_id", user.department_id);
     




let employee_activities = result.data.employee_activities;

if (employee_activities && employee_activities.role_list) {
    let role_list = employee_activities.role_list;
    Util.setSession("role_list", role_list);

    let check_role_list = ['170','120', '240','250','280','270'];
    Util.setSession("check_role_list", check_role_list);

    let final_role = role_list.filter(role => check_role_list.includes(role));

    if (final_role.includes('170') && final_role.includes('120')) {
      // Keep only '170' or '120', depending on the requirement
      final_role = final_role.filter(role => role !== '120');  // This keeps '170'
     
  }

 


    if (final_role.length === 0 || final_role == null) {
      
        if (check_role_list.includes(user.role_id.toString())) {
            final_role = [user.role_id];
        } else {
            return "NO_MATCHES";
        }
    }

    Util.setSession("final_role", final_role);

} else {
    let check_role_list = ['170','120', '240','250','280','270'];
    Util.setSession("role_list", []);

    if (check_role_list.includes(user.role_id.toString())) {
        Util.setSession("final_role", [user.role_id]);
    } else {
        return "NO_MATCHES";
    }
}


        let initials = Util.getInitials(first_name, last_name);
        Util.setSession("logged_in_mobile", mobile);
        Util.setSession("logged_in_tenant_id", tenant_id);
        Util.setSession("logged_in_emp_id", emp_id);
        Util.setSession("logged_in_emp_name", name);
        Util.setSession("initials", initials);
        let tenant = await postgres_db.getTenantName(tenant_id);
        Util.setSession("logged_in_tenant_name", tenant.tenant_name);
        Util.setSession("default_tenant_id", tenant.tenant_id);
        Util.setSession("default_tenant_name", tenant.tenant_name);
        Util.setSession("default_region", tenant.region);
        Util.setSession("default_state", tenant.state);
        Util.setSession("doc_dbs", tenant.doc_dbs);
        if (settings.production == false) {
          Util.setSession("doc_dbs", "chennai99_work_orders,chennai8_hc_notifications");
        }
        Util.setSession("del_doc_dbs", tenant.del_doc_dbs);
        Util.setSession("dashboard_tenant_name", postgres_db.getDashboardTenantName(tenant.doc_dbs));
        
        return result.status;
      }
    } catch (e) {
      console.log("in login catch:" + e);
    }
    sessionStorage.clear();
    return result;
  },

  
  getDashboardTenantName: function (str) {
    try {
      if (str) {
        if (str.indexOf("_") > -1) {
          let tenant = str.substring(0, str.indexOf("_")).toLowerCase();
          if (tenant.startsWith("chennai")) {
            return "chennai";
          } else {
            return tenant;
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  },
  getUserWithMobile: async function (mobile) {
    if (postgres_db.client == null) {
      postgres_db.setup();
    }
    let response = "";
    response = await postgres_db.client.get("/employees?select=*&mobile=eq." + mobile).catch(function (error) {
      console.log("error:" + error);
      return JSON.stringify(error);
    });
    if (response.status == 200 || response.status == 206) {
      return response.data;
    } else if (response.status == 401) {
      location.reload();
    }
    return "Error";
  },
  insertUser: async function (emp) {
    if (postgres_db.client == null) {
      postgres_db.setup();
    }
   let result = await postgres_db.client.post("/employees", emp).catch(function (error) {
    //  let result = await axios.post("/v3/employees", emp).catch(function (error) {
      console.log("error:" + error);
      return JSON.stringify(error);
    });
    return result;
    
  },
  updateUser: async function (emp_id, emp) {
    if (postgres_db.client == null) {
      postgres_db.setup();
    }
   let result = await postgres_db.client.patch("/employees?emp_id=eq." + emp_id, emp).catch(function (error) {
    //let result = await axios.patch("/v3/employees?emp_id=eq." + emp_id, emp).catch(function (error) {
      console.log("error:" + error);
      return JSON.stringify(error);
    });
    return result;
  },
  upsertAllocatedAreas: async function (emp_id, areas) {
    if (postgres_db.client == null) {
      postgres_db.setup();
    }
    let result = await postgres_db.client.get("/technician_allocated_areas?select=taa_id&emp_id=eq." + emp_id).catch(function (error) {
      //let result = await axios.get("/v3/technician_allocated_areas?select=taa_id&emp_id=eq." + emp_id).catch(function (error) {
      console.log("error:" + error);
      return JSON.stringify(error);
    });
    if (result.data.length == 0) {
      result = await postgres_db.client.post("/technician_allocated_areas", areas).catch(function (error) {
        //result = await axios.post("/v3/technician_allocated_areas", areas).catch(function (error) {
        console.log("error:" + error);
        return JSON.stringify(error);
      });
    } else {
      let taa_id = result.data[0].taa_id;
      result = await postgres_db.client.patch("/technician_allocated_areas?taa_id=eq." + taa_id, areas).catch(function (error) {
        //result = await axios.patch("/v3/technician_allocated_areas?taa_id=eq." + taa_id, areas).catch(function (error) {
        console.log("error:" + error);
        return JSON.stringify(error);
      });
    }
    return result;
  },
  getRoleList: async function (search) {
    console.log("in get role list:" + search);
    if (postgres_db.client == null) {
      postgres_db.setup();
    }
    let response = "";
    if (search) {
      response = await postgres_db.client.get("/role_master?select=role_id,role_name&role_name=ilike.*" + search + "*").catch(function (error) {
        console.log("error:" + error);
        return JSON.stringify(error);
      });
    } else {
      response = await postgres_db.client.get("/role_master?select=role_id,role_name").catch(function (error) {
        console.log("error:" + error);
        return JSON.stringify(error);
      });
    }
    if (response.status == 200 || response.status == 206) {
      return response.data;
    } else if (response.status == 401) {
      location.reload();
    }
    return "Error";
  },
  getDeptList: async function (search) {
    if (postgres_db.client == null) {
      postgres_db.setup();
    }
    let response = "";
    if (search) {
      response = await postgres_db.client
        .get("/department_list?select=department_id,department_name&department_name=ilike.*" + search + "*")
        .catch(function (error) {
          console.log("error:" + error);
          return JSON.stringify(error);
        });
    } else {
      response = await postgres_db.client.get("/department_list?select=department_id,department_name").catch(function (error) {
        console.log("error:" + error);
        return JSON.stringify(error);
      });
    }
    if (response.status == 200 || response.status == 206) {
      return response.data;
    } else if (response.status == 401) {
      location.reload();
    }
    return "Error";
  },
  getTenantList: async function (search) {
    if (postgres_db.client == null) {
      postgres_db.setup();
    }
    let response = "";
    if (search) {
      response = await postgres_db.client
        .get("/tenant_master?select=tenant_id,tenant_name,region,state&tenant_name=ilike.*" + search + "*")
        .catch(function (error) {
          console.log("error:" + error);
          return JSON.stringify(error);
        });
    } else {
      response = await postgres_db.client.get("/tenant_master?select=tenant_id,tenant_name,region,state").catch(function (error) {
        console.log("error:" + error);
        return JSON.stringify(error);
      });
    }
    if (response.status == 200 || response.status == 206) {
      return response.data;
    } else if (response.status == 401) {
      location.reload();
    }
    return "Error";
  },
  getAssignedTenantList: async function (search) {
    if (postgres_db.client == null) {
      postgres_db.setup();
    }
    let emp_id = Util.getFromSession("logged_in_emp_id");
    let response = "";
    if (search) {
      response = await postgres_db.client
        .get(
          "/emp_tenant_mapping?select=*,tenant_master(tenant_id,tenant_name,region,state)&emp_id=eq." +
            emp_id +
            "&tenant_master.tenant_name=ilike.*" +
            search +
            "*"
        )
        .catch(function (error) {
          console.log("error:" + error);
          return JSON.stringify(error);
        });
    } else {
      response = await postgres_db.client
        .get("/emp_tenant_mapping?select=*,tenant_master(tenant_id,tenant_name,region,state)&emp_id=eq." + emp_id)
        .catch(function (error) {
          console.log("error:" + error);
          return JSON.stringify(error);
        });
    }
    if (response.status == 200 || response.status == 206) {
      let list = response.data;
      let retArray = [];
      let doc = {
        tenant_id: Util.getFromSession("default_tenant_id"),
        tenant_name: Util.getFromSession("default_tenant_name"),
        region: Util.getFromSession("default_region"),
        state: Util.getFromSession("default_state"),
      };
      retArray.push(doc);
      for (let i = 0; i < list.length; i++) {
        let item = list[i];
        if (item.tenant_master) {
          retArray.push(item.tenant_master);
        }
      }
      return retArray;
    } else if (response.status == 401) {
      location.reload();
    }
    return "Error";
  },
  getTenantName: async function (tenant_id) {
    if (postgres_db.client == null) {
      postgres_db.setup();
    }
    let response = "";
    response = await postgres_db.client
      .get("/tenant_master?select=tenant_id,tenant_name,region,state,doc_dbs,del_doc_dbs&tenant_id=eq." + tenant_id)
      .catch(function (error) {
        console.log("error:" + error);
        return JSON.stringify(error);
      });
    if (response.status == 200 || response.status == 206) {
      console.log("tenant master:" + JSON.stringify(response.data));
      return response.data[0];
    } else if (response.status == 401) {
      location.reload();
    }
    return "Error";
  },
  change_tenant: async function (mod_id) {
    if (postgres_db.client == null) {
      postgres_db.setup();
    }

    let tenant = { tenant_id: mod_id };
    let result = "";
    try {
      result = await postgres_db.client.post("/rpc/change_tenant", tenant).catch(function (error) {
        console.log("error:" + error);
        return JSON.stringify(error);
      });
      console.log("in change tenant:" + result + " " + JSON.stringify(result.data));
      if (result.status == 200) {
        //sessionStorage.clear();
        if (result.data.token) {
          postgres_db.token = result.data.token;
          Util.setSession("pg_admin", postgres_db.token);
          let calendar = moment();
          calendar.add(10, "hours");
          let exp_time = calendar.format("YYYY-MM-DD HH:mm:ss");
          Util.setSession("exp_time", exp_time);
          postgres_db.setup();
        }
        let tenant_id = result.data.tenant_id;
        let first_name = result.data.first;
        let last_name = result.data.last;
        let name = first_name + " " + last_name;
        let emp_id = result.data.emp_id;
        let mobile = result.data.mobile;
        let initials = Util.getInitials(first_name, last_name);
        Util.setSession("logged_in_mobile", mobile);
        Util.setSession("logged_in_tenant_id", tenant_id);
        Util.setSession("logged_in_emp_id", emp_id);
        Util.setSession("logged_in_emp_name", name);
        Util.setSession("initials", initials);
        let tenant = await postgres_db.getTenantName(tenant_id);
        Util.setSession("logged_in_tenant_name", tenant.tenant_name);
        //Util.setSession("default_tenant_id",tenant.tenant_id);
        //Util.setSession("default_tenant_name",tenant.tenant_name);
        //Util.setSession("default_region",tenant.region);
        //Util.setSession("default_state",tenant.state);
        Util.setSession("doc_dbs", tenant.doc_dbs);
        Util.setSession("del_doc_dbs", tenant.del_doc_dbs);
        Util.setSession("dashboard_tenant_name", postgres_db.getDashboardTenantName(tenant.doc_dbs));
        // let user = await postgres_db.getUserDetails(emp_id);
        // Util.setSession("department_name",user.department_name);
        // Util.setSession("department_id",user.department_id);
        // Util.setSession("role_name",user.role_name);
        // Util.setSession("role_id",user.role_id);
        return result.status;
      }
    } catch (e) {
      console.log("in change center:" + e);
    }
    return result;
  },
  getUserDetails: async function (emp_id) {
    if (postgres_db.client == null) {
      postgres_db.setup();
    }
    let response = await postgres_db.client
      .get(
        "/employees?select=*,department_list(department_id,department_name),role_master(role_id,role_name),technician_allocated_areas(allocated_areas)&emp_id=eq." +
          emp_id
      )
      .catch(function (error) {
        console.log("error:" + error);
        return JSON.stringify(error);
      });
    if (response.status == 200 || response.status == 206) {
      let item = response.data[0];
      let alloc_areas = [];
      if (Util.isNotEmpty(item.technician_allocated_areas)) {
        let str = item.technician_allocated_areas[0].allocated_areas;
        alloc_areas = JSON.parse(str);
      }
      let id_card = "";
      if (item.photo_id_card) {
        id_card = item.photo_id_card;
        if (id_card.indexOf("/") > -1) {
          id_card = id_card.substring(id_card.lastIndexOf("/") + 1);
        }
      }
      let result = item;
      result.password = null;
      result._id = item.emp_id;
      result.name = item.first_name + " " + item.last_name;
      result.role_id = item.role_master.role_id;
      result.role_name = item.role_master.role_name;
      result.department_id = item.department_list.department_id;
      result.department_name = item.department_list.department_name;
      result.allocated_areas = alloc_areas;
      result.id_card_location = item.photo_id_card;
      result.photo_id_card = id_card;
      return result;
    } else if (response.status == 401) {
      location.reload();
    }
    return response;
  },
  getAllClients: async function () {
    if (postgres_db.client == null) {
      postgres_db.setup();
    }
    let response = await postgres_db.client
      .get("/doctor_login?select=*,client_master(client_type,client_name)&order=last_updated_at.desc")
      .catch(function (error) {
        console.log("error:" + error);
        return JSON.stringify(error);
      });
    if (response.status == 200 || response.status == 206) {
      let list = response.data;
      console.log(list);
      let retArray = [];
      for (let i = 0; i < list.length; i++) {
        let item = list[i];
        let result = item;
        result.name = item.first_name + " " + item.last_name;
        result.client_name = item.client_master.client_name;
        result.client_type = item.client_master.client_type;
        retArray.push(result);
      }
      return retArray;
    } else if (response.status == 401) {
      location.reload();
    }
    return response;
  },
  getAllUsers: async function (itemsPerPage, offset, search) {
    if (postgres_db.client == null) {
      postgres_db.setup();
    }
    let response = [];
    if (search) {
      response = await postgres_db.client
        .get(
          "/employees?select=*,department_list(department_id,department_name),role_master(role_id,role_name),technician_allocated_areas(allocated_areas)&department_id=eq.64&or=(first_name.ilike.*" +
            search +
            "*,last_name.ilike.*" +
            search +
            "*,mobile.ilike.*" +
            search +
            "*)&employees.&limit=" +
            itemsPerPage +
            "&offset=" +
            offset +
            "&order=last_updated_at.desc"
        )
        .catch(function (error) {
          console.log("error:" + error);
          return JSON.stringify(error);
        });
    } else {
      response = await postgres_db.client
        .get(
          "/employees?select=*,department_list(department_id,department_name),role_master(role_id,role_name),technician_allocated_areas(allocated_areas)&department_id=eq.64&employees.&limit=" +
            itemsPerPage +
            "&offset=" +
            offset +
            "&order=last_updated_at.desc"
        )
        .catch(function (error) {
          console.log("error:" + error);
          return JSON.stringify(error);
        });
    }

    return response;
  },
  deleteUser: async function (emp_id) {
    if (postgres_db.client == null) {
      postgres_db.setup();
    }
    let emp = {
      emp_id: emp_id,
    };
    let result = await postgres_db.client.post("/rpc/delete_employee", emp).catch(function (error) {
      console.log("error:" + error);
      return error;
    });
    console.log("deletion:" + JSON.stringify(result));
    return result;
  },
  getTechnicians: async function (search) {
    if (postgres_db.client == null) {
      postgres_db.setup();
    }
    let response = {};
    let tenant_id = Util.getFromSession("logged_in_tenant_id");
    if (search) {
      response = await postgres_db.client
        .get(
          "/employees?select=*,department_list(department_id,department_name),role_master(role_id,role_name),technician_allocated_areas(allocated_areas)&default_tenant_id=eq." +
            tenant_id +
            "&department_id=eq.64&role_id=eq.240&or=(first_name.ilike.*" +
            search +
            "*,last_name.ilike.*" +
            search +
            "*,mobile.ilike.*" +
            search +
            "*)&order=last_updated_at.desc"
        )
        .catch(function (error) {
          console.log("error:" + error);
          return JSON.stringify(error);
        });
    } else {
      response = await postgres_db.client
        .get(
          "/employees?select=*,department_list(department_id,department_name),role_master(role_id,role_name),technician_allocated_areas(allocated_areas)&default_tenant_id=eq." +
            tenant_id +
            "&department_id=eq.64&role_id=eq.240&order=last_updated_at.desc"
        )
        .catch(function (error) {
          console.log("error:" + error);
          return JSON.stringify(error);
        });
    }
    if (response.status == 200 || response.status == 206) {
      let list = response.data;
      let retArray = [];
      for (let i = 0; i < list.length; i++) {
        let item = list[i];
        let alloc_areas = [];
        if (Util.isNotEmpty(item.technician_allocated_areas)) {
          let str = item.technician_allocated_areas[0].allocated_areas;
          alloc_areas = JSON.parse(str);
        }
        let id_card = "";
        if (item.photo_id_card) {
          id_card = item.photo_id_card;
          if (id_card.indexOf("/") > -1) {
            id_card = id_card.substring(id_card.lastIndexOf("/") + 1);
          }
        }
        if (item.department_list.department_name == "HOME COLLECTION" && item.role_master.role_name == "TECHNICIAN") {
          let result = item;
          result.password = null;
          result._id = item.emp_id;
          result.name = item.first_name + " " + item.last_name;
          (result.mobile = item.mobile), (result.role_id = item.role_master.role_id);
          result.role_name = item.role_master.role_name;
          result.department_id = item.department_list.department_id;
          result.department_name = item.department_list.department_name;
          result.allocated_areas = alloc_areas;
          result.id_card_location = item.photo_id_card;
          result.photo_id_card = id_card;
          retArray.push(result);
        }
      }
      console.log("technicians here:" + retArray.length);
      return retArray;
    } else if (response.status == 401) {
      location.reload();
    }
    return response;
  },
  getB2BClients: async function () {
    if (postgres_db.client == null) {
      postgres_db.setup();
    }
    let response = "";
    response = await postgres_db.client.get("/doctor_login?select=*,client_master(client_id,client_type,client_name)").catch(function (error) {
      console.log("error:" + error);
      return JSON.stringify(error);
    });
    if (response.status == 200 || response.status == 206) {
      console.log("doctor_login:" + JSON.stringify(response.data));
      return response.data;
    } else if (response.status == 401) {
      location.reload();
    }
    return "Error";
  },

  getRoleNamesByIds: async function (role_ids) {
    if (postgres_db.client == null) {
      postgres_db.setup();
    }
  
    try {
      let roleIdArray = [];
  
      // Handle different types of role_ids
      if (Array.isArray(role_ids)) {
        roleIdArray = role_ids;
      } else if (typeof role_ids === 'string') {
        roleIdArray = role_ids.split(',');
      } else {
        throw new Error('Invalid role_ids format');
      }
  
      if (roleIdArray.length === 0) {
        throw new Error('No role_ids provided');
      }
  
      // Build the query to fetch role_names based on role_ids
      const query = `/role_master?select=role_name&role_id=in.(${roleIdArray.join(',')})`;
  
      // Execute the query
      const response = await postgres_db.client.get(query);
  
      // Check if the response contains any data
      if (response.data && response.data.length > 0) {
        // Extract role_names and return as a comma-separated string
        return response.data.map(item => item.role_name).join(', ');
      } else {
        throw new Error('Roles not found');
      }
    } catch (error) {
      console.log('Error fetching role names by role_ids:', error);
      return null; // Or handle the error as needed
    }
  },
  
  search_patients: async function (str, mode) {
    if (postgres_db.client == null) {
      postgres_db.setup();
    }
    let response = "";
    let query = "";
    if (mode == "Mobile") {
      query = "/hc_patient_master?select=*,hc_patient_visit_detail(doc)&mobile_number=eq." + str;
    } else if (mode == "Date") {
      query = "/hc_patient_visit_detail?select=doc&visit_date=eq." + str;
    } else if (mode == "Name") {
      str = str.toLowerCase().trim();
      console.log("in name search:" + str);
      query = "/hc_patient_visit_detail?select=doc&patient_name=ilike." + str + "*&order=visit_date.desc";
    }
    response = await postgres_db.client.get(query).catch(function (error) {
      console.log("error:" + error);
      return new String(error);
    });
    if (response.status == 200 || response.status == 206) {
      //console.log('search_patients:'+JSON.stringify(response.data));
      return response.data;
    }
    return response;
  },
  search_employee: async function (str) {
    if (postgres_db.client == null) {
      postgres_db.setup();
    }
    let response = "";
        response = await postgres_db.client.get("/employees?mobile=eq."+ str).catch(function (error) {
      console.log("error:" + error);
      return new String(error);
    });
    if (response.status == 200 || response.status == 206) {
      //console.log('search_employees'+JSON.stringify(response.data));
      return response.data;
    }
    return response;
  },
getrolename: async function (str) {
    if (postgres_db.client == null) {
      postgres_db.setup();
    }
    let response = "";
        response = await postgres_db.client.get("/role_master?select=role_name&role_id=eq."+ str).catch(function (error) {
      console.log("error:" + error);
      return new String(error);
    });
    if (response.status == 200 || response.status == 206) {
      //console.log('search_employees'+JSON.stringify(response.data));
      return response.data;
    }
    return response;
  },

};
export default postgres_db;
