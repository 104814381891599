import DBHandler from "@/js/database/DBHandler";
import Util from "@/js/Util";

var DashboardDB = {
  get_one: async function (doc_id) {
    let db = await DBHandler.getDb("dashboard");
    let tenant = Util.getFromSession("dashboard_tenant_name");
    doc_id = tenant + "_" + doc_id;
    let doc = db
      .get(doc_id)
      .then(function (doc) {
        return doc;
      })
      .catch(function (err) {
        return err;
      });
    return doc;
  },
};
export default DashboardDB;
