<template>
  <div>
    <v-alert color="grey lighten-1" type="info" dense>
      {{ client_detail }}
      <v-chip
        class="mx-1"
        x-small
        color="red"
        label
        outlined
        dense
        v-if="getClientStatus()"
      >
        {{ getClientStatus() }}
      </v-chip>
    </v-alert>
    <v-stepper v-model="level">
      <v-stepper-header>
        <v-stepper-step :complete="level > 1" step="1">
          Reason For Delay
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="level > 2" step="2">
          Add Tests
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="level > 3" step="3">
          Total Amount
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="level > 4" step="4">
          Enter OTP
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="level > 5" step="5">
          Prescription Photo
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="level > 6" step="6">
          Payment Method
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <!-- text box for delay reason -->
          <v-card :height="height" class="pa-4" elevation="2">
            <v-row justify="center" dense>
              <v-col cols="12" sm="6" md="6">
                You are late by {{ delay_mins }}. Please give reason for the
                delay.
              </v-col>
            </v-row>
            <v-row justify="center" dense>
              <v-col cols="12" sm="6" md="6">
                <v-textarea
                  ref="delay_text_ref"
                  label="Delay text"
                  hint="Delay Text"
                  v-model="delay_text"
                  :rules="delayRules"
                  required
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="pa-4" elevation="2">
            <v-card-actions>
              <v-btn color="primary" @click="after_first_step">
                Save & Continue
              </v-btn>
              <v-btn text @click="cancel"> Cancel </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2">
          <!-- test to be added -->
          <v-card :height="height" class="pa-4" elevation="2">
            <v-row justify="center" dense v-if="trial_client == false">
              <v-col cols="12" sm="6" md="6">
                Use CGHS Rate Card
                <v-switch
                  v-model="cghs_price"
                  label="CGHS Rate Card"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row justify="center" dense>
              <v-col cols="12" sm="6" md="6">
                Click here to add tests
                <v-btn text @click="addTest">Add Tests</v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="pa-4" elevation="2">
            <v-card-actions>
              <v-btn color="primary" @click="addTest"> Add Tests </v-btn>
              <v-btn text @click="cancel"> Cancel </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="3">
          <!-- Total Amount and discount -->
          <v-card :height="450" class="px-2">
            <v-row dense> Bill Amount :{{ format(bill_amount) }} </v-row>
            <v-row dense v-if="credit_client">
              <v-chip color="primary" outlined x-small v-if="credit_client"
                >Credit Client</v-chip
              >
            </v-row>
            <v-row dense>
              <v-switch
                v-model="credit_client"
                label="Credit Client"
                @change="credit_change"
              ></v-switch>
            </v-row>
            <v-row dense>
              <v-text-field
                :disabled="credit_client"
                suffix="%"
                label="Discount Percentage"
                hint="Discount Percentage"
                v-model="discount"
                :rules="discountRules"
                required
                @change="discount_change"
                append-outer-icon="mdi-calculator-variant-outline"
                @click:append-outer="discount_change"
              ></v-text-field>
            </v-row>
            <v-row dense>
              <v-text-field
                :disabled="true"
                label="Amount After Discount"
                v-model="amount_after_discount"
                :rules="amountRules"
                prepend-inner-icon="mdi-currency-inr"
                readonly
              ></v-text-field>
            </v-row>
            <v-row dense>
              <v-select
                :disabled="check_credit_client"
                :items="['0', '25', '50', '100', '150', '200', '250']"
                v-model="hcc_item"
                label="Home Collection Charges"
                prepend-inner-icon="mdi-currency-inr"
                @change="discount_change"
              ></v-select>
            </v-row>
            <v-row dense>
              <v-select
                :disabled="check_credit_client"
                :items="['0', '10', '20', '30', '40', '50', '100']"
                v-model="dis_charges"
                label="Disposable Charges"
                prepend-inner-icon="mdi-currency-inr"
                @change="discount_change"
              ></v-select>
            </v-row>
            <v-row dense>
              <v-text-field
                :disabled="check_credit_client"
                label="Total"
                v-model="amount_received"
                :rules="amountRules"
                prepend-inner-icon="mdi-currency-inr"
                outlined
                readonly
              ></v-text-field>
            </v-row>
          </v-card>
          <v-card class="pa-1" elevation="2">
            <v-card-actions>
              <v-btn color="primary" @click="after_third_step">
                Save & Continue
              </v-btn>
              <v-btn text @click="cancel"> Cancel </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="4">
          <!-- OTP to be added -->
          <v-card :height="height" class="pa-4" elevation="2">
            <v-row justify="center" dense>
              <v-col cols="12" sm="6" md="6">
                OTP sent to client (Mob:{{ client_mobile }}). Please ask client
                to share
              </v-col>
            </v-row>
            <v-row justify="center" dense>
              <v-col cols="12" sm="6" md="6">
                If OTP not received in one min. please enter last 6 digits of
                your mobile.
              </v-col>
            </v-row>
            <v-row justify="center" dense>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  ref="otp_text_ref"
                  label="Enter OTP"
                  hint="Enter OTP"
                  v-model="entered_otp"
                  :rules="otpRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="pa-4" elevation="2">
            <v-card-actions>
              <v-btn color="primary" @click="after_fourth_step">
                Validate & Continue
              </v-btn>
              <v-btn text @click="cancel"> Cancel </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="5">
          <!-- prescription photo to be added -->
          <v-card :height="height" class="pa-4" elevation="2">
            <v-row justify="center" v-if="show_photo_error" dense>
              <v-col cols="12" sm="6" md="3">
                <v-alert type="warning">{{ photo_error_msg }}</v-alert>
              </v-col>
            </v-row>
            <v-row justify="center" dense>
              <v-col cols="12" sm="6" md="2">
                <v-switch
                  v-model="offline"
                  label="Upload Offline"
                  @change="offline_change"
                  dense
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="6" md="2" v-if="isMobile()">
                <v-btn color="primary" @click="take_photo"> Take Photo </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="2" v-else>
                Upload prescription photo
                <input
                  type="file"
                  id="upload_file"
                  accept="image/*"
                  @change="upload_photo"
                  capture="environment"
                />
              </v-col>
            </v-row>
            <v-row justify="center" dense>
              <v-col cols="12" sm="6" md="6">
                <v-chip
                  class="ma-1"
                  color="orange"
                  label
                  outlined
                  v-for="photo in uploaded_photos"
                  :key="photo"
                >
                  {{ photo }}
                </v-chip>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="pa-4" elevation="2">
            <v-card-actions>
              <v-btn
                :loading="photo_loading"
                color="primary"
                @click="validate_photo"
              >
                Save & Continue
              </v-btn>
              <v-btn text @click="cancel"> Cancel </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="6">
          <!-- Choose Payment Method -->
          <v-card :height="height" class="px-1" elevation="2">
            <v-row justify="center" dense>
              <v-col cols="12" sm="6" md="6">
                <v-alert type="info">Please Choose Cash or Gpay</v-alert>
              </v-col>
            </v-row>
            <v-row dense>
              <v-radio-group v-model="payment_method">
                <v-radio label="Cash" color="blue" value="cash"></v-radio>
                <v-radio label="GPay" color="orange" value="gpay"></v-radio>
                <v-radio
                  label="phonePay"
                  color="orange"
                  value="phonepay"
                  @click="handlePaymentMethod"
                ></v-radio>
              </v-radio-group>
            </v-row>
            <v-row dense v-if="payment_method == 'gpay'">
              <v-text-field
                ref="gpay_text_ref"
                label="GPay Ref Number"
                hint="GPay Ref Number"
                v-model="gpay_ref"
                :rules="gpayRules"
                required
              ></v-text-field>
            </v-row>
            <v-row dense>
              <v-textarea
                label="Remarks"
                hint="Remarks"
                v-model="remarks"
              ></v-textarea>
            </v-row>
          </v-card>
          <v-card class="px-1" elevation="2">
            <v-card-actions>
              <v-btn color="primary" @click="validate_payment"> Finish </v-btn>
              <v-btn text @click="cancel"> Cancel </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <!-- 
      payment work order  
    -->

    <v-container v-if="type === 'payment_work_order_dialog'">
      <payment
        :show="show_payment"
        :item="doc"
        @close_payment="close_payment"
      ></payment>
    </v-container>
    <!-- 
      Add test component
    -->

    <v-container v-if="add_test_comp">
      <add_test
        :show="show_add_test"
        :item="doc"
        :cghs="cghs_price"
        @close_add_test="close_test_screen"
      ></add_test>
    </v-container>

    <!-- 
      Message Dialog   
  -->
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>
<script>
import * as blobUtil from "blob-util";
import WorkOrderDB from "@/js/database/workorder_db";
import DBHandler from "@/js/database/DBHandler";
import Util from "@/js/Util";
import add_test from "@/components/my_work_order/add_test.vue";
import sms_template from "@/js/sms_templates";
import settings from "@/js/database/settings";
import com_center from "@/js/com_center";
import axios from "axios";
import payment from "@/components/work_order/payment_integration.vue";
export default {
  name: "HCProcess",
  components: { add_test, payment },
  data: function() {
    return {
      level: 1,
      discount: "0",
      discountRules: [
        (v) => !!v || "Percentage is required",
        (v) => (v && v < 51) || "Percentage cannot be greater than 50",
        (v) => (v && v > -1) || "Percentage must be greater than 0",
      ],
      client_detail: "",
      doc_id: "",
      delay_mins: "",
      delay_text: "",
      delayRules: [(v) => !!v || "Reason for delay is required"],
      otpRules: [
        (v) => !!v || "OTP is required",
        (v) => (v && v.length == 6) || "OTP must be in 6 digits.",
        // v => (v && v > 0 ) || 'OTP must be greater than 0',
      ],
      amountRules: [
        (v) => !!v || "Received amount is required",
        (v) =>
          (v && v < 100000) ||
          "Received amount cannot be greater than Rs.100000",
        (v) => (v && v > 0) || "Received amount must be greater than 0",
      ],
      gpayRules: [(v) => !!v || "GPay Ref Number is required"],
      entered_otp: "",
      show_photo_error: false,
      photo_error_msg: "Please upload a photo to continue.",
      add_test_comp: false,
      show_add_test: false,
      doc: null,
      proforma_inv_loc: "",
      otp: "",
      sms: 0,
      whatsapp: 0,
      email: 0,
      hcc_item: "50",
      dis_charges: "30",
      amount_after_discount: "",
      amount_received: "",
      bill_amount: "",
      client_mobile: "",
      tech_mobile: "",
      credit_client: false,
      trial_client: false,
      photo_loading: false,
      uploaded_photos: [],
      uploaded_photos_path: [],
      b2b_client: false,
      b2b_client_detail: "",
      snackbar: false,
      snackbar_text: "",
      cghs_price: false,
      payment_method: "cash",
      gpay_ref: "Later",
      remarks: "",
      offline: false,
      show_payment: false,
      type: "",
    };
  },
  computed: {
    height: function() {
      console.log("name:" + this.$vuetify.breakpoint.name);
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 300;
        case "sm":
          return 300;
        case "md":
          return 300;
        case "lg":
          return 270;
        case "xl":
          return 600;
      }
      return 400;
    },
    check_credit_client: function() {
      if (this.credit_client) {
        if (this.cghs_price) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    },
  },
  mounted: async function() {
    if (Util.isLoggedIn()) {
      this.doc_id = this.$route.params.id;
      Util.setSession("current_doc", null);
      Util.setSession("sugar_tests", "");
      await this.refresh_doc(this.doc_id);
      this.before_first_step();
      //this.b2b_client = true;
      //this.b2b_client_detail = 'b2b client name';
      console.log(this.doc_id);
    } else {
      location.reload;
    }
  },
  methods: {
    isMobile: function() {
      return false; //Util.isMobile();
    },
    format: function(money) {
      return Util.formatMoney(money);
    },
    handlePaymentMethod() {
      if (this.payment_method === "phonepay") {
        this.view_payment();
      } else {
        this.close_payment();
      }
    },
    view_payment: function() {
      this.type = "payment_work_order_dialog";
      this.show_payment = true;
      this.doc = this.get_doc();
    },
    close_payment: function() {
      this.show_payment = false;
      this.type = "";
      this.doc = null;
    },

    before_first_step: function() {
      let doc = this.get_doc();
      if (doc.proforma_inv_loc) {
        this.proforma_inv_loc = doc.proforma_inv_loc;
      }
      if (doc.otp) {
        this.otp = doc.otp;
      }
      if (doc.b2b_client_id) {
        this.b2b_client = true;
        this.b2b_client_detail = doc.b2b_client_name;
      }
      let credit = doc.credit;
      if (credit == 0) {
        this.credit_client = false;
        this.trial_client = false;
      } else if (credit == 1) {
        this.credit_client = true;
        this.trial_client = false;
      } else if (credit == 2) {
        this.credit_client = false;
        this.trial_client = true;
      }
      if (doc.total) {
        this.bill_amount = doc.total;
        var res = this.bill_amount;
        this.amount_received = res;
        this.discount_change();
      }
      if (doc.process) {
        console.log("process already set");
      } else {
        let process = {
          first_step: "",
          second_step: "",
          third_step: "",
          fourth_step: "",
          fifth_step: "",
          prescription_uploaded_at: "",
          proforma_uploaded_at: "",
        };
        doc.process = process;
      }
      this.client_mobile = doc.mobile;
      this.tech_mobile = Util.getFromSession("logged_in_mobile");
      this.client_detail =
        doc.name +
        " | (" +
        doc.age +
        "/" +
        doc.gender +
        ") | Mob:" +
        doc.mobile +
        " | " +
        doc.appointment_date +
        " " +
        doc.appointment_time;
      if (this.b2b_client) {
        this.b2b_client_detail = "B2B: " + doc.b2b_client_name;
      }
      let app_date = doc.appointment_date;
      let app_time = doc.appointment_time;
      let app_dt_time = app_date + " " + app_time;
      let diff = Util.getPassedMinutes(app_dt_time);
      console.log(diff);
      if (diff > -30) {
        this.delay_text = "On Time";
        this.after_first_step();
        return;
      }
      diff *= -1;
      let str = "";
      if (diff < 60) {
        str = diff + " minutes";
      } else {
        str = Util.timeConvert(diff);
      }
      this.delay_mins = str;
      this.$refs.delay_text_ref.focus();
    },
    after_first_step: function() {
      if (this.delay_mins) {
        let result = this.$refs.delay_text_ref.validate();
        if (result == false) {
          return;
        }
        if (this.delay_text.length < 5) {
          this.snackbar_text = "Please Give Proper Reason.";
          this.snackbar = true;
          return;
        }
      }

      // Get geolocation
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;

          // Call reverse geocoding API (example with OpenCage)
          let apiKey = "AIzaSyCmQTjA5QMVnSb7wglJ34hy_B8A9WGplUc"; // Replace with your OpenCage API key

          let apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${apiKey}`;
          fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
              let location = data.results[0].formatted_address; // Get formatted location from response
              let final_location = location.replace(/-/g, "_");
              // Proceed with the rest of the function after getting location
              let doc = this.get_doc();
              doc.process.first_step = this.delay_text;
              doc.status = "First Step";
              let workorder_started =
                Util.gettime() +
                "-" +
                Util.getFromSession("logged_in_emp_name") +
                "- Work Order Started";

              // Adding the location to work order
              let workorder_started_location =
                Util.gettime() +
                "-" +
                Util.getFromSession("logged_in_emp_name") +
                "- WorkOrder Started Location: " +
                final_location +
                " (" +
                lat +
                ", " +
                lon +
                ")";
              doc.location = location;
              if (doc.time_line) {
                doc.time_line.push(workorder_started);
                doc.time_line.push(workorder_started_location);
              }

              console.log(">>>>updating first step with location");
              this.set_doc(doc);
              this.before_second_step();
            })
            .catch((error) => {
              console.error("Error fetching reverse geocoding data: ", error);
              this.snackbar_text = "Error fetching location data.";
              this.snackbar = true;
            });
        },
        (error) => {
          console.error("Error getting geolocation: ", error);
          this.snackbar_text = "Unable to retrieve location.";
          this.snackbar = true;
        }
      );
    },
    before_second_step: function() {
     // this.level = 2;
      this.level = 6;
    },
    after_second_step: function() {
      let doc = this.get_doc();
      doc.process.second_step = this.proforma_inv_loc;
      doc.status = "Second Step";
      this.bill_amount = doc.total;
      this.discount_change();
      console.log(">>>>updating second step");
      this.set_doc(doc);
      this.before_third_step();
    },
    before_third_step: function() {
      let doc = this.get_doc();
      if (this.b2b_client) {
        doc.amount_received = 0;
        doc.discount = 0;
        doc.process.third_step =
          "Rs. " + this.bill_amount + " Not Received For B2B Client";
        doc.process.fourth_step = "OTP Not Needed For B2B Client";
        this.level = 5;
        doc.status = "Fifth Step";
        this.set_doc(doc);
      } else if (this.trial_client) {
        doc.amount_received = 0;
        doc.discount = 0;
        doc.process.third_step =
          "Rs. " + this.bill_amount + " Not Received For Trial Client";
        doc.process.fourth_step = "OTP Not Needed For Trial Client";
        this.level = 5;
        doc.status = "Fifth Step";
        this.set_doc(doc);
      } else if (this.credit_client) {
        if (this.cghs_price) {
          this.level = 3;
          doc.cghs = 1;
          doc.status = "Third Step";
          this.set_doc(doc);
        } else {
          doc.amount_received = 0;
          doc.discount = 0;
          doc.process.third_step =
            "Rs. " + this.bill_amount + " Not Received For Credit Client";
          doc.process.fourth_step = "OTP Not Needed For Credit Client";
          this.level = 5;
          doc.status = "Fifth Step";
          this.set_doc(doc);
        }
      } else {
        this.level = 3;
      }
    },
    after_third_step: async function() {
      let doc = this.get_doc();
      if (this.credit_client) {
        if (this.cghs_price) {
          doc.credit = 1;
          doc.cghs = 1;
          doc.amount_received = this.amount_received;
          doc.discount = 0;
          doc.process.third_step =
            "Rs. " +
            this.bill_amount +
            " Not Received For CGHS Credit Client. Only HC Charges Rs." +
            this.amount_received +
            " Received For CGHS Credit Client.";
          doc.process.fourth_step = "OTP Not Needed For CGHS Credit Client";
          doc.status = "Fifth Step";
          this.set_doc(doc);
          this.level = 5;
          return;
        } else {
          doc.credit = 1;
          doc.amount_received = 0;
          doc.discount = 0;
          doc.process.third_step =
            "Rs. " + this.bill_amount + " Not Received For Credit Client";
          doc.process.fourth_step = "OTP Not Needed For Credit Client";
          doc.status = "Fifth Step";
          this.set_doc(doc);
          this.level = 5;
          return;
        }
      }
      doc.status = "Third Step";
      doc.process.third_step =
        "Rs. " +
        this.bill_amount +
        ". Received Rs. " +
        this.amount_received +
        " with " +
        this.discount +
        "% discount.";
      doc.amount_received = this.amount_received;
      doc.discount = this.discount;
      doc.hc_charges = this.hcc_item;
      doc.disposable_charges = this.dis_charges;
      if (doc.settings) {
        if (doc.settings.send_sms) {
          this.sms = 1;
        }
        if (doc.settings.send_whatsapp) {
          this.whatsapp = 1;
        }
        if (doc.settings.send_email) {
          this.email = 1;
        }
        if (
          doc.settings.send_sms ||
          doc.settings.send_whatsapp ||
          doc.settings.send_email
        ) {
          this.otp = Util.generateOTP();
          await this.send_otp_msg(doc, this.proforma_inv_loc, this.otp);
          this.before_fourth_step();
        } else {
          this.before_fifth_step();
        }
      }
      this.set_doc(doc);
      console.log(JSON.stringify(doc));
      this.update_doc();
    },
    before_fourth_step: function() {
      let doc = this.get_doc();
      doc.process.fourth_step = this.otp;
      this.level = 4;
      this.set_doc(doc);
    },
    after_fourth_step: function() {
      let doc = this.get_doc();
      doc.status = "Fourth Step";
      this.$refs.otp_text_ref.focus();
      let result = this.$refs.otp_text_ref.validate();
      if (result) {
        if (this.entered_otp == this.otp) {
          this.snackbar_text = "OTP matched";
          this.snackbar = true;
          doc.process.fourth_step = this.otp + " OTP matched";
          this.set_doc(doc);
          this.before_fifth_step();
          return;
        }
        let mTech = this.tech_mobile + "";
        mTech = mTech.substring(4);
        if (this.entered_otp == mTech) {
          this.snackbar_text = "Number matched";
          this.snackbar = true;
          doc.process.fourth_step = this.otp + " Number matched";
          this.set_doc(doc);
          this.before_fifth_step();
          return;
        }
      }
      this.snackbar_text = "OTP Not Matching.";
      this.snackbar = true;
    },
    before_fifth_step: function() {
      this.level = 5;
    },
    after_fifth_step: async function() {
      if (this.credit_client) {
        if (this.cghs_price == false) {
          this.finish_steps("credit");
          return;
        }
      } else if (this.trial_client) {
        this.finish_steps("trial");
        return;
      } else if (this.b2b_client) {
        this.finish_steps("b2b");
        return;
      }
      this.level = 6;
    },
    async checkPaymentStatus(pro_id) {
      const axios = require("axios");
      let data = JSON.stringify({
        mid: "24",
        auth_user: "andersondiagnostics_test",
        auth_key: "n0tKMr09pVykMbb7k4ua9cJYxQ2CjXMg6p0Rq35UVTK7nMamea",
        processing_id: pro_id,
      });

      let config = {
        method: "post",
        url: "https://testing.momentpay.in/ma/payment/api/v1/status",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      try {
        const response = await axios.request(config);
        const responseData = response.data.response_token;
        console.log(JSON.stringify(responseData));

        // Return response data to be used in finish_steps
        return {
          transaction_id: responseData.transaction_id,
          response_message: responseData.response_message,
          response_code: responseData.response_code,
        };
      } catch (error) {
        console.log("Error in checkPaymentStatus:", error);
        return null;
      }
    },
   finish_steps: async function(mode) {
  let doc = this.get_doc();
  doc.status = "Finished";
  doc.server_status = "Not Received";
  doc.process.fifth_step = this.uploaded_photos_path.toString();

  if (mode == "credit") {
    doc.payment_method = "credit";
    doc.gpay_ref = "";
  } else if (mode == "trial") {
    doc.payment_method = "trial";
    doc.gpay_ref = "";
  } else if (mode == "b2b") {
    doc.payment_method = "b2b";
    doc.gpay_ref = "";
  } else {
    doc.payment_method = this.payment_method;
    if (this.payment_method === "gpay") {
      doc.gpay_ref = this.gpay_ref;
    } else if (this.payment_method === "phonepay") {
      let pro_id = doc._id.slice(-6);
      const paymentStatus = await this.checkPaymentStatus(pro_id);

      if (paymentStatus) {
        // Save values to doc
        doc.transaction_id = paymentStatus.transaction_id;
        doc.response_message = paymentStatus.response_message;
        doc.response_code = paymentStatus.response_code;
       doc.process.sixth_step =  paymentStatus.transaction_id ;
       doc.process.seventh_step =  paymentStatus.response_message ;
        console.log("Payment status saved:", {
          transaction_id: doc.transaction_id,
          response_message: doc.response_message,
          response_code: doc.response_code
        });
      }
    } else {
      doc.gpay_ref = "";
    }
  }

  doc.remarks = this.remarks;
  let workorder_completed =
    Util.gettime() +
    "-" +
    Util.getFromSession("logged_in_emp_name") +
    "- Work Order Completed";
  if (doc.time_line) {
    doc.time_line.push(workorder_completed);
  }

  this.snackbar_text = "Completed Successfully.";
  this.snackbar = true;
  await this.send_notification(doc);
  doc.updated_at = Util.getTimeStamp();
  
  // Set the updated doc before saving to ensure all fields are current
  this.set_doc(doc);

  let db = await DBHandler.getDb("work_orders");

  // Update the document in the database with error handling
  try {
    const old_doc = await db.get(doc._id);
    doc._rev = old_doc._rev;

    const response = await db.put(doc);
    if (response.ok) {
      console.log("Document saved successfully:", response);
      console.log("Transaction data in doc:", {
        transaction_id: doc.transaction_id,
        response_message: doc.response_message,
        response_code: doc.response_code,
      });
      this.check_sugar_tests();
      this.cancel();
    }
  } catch (err) {
    console.error("Error saving document:", err);
  }
},

    check_sugar_tests: function() {
      try {
        let selected_str = Util.getFromLocalStorage("selected_tests");
        let selected_array = JSON.parse(selected_str);
        if (selected_array.length > 0) {
          for (let i = 0; i < selected_array.length; i++) {
            let test = selected_array[i];
            let invest_id = test.invest_id;
            console.log("test:" + invest_id);
            if (invest_id == 177) {
              Util.setSession("sugar_tests", this.doc_id);
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
      Util.setLocalStorage("selected_tests", "");
    },
    close_test_screen: function(e) {
      if (typeof e === "string" || e instanceof String) {
        this.proforma_inv_loc = e;
        this.snackbar_text = "Updated Successfully.";
        this.snackbar = true;
        this.after_second_step();
      } else {
        this.add_test_comp = false;
        this.show_add_test = false;
        this.doc = null;
      }
    },
    discount_change: function() {
      let res = this.bill_amount;
      let iDiscount = parseInt(this.discount);
      let iMoney = parseInt(res);
      if (iDiscount > 0) {
        iMoney = iMoney - (iMoney * iDiscount) / 100;
      }
      if (this.credit_client && this.cghs_price) {
        this.amount_after_discount = "0";
        let total_received =
          parseInt(this.dis_charges) + parseInt(this.hcc_item);
        this.amount_received = Math.round(total_received) + "";
      } else {
        this.amount_after_discount = Math.round(iMoney) + "";
        let total_received =
          iMoney + parseInt(this.dis_charges) + parseInt(this.hcc_item);
        this.amount_received = Math.round(total_received) + "";
      }
    },
    cancel: function() {
      this.$router.push("/my_work_orders");
    },
    refresh_doc: async function(id) {
      let doc = await WorkOrderDB.getWithId(id);
      console.log("in refresh:" + doc);
      this.set_doc(doc);
    },
    addTest: function() {
      this.doc = this.get_doc();
      this.add_test_comp = true;
      this.show_add_test = true;
    },
    get_doc: function() {
      let doc = Util.getFromSession("current_doc");
      return JSON.parse(doc);
    },
    set_doc: function(doc) {
      Util.setSession("current_doc", JSON.stringify(doc));
    },
    update_doc: async function() {
      let doc = this.get_doc();
      doc.updated_at = Util.getTimeStamp();
      let db = await DBHandler.getDb("work_orders");
      db.get(doc._id).then(function(old_doc) {
        doc._rev = old_doc._rev;
        db.put(doc)
          .then(function(response) {
            if (response.ok) {
              console.log(response);
            }
          })
          .catch(function(err) {
            console.log(err);
          });
      });
    },
    validate_payment: function() {
      if (this.payment_method == "gpay") {
        this.$refs.gpay_text_ref.focus();
        let result = this.$refs.gpay_text_ref.validate();
        if (!result) {
          this.snackbar_text = "Please Give GPay Ref. Number.";
          this.snackbar = true;
          return;
        }
      }
      this.finish_steps();
    },
    take_photo: function() {
      let Camera = window.navigator.camera;
      Camera.getPicture(this.photoOnSuccess, this.photoOnFail, {
        quality: 50,
        destinationType: Camera.DestinationType.FILE_URI,
      });
    },
    photoOnSuccess: function(imageURI) {
      this.photo_error_msg = imageURI;
      this.show_photo_error = true;
    },
    photoOnFail: function(msg) {
      this.photo_error_msg = msg;
      this.show_photo_error = true;
    },
    validate_photo: function() {
      if (this.uploaded_photos_path.length > 0) {
        this.after_fifth_step();
      } else {
        this.photo_error_msg = "Please upload a photo to continue.";
        this.show_photo_error = true;
      }
    },
    upload_photo: async function() {
      if (this.offline) {
        await this.upload_photo_offline();
      } else {
        await this.upload_photo_online();
      }
    },
    upload_photo_online: async function() {
      try {
        this.show_photo_error = false;
        let upload = document.querySelector("#upload_file");
        if (upload.files[0]) {
          let file_name = upload.files[0].name;
          let flocation =
            "homecollection/prescriptions/" +
            Util.getTodayStringForFolderCreation() +
            "/" +
            file_name;
          let jwt_token = Util.getFromSession("pg_admin");

          let formData = new FormData();
          formData.append("upload_file", upload.files[0]);
          formData.append("key", flocation);
          formData.append("bucket_name", "homecollection");
          formData.append("jwt_token", jwt_token);
          this.photo_loading = true;
          upload.disabled = true;
          let instance = this;
          await axios
            .post(settings.node_url + "/s3/upload_file_v3", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(function(response) {
              console.log(response.data);
              instance.update_doc_file_upload(function(result) {
                if (result == "OK") {
                  instance.photo_loading = false;
                  upload.disabled = false;
                  instance.uploaded_photos.push(file_name);
                  instance.uploaded_photos_path.push(flocation);
                } else {
                  instance.photo_error_msg = result + "";
                  instance.show_photo_error = true;
                  instance.photo_loading = false;
                  upload.disabled = false;
                }
              });
            })
            .catch(function(error) {
              console.log("error:" + error);
              instance.photo_error_msg = error + "";
              instance.show_photo_error = true;
              instance.photo_loading = false;
              upload.disabled = false;
            });
          console.log(this.uploaded_photos.toString());
        } else {
          this.show_photo_error = true;
        }
      } catch (e) {
        console.log("photo error " + e);
        this.show_photo_error = true;
      }
    },
    update_doc_file_upload: async function(fn) {
      let doc = this.get_doc();
      doc.process.fifth_step = this.uploaded_photos_path.toString();
      doc.status = "Fifth Step";
      doc.process.prescription_uploaded_at = Util.getTodayWithTime();
      doc.updated_at = Util.getTimeStamp();
      this.set_doc(doc);
      fn("OK");
    },
    send_notification: async function(doc) {
      let msg_header =
        "Completed Collection " +
        doc.appointment_date +
        " " +
        doc.appointment_time +
        ".";
      let msg_body =
        "Completed home collection for " +
        doc.name +
        "(" +
        doc.age +
        "/" +
        doc.gender +
        ") ";
      msg_body +=
        "address:" +
        doc.address +
        " mobile:" +
        doc.mobile +
        " pincode:" +
        doc.pincode;
      msg_body += " " + doc.free_text;
      let notifications = {
        _id: "notifications:" + Util.getDateForId() + ":" + Util.uuidv4(),
        from_id: Util.getFromSession("logged_in_emp_id"),
        from_name: Util.getFromSession("logged_in_emp_name"),
        to_id: doc.manager_id,
        to_name: doc.manager_name,
        msg_header: msg_header,
        msg_body: msg_body,
        msg_attachment: {},
        status: "New",
        msg_time: Util.getTodayWithTime(),
        updated_at: Util.getTimeStamp(),
      };
      let notification_db = await DBHandler.getDb("notifications");
      notification_db
        .put(notifications)
        .then(function(response) {
          console.log(response);
          if (response.ok) {
            console.log(response);
          }
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    send_otp_msg: async function(doc, flocation, otp) {
      let id_part = Util.getRandomString(5);
      let msg_url = settings.msg_url + id_part;

      let message = {
        center_id: Util.getFromSession("logged_in_tenant_id"),
        center_name: Util.getFromSession("logged_in_tenant_name"),
        department_id: Util.getFromSession("department_id"),
        department_name: Util.getFromSession("department_name"),
        role_id: Util.getFromSession("role_id"),
        role_name: Util.getFromSession("role_name"),
        emp_id: Util.getFromSession("logged_in_emp_id"),
        emp_name: Util.getFromSession("logged_in_emp_name"),
        recipient_mobile: doc.mobile,
        recipient_name: doc.name,
        status: "0",
        s3_loc: flocation,
        msg_time: Util.getTodayWithTime(),
        updated_at: Util.getTimeStamp(),
      };
      if (this.sms) {
        let sms_msg = sms_template.work_order_patient_otp(otp, msg_url);
        message._id = "sms_center:" + id_part + ":" + Util.uuidv4();
        message.message = sms_msg;
        let result = await com_center.send_msg(message);
        if (result == "OK") {
          this.snackbar_text = "SMS Sent Successfully.";
          this.snackbar = true;
        } else {
          this.snackbar_text = result;
          this.snackbar = true;
        }
      }
      if (this.whatsapp) {
        let whatsapp_msg = [msg_url, otp];
        message._id = "whatsapp_center:" + id_part + ":" + Util.uuidv4();
        message.message = whatsapp_msg;
        message.template = "hc_proforma_invoice";
        let result = await com_center.send_msg(message);
        if (result == "OK") {
          this.snackbar_text = "WhatsApp Msg. Sent Successfully.";
          this.snackbar = true;
        } else {
          this.snackbar_text = result;
          this.snackbar = true;
        }
      }
      if (this.email) {
        message._id = "email_center:" + id_part + ":" + Util.uuidv4();
        let result = await com_center.send_msg(message);
        if (result == "OK") {
          this.snackbar_text = "Email Msg. Sent Successfully.";
          this.snackbar = true;
        } else {
          this.snackbar_text = result;
          this.snackbar = true;
        }
      }
    },
    credit_change: function() {
      if (this.credit_client) {
        if (this.cghs_price) {
          this.discount = "0";
          this.amount_after_discount = "";
          this.hcc_item = "50";
          this.dis_charges = "30";
          this.amount_received = "80";
        } else {
          this.discount = "0";
          this.amount_after_discount = "";
          this.hcc_item = "50";
          this.dis_charges = "30";
          this.amount_received = "0";
        }
      }
      this.discount_change();
    },
    getClientStatus: function() {
      let item = this.get_doc();
      let ret = "";
      if (item.urgent) {
        ret += "Urgent ";
      }
      if (item.vip_client) {
        ret += "VIP ";
      }
      if (item.credit == 1) {
        ret += "Credit ";
      } else if (item.credit == 2) {
        ret += "Trial ";
      }
      if (item.b2b_client_id) {
        ret += "B2B";
      }
      return ret;
    },
    offline_change: function() {
      this.uploaded_photos = [];
      this.uploaded_photos_path = [];
    },
    upload_photo_offline: async function() {
      let this_doc_id = this.doc_id;
      let upload = document.querySelector("#upload_file");
      if (upload.files[0]) {
        let file = upload.files[0];
        let file_name = file.name;
        let img_src = URL.createObjectURL(file);
        let temp_doc_id = "temp_db:" + Util.uuidv4();
        let flocation =
          "homecollection/prescriptions/" +
          Util.getTodayStringForFolderCreation() +
          "/" +
          file_name;
        console.log(file_name);
        console.log(flocation);
        let db = await DBHandler.getDb("temp_db");
        this.photo_loading = true;
        upload.disabled = true;
        let instance = this;
        let temp_doc = await blobUtil
          .imgSrcToBlob(img_src)
          .then(function(blob) {
            console.log(blob.type);
            let file_type = blob.type;
            let doc = {
              _id: temp_doc_id,
              for_doc: this_doc_id,
              file_name: file_name,
              msg_time: Util.getTodayWithTime(),
              file_location: flocation,
              _attachments: {
                attach_file: {
                  content_type: file_type,
                  data: blob,
                },
              },
            };
            return doc;
          })
          .catch(function(err) {
            console.log(err);
            return "Error:" + err;
          });
        if (temp_doc._id) {
          let result = await db
            .put(temp_doc)
            .then(function(response) {
              console.log(response);
              if (response.ok) {
                instance.photo_loading = false;
                upload.disabled = false;
                instance.uploaded_photos.push(file_name);
                instance.uploaded_photos_path.push(flocation);
              } else {
                instance.photo_error_msg = result + "";
                instance.show_photo_error = true;
                instance.photo_loading = false;
                upload.disabled = false;
              }
            })
            .catch(function(error) {
              console.log("error:" + error);
              instance.photo_error_msg = error + "";
              instance.show_photo_error = true;
              instance.photo_loading = false;
              upload.disabled = false;
            });
        }
        return "OK";
      }
    },
  },
};
</script>
