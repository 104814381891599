var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"6","sm":"2"}},[(_vm.monthwise)?_c('v-select',{attrs:{"dense":"","items":_vm.years,"label":"Choose Year","solo":""},on:{"change":_vm.year_change},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"6","sm":"2"}},[_c('v-switch',{attrs:{"flat":"","label":"Monthwise"},on:{"change":_vm.switch_change},model:{value:(_vm.monthwise),callback:function ($$v) {_vm.monthwise=$$v},expression:"monthwise"}})],1)],1)],1),_c('v-container',[_c('v-card',[_c('v-toolbar',{attrs:{"color":"blue lighter-1","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Yearly Report "+_vm._s(_vm.year))])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.tableData,"item-key":"_id","loading":_vm.loading,"items-per-page":15,"loading-text":"Loading... Please wait","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.collection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format(item.collection))+" ")]}},{key:"item.credit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format(item.credit))+" ")]}},{key:"item.b2b",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format(item.b2b))+" ")]}},{key:"item.trial",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format(item.trial))+" ")]}},{key:"item.received",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format(item.received))+" ")]}}])})],1)],1),_c('v-container',[_c('v-card',[_c('v-toolbar',{attrs:{"color":"blue lighter-1","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Yearly Cases Report "+_vm._s(_vm.year))])],1),_c('BarChart',{key:_vm.reload,attrs:{"item":_vm.yearly_report_chart_data}})],1)],1),_c('v-container',[_c('v-card',[_c('v-toolbar',{attrs:{"color":"blue lighter-1","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Yearly Collection Report "+_vm._s(_vm.year))])],1),_c('BarChart',{key:_vm.reload2,attrs:{"item":_vm.yearly_collection_chart_data}})],1)],1),_c('v-snackbar',{attrs:{"timeout":2000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }