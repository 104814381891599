import PouchDB from "pouchdb-browser";
//import axios from "axios";
import WorkOrderDB from "@/js/database/workorder_db";
import NotificationDB from "@/js/database/notification_db";
import store from "@/store";
import settings from "@/js/database/settings";
import Util from "@/js/Util";
// import crypto from 'crypto-pouch';

let DBHandler = {
  DbMap: new Map(),
  creating: false,
  Token: "",
  db_names: null,
  init: async function () {
    if (DBHandler.creating) {
      return;
    }
    DBHandler.creating = true;
    console.log("DB init here....");
    DBHandler.db_names = ["last_updated_at", "price_list", "com_center", "temp_db", "login_log", "dashboard"];
    let center_based_db = Util.getFromSession("doc_dbs");
    let del_doc_dbs = Util.getFromSession("del_doc_dbs");
    if (del_doc_dbs) {
      let del_dbs = del_doc_dbs.split(",");
      for (let i = 0; i < del_dbs.length; i++) {
        DBHandler.del_db(del_dbs[i]);
      }
    }
    let db_array = [];
    if (center_based_db) {
      db_array = center_based_db.split(",");
    }
    DBHandler.db_names = DBHandler.db_names.concat(db_array);
    let initPromise = new Promise((resolve, reject) => {
      DBHandler.getToken()
        .then(async function (token) {
          console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>> got token");
          await DBHandler.set_up(token);
          DBHandler.creating = false;
          resolve("OK");
        })
        .catch(function (err) {
          console.log("Init Error: " + err);
          DBHandler.creating = false;
          reject("Error:" + err);
        });
    });
    return initPromise;
  },
  resolve_name: function (name) {
    if (DBHandler.db_names) {
      for (let i = 0; i < DBHandler.db_names.length; i++) {
        let db_name = DBHandler.db_names[i] + "";
        if (db_name.endsWith(name)) {
          return db_name;
        }
      }
    }
    console.log("Db name not found:" + name);
    return null;
  },
  getDbCount: function () {
    try {
      return DBHandler.DBMap.size();
    } catch (e) {
      console.log("get db count error");
    }
    return 0;
  },
  getDb: async function (name) {
    let db_name = DBHandler.resolve_name(name);
    console.log("DBMap checking " + db_name);
    try {
      if (db_name) {
        if (DBHandler.DBMap.has(db_name)) {
          return DBHandler.DBMap.get(db_name);
        }
      }
    } catch (err) {
      console.log(err);
    }
    let result = await DBHandler.set_up_db_with_time();
    if (result == "OK") {
      try {
        if (db_name) {
          if (DBHandler.DBMap.has(db_name)) {
            return DBHandler.DBMap.get(db_name);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  },
  set_up_db_with_time: async function () {
    let promise = new Promise((resolve, reject) => {
      DBHandler.init()
        .then(async function (value) {
          console.log(value);
          let timeDb = DBHandler.DBMap.get("last_updated_at");
          timeDb
            .get("last:updated_time")
            .then(function (doc) {
              console.log("updating time here......" + doc.time);
              let to_day = store.getters.getToday;
              to_day = doc.time;
              store.commit("setToday", to_day);
              resolve("OK");
            })
            .catch(function (err) {
              console.log(err);
              reject("ERROR" + err);
            });
        })
        .catch((err) => {
          console.log(err);
          reject("ERROR" + err);
        });
    });
    return promise;
  },
  set_up: async function (token) {
    try {
      if (DBHandler.DBMap) {
        for (let i = 0; i < DBHandler.db_names.length; i++) {
          let db_name = DBHandler.db_names[i];
          let db = DBHandler.DBMap.get(db_name);
          await db.close();
        }
      }
    } catch (e) {
      console.log("In setup while closing db:" + e);
    }
    DBHandler.DBMap = new Map();
    for (let i = 0; i < DBHandler.db_names.length; i++) {
      let db_name = DBHandler.db_names[i];
      // if((Util.getFromSession('department_name') == 'HOME COLLECTION') && (Util.getFromSession('role_name') == 'TECHNICIAN')){
      //   if(db_name == 'pin_codes'){
      //     continue;
      //   }
      // }
      let db = await DBHandler.create(token, db_name);
      DBHandler.DBMap.set(db_name, db);
    }
  },
  del_db: async function (db_name) {
    new PouchDB(db_name)
      .destroy()
      .then(function () {
        console.log(db_name + " destroyed.");
      })
      .catch(function (err) {
        console.log(db_name + " not found. " + err);
      });
  },
  retry_setup: async function (db_name) {
    try {
      let db = DBHandler.DBMap.get(db_name);
      await db.close();
    } catch (e) {
      console.log("In retry setup while closing db:" + e);
    }
    let db = await DBHandler.create(DBHandler.Token, db_name);
    DBHandler.DBMap.set(db_name, db);
  },
  create: async function (token, db_name) {
    //PouchDB.plugin(crypto);
    var localdb = new PouchDB(db_name, { auto_compaction: true, size: 1000 });
    localdb.setMaxListeners(50);
    //localdb.crypto('lifeisgood');
    let remoteUrl = settings.remote_couch_url + "/" + db_name;

    const remoteOptions = {
      fetch: function (url, opts) {
        opts.headers.set("Authorization", "Bearer " + token);
        return PouchDB.fetch(url, opts);
      },
    };
    const syncOptions = {
      live: true,
      retry: true,
      continuous: true,
    };
    const remoteDb = new PouchDB(remoteUrl, remoteOptions);
    if (db_name == "temp_db" || db_name == "login_log" || db_name == "com_center") {
      localdb.replicate.to(remoteDb, syncOptions).catch(function () {
        console.log("Promise Rejected While Sync..." + db_name);
        DBHandler.retry_setup(db_name);
      });
    } else if (db_name == "pin_codes" || db_name == "last_updated_at") {
      localdb.replicate.from(remoteDb, syncOptions).catch(function () {
        console.log("Promise Rejected While Sync..." + db_name);
        DBHandler.retry_setup(db_name);
      });
    } else {
      if (db_name.indexOf("work_orders") > -1) {
        if (Util.getFromSession("department_name") == "HOME COLLECTION" && Util.getFromSession("role_name") == "TECHNICIAN") {
          let emp_id = parseInt(Util.getFromSession("logged_in_emp_id"));
          const technicianSyncOptions = {
            live: true,
            retry: true,
            continuous: true,
            filter: function (doc) {
              return doc.assigned_id == emp_id;
            },
          };
          localdb.sync(remoteDb, technicianSyncOptions).catch(function () {
            console.log("Promise Rejected While Sync..." + db_name);
            DBHandler.retry_setup(db_name);
          });
        } else {
          localdb.sync(remoteDb, syncOptions).catch(function () {
            console.log("Promise Rejected While Sync..." + db_name);
            DBHandler.retry_setup(db_name);
          });
        }
      } else if (db_name.indexOf("price_list") > -1) {
        if (Util.getFromSession("role_name") == "MANAGER") {
          localdb.sync(remoteDb, syncOptions).catch(function () {
            console.log("Promise Rejected While Sync..." + db_name);
            DBHandler.retry_setup(db_name);
          });
        } else {
          localdb.replicate.from(remoteDb, syncOptions).catch(function () {
            console.log("Promise Rejected While Sync..." + db_name);
            DBHandler.retry_setup(db_name);
          });
        }
      } else if (db_name.indexOf("hc_notifications") > -1) {
        //if((Util.getFromSession('department_name') == 'HOME COLLECTION') && (Util.getFromSession('role_name') == 'TECHNICIAN')){
        let emp_id = parseInt(Util.getFromSession("logged_in_emp_id"));
        const technicianSyncOptions = {
          live: true,
          retry: true,
          continuous: true,
          filter: function (doc) {
            return doc.to_id == emp_id;
          },
        };
        localdb.sync(remoteDb, technicianSyncOptions).catch(function () {
          console.log("Promise Rejected While Sync..." + db_name);
          DBHandler.retry_setup(db_name);
        });
        // } else {
        //     localdb.sync(remoteDb, syncOptions).catch(function() {
        //     console.log("Promise Rejected While Sync..."+db_name);
        //     DBHandler.retry_setup(db_name);
        //   });
        // }
      } else {
        localdb.sync(remoteDb, syncOptions).catch(function () {
          console.log("Promise Rejected While Sync..." + db_name);
          DBHandler.retry_setup(db_name);
        });
      }
    }
    localdb
      .changes({
        since: "now",
        retry: true,
        live: true,
        include_docs: true,
      })
      .on("change", function (change) {
        // change.id contains the doc id, change.doc contains the doc
        // document was added/modified/deleted
        let doc_id = change.doc._id + "";
        // let doc = change.doc;
        //console.log(doc_id);
        if (doc_id.startsWith("work_order:")) {
          WorkOrderDB.handle_changes();
        } else if (doc_id.startsWith("last:updated_time")) {
          store.commit("setToday", change.doc.time);
        } else if (doc_id.startsWith("notifications:")) {
          NotificationDB.handle_changes();
        } else {
          return;
        }
        console.log("database updated...");
      })
      .on("paused", function () {
        console.log("Database updation complete......");
      })
      .on("error", function (err) {
        console.log(err);
        // handle errors
      });
    console.log("returning db for:" + db_name);
    return localdb;
  },
  getToken: async function () {
    if (DBHandler.Token) {
      return DBHandler.Token;
    } else {
      DBHandler.refreshToken();
      return DBHandler.Token;
    }
  },
  refreshToken: function () {
    try {
      console.log("refereshing token");
      DBHandler.Token = Util.getFromSession("pg_admin");
      // let jwt_token = Util.getFromSession('pg_admin');
      // let data = {
      //   jwt_token: jwt_token
      // }
      // const tokenPromise = new Promise((resolve, reject) => {
      //   axios({
      //       method: "post",
      //       responseType: "text",
      //       url: settings.node_url+ "/token",
      //       data: data
      //     })
      //     .then(function(response) {
      //       console.log(response.data.access_token);
      //       let token = response.data.access_token;
      //       DBHandler.Token = token;
      //       resolve(response.data.access_token);
      //     })
      //     .catch(function(error) {
      //       console.log('error:'+error);
      //       reject('ERROR:'+error);
      //     });
      // });
      // return tokenPromise;
    } catch (e) {
      console.log(e);
    }
  },
};
export default DBHandler;
