<template>
  <div>
    <h1>Payment Status</h1>

    <div v-if="responseData">
      <p><strong>Status Code:</strong> {{ responseData.response_code }}</p>
      <p><strong>Status Message:</strong> {{ responseData.response_message }}</p>
      <p><strong>Transaction ID:</strong> {{ responseData.transaction_id }}</p>
      <p><strong>Amount:</strong> {{ responseData.amount }}</p>
      <p><strong>Processing ID:</strong> {{ responseData.processing_id }}</p>
      <p><strong>Response URL:</strong> {{ responseData.response_url  }}</p>
    </div>
    <div v-else>
      <p>Loading payment response...</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      responseData: null, // Initialize responseData as null
    };
  },
  mounted() {
    this.responseData = this.parseResponseData();
  },
  methods: {
    parseResponseData() {
      const responseData = this.$route.query.response_token;
      if (!responseData) {
        console.error("Response token is missing");
        this.$set(this, "responseData", {
          error: "No response token provided.",
        });
        return;
      }
      try {
        return JSON.parse(responseData);
      } catch (error) {
        console.error("Error parsing response token:", error);
        this.$set(this, "responseData", {
          error: "Failed to parse the response token.",
        });
        return;
      }
    },
  },
};
</script>
