<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar color="white">
            <v-toolbar-title
              ><v-chip color="orange" text-color="white" label
                ><h3>Canceled Work Order</h3></v-chip
              ></v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar>
          <v-row justify="center">
            <v-col cols="12" sm="6" md="3">
              <v-select
                :items="suitable_dates"
                v-model="choosen_date"
                label="Choose Date"
                @change="date_change"
              ></v-select>
            </v-col>
          </v-row>
          <v-container>
            <v-data-table
              :headers="tableHeaders"
              :items="tableData"
              :items-per-page="100"
              item-key="_id"
              :loading="loading"
              loading-text="Loading... Please wait"
              class="elevation-1"
              dense
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:item.serialnumber="{ item, index }">
                {{ index + 1 }}
              </template>
              <!-- eslint-disable-next-line -->
    <template v-slot:item.status="{ item }" >
      <v-chip
        :color="getColor(item.status)"
        outlined
        x-small
      >
        {{ item.status }}
      </v-chip>
    </template>
            </v-data-table>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import WorkOrderDB from "@/js/database/workorder_db";
import Util from "@/js/Util";
import moment from "moment";
export default {
  props: ["show"],
  data() {
    return {
      dialog: this.show,
      tableHeaders: [
        { text: "S No", value: "serialnumber" },
        { text: "Name", align: "start", sortable: true, value: "name" },
        { text: "Gender", value: "gender" },
        { text: "Age", value: "age" },
        { text: "Mobile", value: "mobile" },
        { text: "Date", value: "appointment_date" },
        { text: "Time", value: "appointment_time" },
        { text: "Tech. Status", value: "status" },
        { text: "Assigned To", value: "assigned_to" },
        { text: "", value: "data-table-expand" },
      ],
      tableData: [],
      techData: [],
      suitable_dates: [],
      choosen_date: "",
      loading: false,
    };
  },
  mounted: function () {
    this.suitable_dates.push(Util.getWeekDay(1));
    let to_day = Util.getWeekDay(0);
    this.suitable_dates.push(to_day);
    this.suitable_dates.push(Util.getWeekDay(-1));
    this.suitable_dates.push(Util.getWeekDay(-2));
    this.suitable_dates.push(Util.getWeekDay(-3));
    this.suitable_dates.push(Util.getWeekDay(-4));
    this.suitable_dates.push(Util.getWeekDay(-5));
    this.choosen_date = to_day;
    this.date_change();
    //this.dummy_data();
  },
  methods: {
    date_change: async function () {
      let cal = moment(this.choosen_date, "ddd DD MMM YYYY");
      let col_date = cal.format("YYYY-MM-DD");
      this.techData = await WorkOrderDB.canceled_work_order(col_date);
      this.tableData = this.techData;
    },
    close: function (e) {
      this.dialog = false;
      this.$emit("close_canceled_work_order", e);
    },
    getColor: function(status) {
        if(status){
          let lStatus = status.toLowerCase().trim();
          if (lStatus.startsWith('un')) return 'red';
          else if(lStatus == 'assigned') return 'blue';
          else if(lStatus == 'cancelled') return 'grey';
          else if(lStatus == 'finished') return 'green';
          else if(lStatus == 'billed') return 'green';
          else if(lStatus == 'unbilled') return 'red';
          else if(lStatus == 'waiting') return 'blue';
          else return 'orange';
        } else {
          return 'red';
        }
      },
  },
};
</script>