<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar color="white">
            <v-toolbar-title
              ><v-chip color="orange" text-color="white" label><h3>Copy Work Order</h3></v-chip></v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn icon @click="close()"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar>
          <v-container>
            <v-form ref="edit_work_order_form" v-model="valid" lazy-validation>
              <v-row>
                <v-btn text x-small color="primary" :right="true" :absolute="true" @click="show_settings()">Settings</v-btn>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" sm="6" md="3">
                  <v-select :items="suitable_dates" v-model="collection_date" label="Choose Collection Date" required></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-dialog ref="dialog" v-model="time_picker" :return-value.sync="time" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="collection_time"
                        label="Choose Collection Time"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="collectionTimeRules"
                        required
                      ></v-text-field>
                    </template>
                    <v-time-picker v-if="time_picker" v-model="collection_time" full-width>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="time_picker = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.dialog.save(time)"> OK </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-chip small label color="primary" @click="download_photo()"> Uploaded: {{ get_pres_photo() }} </v-chip>
                  <input type="file" accept="image/*" @change="image_change" capture="environment" />
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-img ref="photo" lazy-src="https://picsum.photos/id/11/500/300" max-height="100" max-width="100" :src="image_src"></v-img>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="2">
                  <v-select
                    :items="['Mr', 'Ms', 'Mrs', 'Child Of', 'Dr']"
                    label="Salutation"
                    v-model="salutation"
                    :rules="salutationRules"
                    required
                    @change="salutationChange()"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field label="Name" hint="Name" v-model="name" :rules="nameRules" required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field label="Age" hint="Age" v-model="age" :rules="ageRules" required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select :items="['Male', 'Female', 'Other']" label="Gender" v-model="gender" :rules="genderRules" required></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field type="number" label="Mobile" hint="Mobile" prefix="+91" v-model="mobile" :rules="mobileRules" required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field label="Address" hint="Address" v-model="address" :rules="addressRules" required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field label="Email" hint="Email" v-model="email"></v-text-field>
                </v-col>
                <v-col cols="10" sm="2" md="2">
                  <v-text-field type="number" label="Pincode" hint="Pincode" v-model="pincode" :rules="pincodeRules" required></v-text-field>
                </v-col>
                <v-col cols="2" sm="2" md="1">
                  <v-btn icon @click="show_pincode()">
                    <v-icon>mdi-selection-search</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field type="text" label="Doctor Name" hint="Doctor Name" prefix="Dr." v-model="doctor"></v-text-field>
                  <v-switch v-model="b2b" label="B2B?" @change="changeState()" dense></v-switch>
                  <v-text-field
                    hide-details
                    label="Selected B2B Client"
                    v-model="selected_client_name"
                    readonly
                    v-if="selected_client_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="8">
                  <v-textarea class="mx-2" label="Free text" hint="Free text" v-model="free_text"></v-textarea>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-row>
                    <v-switch v-model="vip" label="VIP Client?" dense></v-switch>
                  </v-row>
                  <v-row>
                    <v-switch v-model="urgent" label="Urgent?" dense></v-switch>
                  </v-row>
                  <v-row>
                    <v-select
                      v-model="credit_select"
                      :items="['None', 'Credit', 'Trial']"
                      label="Credit Or Trial"
                      dense
                      @change="credit_change()"
                    ></v-select>
                  </v-row>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-btn color="primary" class="mx-4" @click="validate()">Save</v-btn>
                <v-btn color="primary" @click="close()" class="mx-4">Close</v-btn>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
    <!--  
   --                         Dialog for msgs
   -->
    <div>
      <v-dialog persistent v-model="settings_dialog" transition="dialog-bottom-transition" max-width="600">
        <v-card>
          <v-toolbar color="orange" dark>Settings</v-toolbar>
          <v-card-text>
            <v-subheader class="pa-2">Do You Want To Send Report, Proforma And Bill To Patient?</v-subheader>
            <v-switch v-model="msg_sms" label="SMS" class="pa-0"></v-switch>
            <v-switch v-model="msg_whatsapp" label="WhatsApp" class="pa-0"></v-switch>
            <v-switch v-model="msg_email" label="Email" class="pa-0"></v-switch>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="ok_settings">OK</v-btn>
            <v-btn text @click="close_settings">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!--
    --        pincode bottom sheet
    -->
    <v-bottom-sheet v-model="sheet" inset>
      <v-toolbar color="white">
        <v-chip color="orange" text-color="white" label><h3>Search Pincode</h3></v-chip>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          @keyup.native="debounce_search()"
        ></v-text-field>
        <v-btn icon @click="closeSheet()"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-sheet height="300px">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Pincode</th>
                <th class="text-left">Area</th>
                <th class="text-left">City</th>
                <th class="text-left">District</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in pincodes" :key="item._id">
                <td>
                  <a @click="selected_pin(item.pincode)">{{ item.pincode }}</a>
                </td>
                <td>
                  <a @click="selected_pin(item.pincode)">{{ item.area }}</a>
                </td>
                <td>
                  <a @click="selected_pin(item.pincode)">{{ item.city }}</a>
                </td>
                <td>
                  <a @click="selected_pin(item.pincode)">{{ item.district }}</a>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-sheet>
    </v-bottom-sheet>
    <!-- Bottom sheet for b2b client -->
    <v-bottom-sheet v-model="sheet2" scrollable inset>
      <v-sheet height="300px">
        <v-toolbar color="white">
          <v-chip color="orange" text-color="white" label><h3>Choose B2B Client</h3></v-chip>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="b2b_client_search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @keyup.native="debounce_client_search"
          ></v-text-field>
          <v-btn icon @click="closeSheet2()"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-simple-table dense fixed-header height="300px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Contact Name</th>
                <th class="text-left">Mobile</th>
                <th class="text-left">Client Name</th>
                <th class="text-left">Client Type</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, j) in b2b_sorted_client" :key="j">
                <td>
                  <a @click="selected_client(item)">{{ getName(item) }}</a>
                </td>
                <td>
                  <a @click="selected_client(item)">{{ item.mobile }}</a>
                </td>
                <td>
                  <a @click="selected_client(item)">{{ item.client_master.client_name }}</a>
                </td>
                <td>
                  <a @click="selected_client(item)">{{ item.client_master.client_type }}</a>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-sheet>
    </v-bottom-sheet>
    <v-overlay :value="overlay" :z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import * as blobUtil from "blob-util";
import Util from "@/js/Util";
import PincodeDB from "@/js/database/pincode_db";
import moment from "moment";
import WorkOrderDB from "@/js/database/workorder_db";
import DBHandler from "@/js/database/DBHandler";
import fileDownload from "js-file-download";
import axios from "axios";
import settings from "@/js/database/settings";
import PostgresDB from "@/js/database/postgres_db";
export default {
  props: ["show", "item"],
  data() {
    return {
      email: "",
      time: null,
      time_picker: false,
      suitable_dates: [],
      dialog: this.show,
      valid: true,
      collection_date: "",
      collection_time: "",
      collectionTimeRules: [
        (v) => !!v || "Collection time is required",
        (v) => (v && this.validateFutureTime(v)) || "Collection time must be a future time.",
      ],
      salutation: "",
      salutationRules: [(v) => !!v || "Salutation is required"],
      name: "",
      nameRules: [(v) => !!v || "Name is required", (v) => (v && v.length <= 30) || "Name must be less than 30 characters"],
      age: "NA",
      ageRules: [(v) => !!v || "Age is required", (v) => (v && v.length <= 10) || "Age must be less than 10 characters"],
      gender: "",
      genderRules: [(v) => !!v || "Gender is required"],
      mobile: "",
      mobileRules: [
        (v) => !!v || "Mobile is required",
        (v) => (v && v.length == 10) || "Mobile number must be in 10 digits.",
        (v) => (v && v > 0) || "Mobile number must be greater than 0",
      ],
      address: "",
      addressRules: [(v) => !!v || "Address is required", (v) => (v && v.length <= 100) || "Address must be less than 100 characters."],
      pincode: "",
      pincodeRules: [
        (v) => !!v || "Pincode is required",
        (v) => (v && v.length == 6) || "Pincode must be in 6 digits.",
        (v) => (v && v > 0) || "Pincode must be greater than 0",
      ],
      free_text: "",
      doctor: "",
      vip: 0,
      urgent: 0,
      credit: 0,
      sheet: false,
      search: "",
      pincodes: [],
      overlay: false,
      edit_data: this.item,
      image_src: "",
      file_name: "",
      file_location: "",
      b2b: 0,
      b2b_clients: [],
      b2b_client_search: "",
      b2b_sorted_client: [],
      sheet2: false,
      selected_item: {},
      selected_client_name: "",
      client_id: "",
      pro_id: "",
      settings_dialog: false,
      msg_sms: 0,
      msg_whatsapp: 0,
      msg_email: 0,
      credit_select: "",
    };
  },
  mounted: function () {
    // let edit_date = Util.getWeekDayForDate(this.edit_data.appointment_date);
    // this.suitable_dates.push(edit_date);
    // console.log("app date:" + edit_date);
    // this.collection_date = edit_date;
    if (this.suitable_dates.includes(Util.getWeekDay(0)) == false) {
      this.suitable_dates.push(Util.getWeekDay(0));
      this.collection_date = Util.getWeekDay(0);
    }
    if (this.suitable_dates.includes(Util.getWeekDay(1)) == false) {
      this.suitable_dates.push(Util.getWeekDay(1));
    }
    if (this.suitable_dates.includes(Util.getWeekDay(2)) == false) {
      this.suitable_dates.push(Util.getWeekDay(2));
    }
    if (this.suitable_dates.includes(Util.getWeekDay(3)) == false) {
      this.suitable_dates.push(Util.getWeekDay(3));
    }
    this.collection_time = this.edit_data.appointment_time;
    let name = this.edit_data.name;
    if (name.indexOf(".") > -1) {
      this.salutation = name.substring(0, name.indexOf(".")).trim();
      this.name = name.substring(name.indexOf(".") + 1).trim();
    } else {
      this.name = name;
    }
    this.age = this.edit_data.age;
    this.gender = this.edit_data.gender;
    this.address = this.edit_data.address;
    this.pincode = this.edit_data.pincode;
    this.mobile = this.edit_data.mobile;
    this.free_text = this.edit_data.free_text;
    this.doctor = this.edit_data.doctor_name;
    this.vip = this.edit_data.vip_client;
    this.urgent = this.edit_data.urgent;
    this.credit = this.edit_data.credit;
    this.email = this.edit_data.email;
    this.file_location = this.edit_data.pres_photo;
    this.client_id = this.edit_data.b2b_client_id;
    if (this.client_id) {
      this.b2b = 1;
    }
    this.selected_client_name = this.edit_data.b2b_client_name;
    if (this.edit_data.pro_id) {
      this.pro_id = this.edit_data.pro_id;
    }
    if (this.edit_data.settings) {
      if (this.edit_data.settings.send_sms) {
        this.msg_sms = 1;
      }
      if (this.edit_data.settings.send_whatsapp) {
        this.msg_whatsapp = 1;
      }
      if (this.edit_data.settings.send_email) {
        this.msg_email = 1;
      }
    }
    if (this.credit == 0) {
      this.credit_select = "None";
    } else if (this.credit == 1) {
      this.credit_select = "Credit";
    } else if (this.credit == 2) {
      this.credit_select = "Trial";
    }
  },
  methods: {
    show_settings: function () {
      this.settings_dialog = true;
    },
    ok_settings: function () {
      this.settings_dialog = false;
    },
    close_settings: function () {
      this.settings_dialog = false;
    },
    validateFutureTime: function (v) {
      if (v) {
        let cal = moment(this.collection_date, "ddd DD MMM YYYY");
        let col_date = cal.year() + "-" + (cal.month() + 1) + "-" + cal.date();
        let dt_with_time = moment(col_date + " " + v);
        let diff = moment().diff(dt_with_time);
        if (diff < 0) {
          return true;
        }
        return false;
      }
    },
    salutationChange: function () {
      if (this.salutation == "Ms" || this.salutation == "Mrs") {
        this.gender = "Female";
      } else if (this.salutation == "Mr") {
        this.gender = "Male";
      } else {
        this.gender = "";
      }
    },
    validate: function () {
      let result = this.$refs.edit_work_order_form.validate();
      if (result) {
        this.save();
      }
    },
    save: async function () {
      let time_stamp = moment().format();
      let time = Util.gettime();
      let workorder_created_by = Util.getFromSession("logged_in_emp_name");
      let workorder_copied = time + "-" + workorder_created_by + "- Work Order Copied";

      let calendar = moment(this.collection_date, "ddd DD MMM YYYY");
      let id_date = calendar.format("YYYY-MM-DD");
      let col_date = calendar.format("DD-MM-YYYY");
      let app_dt_time = col_date + " " + this.collection_time;
      let sort_time = Util.parseAppTime(app_dt_time).valueOf();
      console.log(col_date);
      let new_id = "work_order:" + id_date + ":" + Util.uuidv4();
      if (this.image_src) {
        let result = await this.upload_photo(new_id);
        if (result != "OK") {
          alert(result);
          return;
        }
      }
      let doc = {
        _id: new_id,
        name: this.salutation + ". " + Util.toTitleCase(this.name),
        age: this.age,
        gender: this.gender,
        address: Util.toTitleCase(this.address),
        email: Util.toLowerCase(this.email),
        pincode: this.pincode,
        mobile: this.mobile,
        doctor_name: Util.toTitleCase(this.doctor),
        pro_id: this.pro_id,
        b2b_client_id: this.client_id,
        b2b_client_name: this.selected_client_name,
        vip_client: this.vip,
        urgent: this.urgent,
        credit: this.credit,
        appointment_date: col_date,
        appointment_time: this.collection_time,
        cancel_appointment: "",
        pres_photo: this.file_location,
        status: "assigned",
        server_status: "waiting",
        assigned_to: Util.getFromSession("logged_in_emp_name"),
        assigned_id: Util.getFromSession("logged_in_emp_id"),
        free_text: this.free_text,
        process: {
          first_step: "",
          second_step: "",
          third_step: "",
          fourth_step: "",
          fifth_step: "",
          prescription_uploaded_at: "",
          proforma_uploaded_at: "",
        },
        settings: {
          send_sms: this.msg_sms,
          send_whatsapp: this.msg_whatsapp,
          send_email: this.msg_email,
        },
        updated_at: time_stamp,
        sort_time: sort_time,
        manager_id: "", //Util.getFromSession("logged_in_emp_id"),
        manager_name: "", //Util.getFromSession("logged_in_emp_name"),
        tenant_id: Util.getFromSession("logged_in_tenant_id"),
        time_line: [workorder_copied],
      };
      //console.log(doc);
      this.overlay = true;
      let instance = this;
      //let db = await DBHandler.getDb("work_orders");
      let db = await WorkOrderDB.get_server_db("work_orders");
      db.put(doc)
        .then(function (response) {
          console.log(response);
          if (response.ok) {
            instance.overlay = false;
            instance.close("Updated Successfully");
          }
        })
        .catch(function () {
          instance.overlay = false;
          alert("Error Occurred: Please Check Your Internet Connection");
          // instance.close("Error:" + JSON.stringify(err));
        });
    },
    close: function (e) {
      this.dialog = false;
      this.$emit("close_copy_work_order", e);
    },
    show_pincode: async function () {
      this.sheet = true;
      this.search_pincodes();
    },
    closeSheet: function () {
      this.sheet = false;
    },
    search_pincodes: async function () {
      console.log("searching..." + this.search);
      this.pincodes = [];
      this.overlay = true;
      let list = await PincodeDB.fetch_data(this.search);
      console.log("list size:" + list.length);
      this.pincodes = list;
      this.overlay = false;
    },
    debounce_search: Util.debounce(function () {
      this.search_pincodes();
    }, 500),
    selected_pin: function (pin) {
      this.pincode = pin;
      this.sheet = false;
    },
    get_pres_photo: function () {
      if (this.file_location) {
        let loc = this.file_location;
        if (loc.indexOf("/") > -1) {
          loc = loc.substring(loc.lastIndexOf("/") + 1);
          return loc;
        }
      }
      return "No File";
    },
    image_change: function (event) {
      let file = event.target.files[0];
      this.file_name = file.name;
      this.image_src = URL.createObjectURL(file);
    },
    upload_photo: async function (doc_id) {
      let img_src = this.$refs.photo.src;
      let temp_doc_id = "temp_db:" + Util.uuidv4();
      let fname = this.file_name;
      this.file_location = "homecollection/prescriptions/" + Util.getTodayStringForFolderCreation() + "/" + fname;
      let flocation = this.file_location;
      console.log(fname);
      console.log(flocation);
      let db = await DBHandler.getDb("temp_db");

      let temp_doc = await blobUtil
        .imgSrcToBlob(img_src)
        .then(function (blob) {
          console.log(blob.type);
          let file_type = blob.type;
          let doc = {
            _id: temp_doc_id,
            for_doc: doc_id,
            file_name: fname,
            msg_time: Util.getTodayWithTime(),
            file_location: flocation,
            _attachments: {
              attach_file: {
                content_type: file_type,
                data: blob,
              },
            },
          };
          return doc;
        })
        .catch(function (err) {
          console.log(err);
          return "Error:" + err;
        });
      if (temp_doc._id) {
        let result = await db
          .put(temp_doc)
          .then(function (response) {
            console.log(response);
            if (response.ok) {
              return "OK";
            }
          })
          .catch(function (err) {
            console.log(err);
            return "Error:" + err;
          });
        return result;
      }
      return temp_doc;
    },
    download_photo: function () {
      let item = this.file_location + "";
      let dArray = [];
      if (item.indexOf(" | ") > -1) {
        dArray = item.split(" | ");
      } else {
        dArray[0] = "homecollection";
        dArray[1] = item;
      }
      let jwt_token = Util.getFromSession("pg_admin");
      let data = {
        bucket_name: dArray[0],
        key: dArray[1],
        jwt_token: jwt_token,
      };
      let name = dArray[1];
      if (name.indexOf("/") > -1) {
        name = name.substring(name.lastIndexOf("/") + 1);
      }
      axios({
        method: "post",
        responseType: "blob",
        url: settings.node_url + "/s3/get_file_v2",
        data: data,
      })
        .then(async function (response) {
          //console.log(response.data);
          fileDownload(response.data, name);
        })
        .catch(function (error) {
          console.log("error:" + error);
        });
    },
    changeState: async function () {
      if (this.b2b) {
        this.overlay = true;
        this.b2b_clients = await PostgresDB.getB2BClients();
        this.b2b_sorted_client = this.b2b_clients;
        this.overlay = false;
        this.sheet2 = true;
      } else {
        this.sheet = false;
        this.overlay = false;
        this.selected_client_name = "";
        this.client_id = "";
      }
    },
    selected_client: function (item) {
      this.selected_item = item;
      this.client_id = item.id;
      this.selected_client_name = item.first_name + " " + item.last_name + "(" + item.client_master.client_name + ")";
      this.sheet2 = false;
      this.msg_sms = 0;
      this.msg_whatsapp = 0;
      this.msg_email = 0;
    },
    debounce_client_search: Util.debounce(function () {
      this.client_search();
    }, 500),
    client_search: async function () {
      this.overlay = true;
      this.b2b_sorted_client = await Util.search(this.b2b_clients, this.b2b_client_search.toLowerCase());
      this.overlay = false;
    },
    getName: function (item) {
      return item.first_name + " " + item.last_name;
    },
    closeSheet2: function () {
      this.sheet2 = false;
      this.b2b = 0;
      this.msg_sms = 1;
      this.msg_whatsapp = 1;
      this.msg_email = 1;
    },
    credit_change: function () {
      let str = this.credit_select;
      if (str == "None") {
        this.credit = 0;
      } else if (str == "Credit") {
        this.credit = 1;
      } else if (str == "Trial") {
        this.credit = 2;
      }
    },
  },
};
</script>
