<template>
    <v-container fill-height>
      <v-row align="center" justify="center">
          <div class="loader"></div>
      </v-row>
      <v-row align="center" justify="center">
          Logging out the user....
      </v-row>
    </v-container>
</template>
<script>

export default {
  name: 'Logout',
  data: () => ({
  }),
  mounted: function(){
    console.log('logout....')
    sessionStorage.clear();
    this.$router.push('/login').catch(err => {console.log('its ok:'+err)});
  },
  methods: {
   
  }
};
</script>