<template>
  <v-tabs>
    <v-tab>
      <v-btn small value="unbilled" @click="$emit('unbilled')">
      <v-icon left>mdi-account-remove</v-icon>
      Unbilled
    </v-btn>
    </v-tab>
    <v-tab>
      <v-btn small value="billed" @click="$emit('billed')">
        <v-icon left>mdi-account-check</v-icon>
        Billed
      </v-btn>
    </v-tab>
  </v-tabs>
</template>
<script>
  export default {
    data() {
        return { 
            value: 'unbilled' 
        }
     }    
  }
</script>