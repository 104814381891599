<template>
  <div>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar color="white">
          <v-toolbar-title
            ><v-chip color="orange" text-color="white" label><h3>Price List</h3></v-chip></v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            ref="spl_ref1"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @keyup.native="debounce_search()"
          ></v-text-field>
          <v-btn icon @click="add"><v-icon>mdi-database-plus</v-icon></v-btn>
          <v-btn icon @click="history"><v-icon>mdi-history</v-icon></v-btn>
          <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-sheet>
          <v-data-table
            :headers="headers"
            :items="price_list"
            :items-per-page="-1"
            :single-expand="true"
            :expanded.sync="expanded"
            item-key="_id"
            :loading="loading"
            loading-text="Loading... Please wait"
            class="elevation-1"
            show-expand
            dense
          >
            <!-- eslint-disable-next-line -->
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
              <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-simple-table dense v-if="item.history">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Action</th>
                        <th class="text-left">Summary</th>
                        <th class="text-left">Name</th>
                        <th class="text-left">Mobile</th>
                        <th class="text-left">Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(hist, j) in item.history" :key="j">
                        <td>{{ hist.action }}</td>
                        <td>{{ hist.summary }}</td>
                        <td>{{ hist.emp_name }}</td>
                        <td>{{ hist.emp_mobile }}</td>
                        <td>{{ hist.time_stamp }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>
          </v-data-table>
        </v-sheet>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay" :z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="add_dialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <v-chip color="orange" text-color="white" label
            ><h3>{{ add_dialog_title }}</h3></v-chip
          >
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="dept_name"
                  :disabled="edit_test"
                  :items="dept_names"
                  label="Department name"
                  outlined
                  dense
                  @change="dept_name_change"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="dept_id" label="Department Id" outlined disabled dense></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-combobox
                  v-model="investigation_id"
                  :disabled="edit_test"
                  :items="investigation_ids"
                  label="Invest. ID"
                  outlined
                  dense
                ></v-combobox>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-combobox
                  v-model="investigation_name"
                  :disabled="edit_test"
                  :items="investigation_names"
                  label="Invest. Name"
                  outlined
                  dense
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="base_cost" label="Base Price" dense prefix="₹" type="number"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="min_cost" label="Min. Price" dense prefix="₹" type="number"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="cghs_price" label="CGHS Price" dense prefix="₹" type="number"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-alert dense :type="alert_type" v-if="show_alert">
                  {{ alert_msg }}
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="add_dialog_cancel"> Cancel </v-btn>
          <v-btn color="blue darken-1" text @click="add_dialog_save" @keydown.enter="add_dialog_save"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  
   --                         Dialog for msgs
   -->
    <div>
      <v-dialog persistent v-model="msg_dialog" transition="dialog-bottom-transition" max-width="600">
        <v-card>
          <v-toolbar color="orange" dark>{{ msg_header }}</v-toolbar>
          <v-card-text>
            <v-alert dense :type="msg_type" class="ma-4">
              {{ msg_summary }}
            </v-alert>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="ok_msg">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- 
      Delete Dialog   
    -->

    <div>
      <v-dialog v-model="deleteDialog" max-width="400">
        <v-card>
          <v-card-title class="text-h6 orange lighter-4 white--text"> Do you want to delete this record? </v-card-title>
          <v-card-text>
            {{ delete_summary }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="orange darken-1" text @click="deleteDialog = false"> Cancel </v-btn>
            <v-btn color="orange darken-1" text @click="doDelete()"> Yes </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!--  
   --                         Bottom sheet for history 
   -->
    <v-bottom-sheet v-model="history_sheet" inset>
      <v-toolbar color="white">
        <v-chip color="orange" text-color="white" label><h3>Add-Edit-Delete History</h3></v-chip>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="history_search"
          ref="history_ref"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          @keyup.native="debounce_history_search()"
        ></v-text-field>
        <v-btn icon @click="closeHistorySheet()"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-sheet>
        <v-simple-table dense height="300px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left" width="10%">Action</th>
                <th class="text-left" width="60%">Summary</th>
                <th class="text-left" width="10%">Name</th>
                <!-- <th class="text-left" width="10%">Mobile</th> -->
                <th class="text-left" width="20%">Time</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in history_array" :key="item._id">
                <td width="10%">{{ item.action }}</td>
                <td width="60%">{{ item.summary }}</td>
                <td width="10%">{{ item.emp_name }}</td>
                <!-- <td width="10%">{{ item.emp_mobile }}</td> -->
                <td width="20%">{{ item.time_stamp }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>
<script>
import Util from "@/js/Util";
import PriceListDB from "@/js/database/price_list_db";
import moment from "moment";
export default {
  props: ["show"],
  data() {
    return {
      headers: [
        { text: "Department", value: "dept_name" },
        { text: "Investigation", value: "invest_name" },
        { text: "Price", value: "base_cost" },
        { text: "Min Price", value: "min_cost" },
        { text: "CGHS", value: "cghs_price" },
        { text: "Actions", value: "actions", sortable: false },
        { text: "", value: "data-table-expand" },
      ],
      dialog: this.show,
      search: "",
      price_list: [],
      overlay: false,
      expanded: [],
      loading: false,
      add_dialog: false,
      dept_name: "",
      dept_id: 0,
      investigation_id: "",
      investigation_name: "",
      base_cost: 0,
      min_cost: 0,
      cghs_price: 0,
      dept_names: [],
      investigation_ids: [],
      investigation_names: [],
      add_dialog_title: "",
      show_alert: false,
      alert_type: "",
      alert_msg: "",
      edit_test: false,
      edit_id: "",
      msg_dialog: false,
      msg_header: "",
      msg_type: "",
      msg_summary: "",
      deleteDialog: false,
      delete_summary: "",
      delete_item: {},
      history_search: "",
      history_sheet: false,
      history_full: [],
      history_array: [],
    };
  },
  mounted: function () {
    this.search_price_list();
    this.set_combo_data();
  },
  methods: {
    format: function (money) {
      return Util.formatMoney(money);
    },
    set_combo_data: async function () {
      let result = await PriceListDB.set_combo_data();
      if (result == "OK") {
        this.dept_names = [];
        this.investigation_ids = [];
        this.investigation_names = [];
        let s_entries = sessionStorage.getItem("dept_names");
        let s_map = new Map(JSON.parse(s_entries));
        console.log("dept_names in combo:" + s_map.size);
        for (const entry of s_map.entries()) {
          this.dept_names.push(Util.toTitleCase(entry[0]));
        }

        let i_entries = sessionStorage.getItem("invest_names");
        let map1 = new Map(JSON.parse(i_entries));
        let i_map = new Map([...map1.entries()].sort());
        console.log("invest_names in combo:" + i_map.size);
        for (const entry of i_map.entries()) {
          this.investigation_ids.push(entry[0]);
          this.investigation_names.push(entry[1]);
        }
      }
      let history_doc = await PriceListDB.get_hisory();
      if (history_doc.history) {
        this.history_array = history_doc.history;
        this.history_full = this.history_array;
      }
    },
    close: function (e) {
      this.dialog = false;
      this.$emit("close_price_view", e);
    },
    add: function () {
      this.edit_test = false;
      this.add_dialog_title = "Add New Test";
      this.edit_id = "";
      this.dept_name = "";
      this.dept_id = "";
      this.investigation_name = "";
      this.investigation_id = "";
      this.base_cost = 0;
      this.min_cost = 0;
      this.cghs_price = 0;
      this.show_alert = false;
      this.alert_msg = "";
      this.alert_type = "";
      this.add_dialog = true;
    },
    editItem: function (item) {
      this.edit_test = true;
      this.add_dialog_title = "Edit Test";
      this.edit_id = item._id;
      this.dept_name = item.dept_name;
      this.dept_id = item.dept_id;
      this.investigation_name = item.invest_name;
      this.investigation_id = item.invest_id;
      this.base_cost = item.base_cost;
      this.min_cost = item.min_cost;
      this.cghs_price = item.cghs_price;
      this.show_alert = false;
      this.alert_msg = "";
      this.alert_type = "";
      this.add_dialog = true;
    },
    deleteItem: async function (item) {
      this.edit_id = item._id;
      this.delete_item = item;
      this.delete_summary = "Do You Want To Delete Test Item:" + item.invest_name;
      this.deleteDialog = true;
    },
    doDelete: async function () {
      this.deleteDialog = false;
      let result = await PriceListDB.delete_one(this.edit_id);
      if (result.ok) {
        let time_stamp = Util.getTodayWithTime();
        let history = {
          action: "Deleted",
          summary: "Test Item Deleted: " + this.delete_item.invest_id + "(" + this.delete_item.invest_name + ")",
          emp_id: Util.getFromSession("logged_in_emp_id"),
          emp_name: Util.getFromSession("logged_in_emp_name"),
          emp_mobile: Util.getFromSession("logged_in_mobile"),
          time_stamp: time_stamp,
        };
        let history_result = await PriceListDB.insert_history(history);
        console.log(history_result);
        this.msg_header = "Successfully Deleted";
        this.msg_type = "success";
        (this.msg_summary = "Successfully Deleted Test Item."), (this.msg_dialog = true);
      } else {
        this.msg_header = "Error";
        this.msg_type = "error";
        this.msg_summary = result;
        this.msg_dialog = true;
      }
    },
    history: function () {
      this.history_array = this.history_full;
      this.history_sheet = true;
    },
    dept_name_change: function (str) {
      let s_entries = sessionStorage.getItem("dept_names");
      let s_map = new Map(JSON.parse(s_entries));
      this.dept_id = s_map.get(str.toLowerCase());
    },
    search_price_list: async function () {
      try {
        console.log("searching..." + this.search);
        this.price_list = [];
        this.overlay = true;
        let list = await PriceListDB.fetch_all_data(this.search);
        console.log("list size:" + list.length);
        this.price_list = list;
        this.overlay = false;
        this.$refs.spl_ref1.$refs.input.focus();
      } catch (e) {
        console.log("Error:" + e);
      }
    },
    debounce_search: Util.debounce(function () {
      this.search_price_list();
    }, 500),
    add_dialog_cancel: function () {
      this.add_dialog_title = "";
      this.edit_id = "";
      this.dept_name = "";
      this.dept_id = "";
      this.investigation_name = "";
      this.investigation_id = "";
      this.base_cost = 0;
      this.min_cost = 0;
      this.cghs_price = 0;
      this.show_alert = false;
      this.alert_msg = "";
      this.alert_type = "";
      this.add_dialog = false;
    },
    add_dialog_save: async function () {
      if (this.edit_test) {
        this.edit_dialog_save();
        return;
      }
      this.show_alert = false;
      this.alert_msg = "";
      this.alert_type = "";
      let dept_name = this.dept_name;
      if (!dept_name) {
        this.alert_type = "error";
        this.alert_msg = "Please Choose Department Name.";
        this.show_alert = true;
        return;
      }
      let invest_id = this.investigation_id;
      if (!invest_id) {
        this.alert_type = "error";
        this.alert_msg = "Please Enter Investigation ID.";
        this.show_alert = true;
        return;
      }
      let invest_name = this.investigation_name;
      if (!invest_name) {
        this.alert_type = "error";
        this.alert_msg = "Please Enter Investigation Name.";
        this.show_alert = true;
        return;
      }
      let base_cost = this.base_cost;
      if (base_cost <= 0) {
        this.alert_type = "warning";
        this.alert_msg = "Please Enter Base Cost";
        this.show_alert = true;
        return;
      }
      let min_cost = this.min_cost;
      if (min_cost <= 0) {
        this.alert_type = "warning";
        this.alert_msg = "Please Enter Min Cost";
        this.show_alert = true;
        return;
      }
      if (min_cost > base_cost) {
        this.alert_type = "warning";
        this.alert_msg = "Min. Cost Cannot Be Greater Than Base Cost.";
        this.show_alert = true;
        return;
      }
      let cghs_price = this.cghs_price;
      if (cghs_price <= 0) {
        this.alert_type = "warning";
        this.alert_msg = "Please Enter CGHS Price.";
        this.show_alert = true;
        return;
      }
      let s_entries = sessionStorage.getItem("invest_names");
      let s_map = new Map(JSON.parse(s_entries));
      for (const entry of s_map.entries()) {
        let key = entry[0];
        let value = entry[1];
        if (key == invest_id) {
          this.alert_type = "warning";
          this.alert_msg = "Investigation ID Already Available.";
          this.show_alert = true;
          return;
        }
        if (value.toLowerCase() == invest_name.toLowerCase()) {
          this.alert_type = "warning";
          this.alert_msg = "Investigation Name Already Available.";
          this.show_alert = true;
          return;
        }
      }
      let time_stamp = Util.getTodayWithTime();
      let c_invest_name = Util.toTitleCase(invest_name);
      let history = {
        action: "Created",
        summary: "New Test Item Created: " + invest_id + "(" + c_invest_name + ")",
        emp_id: Util.getFromSession("logged_in_emp_id"),
        emp_name: Util.getFromSession("logged_in_emp_name"),
        emp_mobile: Util.getFromSession("logged_in_mobile"),
        time_stamp: time_stamp,
      };
      let calendar = moment();
      let id_date = calendar.format("YYYY-MM-DD");
      let new_id = "price_list:abp:" + id_date + ":" + Util.uuidv4();
      let doc = {
        _id: new_id,
        dept_id: this.dept_id,
        dept_name: this.dept_name,
        invest_id: invest_id,
        invest_name: c_invest_name,
        rate_card_name: "ANDERSON BASE PRICE SEPT 2021",
        base_cost: this.base_cost,
        min_cost: this.min_cost,
        cghs_price: this.cghs_price,
        history: [history],
      };
      let result = await PriceListDB.insert_new(doc);
      if (result == "OK") {
        let history_result = await PriceListDB.insert_history(history);
        console.log(history_result);
        this.add_dialog = false;
        this.msg_header = "Successfully Inserted";
        this.msg_type = "success";
        (this.msg_summary = "Successfully Created New Test Item."), (this.msg_dialog = true);
      } else {
        this.msg_header = "Error";
        this.msg_type = "error";
        this.msg_summary = result;
        this.msg_dialog = true;
      }
    },
    ok_msg: async function () {
      this.msg_dialog = false;
      await this.search_price_list();
      await this.set_combo_data();
    },
    edit_dialog_save: async function () {
      let base_cost = this.base_cost;
      if (base_cost <= 0) {
        this.alert_type = "warning";
        this.alert_msg = "Please Enter Base Cost";
        this.show_alert = true;
        return;
      }
      let min_cost = this.min_cost;
      if (min_cost <= 0) {
        this.alert_type = "warning";
        this.alert_msg = "Please Enter Min Cost";
        this.show_alert = true;
        return;
      }
      if (min_cost > base_cost) {
        this.alert_type = "warning";
        this.alert_msg = "Min. Cost Cannot Be Greater Than Base Cost.";
        this.show_alert = true;
        return;
      }
      let cghs_price = this.cghs_price;
      if (cghs_price <= 0) {
        this.alert_type = "warning";
        this.alert_msg = "Please Enter CGHS Price.";
        this.show_alert = true;
        return;
      }
      let old_doc = await PriceListDB.get_one(this.edit_id);
      let invest_name = this.investigation_name;
      let invest_id = this.investigation_id;
      let time_stamp = Util.getTodayWithTime();
      let c_invest_name = Util.toTitleCase(invest_name);
      let old_cghs = 0;
      if (old_doc.cghs_price) {
        old_cghs = old_doc.cghs_price;
      }
      let summary =
        "Test Item Updated: Invest Id:" +
        invest_id +
        "(" +
        c_invest_name +
        "). Old Values => Base Cost:" +
        old_doc.base_cost +
        " Min.Cost:" +
        old_doc.min_cost +
        " CGHS:" +
        old_cghs;
      summary +=
        " New Values => Base Cost:" + this.base_cost + " Min.Cost:" + this.min_cost + " CGHS:" + this.cghs_price;
      let history = {
        action: "Updated",
        summary: summary,
        emp_id: Util.getFromSession("logged_in_emp_id"),
        emp_name: Util.getFromSession("logged_in_emp_name"),
        emp_mobile: Util.getFromSession("logged_in_mobile"),
        time_stamp: time_stamp,
      };
      if (old_doc.history) {
        let old_history = old_doc.history;
        old_history.unshift(history);
        old_doc.history = old_history;
      } else {
        old_doc.history = [history];
      }
      old_doc.base_cost = this.base_cost;
      old_doc.min_cost = this.min_cost;
      old_doc.cghs_price = this.cghs_price;
      let result = await PriceListDB.update(old_doc);
      if (result.ok) {
        let history_result = await PriceListDB.insert_history(history);
        console.log(history_result);
        this.add_dialog = false;
        this.msg_header = "Successfully Updated";
        this.msg_type = "success";
        (this.msg_summary = "Successfully Updated Test Item."), (this.msg_dialog = true);
      } else {
        this.msg_header = "Error";
        this.msg_type = "error";
        this.msg_summary = result;
        this.msg_dialog = true;
      }
    },
    debounce_history_search: Util.debounce(function () {
      this.search_history();
    }, 500),
    search_history: function () {
      let result = [];
      let str = this.history_search + "";
      str = str.toLowerCase().trim();
      if (str) {
        for (let i = 0; i < this.history_full.length; i++) {
          let history = JSON.stringify(this.history_full[i]).toLowerCase();
          console.log(history);
          if (history.indexOf(str) > -1) {
            result.push(this.history_full[i]);
          }
        }
        this.history_array = result;
      } else {
        this.history_array = this.history_full;
      }
    },
    closeHistorySheet: async function () {
      this.history_sheet = false;
      this.history_search = "";
      // let mobile = Util.getFromSession('logged_in_mobile');
      // if(mobile == '9952743148'){
      //   console.log('Going to Import Data');
      //   let result = await PriceListDB.import_data();
      //   console.log('Finished Importing Data.'+result);
      // }
    },
  },
};
</script>
