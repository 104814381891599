<template>
  <div>
    <v-toolbar dense flat>
      <v-text-field
        class="ml-5"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        @keyup.native="search_details()"
      ></v-text-field>
    </v-toolbar>
    <v-card class="ma-1" v-for="(item, i) of tableData" :key="i">
      <v-card-title class="subtitle-2">
        {{ item.name }} [{{ item.age }} / {{ item.gender }}] &nbsp;
        <v-chip color="red" outlined x-small v-if="getClientStatus(item)">
          {{ getClientStatus(item) }}
        </v-chip>
      </v-card-title>
      <v-card-subtitle>
        Mobile: {{ item.mobile }} <br />
        App. Dt: {{ item.appointment_date }} {{ item.appointment_time }}<br />
        Ref By: {{ item.doctor_name }}<br />
        Assigned To: {{ item.assigned_to }}<br />
        Total: ₹{{ item.total }}<br />
        Tech. Status :
        <v-chip :color="getColor(item.status)" outlined x-small> {{ item.status }} </v-chip><br />
        Server Status :
        <v-chip :color="getColor(item.server_status)" outlined x-small>
          {{ getStatus(item.server_status) }}
        </v-chip>
      </v-card-subtitle>
      <v-card-actions>
        <v-chip class="mx-2" :color="getColor()" outlined x-small @click="bill_order(item)" v-if="checkstatus(item)"> Enter Bill Details </v-chip>
        <v-spacer></v-spacer>
        <v-btn icon @click="toggle(i)">
          <v-icon>{{ show[i] ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-card-actions>
      <v-expand-transition>
        <div v-show="show[i]">
          <v-divider></v-divider>
          <v-card-text>
            Address : {{ item.address }}<br />
            Pincode : {{ item.pincode }}<br />
            Mobile : {{ item.mobile }}<br />
            Prescription:
            <v-chip color="blue" outlined x-small v-if="item.pres_photo" @click="download_files(item.pres_photo)">
              {{ get_name(item.pres_photo) }}
            </v-chip>
            <br />
            <div v-if="item.process">
              Prescription Photo:
              <v-chip v-if="item.process.fifth_step" color="primary" outlined x-small @click="download_files(item.process.fifth_step)">
                {{ getPrescrptionFileNames(item.process.fifth_step) }} </v-chip
              ><br />
              Proforma Invoice:
              <v-chip v-if="item.process.second_step" color="primary" outlined x-small @click="download_files(item.process.second_step)">
                Proforma Invoice
              </v-chip>
            </div>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Dept. Id</th>
                    <th class="text-left">Dept. Name</th>
                    <th class="text-left">Investigation Id</th>
                    <th class="text-left">Investigation Name</th>
                    <th class="text-left">Base Cost</th>
                    <th class="text-left">Min. Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="thing in item.test_items" :key="thing.invest_id">
                    <td>{{ thing.dept_id }}</td>
                    <td>{{ thing.dept_name }}</td>
                    <td>{{ thing.invest_id }}</td>
                    <td>{{ thing.invest_name }}</td>
                    <td>{{ thing.base_cost }}</td>
                    <td>{{ thing.min_cost }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>
    <!-- 
      Message Dialog   
  -->
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackbar_text }}
    </v-snackbar>

    <!-- 
      Cancel Dialog   
    -->

    <div>
      <v-dialog v-model="billDialog" max-width="400">
        <v-card>
          <v-card-title class="text-h6 orange lighter-4 white--text"> Enter Billing Details </v-card-title>
          <v-card-text>
            <v-form ref="enter_bill_form" v-model="valid" lazy-validation>
              <v-text-field
                ref="bill_no_text_field"
                label="Bill Number"
                hint="Bill Number"
                v-model="bill_number"
                :rules="billRules"
                required
              ></v-text-field>
              <v-text-field
                ref="lab_no_text_field"
                label="Lab Number"
                hint="Lab Number"
                v-model="lab_number"
                :rules="labRules"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="orange darken-1" text @click="billDialog = false"> Cancel </v-btn>
            <v-btn color="orange darken-1" text @click="doBillRegister()"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!--  
   --                         Dialog for view / download
   -->
    <div>
      <v-dialog persistent v-model="file_dialog" transition="dialog-bottom-transition" max-width="600">
        <v-card>
          <v-toolbar color="orange" dark>View / Download Files</v-toolbar>
          <v-card-text>
            <v-sheet color="orange lighten-5" v-for="url in pres_array" :key="url">
              <v-btn text color="primary"> {{ get_name(url) }}</v-btn
              ><br />
              <v-btn text color="primary" @click="download_id(url, 'view')">View </v-btn>
              <v-btn text color="primary" @click="download_id(url, 'download')">Download</v-btn><br />
            </v-sheet>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="close_download_dialog">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!--  
   --                         Dialog for view pdf
   -->
    <v-container v-if="show_report">
      <show_pdf v-bind:show_report="show_report" v-bind:s3_url="s3_url" @close_pdf="pdf_close()"></show_pdf>
    </v-container>
    <v-container v-if="show_img">
      <show_img v-bind:show_img="show_img" v-bind:s3_url="s3_url" @close_image="img_close()"></show_img>
    </v-container>

    <v-overlay :value="overlay_dk" :z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
//import DBHandler from "@/js/database/DBHandler";
import WorkOrderDB from "@/js/database/workorder_db";
import Util from "@/js/Util";
import fileDownload from "js-file-download";
import axios from "axios";
import settings from "@/js/database/settings";
import show_pdf from "@/components/show_pdf.vue";
import show_img from "@/components/show_img.vue";
import { mapState } from "vuex";
export default {
  name: "BillingWorkOrders",
  props: ["type"],
  components: { show_pdf, show_img },
  data() {
    return {
      show: [],
      loading: false,
      expanded: [],
      singleExpand: true,
      search: "",
      tableHeaders: [
        { text: "Name", align: "start", sortable: true, value: "name" },
        { text: "Gender", value: "gender" },
        { text: "Age", value: "age" },
        { text: "Mobile", value: "mobile" },
        { text: "Date", value: "appointment_date" },
        { text: "Time", value: "appointment_time" },
        { text: "Total (₹)", value: "total" },
        { text: "Status", value: "status" },
        { text: "Assigned", value: "assigned_to" },
        { text: "Tech. Status", value: "status" },
        { text: "Server Status", value: "server_status" },
        { text: "Actions", value: "actions", sortable: false },
        { text: "", value: "data-table-expand" },
      ],
      tableData: [],
      work_orders: [],
      snackbar: "",
      snackbar_text: "",
      valid: false,
      item: null,
      bill_item: null,
      billDialog: false,
      bill_number: "",
      billRules: [(v) => !!v || "Bill Number is required", (v) => (v && v.length > 12) || "Bill Number Must Be Greater Than 12 Characters"],
      lab_number: "",
      labRules: [(v) => !!v || "Lab Number is required", (v) => (v && v.length > 8) || "Lab Number Must Be Greater Than 8 Characters"],
      file_dialog: false,
      pres_array: [],
      show_report: false,
      s3_url: "",
      show_img: false,
      overlay_dk: false,
    };
  },
  computed: mapState(["work_order"]),
  watch: {
    work_order(newValue, oldValue) {
      console.log("Updating from " + oldValue + " to " + newValue);
      this.updateScreen();
    },
  },
  mounted: async function () {
    this.updateScreen();
  },
  methods: {
    toggle: function (index) {
      let val = this.show[index];
      val = !val;
      this.$set(this.show, index, val);
    },
    updateScreen: async function () {
      this.loading = true;
      if (this.type == "billed") {
        this.work_orders = await WorkOrderDB.billed_list("Finished", "Billed");
      } else {
        this.work_orders = await WorkOrderDB.billed_list("Finished", "Received");
      }
      this.tableData = this.work_orders;
      this.loading = false;
    },
    checkstatus: function (item) {
      let status = item.status;
      let server_status = item.server_status;
      if (status == "NA") {
        return false;
      } else if (status == "Finished" && server_status == "Received") {
        return true;
      } else if (status == "cancelled") {
        return false;
      }
      return false;
    },
    getColor: function (status) {
      if (status) {
        if (status.toLowerCase().startsWith("un")) return "red";
        else if (status == "") return "red";
        else if (status == "cancelled") return "grey";
        else if (status == "Finished") return "green";
        else if (status == "assigned") return "blue";
        else if (status == "billed") return "green";
        else return "orange";
      } else {
        return "red";
      }
    },
    getServerStatusColor: function (status) {
      if (status) {
        if (status.toLowerCase().indexOf("not") > -1) return "red";
        if (status.toLowerCase().startsWith("un")) return "red";
        else if (status == "") return "red";
        else if (status == "waiting") return "blue";
        else if (status == "cancelled") return "grey";
        else return "green";
      } else {
        return "red";
      }
    },
    getStatus: function (status) {
      if (status == "Received") {
        return "Unbilled";
      }
      return status;
    },
    debounce_search: Util.debounce(function () {
      this.search_details();
    }, 500),
    search_details: function () {
      this.tableData = Util.search(this.work_orders, this.search.toLowerCase());
    },
    bill_order: function (item) {
      this.bill_item = item;
      this.billDialog = true;
    },
    doBillRegister: async function () {
      let result = this.$refs.enter_bill_form.validate();
      if (result) {
        let doc = this.bill_item;
        doc.server_status = "Billed";
        doc.bill_number = this.bill_number.toUpperCase();
        doc.lab_number = this.lab_number;
        let instance = this;
        //let db = await DBHandler.getDb("work_orders");
        let db = await WorkOrderDB.get_server_db("work_orders");
        db.put(doc)
          .then(function (response) {
            console.log(response);
            if (response.ok) {
              instance.snackbar_text = "Updated Successfully";
              instance.snackbar = true;
            }
          })
          .catch(function (err) {
            instance.snackbar_text = "Error: Please Check Your Internet :" + JSON.stringify(err);
            instance.snackbar = true;
            return;
          });
        this.bill_item = null;
        this.billDialog = false;
      }
    },
    getPrescrptionFileNames: function (name) {
      let aName = [];
      let retval = "";
      if (name.indexOf(",") > -1) {
        aName = name.split(",");
      } else {
        aName.push(name);
      }
      if (aName.length > 1) {
        retval = aName.length + " files.";
        return retval;
      } else {
        return Util.getName(name);
      }
    },
    get_name: function (name) {
      return Util.getName(name);
    },
    download_files: function (name) {
      let pres_array = [];
      let aName = [];
      if (name.indexOf(",") > -1) {
        aName = name.split(",");
      } else {
        aName.push(name);
      }
      for (let i = 0; i < aName.length; i++) {
        let fName = aName[i];
        pres_array.push(fName);
      }
      this.pres_array = pres_array;
      this.file_dialog = true;
    },
    download_id: function (id_loc, mode) {
      let item = id_loc + "";
      let dArray = [];
      if (item.indexOf(" | ") > -1) {
        dArray = item.split(" | ");
      } else {
        dArray[0] = "homecollection";
        dArray[1] = item;
      }
      let jwt_token = Util.getFromSession("pg_admin");
      let data = {
        bucket_name: dArray[0],
        key: dArray[1],
        jwt_token: jwt_token,
      };
      let name = dArray[1];
      if (name.indexOf("/") > -1) {
        name = name.substring(name.lastIndexOf("/") + 1);
      }
      if (mode == "view") {
        if (name.endsWith(".pdf")) {
          this.s3_url = id_loc;
          this.show_report = true;
        } else {
          this.s3_url = id_loc;
          this.show_img = true;
        }
        return;
      }
      this.overlay_dk = true;
      let instance = this;
      axios({
        method: "post",
        responseType: "blob",
        url: settings.node_url + "/s3/get_file_v2",
        data: data,
      })
        .then(async function (response) {
          //console.log(response.data);
          if (mode == "download") {
            fileDownload(response.data, name);
          }
          instance.overlay_dk = false;
        })
        .catch(function (error) {
          console.log("error:" + error);
          instance.overlay_dk = false;
          alert(error);
        });
    },
    close_download_dialog: function () {
      this.file_dialog = false;
    },
    pdf_close: function () {
      this.show_report = false;
    },
    img_close: function () {
      this.show_img = false;
    },
    getClientStatus: function (item) {
      let ret = "";
      if (item.urgent) {
        ret += "Urgent ";
      }
      if (item.vip_client) {
        ret += "VIP ";
      }
      if (item.credit == 1) {
        ret += "Credit ";
      } else if (item.credit == 2) {
        ret += "Trial ";
      }
      if (item.b2b_client_id) {
        ret += "B2B";
      }
      return ret;
    },
  },
};
</script>
