<template>
    <v-container fill-height>
      <v-row justify="center" align="center">
          <v-col cols="12" sm="4">
              <v-card>
                <v-toolbar color="orange" >
                    <v-toolbar-title><h4 style="color:white">Anderson CRM</h4></v-toolbar-title>
                </v-toolbar>
                <v-text-field class="px-4" label="Mobile" v-model="mobile"/>
                <v-footer>
                  <v-btn color="primary" text v-on:click="login()">Reset Password</v-btn>  
                </v-footer>
            </v-card>
          </v-col>
      </v-row>
    </v-container>
</template>
<script>
export default {
  name: 'ChangePassword',
  data: () => ({
    mobile:'',
  }),
  methods: {
    login: function(){
      alert('Yet to be implemented');
      //this.$router.push('dashboard');
    }
  }
};
</script>
<style>
</style>