<template>
  <div>
    <v-toolbar dense flat v-if="check_search_result() == false">
      <v-text-field
        class="ml-5"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        @keyup.native="search_details()"
      ></v-text-field>
      <v-btn icon @click="show_price()">
        <v-icon>mdi-clipboard-list-outline</v-icon>
      </v-btn>
      <v-btn icon @click="show_tech_engagements()">
        <v-icon>mdi-account-details-outline</v-icon>
      </v-btn>
    </v-toolbar>
    <v-virtual-scroll bench="0" :items="tableData" :item-height="vir_height" height="1800">
      <template v-slot:default="{ item }">
        <v-card elevation="4" class="ma-1">
          <v-card-title class="subtitle-2">
            {{ item.name }} [{{ item.age }} / {{ item.gender }}] &nbsp;
            <v-icon color="green" outlined elevation="2" small @click="edit_name(item)" v-if="checkstatus(item)"> mdi-pencil </v-icon>&nbsp;
            <v-chip color="red" x-small v-if="getClientStatus(item)">
              {{ getClientStatus(item) }}
            </v-chip>
          </v-card-title>
          <v-simple-table dense>
            <template v-slot:default>
              <!-- display the search result -->

              <tbody v-if="check_search_result()">
                <tr>
                  <td class="text-left">Assigned To</td>
                  <td>{{ item.assigned_to }}</td>
                </tr>
                <tr>
                  <td class="text-left">Mobile</td>
                  <td>{{ item.mobile }}</td>
                </tr>
                <tr>
                  <td class="text-left">App. Time</td>
                  <td>{{ item.appointment_date }} {{ item.appointment_time }}</td>
                </tr>
                <!-- <tr>
                  <td class="text-left">Ref. By</td>
                  <td>{{ item.doctor_name }}</td>
                </tr> -->
                <tr>
                  <td class="text-left">My Status</td>
                  <td>
                    <v-chip :color="getColor(item.status)" outlined x-small> {{ item.status }} </v-chip>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Proforma</td>
                  <td>
                    <div v-if="item.process.second_step">
                      <v-badge bordered color="green" icon="mdi-eye-check-outline" overlap>
                        <v-btn outlined x-small class="white--text" color="blue" @click="download_files(item.process.second_step)">{{
                          getName(item.process.second_step)
                        }}</v-btn>
                      </v-badge>
                    </div>
                    <div v-else>Nil</div>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Prescription Photo</td>
                  <td>
                    <div v-if="item.pres_photo">
                      <v-badge bordered color="green" icon="mdi-eye-check-outline" overlap>
                        <v-btn outlined x-small class="white--text" color="blue" @click="download_files(item.pres_photo)">{{
                          getName(item.pres_photo)
                        }}</v-btn>
                      </v-badge>
                    </div>
                    <div v-else>Nil</div>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Remarks</td>
                  <td>
                    <div v-if="item.remarks">
                      {{ item.remarks }}
                    </div>
                    <div v-else>Nil</div>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Report Status</td>
                  <td>
                    <div v-if="item.report_status">
                      <v-chip :color="getColor(item.report_status)" outlined x-small>{{ item.status_in_number }} {{ item.report_status }}</v-chip>
                    </div>
                    <div v-else>Nil</div>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Report PDF</td>
                  <td>
                    <div v-if="item.report_path">
                      <v-btn icon @click="download_files(item.report_path)">
                        <v-icon color="primary">mdi-cloud-download</v-icon>
                      </v-btn>
                    </div>
                    <div v-else>Nil</div>
                  </td>
                </tr>
              </tbody>

              <!-- when no data to display -->

              <tbody v-else-if="check_not_search_result_cancelled(item)">
                <tr>
                  <td class="text-left">Mobile</td>
                  <td>{{ item.mobile }}</td>
                </tr>
                <tr>
                  <td class="text-left">App. Time</td>
                  <td>{{ item.appointment_date }} {{ item.appointment_time }}</td>
                </tr>
                <!-- <tr>
                  <td class="text-left">Ref. By</td>
                  <td>{{ item.doctor_name }}</td>
                </tr> -->
                <tr>
                  <td class="text-left">My Status</td>
                  <td>
                    <v-chip :color="getColor(item.status)" outlined x-small> {{ item.status }} </v-chip>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Test Items</td>
                  <td>Nil</td>
                </tr>
                <tr>
                  <td class="text-left">GPay</td>
                  <td>Nil</td>
                </tr>
                <tr>
                  <td class="text-left">Prescription Photo</td>
                  <td>
                    <div v-if="item.pres_photo">
                      <v-badge bordered color="green" icon="mdi-eye-check-outline" overlap>
                        <v-btn outlined x-small class="white--text" color="blue" @click="download_files(item.pres_photo)">{{
                          getName(item.pres_photo)
                        }}</v-btn>
                      </v-badge>
                    </div>
                    <div v-else>Nil</div>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Remittence</td>
                  <td>Nil</td>
                </tr>
                <tr>
                  <td class="text-left">Lab Samples</td>
                  <td>Nil</td>
                </tr>
                <tr>
                  <td class="text-left">Remarks</td>
                  <td>
                    <div v-if="item.remarks">
                      <v-badge bordered color="green" icon="mdi-pencil" overlap>
                        <v-btn outlined x-small class="white--text" color="blue" @click="edit_remarks(item)">Edit</v-btn>
                      </v-badge>
                    </div>
                    <div v-else>
                      <v-badge bordered color="green" icon="mdi-pencil" overlap>
                        <v-btn outlined x-small class="white--text" color="blue" @click="edit_remarks(item)">Add</v-btn>
                      </v-badge>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Report Status</td>
                  <td>Nil</td>
                </tr>
                <tr>
                  <td class="text-left">Report PDF</td>
                  <td>Nil</td>
                </tr>
              </tbody>

              <!-- technician work order display -->

              <tbody v-else-if="check_not_search_result(item)">
                <tr>
                  <td class="text-left">Mobile</td>
                  <td>{{ item.mobile }}</td>
                </tr>
                <tr>
                  <td class="text-left">App. Time</td>
                  <td>{{ item.appointment_date }} {{ item.appointment_time }}</td>
                </tr>
                <!-- <tr>
                  <td class="text-left">Ref. By</td>
                  <td>{{ item.doctor_name }}</td>
                </tr> -->
                <tr>
                  <td class="text-left">My Status</td>
                  <td>
                    <v-chip :color="getColor(item.status)" outlined x-small> {{ item.status }} </v-chip>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Test Items</td>
                  <td>
                    <div v-if="item.test_items">
                      <v-badge bordered color="green" icon="mdi-eye-check-outline" overlap>
                        <v-btn outlined x-small class="white--text" color="blue" @click="view_tests(item)">View</v-btn>
                      </v-badge>
                    </div>
                    <div v-else>Nil</div>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">GPay</td>
                  <td>
                    <div v-if="check_gpay_later(item)">
                      <v-badge bordered color="green" icon="mdi-pencil" overlap>
                        <v-btn outlined x-small class="white--text" color="blue" @click="edit_gpay(item)">Edit</v-btn>
                      </v-badge>
                    </div>
                    <div v-else>Nil</div>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Prescription Photo</td>
                  <td>
                    <div v-if="item.pres_photo">
                      <v-badge bordered color="green" icon="mdi-eye-check-outline" overlap>
                        <v-btn outlined x-small class="white--text" color="blue" @click="download_files(item.pres_photo)">{{
                          getName(item.pres_photo)
                        }}</v-btn>
                      </v-badge>
                    </div>
                    <div v-else>Nil</div>
                  </td>
                </tr>
                <tr>
                  <td class="text-left" v-if="item.accept_remittance">Remittence</td>
                  <td class="text-left" v-else>Remitted</td>
                  <td>
                    <v-chip x-small color="green" outlined v-if="item.accept_remittance">Accepted. </v-chip>
                    <v-switch x-small v-model="item.remittance" @change="remit_change(item)" v-else></v-switch>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Lab Samples</td>
                  <td>
                    <v-chip color="green" outlined x-small v-if="item.lab_sample_pics_accepted">Accepted.</v-chip>
                    <div v-else>
                      <v-btn icon @click="upload(item)">
                        <v-icon color="primary">mdi-cloud-upload</v-icon>
                      </v-btn>
                      <v-btn icon @click="view_download(item)">
                        <v-icon color="primary">mdi-cloud-download</v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Remarks</td>
                  <td>
                    <div v-if="item.remarks">
                      <v-badge bordered color="green" icon="mdi-pencil" overlap>
                        <v-btn outlined x-small class="white--text" color="blue" @click="edit_remarks(item)">Edit</v-btn>
                      </v-badge>
                    </div>
                    <div v-else>
                      <v-badge bordered color="green" icon="mdi-pencil" overlap>
                        <v-btn outlined x-small class="white--text" color="blue" @click="edit_remarks(item)">Add</v-btn>
                      </v-badge>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Report Status</td>
                  <td>
                    <div v-if="item.report_status">
                      <v-chip :color="getColor(item.report_status)" outlined x-small>{{ item.status_in_number }} {{ item.report_status }}</v-chip>
                    </div>
                    <div v-else>Nil</div>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Report PDF</td>
                  <td>
                    <div v-if="item.report_path">
                      <v-btn icon @click="download_files(item.report_path)">
                        <v-icon color="primary">mdi-cloud-download</v-icon>
                      </v-btn>
                    </div>
                    <div v-else>Nil</div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card-actions>
            <div v-if="check_search_result()">
              <v-chip class="mx-1" :color="getColor()" outlined x-small @click="copyItem(item)"> Copy </v-chip>
            </div>
            <div v-else>
              <v-chip class="mx-1" :color="getColor()" outlined x-small @click="copyItem(item)"> Copy </v-chip>
              <v-chip class="mx-1" :color="getColor()" outlined x-small @click="startAddTest(item)" v-if="checkstatus(item)"> Start </v-chip>
              <v-chip class="mx-1" :color="getColor()" outlined x-small @click="cancel_order(item)" v-if="checkstatus(item)"> Cancel </v-chip>
              <v-chip class="mx-1" :color="getColor(item.server_status)" outlined x-small v-if="getStatus(item.server_status)">
                {{ getStatus(item.server_status) }}
              </v-chip>
            </div>

            <v-spacer></v-spacer>
            <v-btn icon @click="viewMoreShow(item)">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-virtual-scroll>
    <v-container v-if="type === 'add_test'">
      <add_test v-bind:show="show_add" :item="item" @close_add_test="close_add_test"></add_test>
    </v-container>
    <!-- 
      Copy work order  
    -->

    <v-container v-if="type === 'copy_work_order_dialog'">
      <copy_work_order :show="show_copy" :item="copied_item" @close_copy_work_order="close_copy_screen"></copy_work_order>
    </v-container>

    <v-container v-if="tech_engagement_dialog">
      <tech_engagement v-bind:show="show_engagement" @close_tech_engagements="close_tech_engagements"></tech_engagement>
    </v-container>
    <v-container v-if="price_view_dialog">
      <price_view v-bind:show="show_price_view" @close_price_view="close_price_view"></price_view>
    </v-container>
    <!-- 
      Message Dialog   
  -->
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackbar_text }}
    </v-snackbar>

    <!-- 
      view more data   
    -->

    <div>
      <v-dialog v-model="viewMoreDialog" max-width="400">
        <v-card>
          <v-card-title class="text-h6 orange lighter-4 white--text">More Info</v-card-title>
          <v-card-text>
            Address : {{ view_more_item.address }}<br />
            Pincode : {{ view_more_item.pincode }}<br />
            Mobile : {{ view_more_item.mobile }}<br />
            Additional Info : {{ view_more_item.free_text }} <br />
            Ref. By {{ view_more_item.doctor_name }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="orange darken-1" text @click="viewMoreCancel()"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <!-- 
      Cancel Dialog   
    -->

    <div>
      <v-dialog v-model="cancelDialog" max-width="400">
        <v-card>
          <v-card-title class="text-h6 orange lighter-4 white--text"> Do you want to cancel this order? </v-card-title>
          <v-card-text>
            <v-text-field
              ref="cancel_text_field"
              label="Reason For Cancellation"
              hint="Reason For Cancellation"
              v-model="cancel_reason"
              :rules="cancelRules"
              required
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="orange darken-1" text @click="cancelDialog = false"> No </v-btn>
            <v-btn color="orange darken-1" text @click="doCancel()"> Yes </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- 
      Sugar Dialog   
    -->
    <div>
      <v-dialog v-model="sugarDialog" max-width="400">
        <v-card>
          <v-card-title class="text-h6 orange lighter-4 white--text">Do you want to book Glucose(PP) for this patient? </v-card-title>
          <v-card-text> Do you want to book Glucose(PP) for this patient? </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="orange darken-1" text @click="cancelSugar()"> No </v-btn>
            <v-btn color="orange darken-1" text @click="doSugar()"> Yes </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!--  
   --                         Dialog for view / download
   -->
    <div>
      <v-dialog persistent v-model="file_dialog" max-width="400">
        <v-card>
          <v-toolbar color="orange" dark>View / Download Files</v-toolbar>
          <v-card-text>
            <v-sheet color="orange lighten-5" elevation="7" outlined rounded shaped class="ma-2 pa-2" v-for="url in pres_array" :key="url">
              <v-btn text color="primary" x-small> {{ getName(url) }}</v-btn>
              <v-btn text color="primary" @click="download_id(url, 'view')" x-small>View </v-btn>
              <v-btn text color="primary" @click="download_id(url, 'download')" x-small>Download</v-btn>
            </v-sheet>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="close_download_dialog" x-small>OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!--  
   --                         Dialog for upload
   -->
    <div>
      <v-dialog persistent v-model="file_upload_dialog" max-width="400">
        <v-card>
          <v-toolbar color="orange" dark>Upload Files</v-toolbar>
          <v-card-text>
            <v-row justify="center" v-if="show_photo_error" dense>
              <v-alert type="warning">{{ photo_error_msg }}</v-alert>
            </v-row>
            <v-row dense>
              Upload photo
              <input type="file" id="upload_file" accept="image/*" @change="upload_photo" capture="environment" />
            </v-row>
            <v-row dense>
              <v-chip class="ma-1" color="orange" label outlined v-for="photo in uploaded_photos" :key="photo">
                {{ photo }}
              </v-chip>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn :loading="photo_loading" @click="validate_photo"> Save </v-btn>
            <v-btn @click="cancel_upload"> Cancel </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!--  
   --                         Dialog for view pdf
   -->
    <v-container v-if="show_report">
      <show_pdf v-bind:show_report="show_report" v-bind:s3_url="s3_url" @close_pdf="pdf_close()"></show_pdf>
    </v-container>
    <v-container v-if="show_img">
      <show_img v-bind:show_img="show_img" v-bind:s3_url="s3_url" @close_image="img_close()"></show_img>
    </v-container>

    <v-overlay :value="overlay_dk" :z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import fileDownload from "js-file-download";
//import DBHandler from "@/js/database/DBHandler";
import WorkOrderDB from "@/js/database/workorder_db";
import add_test from "@/components/my_work_order/add_test.vue";
import copy_work_order from "@/components/my_work_order/copy_work_order.vue";
import tech_engagement from "@/components/my_work_order/tech_engagement.vue";
import price_view from "@/components/my_work_order/price_view_mobile.vue";

import Util from "@/js/Util";
import { mapState } from "vuex";
import axios from "axios";
import settings from "@/js/database/settings";
import show_pdf from "@/components/show_pdf.vue";
import show_img from "@/components/show_img.vue";
export default {
  name: "MyWorkOrders",
  props: ["search_result", "mode"],
  components: {
    add_test,
    copy_work_order,
    tech_engagement,
    price_view,
    show_pdf,
    show_img,
  },
  data() {
    return {
      loading: false,
      expanded: [],
      search: "",
      tableHeaders: [
        { text: "Name", align: "start", sortable: true, value: "name" },
        { text: "Gender", value: "gender" },
        { text: "Age", value: "age" },
        { text: "Mobile", value: "mobile" },
        { text: "Date", value: "appointment_date" },
        { text: "Time", value: "appointment_time" },
        { text: "Status", value: "status" },
        { text: "Assigned To", value: "assigned_to" },
        { text: "Actions", value: "actions", sortable: false },
        { text: "", value: "data-table-expand" },
      ],
      tableData: [],
      work_orders: [],
      show_add: false,
      type: "",
      show_copy: false,
      copied_item: {},
      snackbar: "",
      snackbar_text: "",
      item: {},
      viewMoreDialog: false,
      view_more_item: {},
      cancel_item: {},
      sugarDialog: false,
      cancelDialog: false,
      cancel_reason: "",
      cancelRules: [(v) => !!v || "Reason is required", (v) => (v && v.length > 5) || "Give proper reason."],
      tech_engagement_dialog: false,
      show_engagement: false,
      show_price_view: false,
      price_view_dialog: false,
      lab_sample_view_dialog: false,
      show_lab_sample_view: false,
      file_dialog: false,
      pres_array: [],
      show_report: false,
      s3_url: "",
      show_img: false,
      overlay_dk: false,
      edit_item: {},
      file_upload_dialog: false,
      photo_loading: false,
      uploaded_photos: [],
      uploaded_photos_path: [],
      show_photo_error: false,
      photo_error_msg: "Please choose a photo to continue.",
      vir_height: 570,
    };
  },
  computed: mapState(["work_order"]),
  watch: {
    work_order(newValue, oldValue) {
      if (this.check_search_result() == false) {
        console.log("Updating from " + oldValue + " to " + newValue);
        this.updateScreen();
      }
    },
  },
  mounted: async function () {
    if (this.check_search_result()) {
      this.updateSearchScreen();
      this.vir_height = 450;
    } else {
      this.updateScreen();
      this.show_sugar_test();
    }
  },
  methods: {
    getName: function (item) {
      return Util.getName(item);
    },
    updateSearchScreen: function () {
      try {
        this.work_orders = this.search_result;
        this.tableData = this.work_orders;
      } catch (e) {
        console.log(e);
      }
    },
    updateScreen: async function () {
      this.loading = true;
      let emp_id = Util.getFromSession("logged_in_emp_id");
      this.work_orders = await WorkOrderDB.assigned_list(emp_id);
      this.tableData = this.work_orders;
      this.loading = false;
    },
    show_sugar_test: async function () {
      let doc_id = Util.getFromSession("sugar_tests");
      if (doc_id) {
        this.sugarDialog = true;
      }
    },
    cancelSugar: function () {
      Util.setSession("sugar_tests", "");
      this.sugarDialog = false;
    },
    doSugar: async function () {
      let doc_id = Util.getFromSession("sugar_tests");
      if (doc_id) {
        Util.setSession("sugar_tests", "");
        let doc = await WorkOrderDB.getWithId(doc_id);
        doc.appointment_time = "";
        this.copyItem(doc);
      }
    },
    checkstatus: function (item) {
      let status = item.status;
      if (status == "NA") {
        return false;
      } else if (status == "Finished") {
        return false;
      } else if (status == "cancelled") {
        return false;
      }
      return true;
    },
    checkCashPayment: function (item) {
      let status = item.status;
      if (status == "Finished") {
        if (item.payment_method) {
          if (item.payment_method == "cash") {
            if (item.amount_received > 0) {
              return true;
            }
          }
        } else {
          return true;
        }
      }
      return false;
    },
    checkFinished: function (item) {
      let status = item.status;
      if (status == "Finished") {
        if (item.amount_received > 0) {
          return true;
        }
      }
      return false;
    },
    check_gpay_later: function (item) {
      let status = item.status;
      if (status == "Finished") {
        if (item.gpay_ref == "Later") {
          return true;
        }
      }
      return false;
    },
    close_add_test: function (e) {
      if (typeof e === "string" || e instanceof String) {
        this.snackbar_text = e;
        this.snackbar = true;
      }
      this.show_add = false;
      this.type = "";
    },
    startAddTest: function (item) {
      this.item = item;
      this.$router.push("/hc_process/" + item._id);
    },
    checkTime: async function (item) {
      try {
        let app_time = item.appointment_date + " " + item.appointment_time;
        let jwt_token = Util.getFromSession("pg_admin");
        let data = {
          appointment_time: app_time,
          jwt_token: jwt_token,
        };
        let instance = this;
        axios({
          method: "post",
          responseType: "text",
          url: settings.node_url + "/time/check_time",
          data: data,
        })
          .then(function (response) {
            console.log(response.data);
            if (response.data == "OK") {
              instance.$router.push("/hc_process/" + item._id);
            } else {
              instance.snackbar_text = "Timed out for filling this workorder.";
              instance.snackbar = true;
            }
          })
          .catch(function (error) {
            console.log("error:" + error);
          });
      } catch (e) {
        console.log(e);
      }
    },
    getColor: function (status) {
      if (status) {
        status = status.toLowerCase().trim();
        if (status.startsWith("un")) return "red";
        else if (status == "") return "red";
        else if (status == "cancelled") return "grey";
        else if (status == "finished") return "green";
        else if (status == "assigned") return "blue";
        else if (status == "billed") return "green";
        else if (status == "remit") return "blue";
        else if (status == "part") return "orange";
        else if (status == "complete") return "green";
        else if (status == "error") return "red";
        else return "orange";
      } else {
        return "red";
      }
    },
    getServerStatusColor: function (status) {
      if (status) {
        if (status.toLowerCase().indexOf("not") > -1) return "red";
        else if (status == "") return "red";
        else if (status == "waiting") return "blue";
        else if (status == "cancelled") return "grey";
        else return "green";
      } else {
        return "red";
      }
    },
    debounce_search: Util.debounce(function () {
      this.search_details();
    }, 500),
    search_details: function () {
      this.tableData = Util.search(this.work_orders, this.search.toLowerCase());
    },
    viewMoreShow: function (item) {
      this.view_more_item = item;
      this.viewMoreDialog = true;
    },
    viewMoreCancel: function () {
      this.view_more_item = {};
      this.viewMoreDialog = false;
    },
    cancel_order: function (item) {
      this.cancel_reason = "";
      this.cancel_item = item;
      this.cancelDialog = true;
    },
    doCancel: async function () {
      this.$refs.cancel_text_field.focus();
      let result = this.$refs.cancel_text_field.validate();
      if (result) {
        let doc = this.cancel_item;
        let time_stamp = Util.gettime();
        let workorder_created_by = Util.getFromSession("logged_in_emp_name");
        let workorder_cancelled = time_stamp + "-" + workorder_created_by + "- Work Order Cancelled ";
        doc.status = "cancelled";
        doc.server_status = "cancelled";
        doc.cancel_reason = this.cancel_reason;
        if (doc.time_line) {
          doc.time_line.push(workorder_cancelled);
        }
        let instance = this;
        //let db = await DBHandler.getDb("work_orders");
        let db = await WorkOrderDB.get_server_db("work_orders");
        db.put(doc)
          .then(function (response) {
            console.log(response);
            if (response.ok) {
              instance.snackbar_text = "Cancelled Successfully";
              instance.snackbar = true;
            }
          })
          .catch(function (err) {
            instance.snackbar_text = "Error: Please Check Your Internet " + JSON.stringify(err);
            instance.snackbar = true;
            return;
          });
        this.cancel_item = {};
        this.cancelDialog = false;
      }
    },
    getStatus: function (status) {
      if (status == "Received") {
        return "Unbilled";
      }
      return status;
    },
    getClientStatus: function (item) {
      let ret = "";
      if (item.urgent) {
        ret += "Urgent ";
      }
      if (item.vip_client) {
        ret += "VIP ";
      }
      if (item.credit == 1) {
        ret += "Credit ";
      } else if (item.credit == 2) {
        ret += "Trial ";
      }
      if (item.b2b_client_id) {
        ret += "B2B";
      }
      return ret;
    },
    copyItem: function (item) {
      this.copied_item = item;
      this.type = "copy_work_order_dialog";
      this.show_copy = true;
    },
    close_copy_screen: function (e) {
      let ret_msg = "";
      if (typeof e === "string" || e instanceof String) {
        ret_msg = e;
        this.snackbar_text = ret_msg;
        this.snackbar = true;
      }
      //this.edited_item = {};
      this.copied_item = {};
      this.show_copy = false;
      this.type = "";
    },
    close_tech_engagements: function () {
      this.show_engagement = false;
      this.tech_engagement_dialog = false;
    },
    show_tech_engagements: function () {
      this.show_engagement = true;
      this.tech_engagement_dialog = true;
    },
    close_price_view: function () {
      this.show_price_view = false;
      this.price_view_dialog = false;
    },
    show_price: function () {
      this.show_price_view = true;
      this.price_view_dialog = true;
    },
    download_files: function (name) {
      let pres_array = [];
      let aName = [];
      if (name.indexOf(",") > -1) {
        aName = name.split(",");
      } else {
        aName.push(name);
      }
      for (let i = 0; i < aName.length; i++) {
        let fName = aName[i];
        pres_array.push(fName);
      }
      this.pres_array = pres_array;
      this.file_dialog = true;
    },
    download_id: function (id_loc, mode) {
      let item = id_loc + "";
      let dArray = [];
      if (item.indexOf(" | ") > -1) {
        dArray = item.split(" | ");
      } else {
        dArray[0] = "homecollection";
        dArray[1] = item;
      }
      let jwt_token = Util.getFromSession("pg_admin");
      let data = {
        bucket_name: dArray[0],
        key: dArray[1],
        jwt_token: jwt_token,
      };
      let name = dArray[1];
      if (name.indexOf("/") > -1) {
        name = name.substring(name.lastIndexOf("/") + 1);
      }
      if (mode == "view") {
        if (name.endsWith(".pdf")) {
          this.s3_url = id_loc;
          this.show_report = true;
        } else {
          this.s3_url = id_loc;
          this.show_img = true;
        }
        return;
      }
      this.overlay_dk = true;
      let instance = this;
      axios({
        method: "post",
        responseType: "blob",
        url: settings.node_url + "/s3/get_file_v2",
        data: data,
      })
        .then(async function (response) {
          //console.log(response.data);
          if (mode == "download") {
            fileDownload(response.data, name);
          }
          instance.overlay_dk = false;
        })
        .catch(function (error) {
          console.log("error:" + error);
          instance.overlay_dk = false;
          alert(error);
        });
    },
    pdf_close: function () {
      this.show_report = false;
    },
    img_close: function () {
      this.show_img = false;
    },
    close_download_dialog: function () {
      this.file_dialog = false;
    },
    edit_name: function (item) {
      this.edit_item = item;
      this.$router.push("/tech_edit_name_age/" + item._id);
    },
    edit_gpay: function (item) {
      this.edit_item = item;
      this.$router.push("/tech_edit_gpay/" + item._id);
    },
    edit_remarks: function (item) {
      this.edit_item = item;
      this.$router.push("/tech_edit_remarks/" + item._id);
    },
    view_tests: function (item) {
      this.edit_item = item;
      this.$router.push("/tech_view_tests/" + item._id);
    },
    remit_change: async function (item) {
      let id = item._id;
      let doc = await WorkOrderDB.getWithId(id);
      doc.remittance = item.remittance;
      doc.accept_remittance = false;
      try {
        let time_stamp = Util.gettime();
        let user = Util.getFromSession("logged_in_emp_name");
        let new_tl = "";
        if (item.remittance) {
          new_tl = time_stamp + " - " + user + " - Remittance Made.";
        } else {
          new_tl = time_stamp + " - " + user + " - Remittance Cancelled.";
        }
        let old_tl = doc.time_line;
        old_tl.push(new_tl);
        doc.time_line = old_tl;
      } catch (e) {
        console.log("error updating timeline:" + e);
      }
      let result = await WorkOrderDB.doUpdate(doc);
      if (result == "OK") {
        if (item.remittance) {
          this.snackbar_text = "Remittance Made Successfully.";
        } else {
          this.snackbar_text = "Remittance Cancelled.";
        }
        this.snackbar = true;
      } else {
        this.snackbar_text = result;
        this.snackbar = true;
      }
    },
    view_download: function (item) {
      this.edit_item = item;
      if (item.lab_sample_pics) {
        let files = item.lab_sample_pics.toString();
        this.download_files(files);
      } else {
        this.snackbar_text = "No Files To View.";
        this.snackbar = true;
      }
    },
    upload: function (item) {
      this.edit_item = item;
      this.photo_error_msg = "";
      this.show_photo_error = false;
      this.file_upload_dialog = true;
    },
    cancel_upload: function () {
      this.edit_item = {};
      this.photo_error_msg = "";
      this.show_photo_error = false;
      this.file_upload_dialog = false;
      this.uploaded_photos = [];
      this.uploaded_photos_path = [];
    },
    validate_photo: async function () {
      if (this.uploaded_photos_path.length > 0) {
        let doc = this.edit_item;
        let uploaded = this.uploaded_photos_path.toString();
        if (doc.lab_sample_pics) {
          let pics = doc.lab_sample_pics + "," + uploaded;
          doc.lab_sample_pics = pics;
        } else {
          doc.lab_sample_pics = uploaded;
        }
        try {
          let time_stamp = Util.gettime();
          let user = Util.getFromSession("logged_in_emp_name");
          let new_tl = time_stamp + " - " + user + " - Lab Sample Pics Uploaded (" + this.uploaded_photos_path.length + ")";
          let old_tl = doc.time_line;
          old_tl.push(new_tl);
          doc.time_line = old_tl;
        } catch (e) {
          console.log("error updating timeline:" + e);
        }
        let result = await WorkOrderDB.doUpdate(doc);
        if (result == "OK") {
          this.snackbar_text = "Updated Successfully.";
          this.snackbar = true;
          this.cancel_upload();
        } else {
          this.snackbar_text = result;
          this.snackbar = true;
        }
      } else {
        this.photo_error_msg = "Please choose a photo to continue.";
        this.show_photo_error = true;
      }
    },
    upload_photo: async function () {
      try {
        this.show_photo_error = false;
        let upload = document.querySelector("#upload_file");
        if (upload.files[0]) {
          let file_name = upload.files[0].name;
          let flocation = "homecollection/lab_sample_pics/" + Util.getTodayStringForFolderCreation() + "/" + file_name;
          let floc_with_bucket = flocation;
          let jwt_token = Util.getFromSession("pg_admin");

          let formData = new FormData();
          formData.append("upload_file", upload.files[0]);
          formData.append("key", flocation);
          formData.append("bucket_name", "homecollection");
          formData.append("jwt_token", jwt_token);
          this.photo_loading = true;
          upload.disabled = true;
          let instance = this;
          await axios
            .post(settings.node_url + "/s3/upload_file_v3", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(function (response) {
              console.log("response here 23:" + response.data);
              if (response.data == "OK") {
                instance.uploaded_photos.push(file_name);
                instance.uploaded_photos_path.push(floc_with_bucket);
                instance.photo_loading = false;
                upload.disabled = false;
              } else {
                instance.photo_error_msg = response.data + "";
                instance.show_photo_error = true;
                instance.photo_loading = false;
                upload.disabled = false;
                instance.uploaded_photos = [];
                instance.uploaded_photos_path = [];
              }
            })
            .catch(function (error) {
              console.log("error:" + error);
              instance.photo_error_msg = error + "";
              instance.show_photo_error = true;
              instance.photo_loading = false;
              upload.disabled = false;
              instance.uploaded_photos = [];
              instance.uploaded_photos_path = [];
            });
          console.log(this.uploaded_photos.toString());
        } else {
          this.show_photo_error = true;
        }
      } catch (e) {
        console.log("photo error " + e);
        this.show_photo_error = true;
      }
    },
    getReportStatus: function (item) {
      try {
        if (item.server_status == "Billed") {
          if (item.report_path) {
            return true;
          }
        }
        return false;
      } catch (e) {
        console.log(e);
      }
      return false;
    },
    check_not_search_result: function (item) {
      try {
        if (this.mode != "search" && this.check_cancelled(item) == false) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    check_not_search_result_cancelled: function (item) {
      try {
        if (this.mode != "search" && this.check_cancelled(item)) {
          return true;
        }
      } catch (e) {
        console.log(e);
      }
      return false;
    },
    check_search_result: function () {
      try {
        if (this.mode == "search") {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    check_cancelled: function (item) {
      if (item.status == "NA" || item.status == "cancelled") {
        return true;
      }
      return false;
    },
  },
};
</script>
