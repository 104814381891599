<template>
  <div>
    <v-toolbar dense flat>
      <v-text-field class="ml-5" v-model="search"  append-icon="mdi-magnify" label="Search" single-line hide-details @keyup.native="search_details()"></v-text-field>
    </v-toolbar>
    <v-card class="ma-1" v-for="(item,i) of tableData" :key="i">
      <v-card-title  class="subtitle-2">
        {{item.from_name}}
      </v-card-title>
      <v-card-subtitle>
        Msg: {{item.msg_header}} <br>
        Date: {{item.updated}} <br>
        Status: 
        <v-chip
          :color="getColor(item.status)"
          outlined
          x-small
          @click="change_status(item)"
        >
        {{ item.status }}
      </v-chip>
      </v-card-subtitle>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn icon @click="toggle(i)">
          <v-icon>{{ show[i] ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-card-actions>
      <v-expand-transition>
        <div v-show="show[i]">
          <v-divider></v-divider>
          <v-card-text>
            Msg. Description: {{ item.msg_body }}
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>  
    <!-- 
      Message Dialog   
   -->
    <div>
      <v-snackbar v-model="snackbar" :timeout="2000">
        {{ snackbar_text }}
      </v-snackbar>
      <v-overlay :value="overlay" :z-index="2000">
          <v-progress-circular absolute size="64"></v-progress-circular>
      </v-overlay>
    </div>      
  </div>
</template>
<script>
import Util from "@/js/Util";
import NotificationDB from "@/js/database/notification_db";
import { mapState } from "vuex";
export default {
    data () {
      return {
        show: [],
        loading: false,
        search: '',  
        expanded: [],
        singleExpand: false,
        notificationDetails: [],
        tableHeaders: [
          { text: 'Name', align: 'start', sortable: true, value: 'from_name'},
          { text: 'Message', value: 'msg_header' },
          { text: 'Status', value: 'status' },
          { text: 'Date Time', value: 'updated' },
          { text: '', value: 'data-table-expand' },
        ],
        tableData: [],
        item: {},
        snackbar_text: '',
        snackbar: false,
        overlay: false,
      }
    },
    computed: mapState(["notifications"]),
    watch: {
      notifications(newValue, oldValue) {
        console.log("Updating from "+oldValue+" to "+newValue);
        this.updateScreen();
      },
    },
    mounted: async function(){
      this.updateScreen();
    },
    methods: {
      toggle: function(index){
        let val = this.show[index];
        val = !val;
        this.$set(this.show, index, val);
      },
      updateScreen: async function(){
        this.loading = true;
        this.notificationDetails = await NotificationDB.list('all');
        this.tableData = this.notificationDetails;
        this.loading = false;
      },
      search_details: function(){
        let result = Util.search(this.notificationDetails,this.search.toLowerCase());
        console.log(result);
        this.tableData = result;
        console.log('searching....'+this.search);
      },
      change_status: async function(doc){
        doc.status = 'Seen';
        console.log(doc)
        let update = await NotificationDB.doUpdate(doc);
        if(update == 'OK'){
          this.snackbar_text = 'Successfully Updated';
          this.snackbar = true;
          this.updateScreen();
        } else {
          console.log(update);
          this.snackbar_text = 'Error Occurred:'+update;
          this.snackbar = true;
        }
      },
      getColor: function(status) {
        if(status){
          if (status == 'New') return 'blue';
          else return 'grey';
        } else {
          return 'red';
        }
      },
    }
  }
</script>