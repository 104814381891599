<template>
<div>
  <v-data-table
    :headers="tableHeaders"
    :items="tableData"
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    item-key="_id"
    show-expand
    :loading="loading"
    loading-text="Loading... Please wait"
    class="elevation-1"
    dense
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details @keyup.native="search_details()"></v-text-field>
      </v-toolbar>
    </template>
    
    <!-- eslint-disable-next-line -->
    <template v-slot:item.status="{ item }" >
      <v-chip
        :color="getColor(item.status)"
        outlined
        x-small
        @click="change_status(item)"
      >
        {{ item.status }}
      </v-chip>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <v-simple-table width="100%">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Message Detail
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ item.msg_body }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </td>
    </template>
  </v-data-table>
  <!-- 
      Message Dialog   
   -->
    <div>
      <v-snackbar
        v-model="snackbar"
        :timeout="2000"
      >
      {{ snackbar_text }}
      </v-snackbar>
      <v-overlay :value="overlay" :z-index="2000">
          <v-progress-circular absolute size="64"></v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>
<script>
import Util from "@/js/Util";
import NotificationDB from "@/js/database/notification_db";
import { mapState } from "vuex";
export default {
    data () {
      return {
        loading: false,
        search: '',  
        expanded: [],
        singleExpand: false,
        notificationDetails: [],
        tableHeaders: [
          { text: 'Name', align: 'start', sortable: true, value: 'from_name'},
          { text: 'Message', value: 'msg_header' },
          { text: 'Status', value: 'status' },
          { text: 'Date Time', value: 'updated' },
          { text: '', value: 'data-table-expand' },
        ],
        tableData: [],
        item: {},
        snackbar_text: '',
        snackbar: false,
        overlay: false,
      }
    },
    computed: mapState(["notifications"]),
    watch: {
      notifications(newValue, oldValue) {
        console.log("Updating from "+oldValue+" to "+newValue);
        this.updateScreen();
      },
    },
    mounted: async function(){
      this.updateScreen();
    },
    methods: {
      updateScreen: async function(){
        this.loading = true;
        this.notificationDetails = await NotificationDB.list('all');
        this.tableData = this.notificationDetails;
        this.loading = false;
      },
      search_details: function(){
        let result = Util.search(this.notificationDetails,this.search.toLowerCase());
        console.log(result);
        this.tableData = result;
        console.log('searching....'+this.search);
      },
      change_status: async function(doc){
        doc.status = 'Seen';
        console.log(doc)
        let update = await NotificationDB.doUpdate(doc);
        if(update == 'OK'){
          this.snackbar_text = 'Successfully Updated';
          this.snackbar = true;
          this.updateScreen();
        } else {
          console.log(update);
          this.snackbar_text = 'Error Occurred:'+update;
          this.snackbar = true;
        }
      },
      getColor: function(status) {
        if(status){
          if (status == 'New') return 'blue';
          else return 'grey';
        } else {
          return 'red';
        }
      },
    }
  }
</script>
