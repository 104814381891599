<template>
  <v-container>
    <v-card  :loading="loading">
        <v-card-title>{{technician_deatils.name}}</v-card-title>
        <v-card-subtitle>
          Mobile : {{technician_deatils.mobile}}<br>
          Department Name : {{technician_deatils.department_name}}<br>
          Role Name : {{technician_deatils.role_name}}<br>
        </v-card-subtitle>
      </v-card>
    <v-btn-toggle
        v-model="selectedComponent"
        tile
        color="deep-purple accent-3"
        group
      >
        <v-btn value="daily_report">
          Daily 
        </v-btn>

        <v-btn value="weekly_report">
          Weekly
        </v-btn>

        <v-btn value="monthly_report">
          Monthly
        </v-btn>

        <v-btn value="yearly_report">
          Yearly
        </v-btn>
      </v-btn-toggle>
      
      <component :is="selectedComponent"></component>
  </v-container>
</template>
<script>
import monthly_report from './monthly_report.vue';
import daily_report from './daily_report.vue';
import weekly_report from './weekly_report.vue';
import yearly_report from './yearly_report.vue';
import PostgresDB from "@/js/database/postgres_db";
import Util from "@/js/Util";
export default {
  components:{monthly_report,daily_report,weekly_report,yearly_report},
  data () {
      return {
        selectedComponent: 'monthly_report',
        emp_id : Util.getFromSession("logged_in_emp_id"),
        technician_deatils:[],
        loading:false
      }
  },
  mounted:function(){
    this.tech_detail()
  },
  methods:{
    tech_detail: async function(){
      this.loading = true;
      this.technician_deatils = await PostgresDB.getUserDetails(this.emp_id)
      this.loading = false;
      console.log(this.technician_deatils)
    }
  }
}
</script>