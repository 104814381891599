<template>
  <div>
    <v-container fill-height>
      <v-row justify="center" v-if="show_error">
        <v-col cols="12" sm="6" md="6">
          <v-alert type="warning">{{ error_msg }}</v-alert>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" dense>
        <v-col cols="12" sm="4" md="4">
          <v-card>
            <v-toolbar color="orange">
              <v-toolbar-title
                ><h4 style="color:white">Anderson CRM</h4></v-toolbar-title
              >
            </v-toolbar>
            <v-text-field
              class="px-4"
              ref="mobile_field"
              label="Mobile Number"
              v-model="mobile"
              prefix="+91"
              @keydown.enter="get_otp"
              @keydown.esc="dummy_login"
            />

            <v-select
              v-if="shouldShowSelect"
              class="px-4"
              :items="formattedRoles"
              item-value="id"
              item-text="name"
              label="Select Role"
              v-model="selectedRole"
              :rules="roleRules"
              required
            ></v-select>

            <v-checkbox
              class="px-4"
              v-model="remember"
              label="Remember my mobile number"
              @change="remember_change"
            ></v-checkbox>
            <v-footer>
              <v-btn
                :loading="loading1"
                color="primary"
                text
                v-on:click="get_otp"
                >Get OTP</v-btn
              >
            </v-footer>
            <v-footer>
              <v-btn color="green" text @click="get_location"
                >Get location</v-btn
              >
            </v-footer>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card class="mx-auto" tile elevation="2">
        <v-card-title class="headline">
          <v-chip color="orange" text-color="white" label
            ><h3>OTP Verification</h3></v-chip
          >
          <v-spacer></v-spacer>
          <v-btn icon id="close" class="close" @click.native="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-container align="center">
          <v-card align="center">
            <span class="input_label">{{ this.mobile }}</span>
            <br />
            <v-label>One time password (OTP) sent via SMS</v-label>
            <br />
            <span>Enter 6 digit OTP</span>
            <v-text-field
              type="number"
              label="Enter OTP"
              class="px-8"
              counter
              v-model="otp"
              @keydown.enter="login"
            />
          </v-card>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="loading"
            color="primary"
            class="ma-2"
            text
            @click="login()"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import PouchDB from "pouchdb-browser";
import axios from "axios";
import settings from "@/js/database/settings";
import PostgresDB from "@/js/database/postgres_db";
import Util from "@/js/Util";
export default {
  name: "Login",
  data: () => ({
    mobile: "",
    remember: false,
    dialog: false,
    loading: false,
    loading1: false,
    otp: "",
    error_msg: "",
    geocodeApiKey: "AIzaSyCmQTjA5QMVnSb7wglJ34hy_B8A9WGplUc",
    show_error: false,
    formattedRoles: [],
    shouldShowSelect: false, // added
    selectedRole: "", // added
    roleRules: [(v) => !!v || "Role is required"],
  }),
  mounted: function() {
    let log_mobile = Util.getFromLocalStorage("LOGGED_IN_MOBILE");
    if (log_mobile) {
      this.mobile = log_mobile;
      this.remember = true;
    }
    this.$refs.mobile_field.focus();
    //this.fetchRoles();
  },

  methods: {
    clear_msg: function() {
      this.show_error = false;
      this.error_msg = "";
      this.loading = false;
    },

    fetchRoles: async function() {
      let roles = await this.getFinalRoles();
      this.formattedRoles = roles.map((role) => ({
        id: role.id,
        name: role.name,
      }));
      console.log("formatted roles:", this.formattedRoles); // Add this line
    },

    getFinalRoles: async function() {
      try {
        let roleIds = Util.getFromSession("final_role");
        console.log("Role IDs:", roleIds);

        let roles = [];

        if (!roleIds || roleIds.length === 0) {
          console.log("No role IDs found in session.");
          return roles; // Return an empty array
        }

        for (let roleId of roleIds) {
          let roleName = await PostgresDB.getrolename(roleId);
          let roleNames = roleName[0].role_name;
          console.log("Role Name for ID", roleId, ":", roleNames);
          roles.push({ id: roleId, name: roleNames });
        }

        return roles;
      } catch (error) {
        console.error("Error fetching roles:", error);
        return [];
      }
    },
    handleRoleSelection: async function() {
      let finalRoles = Util.getFromSession("final_role");
      await this.fetchRoles(); // Ensure roles are fetched before proceeding

      if (finalRoles && finalRoles.length > 1) {
        this.shouldShowSelect = true;

        this.$watch("selectedRole", async (newVal) => {
          if (newVal) {
            Util.setSession("role_id", newVal);
            let roleNameData = await PostgresDB.getrolename(newVal);
            Util.setSession("role_name", roleNameData[0].role_name);
            location.reload();
          }
        });
      } else if (finalRoles && finalRoles.length === 1) {
        this.shouldShowSelect = false;
        Util.setSession("role_id", finalRoles[0]);
        let roleNameData = await PostgresDB.getrolename(finalRoles[0]);
        Util.setSession("role_name", roleNameData[0].role_name);
        location.reload();
      }
    },
    get_otp: async function() {
      if (this.mobile.length != 10) {
        this.error_msg = "Incorrect Mobile Number";
        this.show_error = true;
        Util.callThisAfter(this.clear_msg);
        return;
      }
      let log_mobile = this.getFromStorage("LOGGED_IN_MOBILE");
      if (log_mobile) {
        if (this.mobile == log_mobile) {
          this.loading1 = true;
          let result = await PostgresDB.login(log_mobile, "");
          if (result == 200) {
            await this.handleRoleSelection();
            await this.write_log();
            // location.reload();
          } else {
            this.loading1 = false;
            console.log("else :" + result);
            if (
              result.indexOf("Error") > -1 ||
              result.indexOf("NO_MATCHES") > -1
            ) {
              this.otp = "";
              this.dialog = false;
              this.error_msg = "User Not Found";
              this.show_error = true;
              Util.callThisAfter(this.clear_msg);
              return;
            } else if (result.indexOf("NEW_APP") > -1) {
              this.otp = "";
              this.dialog = false;
              this.error_msg =
                "Please download and install the new version of this app.";
              this.show_error = true;
              //Util.callThisAfter(this.clear_msg);
              return;
            }
          }
        }
      }
      let data = {
        mobile: this.mobile,
      };
      let instance = this;
      axios({
        method: "post",
        responseType: "text",
        url: settings.node_url + "/sms/send_otp",
        data: data,
        withCredentials: true,
      })
        .then(async function(response) {
          console.log(response.data);
        })
        .catch(function(error) {
          instance.error_msg = error;
          instance.show_error = true;
          Util.callThisAfter(instance.clear_msg);
        });
      this.otp = "";
      this.dialog = true;
    },
    dummy_login: async function() {
      console.log("going to login");
      let result = await PostgresDB.login(this.mobile, ""); // Example mobile numbers

      if (result == 200) {
        console.log("logged in:", result);
        await this.handleRoleSelection();
      } else {
        console.log("else:", JSON.stringify(result));
        if (result.indexOf("Error") > -1 || result.indexOf("NO_MATCHES") > -1) {
          this.otp = "";
          this.dialog = false;
          this.error_msg = "User Not Found";
          this.show_error = true;
          Util.callThisAfter(this.clear_msg);
          return;
        } else if (result.indexOf("NEW_APP") > -1) {
          this.otp = "";
          this.dialog = false;
          this.error_msg =
            "Please download and install the new version of this app.";
          this.show_error = true;
          return;
        }
      }
    },
    get_location2() {
    const successCallback = (position) => {
      // Update latitude and longitude
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
      console.log("Latitude:", this.latitude);
      console.log("Longitude:", this.longitude);

      // Call Geocoding API to convert lat/lon into a location
      this.getAddressFromCoordinates2(this.latitude, this.longitude);
    };

    const errorCallback = (error) => {
      console.error("Error getting location:", error);
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  },

  getAddressFromCoordinates2(latitude, longitude) {
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${this.geocodeApiKey}`;

    fetch(geocodeUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "OK" && data.results.length > 0) {
          // Update the address
          this.address = data.results[0].formatted_address;
          console.log("Address:", this.address);
        } else {
          console.error(`No results found, Status: ${data.status}`);
        }
      })
      .catch((error) => {
        console.error("Error fetching address:", error);
      });
  },
    get_location: function() {
    

      const successCallback = (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        console.log("Latitude:", latitude);
        console.log("Longitude:", longitude);

        // Call Geocoding API to convert lat/lon into a location
        this.getAddressFromCoordinates(latitude, longitude);
      };

      const errorCallback = (error) => {
        console.error("Error getting location:", error);
      
      };

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          successCallback,
          errorCallback
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
       
      }
    },
    getAddressFromCoordinates(latitude, longitude) {
      const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${this.geocodeApiKey}`;

      fetch(geocodeUrl)
        .then((response) => response.json())
        .then((data) => {
        console.log('Full API Response:', data); // Log full response for debugging
          if (data.status === "OK" && data.results.length > 0) {
            const address = data.results[0].formatted_address;
            console.log("Address:", address);
            // You can now display or use this address in your app
          } else {
            console.error(`No results found, Status: ${data.status}`);
          }
      
        })
        .catch((error) => {
          console.error("Error fetching address:", error);
         
        });
    },

    //get_location: function() {
    //const successCallback = (position) => {
    //const latitude = position.coords.latitude;
    // const longitude = position.coords.longitude;
    // console.log("Latitude:", latitude);
    // console.log("Longitude:", longitude);
    // };

    //const errorCallback = (error) => {
    //console.error("Error getting location:", error);
    // };

    // if (navigator.geolocation) {
    //navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    //} else {
    //console.error("Geolocation is not supported by this browser.");
    //}
    //},
    write_log: async function() {
      let db = new PouchDB("login_log");
      let login = {
        _id: "hc:" + Util.getDateForId() + "_" + Util.uuidv4(),
        date: Util.getTodayWithTime(),
        type: settings.type,
        version: settings.version,
        emp_id: Util.getFromSession("logged_in_emp_id"),
        emp_name: Util.getFromSession("logged_in_emp_name"),
        mobile: Util.getFromSession("logged_in_mobile"),
        center: Util.getFromSession("logged_in_tenant_name"),
        department: Util.getFromSession("department_name"),
        role_name: Util.getFromSession("role_name"),
        region: Util.getFromSession("default_region"),
        state: Util.getFromSession("default_state"),
        last_updated_at: Util.getTimeStamp(),
      };
      await db.put(login).catch(function(e) {
        console.log("Error while writing login log:" + e);
      });
    },
    login: async function() {
      if (this.otp.length != 6) {
        this.error_msg = "Incorrect OTP";
        this.show_error = true;
        Util.callThisAfter(this.clear_msg);
        return;
      }
      this.loading = true;
      let data = {
        mobile: this.mobile,
        entered_otp: this.otp,
      };
      let instance = this;
      try {
        // Make the POST request using async/await
        let response = await axios.post(
          settings.node_url + "/sms/check_otp",
          data,
          {
            withCredentials: true,
          }
        );
        let res = response.data;

        if (res === "OTP_MATCH") {
          let log_mobile = this.mobile;
          let result = await PostgresDB.login(log_mobile, "");

          if (result === 200) {
            await this.handleRoleSelection();
            await this.write_log();
            this.dialog = false;
            // location.reload();
          } else {
            console.log("else :" + result);
            if (result.indexOf("Error") > -1) {
              instance.otp = "";
              instance.dialog = false;
              instance.error_msg = "User Not Found";
              instance.show_error = true;
              Util.callThisAfter(instance.clear_msg);
            } else if (
              result.indexOf("NEW_APP") > -1 ||
              result.indexOf("NO_MATCHES") > -1
            ) {
              instance.otp = "";
              instance.dialog = false;
              instance.error_msg =
                "Please download and install the new version of this app.";
              instance.show_error = true;
              return;
            }
          }
        }
      } catch (error) {
        console.log("error:", error);
        instance.error_msg = error.message || "An error occurred during login.";
        instance.show_error = true;
        Util.callThisAfter(this.clear_msg);
      }
    },
    getFromStorage: function(item) {
      if (Util.isMobile()) {
        return Util.getFromLocalStorage(item);
      } else {
        return Util.getFromSession(item);
      }
    },
    setToStorage: function(item, value) {
      if (Util.isMobile()) {
        Util.setLocalStorage(item, value);
      } else {
        Util.setSession(item, value);
      }
    },
    remember_change: function() {
      if (this.remember) {
        Util.setLocalStorage("LOGGED_IN_MOBILE", this.mobile);
      } else {
        Util.setLocalStorage("LOGGED_IN_MOBILE", "");
      }
    },
  },
};
</script>
<style></style>
