var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.tableData,"single-expand":_vm.singleExpand,"options":_vm.options,"server-items-length":_vm.total_technician_length,"expanded":_vm.expanded,"item-key":"_id","show-expand":"","loading":_vm.loading,"loading-text":"Loading... Please wait","footer-props":_vm.footerProps,"dense":""},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},nativeOn:{"keyup":function($event){return _vm.search_details()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.add_technician()}}},[_c('v-icon',[_vm._v("mdi-account-plus-outline")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),(_vm.check_manager())?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}},{key:"item.photo_id_card",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","outlined":"","x-small":""},on:{"click":function($event){return _vm.download_id(item.id_card_location, item.photo_id_card)}}},[_vm._v(" "+_vm._s(_vm.check_id(item.photo_id_card))+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Pincode")]),_c('th',{staticClass:"text-left"},[_vm._v("Area")])])]),_c('tbody',_vm._l((item.allocated_areas),function(thing){return _c('tr',{key:thing._id},[_c('td',[_vm._v(_vm._s(thing.pincode))]),_c('td',[_vm._v(_vm._s(thing.area))])])}),0)]},proxy:true}],null,true)})],1)]}}])}),(_vm.type === 'add_technician_dialog')?_c('v-container',[_c('add_user',{attrs:{"show":_vm.show_add,"item":_vm.item},on:{"close_add_technician":function($event){return _vm.close_add_screen()}}})],1):_vm._e(),_c('div',[_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6 orange lighter-4 white--text"},[_vm._v(" Do you want to delete this record? ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.delete_summary)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"orange darken-1","text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"loading":_vm.loading2,"disabled":_vm.loading2,"color":"orange darken-1","text":""},on:{"click":function($event){return _vm.doDelete()}}},[_vm._v(" Yes ")])],1)],1)],1)],1),_c('div',[_c('v-snackbar',{attrs:{"timeout":2000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")]),_c('v-overlay',{attrs:{"value":_vm.overlay,"z-index":2000}},[_c('v-progress-circular',{attrs:{"absolute":"","size":"64"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }