import Util from "@/js/Util";
import DBHandler from '@/js/database/DBHandler';
import settings from '@/js/database/settings';
import PouchDB from "pouchdb-browser";
// import crypto from 'crypto-pouch'

var PincodeDB = {
  pincode_db: null,
  setup: async function(){
    let remoteUrl = settings.remote_couch_url + "/pin_codes";
    let token = DBHandler.Token; 
    const remoteOptions = {
      fetch: function(url, opts) {
        opts.headers.set("Authorization", "Bearer " + token);
        return PouchDB.fetch(url, opts);
      },
    };
    PincodeDB.pincode_db = new PouchDB(remoteUrl, remoteOptions);
  },
  setup_old: async function(){
    let remoteUrl = settings.remote_couch_url + "/pin_codes";
    PincodeDB.pincode_db = new PouchDB(remoteUrl);
  },
  fetch_data: async function(str){
    var localDb = null;
    if(PincodeDB.pincode_db){
      localDb = PincodeDB.pincode_db;
    } else {
      await PincodeDB.setup();
      localDb = PincodeDB.pincode_db;
    }
    if(localDb){
        let doc = null;
        let num = null;
        try{
          num = parseInt(str);
        }catch(e){
          console.log(e);
        }
        if(num){
          doc = await localDb.allDocs({
            include_docs: true,
            startkey: 'pin:code_'+num,
            endkey: 'pin:code_\ufff0'
          })
        } else {
          doc = await localDb.allDocs({
            include_docs: true,
            startkey: 'pin:code_',
            endkey: 'pin:code_\ufff0'
          })
        }
        var items = [];
        if (doc.total_rows > 0) {
            for (var i = 0; i < doc.rows.length; i++) {
              let item = doc.rows[i].doc;
              if(Util.criteria(item,str)){
                items.push(doc.rows[i].doc); 
              }
              if(items.length > 9){
                break;
              }
            }
        }
        if(items.length == 0){
            items.push({
                pincode: 'No Pincode Found.',
                area: 'NA',
                district: 'NA',
                city: 'NA',
            });
        }
        return items;
    } else {
        console.log('Db is null here....');
    }
  },
};
export default PincodeDB;
