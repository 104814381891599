<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="white">
          <v-toolbar-title><v-chip color="orange" text-color="white" label
              ><h3>Time Line</h3></v-chip>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-timeline
          align-top
          dense
          v-for="(timelinedata, i) in timeline"
          :key="i"
        >
          <v-timeline-item color="pink" small>
            <v-row class="pt-1">
              <v-col cols="3">
                <strong>{{ format(timelinedata)[0] }}</strong>
              </v-col>
              <v-col>
                <strong>{{ format(timelinedata)[2] }}</strong>
                <div class="text-caption">
                  {{ format(timelinedata)[1] }}
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["show", "item"],
  data() {
    return {
      dialog: this.show,
      time_line: this.item,
      timeline: this.item.time_line,
    };
  },
  methods: {
    close: function(e) {
      this.dialog = false;
      this.$emit("close_time_line", e);
    },
    format: function(item) {
      return item.split("-");
    },
  },
};
</script>
