let sms_template = {
  home_collection_cancellation: function (reschedule_url) {
    var message =
      "We have cancelled your home collection request based on your request from Anderson Diagnostics & Labs. Kindly reschedule your appointment request by click here " +
      reschedule_url;
    message = encodeURIComponent(message);
    message = "&templateid=1207162787348873286&sms=" + message;
    return message;
  },
  home_collection_tech_change: function (technician_name, mobile_number, url) {
    var message =
      "Technician has been changed due to operational reason, for collecting your samples from Anderson Diagnostics & Labs. " +
      technician_name +
      " will visit your home for sample collection. Technician mobile number is " +
      mobile_number +
      ". Please click here to view about technician details " +
      url;
    message = encodeURIComponent(message);
    message = "&templateid=1207162787337166061&sms=" + message;
    console.log(message);
    return message;
  },
  bill_details: function (bill_no, test_name, date) {
    var message =
      "Received with thanks " +
      bill_no +
      " towards inv " +
      test_name +
      " dt " +
      date +
      " - Anderson Diagnostics & Labs. contact 9176677700 for any mismatch";
    message = encodeURIComponent(message);
    message = "&templateid=1207162787374145483&sms=" + message;
    return message;
  },
  work_order_patient_otp: function (otp, url) {
    var message =
      otp +
      " is your OTP for ordering the tests. Please visit " +
      url +
      " for the test allocated to you and share this OTP with the technician - Anderson Diagnostic Labs ";
    message = encodeURIComponent(message);
    message = "&templateid=1207165760370268539&sms=" + message;
    return message;
  },
  login_otp: function (otp) {
    var message = otp + " is your OTP to login - Anderson Diagnostics & Labs";
    message = encodeURIComponent(message);
    message = "&templateid=1207162445556927603&sms=" + message;
    return message;
  },
  sample_collection: function (patient_name, technician_name, datetime, mobile, technician_url) {
    //var message = "Thank you " + patient_name + " for choosing Anderson diagnostics. " + technician_name + " will reach you by " + datetime + ". please ask for 9 digit lab number from him after sample collection. Please click here to view about technician details " + technician_url;
    //var message = "Thank you "+patient_name+" for choosing Anderson diagnostics. "+technician_name+" will reach you by " + datetime + ". Please click here to view about technician details " + technician_url;
    var message =
      "Thank you " +
      patient_name +
      " for choosing Anderson diagnostics. " +
      technician_name +
      " will reach you by " +
      datetime +
      ". Technician mobile number is " +
      mobile +
      ". Please click here to view about technician details " +
      technician_url;
    message = encodeURIComponent(message);
    message = "&templateid=1207162787314553406&sms=" + message;
    return message;
  },
};
export default sms_template;
