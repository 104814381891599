<template>
  <div>
    <div>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="11" sm="5">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Choose a Week"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="datesProxy"
              no-title
              scrollable
              range
              @change="
                $refs.menu.save(dates);
                load_data(dates[0]);
                menu = false;
              "
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(dates);
                  load_data(dates[0]);
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </div>
    <v-container>
      <v-card>
        <v-toolbar color="blue lighter-1" dark dense>
          <v-toolbar-title>Weekly Cases Report {{ dateRangeText }}</v-toolbar-title>
        </v-toolbar>
        <v-data-table
          :headers="tableHeaders"
          :items="tableData"
          item-key="_id"
          :loading="loading"
          loading-text="Loading... Please wait"
          class="elevation-1"
          hide-default-footer
          dense
        >
          <!-- eslint-disable-next-line -->
          <template v-slot:item.collection="{ item }">
            {{ format(item.collection) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.credit="{ item }">
            {{ format(item.credit) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.b2b="{ item }">
            {{ format(item.b2b) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.trial="{ item }">
            {{ format(item.trial) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.received="{ item }">
            {{ format(item.received) }}
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-toolbar color="blue lighter-1" dark dense>
          <v-toolbar-title>Weekly Cases Report {{ dateRangeText }}</v-toolbar-title>
        </v-toolbar>
        <BarChart :item="weekly_report_chartData" :key="reload"></BarChart>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-toolbar color="blue lighter-1" dark dense>
          <v-toolbar-title>Weekly Collection {{ dateRangeText }}</v-toolbar-title>
        </v-toolbar>
        <BarChart :item="weekly_collection_chartData" :key="reload2"></BarChart>
      </v-card>
    </v-container>
    <!-- 
      Message Dialog   
    -->
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>
<script>
import moment from "moment";
import DashboardDB from "@/js/database/dashboard_db";
import BarChart from "@/components/work_order/dashboard/bar_chat.vue";
import Util from "@/js/Util";
export default {
  components: {
    BarChart,
  },
  name: "Dashboard",
  data: () => ({
    dates: [],
    menu: false,
    loading: false,
    snackbar: false,
    snackbar_text: "",
    reload: 0,
    reload2: 0,
    doc: {},
    tableHeaders: [
      { text: "Date", value: "date" },
      { text: "Assigned", value: "assigned" },
      { text: "Finished", value: "finished" },
      { text: "Cancelled", value: "cancelled" },
      { text: "Pending", value: "pending" },
      { text: "Total Collection", value: "collection" },
      { text: "Received", value: "received" },
      { text: "Credit", value: "credit" },
      { text: "B2B", value: "b2b" },
      { text: "Trial", value: "trial" },
    ],
    tableData: [],
    weekly_collection_chartData: {
      labels: [],
      datasets: [
        {
          label: "Total Collection",
          type: "bar",
          stack: "Total Collection",
          backgroundColor: "#9999ff",
          data: [],
        },
        {
          label: "Received",
          type: "bar",
          stack: "Received",
          backgroundColor: "#b3ffb3",
          data: [],
        },
        {
          label: "Credit",
          type: "bar",
          stack: "Credit",
          backgroundColor: "#cccccc",
          data: [],
        },
        {
          label: "B2B",
          type: "bar",
          stack: "B2B",
          backgroundColor: "#ffa500",
          data: [],
        },
        {
          label: "Trial",
          type: "bar",
          stack: "Trial",
          backgroundColor: "#2db83d",
          data: [],
        },
      ],
    },
    weekly_report_chartData: {
      labels: [],
      datasets: [
        {
          label: "Assigned",
          type: "bar",
          stack: "Assigned",
          backgroundColor: "#9999ff",
          data: [],
        },
        {
          label: "Fineshed",
          type: "bar",
          stack: "Fineshed",
          backgroundColor: "#b3ffb3",
          data: [],
        },
        {
          label: "Cancelled",
          type: "bar",
          stack: "Cancelled",
          backgroundColor: "#cccccc",
          data: [],
        },
        {
          label: "Pending",
          type: "bar",
          stack: "Pending",
          backgroundColor: "#ffa500",
          data: [],
        },
      ],
    },
  }),
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    datesProxy: {
      get() {
        return this.dates;
      },
      set([start_date]) {
        let end_week = moment(start_date, "YYYY-MM-DD").add(6, "days").toDate();
        var end_date = moment(end_week).format("YYYY-MM-DD");
        this.dates = [start_date, end_date];
      },
    },
  },
  mounted: function () {
    var startOfWeek = moment().subtract(1, "weeks").startOf("week").toDate();
    var endOfWeek = moment().subtract(1, "weeks").endOf("week").toDate();
    var start = moment(startOfWeek).format("YYYY-MM-DD");
    var end = moment(endOfWeek).format("YYYY-MM-DD");
    this.dates = [start, end];
    this.load_data(start);
  },
  methods: {
    format: function (str) {
      return Util.formatMoney(str);
    },
    load_data: async function (start) {
      let calendar = moment(start, "YYYY-MM-DD");
      if (calendar.isAfter()) {
        this.snackbar_text = "Future Date May Not Have Data To Show.";
        this.snackbar = true;
        return;
      }
      this.tableData = [];
      let year = calendar.year();
      let doc_id = "daily_" + year;
      // console.log(doc_id + "  >" + start);
      this.doc = await DashboardDB.get_one(doc_id);
      let data = this.doc.data;
      let total_assigned = 0;
      let total_cancelled = 0;
      let total_finished = 0;
      let total_pending = 0;
      let total_collection = 0;
      let total_credit = 0;
      let total_b2b = 0;
      let total_trial = 0;
      let total_received = 0;
      let labels = [];
      let assigned = [];
      let cancelled = [];
      let finished = [];
      let pending = [];
      let collection = [];
      let received = [];
      let credit = [];
      let b2b = [];
      let trial = [];
      for (let i = 0; i < 7; i++) {
        let cal = moment(start, "YYYY-MM-DD");
        let this_cal = cal.add(i, "days");
        let this_date = this_cal.format("YYYY-MM-DD");
        // console.log(i + "  >><< " + this_date);
        let today_data = data[this_date];
        if (today_data) {
          today_data.date = this_date;
          total_assigned += today_data.assigned;
          total_cancelled += today_data.cancelled;
          total_finished += today_data.finished;
          total_pending += today_data.pending;
          total_collection += today_data.collection;
          total_credit += today_data.credit;
          total_b2b += today_data.b2b;
          total_trial += today_data.trial;
          total_received += today_data.received;
          this.tableData.push(today_data);
          labels.push(this_date);
          assigned.push(today_data.assigned);
          cancelled.push(today_data.cancelled);
          finished.push(today_data.finished);
          pending.push(today_data.pending);

          collection.push(today_data.collection);
          received.push(today_data.received);
          credit.push(today_data.credit);
          b2b.push(today_data.b2b);
          trial.push(today_data.trial);
        }
      }
      if (this.tableData.length == 0) {
        this.snackbar_text = "No Data Found For The Selected Dates";
        this.snackbar = true;
        this.reset_graph();
        return;
      }
      let total = {
        date: "Total",
        assigned: total_assigned,
        cancelled: total_cancelled,
        finished: total_finished,
        pending: total_pending,
        collection: total_collection,
        credit: total_credit,
        b2b: total_b2b,
        trial: total_trial,
        received: total_received,
      };
      this.tableData.push(total);
      this.chart_cases_data(labels, assigned, cancelled, finished, pending);
      this.chart_collection_data(labels, collection, received, credit, b2b, trial);
    },
    chart_cases_data: function (labels, assigned, cancelled, finished, pending) {
      this.weekly_report_chartData.labels = labels;
      this.weekly_report_chartData.datasets[0].data = assigned;
      this.weekly_report_chartData.datasets[1].data = finished;
      this.weekly_report_chartData.datasets[2].data = cancelled;
      this.weekly_report_chartData.datasets[3].data = pending;
      this.reload += 1;
    },
    chart_collection_data: function (labels, collection, received, credit, b2b, trial) {
      this.weekly_collection_chartData.labels = labels;
      this.weekly_collection_chartData.datasets[0].data = collection;
      this.weekly_collection_chartData.datasets[1].data = received;
      this.weekly_collection_chartData.datasets[2].data = credit;
      this.weekly_collection_chartData.datasets[3].data = b2b;
      this.weekly_collection_chartData.datasets[4].data = trial;
      this.reload2 += 1;
    },
    reset_graph: function () {
      this.weekly_report_chartData.labels = [];
      this.weekly_report_chartData.datasets[0].data = [];
      this.weekly_report_chartData.datasets[1].data = [];
      this.weekly_report_chartData.datasets[2].data = [];
      this.weekly_report_chartData.datasets[3].data = [];
      this.reload += 1;

      this.weekly_collection_chartData.labels = [];
      this.weekly_collection_chartData.datasets[0].data = [];
      this.weekly_collection_chartData.datasets[1].data = [];
      this.weekly_collection_chartData.datasets[2].data = [];
      this.weekly_collection_chartData.datasets[3].data = [];
      this.weekly_collection_chartData.datasets[4].data = [];
      this.reload2 += 1;
    },
  },
};
</script>
