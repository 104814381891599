import DBHandler from "@/js/database/DBHandler";
import Util from "@/js/Util";
import store from "@/store";
import moment from "moment";
import couch_db from "@/js/database/couch_db";
import PouchDB from "pouchdb-browser";

var WorkOrderDB = {
  handle_changes: Util.debounce(function () {
    WorkOrderDB.updateStore();
  }, 500),
  updateStore: function () {
    let work_order = store.getters.getWorkOrder;
    work_order = "updated: " + new Date().getTime();
    console.log(work_order);
    store.commit("setWorkOrder", work_order);
  },
  get_server_db: function (name) {
    let db_name = DBHandler.resolve_name(name);
    let dbUrl = "https://webuser:cka0t20iwlxf@db-2.andrsn.in/" + db_name;
    const db = new PouchDB(dbUrl);
    return db;
  },
  getWithId: async function (id) {
    var localDb = await DBHandler.getDb("work_orders");
    if (localDb) {
      let result = await localDb
        .get(id)
        .then(function (doc) {
          return doc;
        })
        .catch(function (err) {
          console.log(err);
          return "ERROR";
        });
      return result;
    }
  },
  list: async function (this_date) {
    console.log("Fetch data > list() > " + this_date);
    let localDb = await DBHandler.getDb("work_orders");
    var items = [];
    if (localDb) {
      var doc = await localDb.allDocs({
        include_docs: true,
        startkey: "work_order:" + this_date,
        endkey: "work_order:" + this_date + "\ufff0",
      });
      if (doc.total_rows > 0) {
        for (var i = 0; i < doc.rows.length; i++) {
          if (doc.rows[i].doc.name !== undefined) {
            let work_doc = doc.rows[i].doc;
            let app_dt_time = work_doc.appointment_date + " " + work_doc.appointment_time;
            let sort_time = Util.parseAppTime(app_dt_time).valueOf();
            work_doc.sort_time = sort_time;
            items.push(work_doc);
          }
        }
        items.sort(function (a, b) {
          return a.sort_time - b.sort_time;
        });
      }
      console.log("items length:" + items.length);
      return items;
    } else {
      console.log("Db is null here....work order db list ");
    }
  },
  list_remote_data: async function (this_date) {
    console.log("Remote Fetch data > list_remote_data() > " + this_date);
    var items = [];
    let name = DBHandler.resolve_name("work_orders");
    let remoteDb = await couch_db.get_db(name);
    if (remoteDb) {
      var doc2 = await remoteDb.allDocs({
        include_docs: true,
        startkey: "work_order:" + this_date,
        endkey: "work_order:" + this_date + "\ufff0",
      });
      if (doc2.total_rows > 0) {
        for (var i2 = 0; i2 < doc2.rows.length; i2++) {
          if (doc2.rows[i2].doc.name !== undefined) {
            let work_doc = doc2.rows[i2].doc;
            let app_dt_time = work_doc.appointment_date + " " + work_doc.appointment_time;
            let sort_time = Util.parseAppTime(app_dt_time).valueOf();
            work_doc.sort_time = sort_time;
            items.push(work_doc);
          }
        }
        items.sort(function (a, b) {
          return a.sort_time - b.sort_time;
        });
      }
      console.log("items length:" + items.length);
      return items;
    } else {
      console.log("Db is null here....work order remote db list ");
    }
  },
  assigned_list: async function (id) {
    var localDb = await DBHandler.getDb("work_orders");
    var items = [];
    if (localDb) {
      var doc = await localDb.allDocs({
        include_docs: true,
        startkey: "work_order:",
        endkey: "work_order:\ufff0",
      });
      if (doc.total_rows > 0) {
        for (var i = 0; i < doc.rows.length; i++) {
          if (doc.rows[i].doc.name !== undefined) {
            if (doc.rows[i].doc.assigned_id !== undefined && doc.rows[i].doc.assigned_id == id) {
              let assigned_doc = doc.rows[i].doc;
              let app_dt_time = assigned_doc.appointment_date + " " + assigned_doc.appointment_time;
              let sort_time = Util.parseAppTime(app_dt_time).valueOf();
              assigned_doc.sort_time = sort_time;
              items.push(assigned_doc);
            }
          }
        }
        items.sort(function (a, b) {
          return b.sort_time - a.sort_time;
        });
      }
      if (items.length == 0) {
        items.push({
          name: "No Appointments Found.",
          age: "NA",
          gender: "NA",
          status: "NA",
          appointment_date: "NA",
        });
      }
      console.log("items length:" + items.length);
      return items;
    } else {
      console.log("Db is null here....");
    }
  },
  billed_list: async function (status, server_status) {
    let to_day = store.getters.getToday;
    let calendar = moment(to_day, "YYYY-MM-DD HH:mm:ss", true);
    let end_date = calendar.format("YYYY-MM-DD");
    calendar = calendar.subtract(7, "days");
    let start_date = calendar.format("YYYY-MM-DD");
    console.log("billed data > list() > " + start_date + " till " + end_date);
    var localDb = await DBHandler.getDb("work_orders");
    if (localDb) {
      var doc = await localDb.allDocs({
        include_docs: true,
        startkey: "work_order:" + start_date,
        endkey: "work_order:" + end_date + "\ufff0",
      });
      var items = [];
      if (doc.total_rows > 0) {
        for (var i = 0; i < doc.rows.length; i++) {
          if (doc.rows[i].doc.name !== undefined) {
            if (doc.rows[i].doc.status !== undefined && doc.rows[i].doc.status == status && doc.rows[i].doc.server_status == server_status) {
              items.push(doc.rows[i].doc);
            }
          }
        }
        if (server_status == "Billed") {
          items.sort(function (a, b) {
            return new Date(Date.parse(b.updated_at)) - new Date(Date.parse(a.updated_at));
          });
        } else {
          items.sort(function (a, b) {
            return new Date(Date.parse(a.updated_at)) - new Date(Date.parse(b.updated_at));
          });
        }
      }
      if (items.length == 0) {
        items.push({
          name: "No Appointments Found.",
          age: "NA",
          gender: "NA",
          status: "NA",
          appointment_date: "NA",
        });
      }
      console.log("items length:" + items.length);
      return items;
    } else {
      console.log("Db is null here....");
    }
  },
  doUpdate: async function (doc) {
    var localDb = await DBHandler.getDb("work_orders");
    if (localDb) {
      let result = localDb
        .put(doc)
        .then(function (response) {
          console.log(response);
          if (response.ok) {
            console.log("updated doc successfully.");
            return "OK";
          }
        })
        .catch(function (err) {
          console.error(err);
          return "ERROR:" + err;
        });
      return result;
    } else {
      console.log("Db is null here....");
      return "ERROR";
    }
  },
  doUpdate2: async function (doc) {
    var localDb = WorkOrderDB.get_server_db("work_orders");
    if (localDb) {
      let result = localDb
        .put(doc)
        .then(function (response) {
          console.log(response);
          if (response.ok) {
            console.log("updated doc successfully.");
            return "OK";
          }
        })
        .catch(function (err) {
          console.error(err);
          return "ERROR:" + err;
        });
      return result;
    } else {
      console.log("Db is null here....");
      return "ERROR";
    }
  },
  Update_amount_deposit: async function (doc) {
    var localDb = await DBHandler.getDb("work_orders");
    if (localDb) {
      let result = localDb
        .bulkDocs(doc)
        .then(function (response) {
          if (response[0].ok) {
            console.log("updated doc successfully.");
            result = "OK";
            return "OK";
          }
        })
        .catch(function (err) {
          console.error(err);
          return "ERROR:" + err;
        });
      return result;
    } else {
      console.log("Db is null here....");
      return "ERROR";
    }
  },
  tech_Aggregate_view: async function (id, this_date) {
    console.log("TechEngagement > " + this_date);
    var localDb = await DBHandler.getDb("work_orders");
    if (localDb) {
      var doc = await localDb.allDocs({
        include_docs: true,
        startkey: "work_order:" + this_date,
        endkey: "work_order:" + this_date + "\ufff0",
      });
      var items = [];
      if (doc.total_rows > 0) {
        for (var i = 0; i < doc.rows.length; i++) {
          if (doc.rows[i].doc.name !== undefined) {
            //console.log('assigned_id:'+doc.rows[i].doc.assigned_id);
            if (doc.rows[i].doc.assigned_id !== undefined && doc.rows[i].doc.assigned_id == id) {
              items.push(doc.rows[i].doc);
            }
          }
        }
      }
      let total_assigned = 0;
      let total_finished = 0;
      let total_cancelled = 0;
      let total_pending = 0;
      let tec_doc = {
        total_assigned: 0,
        total_finished: 0,
        total_cancelled: 0,
        total_pending: 0,
        total_amount: 0,
        amount_deposit: 0,
        amount_deposited_status: "",
        recived_sample: [],
        assigned_list: [],
      };
      let time = [];
      let total = 0;
      let collected = 0;
      let accepted = 0;
      tec_doc.total_amount = total;
      tec_doc.amount_collected = collected;
      tec_doc.amount_accepted = accepted;
      for (let j = 0; j < items.length; j++) {
        let item = items[j];
        if (item.assigned_id) {
          total_assigned++;
          tec_doc.total_assigned = total_assigned;
          if (item.status == "Finished") {
            total_finished++;
            tec_doc.total_finished = total_finished;
          } else if (item.status == "cancelled") {
            total_cancelled++;
            tec_doc.total_cancelled = total_cancelled;
          } else {
            total_pending++;
            tec_doc.total_pending = total_pending;
          }
          time.push(item.appointment_time);
          if (Util.isNotEmpty(item.amount_received)) {
            total += Util.getNumbers(item.amount_received + "");
            if (item.remittance) {
              collected += Util.getNumbers(item.amount_received + "");
            }
            if (item.accept_remittance) {
              accepted += Util.getNumbers(item.amount_received + "");
            }
          }
          tec_doc.total_amount = total;
          tec_doc.amount_collected = collected;
          tec_doc.amount_accepted = accepted;
          if (Util.isNotEmpty(time)) {
            time.sort();
            tec_doc.time_till = time[time.length - 1];
          } else {
            tec_doc.time_till = "";
          }
          tec_doc.recived_sample = item.recived_sample;
          tec_doc.assigned_list.push(item);
        }
        //console.log('work order:'+ JSON.stringify(item));
      }

      let ret_array = [];
      ret_array.push(tec_doc);
      console.log("array length:" + ret_array.length);
      return ret_array;
    } else {
      console.log("Db is null here....work order db list ");
    }
  },
  aggregate_view: async function (start_date, end_date, local) {
    console.log("TechEngagement > AggregateView:" + start_date);
    if (end_date == "") {
      end_date = start_date;
    }
    var localDb = null;
    if (local) {
      localDb = await DBHandler.getDb("work_orders");
    } else {
      localDb = await couch_db.get_db("chennai1_work_orders_log");
    }
    if (localDb) {
      var doc = await localDb.allDocs({
        include_docs: true,
        startkey: "work_order:" + start_date,
        endkey: "work_order:" + end_date + "\ufff0",
      });
      var items = [];
      if (doc.total_rows > 0) {
        for (var i = 0; i < doc.rows.length; i++) {
          if (doc.rows[i].doc.name !== undefined) {
            items.push(doc.rows[i].doc);
          }
        }
      }
      let total_assigned = 0;
      let total_finished = 0;
      let total_accounted = 0;
      let total_cancelled = 0;
      let total_pending = 0;
      let tec_doc = {
        total_assigned: 0,
        total_finished: 0,
        total_accounted: 0,
        total_cancelled: 0,
        total_pending: 0,
        total_amount: 0,
        cash: 0,
        gpay: 0,
        hc_charges: 0,
        assigned_list: [],
      };
      let time = [];
      let total = 0;
      let collected = 0;
      let cash = 0;
      let gpay = 0;
      let hc_charges = 0;
      for (let j = 0; j < items.length; j++) {
        let item = items[j];
        if (item.assigned_id) {
          total_assigned++;
          tec_doc.total_assigned = total_assigned;
          if (item.status == "Finished") {
            total_finished++;
            tec_doc.total_finished = total_finished;
            let glu_pp_only = WorkOrderDB.glucose_pp_only(item);
            if (glu_pp_only == false) {
              total_accounted++;
              tec_doc.total_accounted = total_accounted;
            }
          } else if (item.status == "cancelled") {
            total_cancelled++;
            tec_doc.total_cancelled = total_cancelled;
          } else {
            total_pending++;
            tec_doc.total_pending = total_pending;
          }
          time.push(item.appointment_time);
          if (Util.isNotEmpty(item.amount_received)) {
            let rec_amount = Util.getNumbers(item.amount_received + "");
            total += rec_amount;
            if (item.accept_remittance) {
              collected += rec_amount;
            }
            if (item.payment_method) {
              if (item.payment_method == "cash") {
                cash += rec_amount;
              } else if (item.payment_method == "gpay") {
                gpay += rec_amount;
              }
            }
            if (item.hc_charges) {
              hc_charges += Util.getNumbers(item.hc_charges);
            }
          }

          tec_doc.total_amount = total;
          tec_doc.amount_collected = collected;
          tec_doc.cash = cash;
          tec_doc.gpay = gpay;
          tec_doc.hc_charges = hc_charges;
          if (Util.isNotEmpty(time)) {
            time.sort();
            tec_doc.time_till = time[time.length - 1];
          } else {
            tec_doc.time_till = "";
          }
          tec_doc.assigned_list.push(item);
        }
        //console.log('work order:'+ JSON.stringify(item));
      }

      let ret_array = [];
      ret_array.push(tec_doc);
      console.log("array length:" + ret_array.length);
      return ret_array;
    } else {
      console.log("Db is null here....work order db list ");
    }
  },
  glucose_pp_only: function (doc) {
    try {
      let test_items = doc.test_items;
      if (test_items) {
        if (test_items.length == 1) {
          for (let i = 0; i < test_items.length; i++) {
            let item = test_items[i];
            if (item.invest_id == 177 || item.invest_id == 181) {
              return true;
            }
          }
        }
      }
    } catch (e) {
      console.log("exception in glucose_pp_only");
    }
    return false;
  },
  canceled_work_order: async function (this_date) {
    console.log("TechEngagement > " + this_date);
    var localDb = await DBHandler.getDb("work_orders");
    if (localDb) {
      var doc = await localDb.allDocs({
        include_docs: true,
        startkey: "work_order:" + this_date,
        endkey: "work_order:" + this_date + "\ufff0",
      });
      var items = [];
      if (doc.total_rows > 0) {
        for (var i = 0; i < doc.rows.length; i++) {
          if (doc.rows[i].doc.assigned_id !== undefined && doc.rows[i].doc.status == "cancelled") {
            items.push(doc.rows[i].doc);
          }
        }
      }
      return items;
    } else {
      console.log("Db is null here....work order db list ");
    }
  },
  tech_engagements: async function (start_date, end_date, technicians, local) {
    console.log("TechEngagement > " + start_date);
    if (end_date == "") {
      end_date = start_date;
    }
    var localDb = null;
    if (local) {
      localDb = await DBHandler.getDb("work_orders");
    } else {
      localDb = await couch_db.get_db("chennai1_work_orders_log");
    }
    if (localDb) {
      var doc = await localDb.allDocs({
        include_docs: true,
        startkey: "work_order:" + start_date,
        endkey: "work_order:" + end_date + "\ufff0",
      });
      var items = [];
      if (doc.total_rows > 0) {
        for (var i = 0; i < doc.rows.length; i++) {
          if (doc.rows[i].doc.name !== undefined) {
            items.push(doc.rows[i].doc);
          }
        }
      }
      let ret_array = [];
      for (let i = 0; i < technicians.length; i++) {
        let tech = technicians[i];
        let assigned = 0;
        let finished = 0;
        let cancelled = 0;
        let pending = 0;
        let tech_doc = {
          _id: tech.emp_id,
          name: tech.name,
          assigned: 0,
          finished: 0,
          cancelled: 0,
          pending: 0,
          amount: 0,
          amount_collected: 0,
          amount_deposit: 0,
          amount_deposited_status: "",
          cash: 0,
          gpay: 0,
          hc_charges: 0,
          assigned_list: [],
        };
        let time = [];
        let total = 0;
        let collected = 0;
        let cash = 0;
        let gpay = 0;
        let hc_charges = 0;
        for (let j = 0; j < items.length; j++) {
          let item = items[j];
          if (tech.emp_id == item.assigned_id) {
            assigned++;
            tech_doc.assigned = assigned;
            if (item.status == "Finished") {
              finished++;
              tech_doc.finished = finished;
            } else if (item.status == "cancelled") {
              cancelled++;
              tech_doc.cancelled = cancelled;
            } else {
              pending++;
              tech_doc.pending = pending;
            }
            time.push(item.appointment_time);
            if (Util.isNotEmpty(item.amount_received)) {
              let rec_amount = Util.getNumbers(item.amount_received + "");
              total += rec_amount;
              if (item.accept_remittance) {
                collected += rec_amount;
              }
              if (item.payment_method) {
                if (item.payment_method == "cash") {
                  cash += rec_amount;
                } else if (item.payment_method == "gpay") {
                  gpay += rec_amount;
                }
              }
              if (item.hc_charges) {
                hc_charges += Util.getNumbers(item.hc_charges);
              }
            }
            tech_doc.amount = total;
            tech_doc.amount_collected = collected;
            tech_doc.cash = cash;
            tech_doc.gpay = gpay;
            tech_doc.hc_charges = hc_charges;
            if (Util.isNotEmpty(time)) {
              time.sort();
              tech_doc.time_till = time[time.length - 1];
            } else {
              tech_doc.time_till = "";
            }

            tech_doc.assigned_list.push(item);
          }
        }
        ret_array.push(tech_doc);
      }

      console.log("array length:" + ret_array.length);
      return ret_array;
    } else {
      console.log("Db is null here....work order db list ");
    }
  },
  tech_sample_verification: async function (this_date, technicians) {
    console.log("TechEngagement > " + this_date);
    var localDb = await DBHandler.getDb("work_orders");
    if (localDb) {
      var doc = await localDb.allDocs({
        include_docs: true,
        startkey: "work_order:" + this_date,
        endkey: "work_order:" + this_date + "\ufff0",
      });
      var items = [];
      if (doc.total_rows > 0) {
        for (var i = 0; i < doc.rows.length; i++) {
          if (doc.rows[i].doc.name !== undefined) {
            items.push(doc.rows[i].doc);
          }
        }
      }
      let ret_array = [];
      for (let i = 0; i < technicians.length; i++) {
        let tech = technicians[i];
        let assigned = 0;
        let finished = 0;
        let cancelled = 0;
        let pending = 0;
        let tech_doc = {
          _id: tech.emp_id,
          name: tech.name,
          assigned: 0,
          finished: 0,
          cancelled: 0,
          pending: 0,
          amount: 0,
          total_recevied_sample: 0,
          assigned_list: [],
          lab_sample_pics: "",
        };
        let time = [];
        let total = 0;
        let total_pics = 0;
        let accept_count = 0;
        for (let j = 0; j < items.length; j++) {
          let item = items[j];
          if (tech.emp_id == item.assigned_id) {
            assigned++;
            tech_doc.assigned = assigned;
            if (item.status == "Finished") {
              finished++;
              tech_doc.finished = finished;
            } else if (item.status == "cancelled") {
              cancelled++;
              tech_doc.cancelled = cancelled;
            } else {
              pending++;
              tech_doc.pending = pending;
            }
            time.push(item.appointment_time);
            if (Util.isNotEmpty(item.total)) {
              total += Util.getNumbers(item.total + "");
            }
            tech_doc.amount_received = total;

            if (item.lab_sample_pics) {
              let aLabPics = item.lab_sample_pics.split(",");
              total_pics += aLabPics.length;
            }
            if (item.lab_sample_pics_accepted) {
              accept_count += 1;
            }
            tech_doc.total_sample_accepted = accept_count;
            tech_doc.total_recevied_sample = total_pics;
            tech_doc.assigned_list.push(item);
          }
          //console.log('work order:'+ JSON.stringify(item));
        }
        ret_array.push(tech_doc);
        //console.log('engagement: '+tech.emp_id);
      }

      console.log("array length:" + ret_array.length);
      return ret_array;
    } else {
      console.log("Db is null here....work order db list ");
    }
  },
};
export default WorkOrderDB;
