
import DBHandler from '@/js/database/DBHandler';
let com_center = {
    send_msg: async function(message){
        let com_db = await DBHandler.getDb('com_center');
        let result = await com_db.put(message).then(function(response) {
            console.log(response);
            if(response.ok){
                return 'OK';
            }
        }).catch(function (err) {
            console.log(err);
            result = 'Error: '+err;
        });
        return result;
    }
}
export default com_center;