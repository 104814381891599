


let cghs_data = {
    cghs_map: null,
    get: function(invest_id){
        try{
            if(cghs_data.cghs_map == null){
                cghs_data.set();
            }
            if(cghs_data.cghs_map.has(invest_id)){
                return cghs_data.cghs_map.get(invest_id);
            } else {
                return 0;
            }
        }catch(e){
            console.log(e);
        }
    },
    set: function(){
        cghs_data.cghs_map = new Map();
        cghs_data.cghs_map.set(645,250);
        cghs_data.cghs_map.set(3978,575);
        cghs_data.cghs_map.set(3792,460);
        cghs_data.cghs_map.set(630,2000);
        cghs_data.cghs_map.set(631,1000);
        cghs_data.cghs_map.set(835,200);
        cghs_data.cghs_map.set(836,400);
        cghs_data.cghs_map.set(837,150);
        cghs_data.cghs_map.set(780,2500);
        cghs_data.cghs_map.set(819,2875);
        cghs_data.cghs_map.set(648,1500);
        cghs_data.cghs_map.set(712,2000);
        cghs_data.cghs_map.set(629,1000);
        cghs_data.cghs_map.set(628,750);
        cghs_data.cghs_map.set(656,14000);
        cghs_data.cghs_map.set(655,13000);
        cghs_data.cghs_map.set(621,1300);
        cghs_data.cghs_map.set(623,3550);
        cghs_data.cghs_map.set(784,650);
        cghs_data.cghs_map.set(653,4500);
        cghs_data.cghs_map.set(615,2500);
        cghs_data.cghs_map.set(619,2500);
        cghs_data.cghs_map.set(620,1250);
        cghs_data.cghs_map.set(638,4000);
        cghs_data.cghs_map.set(636,4000);
        cghs_data.cghs_map.set(640,4000);
        cghs_data.cghs_map.set(639,4000);
        cghs_data.cghs_map.set(637,4000);
        cghs_data.cghs_map.set(641,4000);
        cghs_data.cghs_map.set(632,2500);
        cghs_data.cghs_map.set(634,1750);
        cghs_data.cghs_map.set(633,2500);
        cghs_data.cghs_map.set(635,1750);
        cghs_data.cghs_map.set(828,800);
        cghs_data.cghs_map.set(691,3000);
        cghs_data.cghs_map.set(692,3000);
        cghs_data.cghs_map.set(854,350);
        cghs_data.cghs_map.set(865,300);
        cghs_data.cghs_map.set(862,300);
        cghs_data.cghs_map.set(863,300);
        cghs_data.cghs_map.set(864,300);
        cghs_data.cghs_map.set(831,400);
        cghs_data.cghs_map.set(688,2000);
        cghs_data.cghs_map.set(686,3550);
        cghs_data.cghs_map.set(685,3550);
        cghs_data.cghs_map.set(687,2150);
        cghs_data.cghs_map.set(729,1450);
        cghs_data.cghs_map.set(657,750);
        cghs_data.cghs_map.set(658,1500);
        cghs_data.cghs_map.set(771,1025);
        cghs_data.cghs_map.set(772,2050);
        cghs_data.cghs_map.set(773,2050);
        cghs_data.cghs_map.set(861,300);
        cghs_data.cghs_map.set(642,3050);
        cghs_data.cghs_map.set(643,3050);
        cghs_data.cghs_map.set(609,1000);
        cghs_data.cghs_map.set(649,3000);
        cghs_data.cghs_map.set(651,3000);
        cghs_data.cghs_map.set(652,3500);
        cghs_data.cghs_map.set(804,3950);
        cghs_data.cghs_map.set(805,3950);
        cghs_data.cghs_map.set(763,300);
        cghs_data.cghs_map.set(715,150);
        cghs_data.cghs_map.set(817,250);
        cghs_data.cghs_map.set(824,250);
        cghs_data.cghs_map.set(1661,300);
        cghs_data.cghs_map.set(730,50);
        cghs_data.cghs_map.set(2,2000);
        cghs_data.cghs_map.set(25,1058);
        cghs_data.cghs_map.set(30,2500);
        cghs_data.cghs_map.set(89,150);
        cghs_data.cghs_map.set(947,2150);
        cghs_data.cghs_map.set(811,1300);
        cghs_data.cghs_map.set(877,1178);
        cghs_data.cghs_map.set(1311,1178);
        cghs_data.cghs_map.set(12,90);
        cghs_data.cghs_map.set(813,550);
        cghs_data.cghs_map.set(14,550);
        cghs_data.cghs_map.set(15,550);
        cghs_data.cghs_map.set(21,20);
        cghs_data.cghs_map.set(22,58);
        cghs_data.cghs_map.set(20,21);
        cghs_data.cghs_map.set(23,1200);
        cghs_data.cghs_map.set(27,950);
        cghs_data.cghs_map.set(26,62);
        cghs_data.cghs_map.set(28,1300);
        cghs_data.cghs_map.set(31,1800);
        cghs_data.cghs_map.set(32,104);
        cghs_data.cghs_map.set(33,260);
        cghs_data.cghs_map.set(34,300);
        cghs_data.cghs_map.set(35,121);
        cghs_data.cghs_map.set(8,1000);
        cghs_data.cghs_map.set(56,230);
        cghs_data.cghs_map.set(57,229);
        cghs_data.cghs_map.set(59,50);
        cghs_data.cghs_map.set(62,48);
        cghs_data.cghs_map.set(63,550);
        cghs_data.cghs_map.set(64,93);
        cghs_data.cghs_map.set(66,58);
        cghs_data.cghs_map.set(799,1500);
        cghs_data.cghs_map.set(69,83);
        cghs_data.cghs_map.set(71,83);
        cghs_data.cghs_map.set(70,83);
        cghs_data.cghs_map.set(1230,83);
        cghs_data.cghs_map.set(644,56);
        cghs_data.cghs_map.set(78,104);
        cghs_data.cghs_map.set(80,75);
        cghs_data.cghs_map.set(83,104);
        cghs_data.cghs_map.set(88,56);
        cghs_data.cghs_map.set(844,2500);
        cghs_data.cghs_map.set(101,140);
        cghs_data.cghs_map.set(102,83);
        cghs_data.cghs_map.set(103,62);
        cghs_data.cghs_map.set(104,414);
        cghs_data.cghs_map.set(113,518);
        cghs_data.cghs_map.set(115,130);
        cghs_data.cghs_map.set(116,49);
        cghs_data.cghs_map.set(117,62);
        cghs_data.cghs_map.set(118,130);
        cghs_data.cghs_map.set(125,518);
        cghs_data.cghs_map.set(126,518);
        cghs_data.cghs_map.set(129,115);
        cghs_data.cghs_map.set(130,219);
        cghs_data.cghs_map.set(131,58);
        cghs_data.cghs_map.set(132,100);
        cghs_data.cghs_map.set(133,58);
        cghs_data.cghs_map.set(134,200);
        cghs_data.cghs_map.set(135,92);
        cghs_data.cghs_map.set(136,58);
        cghs_data.cghs_map.set(137,115);
        cghs_data.cghs_map.set(138,100);
        cghs_data.cghs_map.set(139,50);
        cghs_data.cghs_map.set(82,150);
        cghs_data.cghs_map.set(142,150);
        cghs_data.cghs_map.set(143,100);
        cghs_data.cghs_map.set(787,320);
        cghs_data.cghs_map.set(866,3000);
        cghs_data.cghs_map.set(867,1000);
        cghs_data.cghs_map.set(147,460);
        cghs_data.cghs_map.set(616,203);
        cghs_data.cghs_map.set(160,460);
        cghs_data.cghs_map.set(162,460);
        cghs_data.cghs_map.set(171,207);
        cghs_data.cghs_map.set(174,400);
        cghs_data.cghs_map.set(913,115);
        cghs_data.cghs_map.set(175,93);
        cghs_data.cghs_map.set(845,1600);
        cghs_data.cghs_map.set(777,800);
        cghs_data.cghs_map.set(176,21);
        cghs_data.cghs_map.set(183,27);
        cghs_data.cghs_map.set(177,27);
        cghs_data.cghs_map.set(178,27);
        cghs_data.cghs_map.set(179,104);
        cghs_data.cghs_map.set(855,27);
        cghs_data.cghs_map.set(1012,27);
        cghs_data.cghs_map.set(180,35);
        cghs_data.cghs_map.set(181,35);
        cghs_data.cghs_map.set(182,27);
        cghs_data.cghs_map.set(809,27);
        cghs_data.cghs_map.set(184,28);
        cghs_data.cghs_map.set(185,35);
        cghs_data.cghs_map.set(822,104);
        cghs_data.cghs_map.set(720,104);
        cghs_data.cghs_map.set(829,420);
        cghs_data.cghs_map.set(840,1400);
        cghs_data.cghs_map.set(194,150);
        cghs_data.cghs_map.set(203,71);
        cghs_data.cghs_map.set(211,115);
        cghs_data.cghs_map.set(858,2200);
        cghs_data.cghs_map.set(775,1553);
        cghs_data.cghs_map.set(220,51);
        cghs_data.cghs_map.set(221,93);
        cghs_data.cghs_map.set(223,81);
        cghs_data.cghs_map.set(1457,900);
        cghs_data.cghs_map.set(224,115);
        cghs_data.cghs_map.set(81,500);
        cghs_data.cghs_map.set(225,115);
        cghs_data.cghs_map.set(226,71);
        cghs_data.cghs_map.set(228,1600);
        cghs_data.cghs_map.set(229,1600);
        cghs_data.cghs_map.set(233,135);
        cghs_data.cghs_map.set(611,230);
        cghs_data.cghs_map.set(234,495);
        cghs_data.cghs_map.set(235,135);
        cghs_data.cghs_map.set(614,259);
        cghs_data.cghs_map.set(236,173);
        cghs_data.cghs_map.set(237,300);
        cghs_data.cghs_map.set(238,250);
        cghs_data.cghs_map.set(239,104);
        cghs_data.cghs_map.set(783,1800);
        cghs_data.cghs_map.set(244,72);
        cghs_data.cghs_map.set(245,350);
        cghs_data.cghs_map.set(250,1000);
        cghs_data.cghs_map.set(251,1000);
        cghs_data.cghs_map.set(762,2000);
        cghs_data.cghs_map.set(834,300);
        cghs_data.cghs_map.set(982,4200);
        cghs_data.cghs_map.set(725,150);
        cghs_data.cghs_map.set(255,147);
        cghs_data.cghs_map.set(256,147);
        cghs_data.cghs_map.set(258,1450);
        cghs_data.cghs_map.set(266,150);
        cghs_data.cghs_map.set(268,170);
        cghs_data.cghs_map.set(267,62);
        cghs_data.cghs_map.set(270,58);
        cghs_data.cghs_map.set(271,150);
        cghs_data.cghs_map.set(272,92);
        cghs_data.cghs_map.set(778,300);
        cghs_data.cghs_map.set(283,58);
        cghs_data.cghs_map.set(276,104);
        cghs_data.cghs_map.set(277,21);
        cghs_data.cghs_map.set(613,58);
        cghs_data.cghs_map.set(119,800);
        cghs_data.cghs_map.set(726,233);
        cghs_data.cghs_map.set(718,650);
        cghs_data.cghs_map.set(847,1150);
        cghs_data.cghs_map.set(293,104);
        cghs_data.cghs_map.set(850,1300);
        cghs_data.cghs_map.set(298,58);
        cghs_data.cghs_map.set(299,58);
        cghs_data.cghs_map.set(304,150);
        cghs_data.cghs_map.set(305,86);
        cghs_data.cghs_map.set(306,58);
        cghs_data.cghs_map.set(318,104);
        cghs_data.cghs_map.set(846,489);
        cghs_data.cghs_map.set(319,64);
        cghs_data.cghs_map.set(321,58);
        cghs_data.cghs_map.set(328,104);
        cghs_data.cghs_map.set(329,78);
        cghs_data.cghs_map.set(330,115);
        cghs_data.cghs_map.set(331,621);
        cghs_data.cghs_map.set(335,110);
        cghs_data.cghs_map.set(336,58);
        cghs_data.cghs_map.set(337,58);
        cghs_data.cghs_map.set(345,325);
        cghs_data.cghs_map.set(733,345);
        cghs_data.cghs_map.set(774,2750);
        cghs_data.cghs_map.set(350,100);
        cghs_data.cghs_map.set(1228,1500);
        cghs_data.cghs_map.set(351,1553);
        cghs_data.cghs_map.set(353,475);
        cghs_data.cghs_map.set(9,31);
        cghs_data.cghs_map.set(67,26);
        cghs_data.cghs_map.set(68,26);
        cghs_data.cghs_map.set(253,28);
        cghs_data.cghs_map.set(254,37);
        cghs_data.cghs_map.set(852,50);
        cghs_data.cghs_map.set(290,60);
        cghs_data.cghs_map.set(789,40);
        cghs_data.cghs_map.set(309,37);
        cghs_data.cghs_map.set(354,40);
        cghs_data.cghs_map.set(339,300);
        cghs_data.cghs_map.set(340,124);
        cghs_data.cghs_map.set(341,27);
        cghs_data.cghs_map.set(342,27);
        cghs_data.cghs_map.set(343,25);
        cghs_data.cghs_map.set(344,68);
        cghs_data.cghs_map.set(830,100);
        cghs_data.cghs_map.set(346,23);
        cghs_data.cghs_map.set(75,37);
        cghs_data.cghs_map.set(610,37);
        cghs_data.cghs_map.set(158,745);
        cghs_data.cghs_map.set(807,704);
        cghs_data.cghs_map.set(808,2100);
        cghs_data.cghs_map.set(826,1050);
        cghs_data.cghs_map.set(84,81);
        cghs_data.cghs_map.set(694,500);
        cghs_data.cghs_map.set(146,155);
        cghs_data.cghs_map.set(765,200);
        cghs_data.cghs_map.set(702,207);
        cghs_data.cghs_map.set(857,2400);
        cghs_data.cghs_map.set(856,700);
        cghs_data.cghs_map.set(800,3500);
        cghs_data.cghs_map.set(801,6000);
        cghs_data.cghs_map.set(791,1766);
        cghs_data.cghs_map.set(792,1200);
        cghs_data.cghs_map.set(108,196);
        cghs_data.cghs_map.set(109,900);
        cghs_data.cghs_map.set(750,900);
        cghs_data.cghs_map.set(159,4000);
        cghs_data.cghs_map.set(796,9500);
        cghs_data.cghs_map.set(199,5000);
        cghs_data.cghs_map.set(198,4700);
        cghs_data.cghs_map.set(200,3500);
        cghs_data.cghs_map.set(201,5500);
        cghs_data.cghs_map.set(202,5046);
        cghs_data.cghs_map.set(205,3000);
        cghs_data.cghs_map.set(207,4000);
        cghs_data.cghs_map.set(206,4000);
        cghs_data.cghs_map.set(209,1600);
        cghs_data.cghs_map.set(849,251);
        cghs_data.cghs_map.set(833,8500);
        cghs_data.cghs_map.set(263,1035);
        cghs_data.cghs_map.set(222,1770);
        cghs_data.cghs_map.set(3,125);
        cghs_data.cghs_map.set(4,49);
        cghs_data.cghs_map.set(5,125);
        cghs_data.cghs_map.set(6,125);
        cghs_data.cghs_map.set(7,125);
        cghs_data.cghs_map.set(794,6000);
        cghs_data.cghs_map.set(55,2484);
        cghs_data.cghs_map.set(626,35);
        cghs_data.cghs_map.set(79,155);
        cghs_data.cghs_map.set(87,80);
        cghs_data.cghs_map.set(111,50);
        cghs_data.cghs_map.set(112,50);
        cghs_data.cghs_map.set(843,1400);
        cghs_data.cghs_map.set(122,140);
        cghs_data.cghs_map.set(1149,275);
        cghs_data.cghs_map.set(123,93);
        cghs_data.cghs_map.set(124,104);
        cghs_data.cghs_map.set(151,50);
        cghs_data.cghs_map.set(144,50);
        cghs_data.cghs_map.set(152,32);
        cghs_data.cghs_map.set(155,29);
        cghs_data.cghs_map.set(190,21);
        cghs_data.cghs_map.set(191,115);
        cghs_data.cghs_map.set(192,300);
        cghs_data.cghs_map.set(227,300);
        cghs_data.cghs_map.set(240,200);
        cghs_data.cghs_map.set(802,350);
        cghs_data.cghs_map.set(803,350);
        cghs_data.cghs_map.set(243,43);
        cghs_data.cghs_map.set(246,43);
        cghs_data.cghs_map.set(839,58);
        cghs_data.cghs_map.set(262,117);
        cghs_data.cghs_map.set(264,15);
        cghs_data.cghs_map.set(265,45);
        cghs_data.cghs_map.set(269,49);
        cghs_data.cghs_map.set(278,2484);
        cghs_data.cghs_map.set(281,2484);
        cghs_data.cghs_map.set(282,115);
        cghs_data.cghs_map.set(289,80);
        cghs_data.cghs_map.set(291,49);
        cghs_data.cghs_map.set(292,92);
        cghs_data.cghs_map.set(300,89);
        cghs_data.cghs_map.set(302,250);
        cghs_data.cghs_map.set(303,43);
        cghs_data.cghs_map.set(324,32);
        cghs_data.cghs_map.set(1,455);
        cghs_data.cghs_map.set(10,2125);
        cghs_data.cghs_map.set(11,300);
        cghs_data.cghs_map.set(13,575);
        cghs_data.cghs_map.set(24,1294);
        cghs_data.cghs_map.set(29,311);
        cghs_data.cghs_map.set(37,690);
        cghs_data.cghs_map.set(16,3500);
        cghs_data.cghs_map.set(50,900);
        cghs_data.cghs_map.set(247,2500);
        cghs_data.cghs_map.set(798,230);
        cghs_data.cghs_map.set(53,489);
        cghs_data.cghs_map.set(1385,607);
        cghs_data.cghs_map.set(65,332);
        cghs_data.cghs_map.set(77,2750);
        cghs_data.cghs_map.set(90,380);
        cghs_data.cghs_map.set(91,380);
        cghs_data.cghs_map.set(975,950);
        cghs_data.cghs_map.set(93,600);
        cghs_data.cghs_map.set(94,840);
        cghs_data.cghs_map.set(95,437);
        cghs_data.cghs_map.set(98,580);
        cghs_data.cghs_map.set(99,637);
        cghs_data.cghs_map.set(96,2000);
        cghs_data.cghs_map.set(97,1650);
        cghs_data.cghs_map.set(100,518);
        cghs_data.cghs_map.set(110,352);
        cghs_data.cghs_map.set(127,288);
        cghs_data.cghs_map.set(128,288);
        cghs_data.cghs_map.set(860,230);
        cghs_data.cghs_map.set(150,2300);
        cghs_data.cghs_map.set(1093,900);
        cghs_data.cghs_map.set(716,2200);
        cghs_data.cghs_map.set(724,2200);
        cghs_data.cghs_map.set(153,3000);
        cghs_data.cghs_map.set(156,239);
        cghs_data.cghs_map.set(157,900);
        cghs_data.cghs_map.set(161,115);
        cghs_data.cghs_map.set(163,345);
        cghs_data.cghs_map.set(165,1700);
        cghs_data.cghs_map.set(164,3000);
        cghs_data.cghs_map.set(166,1750);
        cghs_data.cghs_map.set(167,389);
        cghs_data.cghs_map.set(168,125);
        cghs_data.cghs_map.set(169,125);
        cghs_data.cghs_map.set(170,173);
        cghs_data.cghs_map.set(172,173);
        cghs_data.cghs_map.set(1028,1530);
        cghs_data.cghs_map.set(187,380);
        cghs_data.cghs_map.set(210,460);
        cghs_data.cghs_map.set(755,600);
        cghs_data.cghs_map.set(213,400);
        cghs_data.cghs_map.set(851,1708);
        cghs_data.cghs_map.set(734,311);
        cghs_data.cghs_map.set(838,259);
        cghs_data.cghs_map.set(214,259);
        cghs_data.cghs_map.set(215,288);
        cghs_data.cghs_map.set(216,3000);
        cghs_data.cghs_map.set(745,1200);
        cghs_data.cghs_map.set(217,1500);
        cghs_data.cghs_map.set(917,173);
        cghs_data.cghs_map.set(218,173);
        cghs_data.cghs_map.set(219,173);
        cghs_data.cghs_map.set(232,173);
        cghs_data.cghs_map.set(1721,2691);
        cghs_data.cghs_map.set(812,1300);
        cghs_data.cghs_map.set(257,1900);
        cghs_data.cghs_map.set(261,1050);
        cghs_data.cghs_map.set(274,259);
        cghs_data.cghs_map.set(275,173);
        cghs_data.cghs_map.set(279,311);
        cghs_data.cghs_map.set(280,520);
        cghs_data.cghs_map.set(285,323);
        cghs_data.cghs_map.set(286,575);
        cghs_data.cghs_map.set(288,1620);
        cghs_data.cghs_map.set(296,414);
        cghs_data.cghs_map.set(749,489);
        cghs_data.cghs_map.set(841,1380);
        cghs_data.cghs_map.set(313,173);
        cghs_data.cghs_map.set(315,345);
        cghs_data.cghs_map.set(316,607);
        cghs_data.cghs_map.set(714,207);
        cghs_data.cghs_map.set(612,207);
        cghs_data.cghs_map.set(317,345);
        cghs_data.cghs_map.set(1131,355);
        cghs_data.cghs_map.set(1646,607);
        cghs_data.cghs_map.set(320,288);
        cghs_data.cghs_map.set(322,125);
        cghs_data.cghs_map.set(323,125);
        cghs_data.cghs_map.set(743,920);
        cghs_data.cghs_map.set(332,104);
        cghs_data.cghs_map.set(793,5000);
        cghs_data.cghs_map.set(1299,3000);
        cghs_data.cghs_map.set(946,2375);
        cghs_data.cghs_map.set(348,288);
        cghs_data.cghs_map.set(1377,2250);
        cghs_data.cghs_map.set(349,2000);
        cghs_data.cghs_map.set(1379,2500);
        cghs_data.cghs_map.set(1111,633);
        cghs_data.cghs_map.set(1378,2800);
        cghs_data.cghs_map.set(19,276);
        cghs_data.cghs_map.set(17,750);
        cghs_data.cghs_map.set(18,230);
        cghs_data.cghs_map.set(785,250);
        cghs_data.cghs_map.set(61,207);
        cghs_data.cghs_map.set(60,350);
        cghs_data.cghs_map.set(76,207);
        cghs_data.cghs_map.set(1072,207);
        cghs_data.cghs_map.set(1076,850);
        cghs_data.cghs_map.set(173,147);
        cghs_data.cghs_map.set(186,276);
        cghs_data.cghs_map.set(193,150);
        cghs_data.cghs_map.set(853,1309);
        cghs_data.cghs_map.set(719,100);
        cghs_data.cghs_map.set(252,380);
        cghs_data.cghs_map.set(738,1500);
        cghs_data.cghs_map.set(735,1500);
        cghs_data.cghs_map.set(736,1500);
        cghs_data.cghs_map.set(737,1500);
        cghs_data.cghs_map.set(823,500);
        cghs_data.cghs_map.set(287,207);
        cghs_data.cghs_map.set(297,320);
        cghs_data.cghs_map.set(301,150);
        cghs_data.cghs_map.set(307,207);
        cghs_data.cghs_map.set(308,207);
        cghs_data.cghs_map.set(1079,650);
        cghs_data.cghs_map.set(314,207);
        cghs_data.cghs_map.set(338,207);
        cghs_data.cghs_map.set(727,400);
        cghs_data.cghs_map.set(1662,550);
        cghs_data.cghs_map.set(732,600);
        cghs_data.cghs_map.set(141,2600);
        cghs_data.cghs_map.set(204,2600);
        cghs_data.cghs_map.set(761,3500);
        cghs_data.cghs_map.set(140,3500);
        cghs_data.cghs_map.set(770,5500);
        cghs_data.cghs_map.set(38,518);
        cghs_data.cghs_map.set(869,2150);
        cghs_data.cghs_map.set(39,362);
        cghs_data.cghs_map.set(748,700);
        cghs_data.cghs_map.set(41,700);
        cghs_data.cghs_map.set(40,700);
        cghs_data.cghs_map.set(42,500);
        cghs_data.cghs_map.set(43,600);
        cghs_data.cghs_map.set(44,500);
        cghs_data.cghs_map.set(45,450);
        cghs_data.cghs_map.set(47,1500);
        cghs_data.cghs_map.set(48,900);
        cghs_data.cghs_map.set(49,1200);
        cghs_data.cghs_map.set(36,230);
        cghs_data.cghs_map.set(51,575);
        cghs_data.cghs_map.set(52,575);
        cghs_data.cghs_map.set(58,1750);
        cghs_data.cghs_map.set(54,393);
        cghs_data.cghs_map.set(810,2200);
        cghs_data.cghs_map.set(756,1000);
        cghs_data.cghs_map.set(757,1000);
        cghs_data.cghs_map.set(85,265);
        cghs_data.cghs_map.set(86,265);
        cghs_data.cghs_map.set(92,489);
        cghs_data.cghs_map.set(760,1300);
        cghs_data.cghs_map.set(105,650);
        cghs_data.cghs_map.set(106,575);
        cghs_data.cghs_map.set(107,575);
        cghs_data.cghs_map.set(114,350);
        cghs_data.cghs_map.set(815,274);
        cghs_data.cghs_map.set(814,274);
        cghs_data.cghs_map.set(120,787);
        cghs_data.cghs_map.set(121,787);
        cghs_data.cghs_map.set(145,120);
        cghs_data.cghs_map.set(148,200);
        cghs_data.cghs_map.set(149,200);
        cghs_data.cghs_map.set(1577,200);
        cghs_data.cghs_map.set(752,1200);
        cghs_data.cghs_map.set(154,1200);
        cghs_data.cghs_map.set(758,4500);
        cghs_data.cghs_map.set(832,1450);
        cghs_data.cghs_map.set(188,1750);
        cghs_data.cghs_map.set(189,1750);
        cghs_data.cghs_map.set(816,1600);
        cghs_data.cghs_map.set(195,700);
        cghs_data.cghs_map.set(197,115);
        cghs_data.cghs_map.set(196,115);
        cghs_data.cghs_map.set(1169,147);
        cghs_data.cghs_map.set(46,147);
        cghs_data.cghs_map.set(731,4250);
        cghs_data.cghs_map.set(622,173);
        cghs_data.cghs_map.set(624,173);
        cghs_data.cghs_map.set(208,2200);
        cghs_data.cghs_map.set(212,300);
        cghs_data.cghs_map.set(231,700);
        cghs_data.cghs_map.set(230,500);
        cghs_data.cghs_map.set(241,1050);
        cghs_data.cghs_map.set(242,1050);
        cghs_data.cghs_map.set(248,1200);
        cghs_data.cghs_map.set(249,1200);
        cghs_data.cghs_map.set(259,489);
        cghs_data.cghs_map.set(759,1300);
        cghs_data.cghs_map.set(273,1900);
        cghs_data.cghs_map.set(347,46);
        cghs_data.cghs_map.set(294,320);
        cghs_data.cghs_map.set(295,320);
        cghs_data.cghs_map.set(848,1500);
        cghs_data.cghs_map.set(767,1500);
        cghs_data.cghs_map.set(768,1500);
        cghs_data.cghs_map.set(312,2500);
        cghs_data.cghs_map.set(739,2500);
        cghs_data.cghs_map.set(325,300);
        cghs_data.cghs_map.set(326,300);
        cghs_data.cghs_map.set(327,300);
        cghs_data.cghs_map.set(333,400);
        cghs_data.cghs_map.set(334,360);
        cghs_data.cghs_map.set(746,900);
        cghs_data.cghs_map.set(747,900);
        cghs_data.cghs_map.set(352,70);  
    }

}
export default cghs_data;