import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    status: '',
    work_order: '',
    to_day: '',
    notifications: '',
  },
  mutations: {
    setStatus: function (state,details){
      state.status = details;
    },
    setWorkOrder:function(state,details){
      state.work_order=details;
    },
    setToday:function(state,details){
      state.to_day=details;
    },
    setNotifications:function(state,details){
      state.notifications=details;
    },
  },
  getters: {
    getWorkOrder: function(state) {
      return state.work_order;
    },
    getStatus: function(state){
      return state.status;
    },
    getToday: function(state){
      return state.to_day;
    },
    getNotifications: function(state){
      return state.notifications;
    },
  },
  actions: {
  },
  modules: {
  }
})
export default store
