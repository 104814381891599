import DBHandler from '@/js/database/DBHandler';
import Util from '@/js/Util';
import imp_data from '@/js/database/import_data';

var PriceListDB = {
  fetch_data: async function(str){
    var localDb = await DBHandler.getDb('price_list');
    if(localDb){
        let doc = await localDb.allDocs({
            include_docs: true,
            startkey: 'price_list:abp:',
            endkey: 'price_list:abp:\ufff0'
          })
        var items = [];
        str = str.trim();
        str = str.toLowerCase();
        if (doc.total_rows > 0) {
            for (var i = 0; i < doc.rows.length; i++) {
              let item = PriceListDB.add_cghs_price(doc.rows[i].doc);
              if(str){
                let search_item = item.invest_name.toLowerCase();
                if(search_item.indexOf(str) > -1){
                  items.push(doc.rows[i].doc);   
                }
              }
              else{
                items.push(doc.rows[i].doc); 
              }
              if(items.length > 29){
                break;
              }
            }
        }
        if(items.length == 0){
            items.push({
                invest_name: 'No Investigation Found.',
                department_name: 'NA',
                base_cost: '0',
                min_cost: '0',
            });
        }
        return items;
    } else {
        console.log('Db is null here....');
    }
  },
  fetch_all_data: async function(str){
    var localDb = await DBHandler.getDb('price_list');
    if(localDb){
        let doc = await localDb.allDocs({
            include_docs: true,
            startkey: 'price_list:abp:',
            endkey: 'price_list:abp:\ufff0'
          })
        var items = [];
        str = str.trim();
        str = str.toLowerCase();
        if (doc.total_rows > 0) {
            for (var i = 0; i < doc.rows.length; i++) {
              let item = PriceListDB.add_cghs_price(doc.rows[i].doc);
              if(str){
                let search_item = '';
                if((str.indexOf('>') > -1) || (str.indexOf('<') > -1) || (str.indexOf('=') > -1)){
                  let item_cost = parseInt(item.base_cost);
                  let ent_value = Util.getNumbers(str);
                  if(str.indexOf('>') > -1){
                    if(item_cost > ent_value){
                      items.push(item);
                    }
                  } else if(str.indexOf('<') > -1){
                    if(item_cost < ent_value){
                      items.push(item);
                    }
                  } else if(str.indexOf('=') > -1){
                    if(item_cost == ent_value){
                      items.push(item);
                    }
                  }
                }
                else if(str.indexOf('id:') > -1){
                  let invest_id = item.invest_id;
                  let ent_value = Util.getNumbers(str);
                  if(invest_id == ent_value){
                    items.push(item);
                  }
                }
                else if(isNaN(str)){
                  search_item = item.invest_name.toLowerCase()+' *** '+item.dept_name.toLowerCase();
                  if(search_item.indexOf(str) > -1){
                    items.push(item);   
                  }
                }
                else{
                  if(str == item.base_cost){
                    items.push(item);   
                  }
                }
              }
              else{
                items.push(item); 
              }
            }
        }
        if(items.length == 0){
            items.push({
                invest_name: 'No Investigation Found.',
                department_name: 'NA',
                base_cost: '0',
                min_cost: '0',
                cghs_price: '0',
            });
        }
        // console.log('dept_names:'+dept_map.size);
        // let d_entries = sessionStorage.getItem('dept_names');
        // let d_map = new Map(JSON.parse(d_entries));
        // if (d_map.size > 0) {
        //   console.log('dept names set');
        // } else {
        //   let entries = JSON.stringify(Array.from(dept_map.entries()));
        //   sessionStorage.setItem('dept_names',entries);
        // }
        // let e_entries = sessionStorage.getItem('invest_names');
        // let e_map = new Map(JSON.parse(e_entries));
        // if (e_map.size > 0) {
        //   console.log('investigation id, names set');
        // } else {
        //   let entries = JSON.stringify(Array.from(invest_map.entries()));
        //   sessionStorage.setItem('invest_names',entries);
        // }
        return items;
    } else {
        console.log('Db is null here....');
    }
  },
  insert_new: async function(doc){
    let db = await DBHandler.getDb('price_list');
    let result = db.put(doc).then(function(response) {
      console.log(response);
      if(response.ok){
        return 'OK';
      }
    }).catch(function (err) {
      return err;
    });
    return result;
  },
  delete_one: async function(doc_id){
    let db = await DBHandler.getDb('price_list');
    let result = db.get(doc_id).then(function (doc) {
      return db.remove(doc);
    }).catch(function (err) {
      return err;
    });
    return result;
  },
  get_one: async function(doc_id){
    let db = await DBHandler.getDb('price_list');
    let doc = db.get(doc_id).then(function (doc) {
      return doc;
    }).catch(function (err) {
      return err;
    });
    return doc;
  },
  update: async function(new_doc){
    let db = await DBHandler.getDb('price_list');
    let result = db.get(new_doc._id).then(function (old_doc) {
      new_doc._rev = old_doc._rev;
      return db.put(new_doc);
    }).catch(function (err) {
      return err;
    });
    return result;
  },
  add_cghs_price: function(item){
    if(!item.history){
      let history = { 
        "action": 'NA',
        "summary": 'Not Found', 
        "emp_id": 'NA',
        "emp_name": 'NA',
        "emp_mobile": 'NA',
        "time_stamp": 'NA',
      }
      item.history = [history];
    }
    if(item.cghs_price){
      return item;
    } else {
      item.cghs_price = '0';
      return item;
    }
  },
  set_combo_data: async function(){
    var localDb = await DBHandler.getDb('price_list');
    const dept_map = new Map();
    const invest_map = new Map();
    if(localDb){
        let doc = await localDb.allDocs({
            include_docs: true,
            startkey: 'price_list:abp:',
            endkey: 'price_list:abp:\ufff0'
          })
        if (doc.total_rows > 0) {
            for (var i = 0; i < doc.rows.length; i++) {
              let item = doc.rows[i].doc;
              let dept_name = item.dept_name.toLowerCase();
              let dept_id = item.dept_id;
              if(dept_map.has(dept_name) == false){
                dept_map.set(dept_name,dept_id);
              }
              let invest_id = item.invest_id;
              let invest_name = item.invest_name;
              if(invest_map.has(invest_id) == false){
                invest_map.set(invest_id,invest_name);
              }
            }
        }
        let d_entries = JSON.stringify(Array.from(dept_map.entries()));
        sessionStorage.setItem('dept_names',d_entries);
        let i_entries = JSON.stringify(Array.from(invest_map.entries()));
        sessionStorage.setItem('invest_names',i_entries);
        return 'OK';
    } else {
        console.log('Db is null here....');
    }
    return 'Error';
  },
  get_hisory: async function(){
    let db = await DBHandler.getDb('price_list');
    let doc = db.get('price_list:history').then(function (doc) {
      return doc;
    }).catch(function (err) {
      return err;
    });
    return doc;
  },
  insert_history: async function(this_history){
    let db = await DBHandler.getDb('price_list');
    let result = db.get('price_list:history').then(function (old_doc) {
      if(old_doc.history){
        let old_history = old_doc.history;
        old_history.unshift(this_history);  
        old_doc.history = old_history;   
      } else {
        old_doc.history = [this_history];
      }     
      return db.put(old_doc);
    }).catch(function (err) {
      if(err.name == 'not_found'){
        let doc = {
          '_id': 'price_list:history',
          'history': [this_history]
        }
        return db.put(doc);
      }
      return err;
    });
    return result;
  },
  import_data: async function(){
    var localDb = await DBHandler.getDb('price_list');
    if(localDb){
        let doc = await localDb.allDocs({
            include_docs: true,
            startkey: 'price_list:abp:',
            endkey: 'price_list:abp:\ufff0'
          })
        if (doc.total_rows > 0) {
            let line = 1;
            for (var i = 0; i < doc.rows.length; i++) {
              let item = doc.rows[i].doc;
              let item_invest_id = parseInt(item.invest_id);
              let cghs_price = imp_data.get(item_invest_id);  
              if(cghs_price == 0){
                //console.log(item.cghs_price+' '+item.invest_name);
                //line++;
              } else {
                item.cghs_price = cghs_price;
                PriceListDB.update(item);
                console.log(line+' '+item.cghs_price+' '+item.invest_name);
                line++;
              }
            }
        }
    } else {
        console.log('Db is null here....');
    }
    return 'OK';
  }      
};
export default PriceListDB;
