<template>
  <div>
    <v-data-table
      :headers="tableHeaders"
      :items="tableData"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="_id"
      show-expand
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1"
      dense
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @keyup.native="debounce_search()"
          ></v-text-field>
          <v-btn icon @click="show_price()">
            <v-icon>mdi-clipboard-list-outline</v-icon>
          </v-btn>
          <v-btn icon @click="show_tech_engagements()">
            <v-icon>mdi-account-details-outline</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.name="{ item }">
        {{ item.name }} &nbsp;
        <v-chip color="red" outlined x-small v-if="getClientStatus(item)">
          {{ getClientStatus(item) }}
        </v-chip>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon small @click="copyItem(item)"> mdi-content-copy </v-icon>
        <v-btn color="green" class="mx-1" elevation="2" icon x-small @click="startAddTest(item)" v-if="checkstatus(item)">
          <v-icon dark> mdi-calendar-start </v-icon>
        </v-btn>
        <v-btn color="red" class="mx-1" elevation="2" icon x-small v-if="checkstatus(item)" @click="cancel_order(item)">
          <v-icon dark> mdi-close-outline </v-icon>
        </v-btn>
        <v-btn color="green" class="mx-1" elevation="2" icon x-small @click="edit_name(item)" v-if="checkstatus(item)">
          <v-icon dark> mdi-pencil </v-icon>
        </v-btn>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.status="{ item }">
        <v-chip :color="getColor(item.status)" outlined x-small>
          {{ item.status }}
        </v-chip>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.server_status="{ item }">
        <v-chip :color="getColor(item.server_status)" outlined x-small>
          {{ getStatus(item.server_status) }}
        </v-chip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Address</th>
                  <th class="text-left">Pincode</th>
                  <th class="text-left">Additional Info</th>
                  <th class="text-left">Status</th>
                  <th class="text-left">Assigned To</th>
                  <th class="text-left">Transaction Id</th>
                   <th class="text-left">Payment Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ item.address }}</td>
                  <td>{{ item.pincode }}</td>
                  <td>{{ item.free_text }}</td>
                  <td>{{ item.status }}</td>
                  <td>{{ item.assigned_to }}</td>
                   <td v-if="item.transaction_id">{{ item.transaction_id }}</td>
                <td v-if="item.response_message">{{ item.response_message }}</td>   
                </tr>
                <tr v-if="item.pres_photo">
                  <td colspan="5">
                    <v-chip color="red" outlined x-small> Prescription: </v-chip>
                    <v-chip color="primary" outlined x-small @click="download_files(item.pres_photo)">
                      {{ getName(item.pres_photo) }}
                    </v-chip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
    </v-data-table>
    <v-container v-if="type === 'add_test'">
      <add_test v-bind:show="show_add" :item="item" @close_add_test="close_add_test"></add_test>
    </v-container>
    <!-- 
      Copy work order  
    -->

    <v-container v-if="type === 'copy_work_order_dialog'">
      <copy_work_order :show="show_copy" :item="copied_item" @close_copy_work_order="close_copy_screen"></copy_work_order>
    </v-container>

    <v-container v-if="tech_engagement_dialog">
      <tech_engagement v-bind:show="show_engagement" @close_tech_engagements="close_tech_engagements"></tech_engagement>
    </v-container>
    <v-container v-if="price_view_dialog">
      <price_view v-bind:show="show_price_view" @close_price_view="close_price_view"></price_view>
    </v-container>
    <!--  
   --                         Dialog for edit name / age gender
   -->
    <div>
      <v-dialog persistent v-model="name_edit_dialog" transition="dialog-bottom-transition" max-width="600">
        <v-card>
          <v-toolbar color="orange" dark>Edit Name & Age</v-toolbar>
          <v-card-text>
            <v-form ref="name_edit_form" lazy-validation>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    :items="['Mr', 'Ms', 'Mrs', 'Child Of', 'Dr']"
                    label="Salutation"
                    v-model="salutation"
                    :rules="salutationRules"
                    required
                    @change="salutationChange()"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field label="Name" hint="Name" v-model="edited_name" :rules="nameRules" required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field label="Age" hint="Age" v-model="edited_age" :rules="ageRules" required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select :items="['Male', 'Female', 'Other']" label="Gender" v-model="edited_gender" :rules="genderRules" required></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field label="Mobile" hint="MObile" v-model="edited_mobile" :rules="mobileRules" required></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="save_edit_name_dialog">OK</v-btn>
            <v-btn text @click="close_edit_name_dialog">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- 
      Message Dialog   
  -->
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackbar_text }}
    </v-snackbar>
    <!-- 
      Cancel Dialog   
    -->

    <div>
      <v-dialog v-model="cancelDialog" max-width="400">
        <v-card>
          <v-card-title class="text-h6 orange lighter-4 white--text"> Do you want to cancel this order? </v-card-title>
          <v-card-text>
            <v-text-field
              ref="cancel_text_field"
              label="Reason For Cancellation"
              hint="Reason For Cancellation"
              v-model="cancel_reason"
              :rules="cancelRules"
              required
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="orange darken-1" text @click="cancelDialog = false"> No </v-btn>
            <v-btn color="orange darken-1" text @click="doCancel()"> Yes </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- 
      Sugar Dialog   
    -->
    <div>
      <v-dialog v-model="sugarDialog" max-width="400">
        <v-card>
          <v-card-title class="text-h6 orange lighter-4 white--text">Do you want to book Glucose(PP) for this patient? </v-card-title>
          <v-card-text> Do you want to book Glucose(PP) for this patient? </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="orange darken-1" text @click="cancelSugar()"> No </v-btn>
            <v-btn color="orange darken-1" text @click="doSugar()"> Yes </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!--  
   --                         Dialog for view / download
   -->
    <div>
      <v-dialog persistent v-model="file_dialog" transition="dialog-bottom-transition" max-width="600">
        <v-card>
          <v-toolbar color="orange" dark>View / Download Files</v-toolbar>
          <v-card-text>
            <v-sheet color="orange lighten-5" elevation="7" outlined rounded shaped class="ma-2 pa-2" v-for="url in pres_array" :key="url">
              <v-btn text color="primary" x-small> {{ getName(url) }}</v-btn>
              <v-btn text color="primary" @click="download_id(url, 'view')" x-small>View </v-btn>
              <v-btn text color="primary" @click="download_id(url, 'download')" x-small>Download</v-btn>
            </v-sheet>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="close_download_dialog">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!--  
   --                         Dialog for view pdf
   -->
    <v-container v-if="show_report">
      <show_pdf v-bind:show_report="show_report" v-bind:s3_url="s3_url" @close_pdf="pdf_close()"></show_pdf>
    </v-container>
    <v-container v-if="show_img">
      <show_img v-bind:show_img="show_img" v-bind:s3_url="s3_url" @close_image="img_close()"></show_img>
    </v-container>

    <v-overlay :value="overlay_dk" :z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import fileDownload from "js-file-download";
//import DBHandler from "@/js/database/DBHandler";
import WorkOrderDB from "@/js/database/workorder_db";
import add_test from "@/components/my_work_order/add_test.vue";
import copy_work_order from "@/components/my_work_order/copy_work_order.vue";
import tech_engagement from "@/components/my_work_order/tech_engagement.vue";
import price_view from "@/components/my_work_order/price_view.vue";
import Util from "@/js/Util";
import { mapState } from "vuex";
import axios from "axios";
import settings from "@/js/database/settings";
import show_pdf from "@/components/show_pdf.vue";
import show_img from "@/components/show_img.vue";
export default {
  name: "MyWorkOrders",
  components: { add_test, copy_work_order, tech_engagement, price_view, show_pdf, show_img },
  data() {
    return {
      loading: false,
      expanded: [],
      singleExpand: true,
      search: "",
      tableHeaders: [
        { text: "Name", align: "start", sortable: true, value: "name" },
        { text: "Gender", value: "gender" },
        { text: "Age", value: "age" },
        { text: "Mobile", value: "mobile" },
        { text: "Date", value: "appointment_date" },
        { text: "Time", value: "appointment_time" },
        { text: "Ref. By", value: "doctor_name" },
        { text: "Status", value: "status" },
        { text: "Server Status", value: "server_status" },
        { text: "Actions", value: "actions", sortable: false },
        { text: "", value: "data-table-expand" },
      ],
      salutation: "",
      salutationRules: [(v) => !!v || "Salutation is required"],
      edited_name: "",
      nameRules: [(v) => !!v || "Name is required", (v) => (v && v.length <= 30) || "Name must be less than 30 characters"],
      edited_age: "NA",
      ageRules: [(v) => !!v || "Age is required", (v) => (v && v.length <= 10) || "Age must be less than 10 characters"],
      edited_gender: "",
      genderRules: [(v) => !!v || "Gender is required"],
      mobileRules: [(v) => !!v || "Mobile is required", (v) => (v && v.length == 10) || "Mobile must have 10 digits."],
      edited_mobile: "",
      tableData: [],
      work_orders: [],
      show_add: false,
      type: "",
      show_copy: false,
      copied_item: null,
      snackbar: "",
      snackbar_text: "",
      item: null,
      sugarDialog: false,
      cancel_item: null,
      cancelDialog: false,
      cancel_reason: "",
      cancelRules: [(v) => !!v || "Reason is required", (v) => (v && v.length > 5) || "Give proper reason."],
      tech_engagement_dialog: false,
      show_engagement: false,
      show_price_view: false,
      price_view_dialog: false,
      file_dialog: false,
      pres_array: [],
      show_report: false,
      s3_url: "",
      show_img: false,
      overlay_dk: false,
      name_edit_dialog: false,
      edit_item: {},
      name_edit_form: false,
    };
  },
  computed: mapState(["work_order"]),
  watch: {
    work_order(newValue, oldValue) {
      console.log("Updating from " + oldValue + " to " + newValue);
      this.updateScreen();
    },
  },
  mounted: async function () {
    this.updateScreen();
    this.show_sugar_test();
  },
  methods: {
    salutationChange: function () {
      if (this.salutation == "Ms" || this.salutation == "Mrs") {
        this.gender = "Female";
      } else if (this.salutation == "Mr") {
        this.gender = "Male";
      } else {
        this.gender = "";
      }
    },
    getName: function (item) {
      return Util.getName(item);
    },
    updateScreen: async function () {
      this.loading = true;
      let emp_id = Util.getFromSession("logged_in_emp_id");
      this.work_orders = await WorkOrderDB.assigned_list(emp_id);
      this.tableData = this.work_orders;
      this.loading = false;
    },
    show_sugar_test: function () {
      let doc_id = Util.getFromSession("sugar_tests");
      if (doc_id) {
        this.sugarDialog = true;
      }
    },
    cancelSugar: function () {
      Util.setSession("sugar_tests", "");
      this.sugarDialog = false;
    },
    doSugar: async function () {
      let doc_id = Util.getFromSession("sugar_tests");
      if (doc_id) {
        Util.setSession("sugar_tests", "");
        let doc = await WorkOrderDB.getWithId(doc_id);
        doc.appointment_time = "";
        this.copyItem(doc);
      }
    },
    checkstatus: function (item) {
      let status = item.status;
      if (status == "NA") {
        return false;
      } else if (status == "Finished") {
        return false;
      } else if (status == "cancelled") {
        return false;
      }
      return true;
    },
    close_add_test: function (e) {
      if (typeof e === "string" || e instanceof String) {
        this.snackbar_text = e;
        this.snackbar = true;
      }
      this.show_add = false;
      this.type = "";
    },
    startAddTest: function (item) {
      this.item = item;
      //this.type = 'add_test';
      //this.show_add=true;
      //this.$router.push
      //this.checkTime(item);
      this.$router.push("/hc_process/" + item._id);
    },
    checkTime: async function (item) {
      try {
        let app_time = item.appointment_date + " " + item.appointment_time;
        let jwt_token = Util.getFromSession("pg_admin");
        let data = {
          appointment_time: app_time,
          jwt_token: jwt_token,
        };
        let instance = this;
        axios({
          method: "post",
          responseType: "text",
          url: settings.node_url + "/time/check_time",
          data: data,
        })
          .then(function (response) {
            console.log(response.data);
            if (response.data == "OK") {
              instance.$router.push("/hc_process/" + item._id);
            } else {
              instance.snackbar_text = "Timed out for filling this workorder.";
              instance.snackbar = true;
            }
          })
          .catch(function (error) {
            console.log("error:" + error);
          });
      } catch (e) {
        console.log(e);
      }
    },
    getColor: function (status) {
      if (status) {
        let lstatus = status.toLowerCase().trim();
        if (lstatus.startsWith("un")) return "red";
        else if (lstatus == "") return "red";
        else if (lstatus == "cancelled") return "grey";
        else if (lstatus == "finished") return "green";
        else if (lstatus == "assigned") return "blue";
        else if (lstatus == "billed") return "green";
        else if (lstatus == "waiting") return "grey";
        else return "orange";
      } else {
        return "red";
      }
    },
    getServerStatusColor: function (status) {
      if (status) {
        if (status.toLowerCase().indexOf("not") > -1) return "red";
        else if (status == "") return "red";
        else if (status == "waiting") return "blue";
        else if (status == "cancelled") return "grey";
        else return "green";
      } else {
        return "red";
      }
    },
    debounce_search: Util.debounce(function () {
      this.search_details();
    }, 500),
    search_details: function () {
      this.tableData = Util.search(this.work_orders, this.search.toLowerCase());
    },
    cancel_order: function (item) {
      this.cancel_reason = "";
      this.cancel_item = item;
      this.cancelDialog = true;
    },
    doCancel: async function () {
      this.$refs.cancel_text_field.focus();
      let result = this.$refs.cancel_text_field.validate();
      if (result) {
        let doc = this.cancel_item;
        let time_stamp = Util.gettime();
        let workorder_created_by = Util.getFromSession("logged_in_emp_name");
        let workorder_cancelled = time_stamp + "-" + workorder_created_by + "- Work Order Cancelled ";
        doc.status = "cancelled";
        doc.server_status = "cancelled";
        doc.cancel_reason = this.cancel_reason;
        if (doc.time_line) {
          doc.time_line.push(workorder_cancelled);
        }
        let instance = this;
        //let db = await DBHandler.getDb("work_orders");
        let db = await WorkOrderDB.get_server_db("work_orders");
        db.put(doc)
          .then(function (response) {
            console.log(response);
            if (response.ok) {
              instance.snackbar_text = "Cancelled Successfully";
              instance.snackbar = true;
            }
          })
          .catch(function (err) {
            instance.snackbar_text = "Error: Please Check Your Internet " + JSON.stringify(err);
            instance.snackbar = true;
            return;
          });
        this.cancel_item = null;
        this.cancelDialog = false;
      }
    },
    getStatus: function (status) {
      if (status == "Received") {
        return "Unbilled";
      }
      return status;
    },
    getClientStatus: function (item) {
      let ret = "";
      if (item.urgent) {
        ret += "Urgent ";
      }
      if (item.vip_client) {
        ret += "VIP ";
      }
      if (item.credit == 1) {
        ret += "Credit ";
      } else if (item.credit == 2) {
        ret += "Trial ";
      }
      if (item.b2b_client_id) {
        ret += "B2B";
      }
      return ret;
    },
    copyItem: function (item) {
      this.type = "copy_work_order_dialog";
      this.show_copy = true;
      this.copied_item = item;
    },
    close_copy_screen: function (e) {
      let ret_msg = "";
      if (typeof e === "string" || e instanceof String) {
        ret_msg = e;
        this.snackbar_text = ret_msg;
        this.snackbar = true;
      }
      //this.edited_item = null;
      this.show_copy = false;
      this.type = "";
    },
    close_tech_engagements: function () {
      this.show_engagement = false;
      this.tech_engagement_dialog = false;
    },
    show_tech_engagements: function () {
      this.show_engagement = true;
      this.tech_engagement_dialog = true;
    },
    close_price_view: function () {
      this.show_price_view = false;
      this.price_view_dialog = false;
    },
    show_price: function () {
      this.show_price_view = true;
      this.price_view_dialog = true;
    },
    download_files: function (name) {
      let pres_array = [];
      let aName = [];
      if (name.indexOf(",") > -1) {
        aName = name.split(",");
      } else {
        aName.push(name);
      }
      for (let i = 0; i < aName.length; i++) {
        let fName = aName[i];
        pres_array.push(fName);
      }
      this.pres_array = pres_array;
      this.file_dialog = true;
    },
    download_id: function (id_loc, mode) {
      let item = id_loc + "";
      let dArray = [];
      if (item.indexOf(" | ") > -1) {
        dArray = item.split(" | ");
      } else {
        dArray[0] = "homecollection";
        dArray[1] = item;
      }
      let jwt_token = Util.getFromSession("pg_admin");
      let data = {
        bucket_name: dArray[0],
        key: dArray[1],
        jwt_token: jwt_token,
      };
      let name = dArray[1];
      if (name.indexOf("/") > -1) {
        name = name.substring(name.lastIndexOf("/") + 1);
      }
      if (mode == "view") {
        if (name.endsWith(".pdf")) {
          this.s3_url = id_loc;
          this.show_report = true;
        } else {
          this.s3_url = id_loc;
          this.show_img = true;
        }
        return;
      }
      this.overlay_dk = true;
      let instance = this;
      axios({
        method: "post",
        responseType: "blob",
        url: settings.node_url + "/s3/get_file_v2",
        data: data,
      })
        .then(async function (response) {
          //console.log(response.data);
          if (mode == "download") {
            fileDownload(response.data, name);
          }
          instance.overlay_dk = false;
        })
        .catch(function (error) {
          console.log("error:" + error);
          instance.overlay_dk = false;
          alert(error);
        });
    },
    pdf_close: function () {
      this.show_report = false;
    },
    img_close: function () {
      this.show_img = false;
    },
    close_download_dialog: function () {
      this.file_dialog = false;
    },
    edit_name: function (item) {
      this.edit_item = item;
      let name = item.name;
      let sal = "";
      if (name.indexOf(".") > -1) {
        sal = name.substring(0, name.indexOf("."));
        name = name.substring(name.indexOf(".") + 1).trim();
      }
      this.salutation = sal;
      this.edited_name = name;
      this.edited_age = item.age;
      this.edited_gender = item.gender;
      this.edited_mobile = item.mobile;
      this.name_edit_dialog = true;
    },
    close_edit_name_dialog: function () {
      this.edit_item = {};
      this.name_edit_dialog = false;
    },
    save_edit_name_dialog: async function () {
      let result = this.$refs.name_edit_form.validate();
      if (result) {
        let id = this.edit_item._id;
        let doc = await WorkOrderDB.getWithId(id);
        doc.name = this.salutation + ". " + Util.toTitleCase(this.edited_name);
        doc.age = this.edited_age;
        doc.gender = this.edited_gender;
        doc.mobile = this.edited_mobile;
        let result = await WorkOrderDB.doUpdate(doc);
        if (result == "OK") {
          this.close_edit_name_dialog();
          this.snackbar_text = "Updated Successfully.";
          this.snackbar = true;
        } else {
          this.snackbar_text = result;
          this.snackbar = true;
        }
      }
    },
  },
};
</script>
<style></style>
