<template>
  <div>
    <div>
      <v-row dense>
        <v-spacer></v-spacer>
        <v-col cols="6" sm="2">
          <v-select dense v-model="year" :items="years" label="Choose Year" solo v-if="monthwise" @change="year_change"></v-select>
        </v-col>
        <v-col cols="6" sm="2">
          <v-switch v-model="monthwise" flat label="Monthwise" @change="switch_change"></v-switch>
        </v-col>
      </v-row>
    </div>
    <v-container>
      <v-card>
        <v-toolbar color="blue lighter-1" dark dense>
          <v-toolbar-title>Yearly Report {{ year }}</v-toolbar-title>
        </v-toolbar>
        <v-data-table
          :headers="tableHeaders"
          :items="tableData"
          item-key="_id"
          :loading="loading"
          :items-per-page="15"
          loading-text="Loading... Please wait"
          class="elevation-1"
          hide-default-footer
          dense
        >
          <!-- eslint-disable-next-line -->
          <template v-slot:item.collection="{ item }">
            {{ format(item.collection) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.credit="{ item }">
            {{ format(item.credit) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.b2b="{ item }">
            {{ format(item.b2b) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.trial="{ item }">
            {{ format(item.trial) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.received="{ item }">
            {{ format(item.received) }}
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-toolbar color="blue lighter-1" dark dense>
          <v-toolbar-title>Yearly Cases Report {{ year }}</v-toolbar-title>
        </v-toolbar>
        <BarChart :item="yearly_report_chart_data" :key="reload"></BarChart>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-toolbar color="blue lighter-1" dark dense>
          <v-toolbar-title>Yearly Collection Report {{ year }}</v-toolbar-title>
        </v-toolbar>
        <BarChart :item="yearly_collection_chart_data" :key="reload2"></BarChart>
      </v-card>
    </v-container>
    <!-- 
      Message Dialog   
    -->
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>
<script>
import moment from "moment";
import DashboardDB from "@/js/database/dashboard_db";
import BarChart from "@/components/work_order/dashboard/bar_chat.vue";
import Util from "@/js/Util";

export default {
  components: {
    BarChart,
  },
  name: "Dashboard",
  data: () => ({
    monthwise: true,
    loading: false,
    snackbar: false,
    snackbar_text: "",
    doc: {},
    reload: 0,
    reload2: 0,
    year: "",
    years: [],
    tableHeaders: [
      { text: "Month", value: "month" },
      { text: "Assigned", value: "assigned" },
      { text: "Finished", value: "finished" },
      { text: "Cancelled", value: "cancelled" },
      { text: "Pending", value: "pending" },
      { text: "Total Collection", value: "collection" },
      { text: "Received", value: "received" },
      { text: "Credit", value: "credit" },
      { text: "B2B", value: "b2b" },
      { text: "Trial", value: "trial" },
    ],
    tableData: [],
    yearly_collection_chart_data: {
      labels: [],
      datasets: [
        {
          label: "Total Collection",
          type: "bar",
          stack: "Total Collection",
          backgroundColor: "#9999ff",
          data: [],
        },
        {
          label: "Received",
          type: "bar",
          stack: "Received",
          backgroundColor: "#b3ffb3",
          data: [],
        },
        {
          label: "Credit",
          type: "bar",
          stack: "Credit",
          backgroundColor: "#cccccc",
          data: [],
        },
        {
          label: "B2B",
          type: "bar",
          stack: "B2B",
          backgroundColor: "#ffa500",
          data: [],
        },
        {
          label: "Trial",
          type: "bar",
          stack: "Trial",
          backgroundColor: "#2db83d",
          data: [],
        },
      ],
    },
    yearly_report_chart_data: {
      labels: [],
      datasets: [
        {
          label: "Assigned",
          type: "bar",
          stack: "Assigned",
          backgroundColor: "#9999ff",
          data: [],
        },
        {
          label: "Fineshed",
          type: "bar",
          stack: "Fineshed",
          backgroundColor: "#b3ffb3",
          data: [],
        },
        {
          label: "Cancelled",
          type: "bar",
          stack: "Cancelled",
          backgroundColor: "#cccccc",
          data: [],
        },
        {
          label: "Pending",
          type: "bar",
          stack: "Pending",
          backgroundColor: "#ffa500",
          data: [],
        },
      ],
    },
  }),
  mounted: async function () {
    await this.fill_years();
    await this.load_data();
  },
  methods: {
    format: function (str) {
      return Util.formatMoney(str);
    },
    year_change: async function () {
      this.load_data();
    },
    switch_change: async function () {
      if (this.monthwise) {
        this.tableHeaders[0].text = "Month";
        await this.fill_years();
        await this.load_data();
      } else {
        this.year = "";
        this.years = [];
        this.tableHeaders[0].text = "Year";
        this.load_data2();
      }
    },
    fill_years: async function () {
      let yearly = await DashboardDB.get_one("yearly");
      let data = yearly.data;
      this.years = Object.keys(data);
      // console.log("year:" + moment().year());
      this.year = moment().year() + "";
    },
    load_data2: async function () {
      this.tableData = [];
      let yearly = await DashboardDB.get_one("yearly");
      let data = yearly.data;
      let years = Object.keys(data);
      let total_assigned = 0;
      let total_cancelled = 0;
      let total_finished = 0;
      let total_pending = 0;
      let total_collection = 0;
      let total_credit = 0;
      let total_b2b = 0;
      let total_trial = 0;
      let total_received = 0;
      let labels = [];
      let assigned = [];
      let cancelled = [];
      let finished = [];
      let pending = [];
      let collection = [];
      let received = [];
      let credit = [];
      let b2b = [];
      let trial = [];
      for (let i = 0; i < years.length; i++) {
        let this_year = years[i];
        let today_data = data[this_year];
        if (today_data) {
          today_data.month = this_year;
          total_assigned += today_data.assigned;
          total_cancelled += today_data.cancelled;
          total_finished += today_data.finished;
          total_pending += today_data.pending;
          total_collection += today_data.collection;
          total_credit += today_data.credit;
          total_b2b += today_data.b2b;
          total_trial += today_data.trial;
          total_received += today_data.received;
          this.tableData.push(today_data);
          labels.push(this_year);
          assigned.push(today_data.assigned);
          cancelled.push(today_data.cancelled);
          finished.push(today_data.finished);
          pending.push(today_data.pending);

          collection.push(today_data.collection);
          received.push(today_data.received);
          credit.push(today_data.credit);
          b2b.push(today_data.b2b);
          trial.push(today_data.trial);
        }
      }
      if (this.tableData.length == 0) {
        this.snackbar_text = "No Data Found For The Selected Year";
        this.snackbar = true;
        this.reset_graph();
        return;
      }
      let total = {
        month: "Total",
        assigned: total_assigned,
        cancelled: total_cancelled,
        finished: total_finished,
        pending: total_pending,
        collection: total_collection,
        credit: total_credit,
        b2b: total_b2b,
        trial: total_trial,
        received: total_received,
      };
      this.tableData.push(total);
      this.chart_cases_data(labels, assigned, cancelled, finished, pending);
      this.chart_collection_data(labels, collection, received, credit, b2b, trial);
    },

    load_data: async function () {
      this.tableData = [];
      let doc_id = "yearly_" + this.year;
      // console.log(doc_id + "  >" + this.year);
      this.doc = await DashboardDB.get_one(doc_id);
      let data = this.doc.data;
      let total_assigned = 0;
      let total_cancelled = 0;
      let total_finished = 0;
      let total_pending = 0;
      let total_collection = 0;
      let total_credit = 0;
      let total_b2b = 0;
      let total_trial = 0;
      let total_received = 0;
      let labels = [];
      let assigned = [];
      let cancelled = [];
      let finished = [];
      let pending = [];
      let collection = [];
      let received = [];
      let credit = [];
      let b2b = [];
      let trial = [];
      for (let i = 1; i < 13; i++) {
        try {
          let today_data = data[i];
          let month = moment(i, "M").format("MMMM");
          if (today_data) {
            today_data.month = month;
            total_assigned += today_data.assigned;
            total_cancelled += today_data.cancelled;
            total_finished += today_data.finished;
            total_pending += today_data.pending;
            total_collection += today_data.collection;
            total_credit += today_data.credit;
            total_b2b += today_data.b2b;
            total_trial += today_data.trial;
            total_received += today_data.received;
            this.tableData.push(today_data);
            labels.push(month);
            assigned.push(today_data.assigned);
            cancelled.push(today_data.cancelled);
            finished.push(today_data.finished);
            pending.push(today_data.pending);

            collection.push(today_data.collection);
            received.push(today_data.received);
            credit.push(today_data.credit);
            b2b.push(today_data.b2b);
            trial.push(today_data.trial);
          } else {
            today_data = {};
            today_data.month = month;
            today_data.assigned = 0;
            today_data.cancelled = 0;
            today_data.finished = 0;
            today_data.pending = 0;
            today_data.collection = 0;
            today_data.credit = 0;
            today_data.b2b = 0;
            today_data.trial = 0;
            today_data.received = 0;
            this.tableData.push(today_data);
          }
        } catch (e) {
          console.log("Caught error here:" + e);
        }
      }
      if (this.tableData.length == 0) {
        this.snackbar_text = "No Data Found For The Selected Year";
        this.snackbar = true;
        this.reset_graph();
        return;
      }
      let total = {
        month: "Total",
        assigned: total_assigned,
        cancelled: total_cancelled,
        finished: total_finished,
        pending: total_pending,
        collection: total_collection,
        credit: total_credit,
        b2b: total_b2b,
        trial: total_trial,
        received: total_received,
      };
      this.tableData.push(total);
      this.chart_cases_data(labels, assigned, cancelled, finished, pending);
      this.chart_collection_data(labels, collection, received, credit, b2b, trial);
    },
    chart_cases_data: function (labels, assigned, cancelled, finished, pending) {
      this.yearly_report_chart_data.labels = labels;
      this.yearly_report_chart_data.datasets[0].data = assigned;
      this.yearly_report_chart_data.datasets[1].data = finished;
      this.yearly_report_chart_data.datasets[2].data = cancelled;
      this.yearly_report_chart_data.datasets[3].data = pending;
      this.reload += 1;
    },
    chart_collection_data: function (labels, collection, received, credit, b2b, trial) {
      this.yearly_collection_chart_data.labels = labels;
      this.yearly_collection_chart_data.datasets[0].data = collection;
      this.yearly_collection_chart_data.datasets[1].data = received;
      this.yearly_collection_chart_data.datasets[2].data = credit;
      this.yearly_collection_chart_data.datasets[3].data = b2b;
      this.yearly_collection_chart_data.datasets[4].data = trial;
      this.reload2 += 1;
    },
    reset_graph: function () {
      this.yearly_report_chart_data.labels = [];
      this.yearly_report_chart_data.datasets[0].data = [];
      this.yearly_report_chart_data.datasets[1].data = [];
      this.yearly_report_chart_data.datasets[2].data = [];
      this.yearly_report_chart_data.datasets[3].data = [];
      this.reload += 1;

      this.yearly_collection_chart_data.labels = [];
      this.yearly_collection_chart_data.datasets[0].data = [];
      this.yearly_collection_chart_data.datasets[1].data = [];
      this.yearly_collection_chart_data.datasets[2].data = [];
      this.yearly_collection_chart_data.datasets[3].data = [];
      this.yearly_collection_chart_data.datasets[4].data = [];
      this.reload2 += 1;
    },
  },
};
</script>
