<template>
  <div>
    <v-card>
      <v-toolbar color="orange" dark>
        <v-toolbar-title><h5>Edit GPay Ref</h5></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-card-text>
        <v-form ref="gpay_edit_form" lazy-validation>
          <v-row dense>
            <v-col cols="12" sm="6" md="2">
              <v-text-field ref="gpay_text" label="GPay" hint="GPay" v-model="gpay" :rules="gpayRules" required validate-on-blur></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text @click="save_edit_dialog">OK</v-btn>
        <v-btn text @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
    <!-- 
      Message Dialog   
    -->
    <div v-if="snackbar">
      <v-snackbar v-model="snackbar" :timeout="2000">
        {{ snackbar_text }}
      </v-snackbar>
    </div>
  </div>
</template>
<script>
import WorkOrderDB from "@/js/database/workorder_db";
import Util from "@/js/Util";
export default {
  name: "GPayEdit",
  data() {
    return {
      doc_id: "",
      edit_item: {},
      gpay: "",
      gpayRules: [(v) => !!v || "GPay Ref is required", (v) => (v && v.length >= 8) || "GPay must be more than 8 characters"],
      snackbar: false,
      snackbar_text: "",
    };
  },
  mounted: function () {
    if (Util.isLoggedIn()) {
      this.doc_id = this.$route.params.id;
      this.get_doc();
    } else {
      location.reload;
    }
    this.$refs.gpay_text.focus();
  },
  methods: {
    close: function () {
      this.$router.push("/my_work_orders");
    },
    get_doc: async function () {
      let doc = await WorkOrderDB.getWithId(this.doc_id);
      this.edit_item = doc;
      this.gpay = doc.gpay_ref;
    },
    save_edit_dialog: async function () {
      let result = this.$refs.gpay_edit_form.validate();
      if (result) {
        let id = this.edit_item._id;
        let doc = await WorkOrderDB.getWithId(id);
        doc.gpay_ref = this.gpay;
        let result = await WorkOrderDB.doUpdate(doc);
        if (result == "OK") {
          this.snackbar_text = "Updated Successfully.";
          this.snackbar = true;
          Util.callThisAfter(this.close, 1000);
        } else {
          this.snackbar_text = result;
          this.snackbar = true;
        }
      }
    },
  },
};
</script>
