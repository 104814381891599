<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <!-- Toolbar -->
        <v-toolbar color="white">
          <v-toolbar-title>
            <v-chip color="orange" text-color="white" label>
              <h3>Payment</h3>
            </v-chip>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <!-- Centered and Conditionally Styled Button/Chip -->
        <v-container fluid class="d-flex justify-center" style="height: 100vh;">
          <div v-if="!transactionCompleted">
            <v-btn
              @click="startBillingProcess"
              :disabled="loading"
              color="blue"
              dark
              elevation="12"
              rounded
              large
              class="px-12 py-4"
            >
              <v-icon left>mdi-cash</v-icon>
              {{ loading ? "Processing..." : "Start Billing Process" }}
            </v-btn>
          </div>
          <div v-else>
            <v-chip color="green" text-color="white" label>
              <h3>Payment Portal is closed</h3>
            </v-chip>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script src="https://testing.momentpay.in/ma/v2/extended-iframe-payment"></script>

<script>
class MomentPay {
  constructor() {
    this.overlay = null;
    this.iframe = null;
    this.onCloseCallback = null;
    this.onResponseCallback = null;
  }

  init(params) {
    this.createOverlay();
    this.createCloseButton();
    this.createIframe(params);
  }

  createOverlay() {
    this.overlay = document.createElement("div");
    this.setStyle(this.overlay, {
      position: "fixed",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: "10000",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    });
    document.body.appendChild(this.overlay);
  }

  createCloseButton() {
    const closeButton = document.createElement("button");
    closeButton.innerHTML = "&#x2715;";
    this.setStyle(closeButton, {
      position: "absolute",
      top: "20px",
      right: "20px",
      zIndex: "10001",
      cursor: "pointer",
      border: "2px solid red",
      borderRadius: "50%",
      width: "30px",
      height: "30px",
      lineHeight: "24px",
      textAlign: "center",
      backgroundColor: "white",
      color: "red",
      fontSize: "20px",
    });
    closeButton.onclick = () => this.closeIframe();
    this.overlay.appendChild(closeButton);
  }

  createIframe(params) {
    this.iframe = document.createElement("iframe");
    this.setStyle(this.iframe, {
      width: "60%",
      height: "80%",
      border: "none",
    });
    this.overlay.appendChild(this.iframe);

    window.requestAnimationFrame(() => this.setupFormInIframe(params));
  }

  setupFormInIframe(params) {
    const doc =
      this.iframe.contentDocument || this.iframe.contentWindow.document;
    doc.open();
    doc.close();

    const form = doc.createElement("form");
    form.method = "post";
    form.action = params.actionUrl;
    doc.body.appendChild(form);

    this.addHiddenInput(doc, form, "token", JSON.stringify(params.token));
    form.submit();
  }

  addHiddenInput(doc, form, name, value) {
    const input = doc.createElement("input");
    input.type = "hidden";
    input.name = name;
    input.value = value;
    form.appendChild(input);
  }

  setStyle(element, styles) {
    for (const property in styles) {
      if (Object.hasOwnProperty.call(styles, property)) {
        element.style[property] = styles[property];
      }
    }
  }

  closeIframe() {
    if (this.overlay) {
      document.body.removeChild(this.overlay);
      this.overlay = null;
      this.iframe = null;
      if (typeof this.onCloseCallback === "function") {
        this.onCloseCallback();
      }
    }
  }

  onClose(callback) {
    this.onCloseCallback = callback;
  }

  onResponse(callback) {
    window.addEventListener(
      "message",
      (event) => {
        if (typeof callback === "function") {
          callback(event.data);
          this.closeIframe();
        }
      },
      false
    );
  }
}

const paymentMoment = new MomentPay();

export default {
  props: ["show", "item"],
  data() {
    return {
      dialog: this.show,
      payment: this.item,
      transactionCompleted: false,
      loading: false,
    };
  },

  watch: {
    show(val) {
      this.dialog = val;
    },
    dialog(val) {
      if (!val) this.$emit("close_payment");
    },
  },

  mounted() {
     window.addEventListener(
      "message",
      function(event) {
      console.log("testing");
        // Make sure to validate the origin to ensure it's from the trusted source (e.g., momentPay)
        if (
          event.origin !==
          "https://testing.momentpay.in/ma/v2/extended-iframe-payment"
        ) {
          console.warn("Untrusted message source: ", event.origin);
          return;
        }
        // Parse the data received from the iframe post
        var data = event.data;
        console.log(data);
        // Handling different status codes received from momentPay
        switch (data.Code) {
          case "PAYMENT_SUCCESS":
            console.log("Transaction processed successfully.");
           
            break;
          case "PAYMENT_PENDING":
            console.log("Payment is pending. Awaiting user action.");
            
            break;
          default:
            console.log("Received unknown status code: ", data.Code);
            // window.location.href =
              //"https://testapp.ariticapp.com/ma/thankyou-new";

            // Handle unexpected status codes
            break;
        }
      },
      false
    );

    paymentMoment.onClose(() => {
      console.log("Payment window closed");
      this.transactionCompleted = true;
      this.loading = false;
    });
  },

  methods: {
    close() {
      this.dialog = false;
    },
    startBillingProcess() {
      this.loading = true;
      const params = {
        actionUrl: "https://testing.momentpay.in/ma/v2/extended-iframe-payment",
        token: {
          auth: {
            user: "andersondiagnostics_test",
            key: "n0tKMr09pVykMbb7k4ua9cJYxQ2CjXMg6p0Rq35UVTK7nMamea",
          },
          cashier_id: "24",
          accounts: [
            {
              patient_name: "Femi Bose",
              account_number: "1234",
              amount: "150",
              email: "femibose2001@gmail.com",
              phone: "9876543210",
            },
          ],
          processing_id: "876543210",
          paymode: "mobile-app",
          payment_fill: "pre_full",
          payment_location: "Test Hospital",
          return_url: "https://testapp.ariticapp.com/ma/thankyou-new",
          response_url: "https://testapp.ariticapp.com/ma/thankyou-new",
        },
      };

      paymentMoment.init(params);
    },
  },
};
</script>
