<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar color="white">
            <v-toolbar-title
              ><v-chip color="orange" text-color="white" label><h3>View PDF</h3></v-chip></v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn icon @click="prev()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-btn icon @click="next()"><v-icon>mdi-arrow-right</v-icon></v-btn>
            <v-btn icon @click="close()"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar>
          <v-container v-if="show_error">
            <v-alert type="warning">{{ error_msg }}</v-alert>
          </v-container>
          <v-container>
            <canvas id="the_viewer" ref="the_viewer"></canvas>
          </v-container>
        </v-card>
        <v-bottom-navigation>
          <v-spacer></v-spacer>
          <v-btn icon @click="prev()"><v-icon>mdi-arrow-left</v-icon></v-btn>
          <v-btn icon @click="next()"><v-icon>mdi-arrow-right</v-icon></v-btn>
          <v-btn icon @click="close()"><v-icon>mdi-close</v-icon></v-btn>
        </v-bottom-navigation>
      </v-dialog>
    </v-row>
    <v-overlay :value="overlay" :z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import axios from "axios";
import settings from "@/js/database/settings";
import Util from "@/js/Util";
export default {
  props: ["show_report", "s3_url"],
  data() {
    return {
      overlay: false,
      dialog: this.show_report,
      base64_data: "",
      pageNumber: 1,
      numPages: 0,
      show_error: false,
      error_msg: "",
    };
  },
  mounted: function () {
    //var body = document.getElementsByTagName("BODY")[0];
    //body.classList.add("dialog_class");
    console.log(this.s3_url);
    // this.overlay = true;
    // this.previewPDF(1);
    // this.overlay = false;
    this.getPDF();
  },
  methods: {
    showPDF: async function (data) {
      var buffer = await data.arrayBuffer();
      this.base64_data = buffer;
      this.previewPDF(1);
    },
    getPDF: function () {
      let item = this.s3_url;
      let dArray = [];
      if (item.indexOf(" | ") > -1) {
        dArray = item.split(" | ");
      } else {
        dArray[0] = "homecollection";
        dArray[1] = item;
      }
      let jwt_token = Util.getFromSession("pg_admin");
      let data = {
        bucket_name: dArray[0],
        key: dArray[1],
        jwt_token: jwt_token,
      };
      let name = dArray[1];
      if (name.indexOf("/") > -1) {
        name = name.substring(name.lastIndexOf("/") + 1);
      }
      this.overlay = true;
      let instance = this;
      axios({
        method: "post",
        responseType: "blob",
        url: settings.node_url + "/s3/get_file_v2",
        data: data,
      })
        .then(async function (response) {
          instance.overlay = false;
          if (response.data.size < 1000) {
            instance.error_msg = "Error Occurred. Please try later.";
            instance.show_error = true;
          } else {
            instance.showPDF(response.data);
          }
        })
        .catch(function (error) {
          instance.overlay = false;
          console.log("error:" + error);
          alert(error);
        });
    },
    next: function () {
      if (this.pageNumber >= this.numPages) {
        return;
      }
      this.previewPDF(++this.pageNumber);
      this.$nextTick(() => {
        this.$refs["the_viewer"].scrollIntoView({
          block: "start",
          scrollBehavior: "smooth",
        });
      });
    },
    prev: function () {
      if (this.pageNumber == 1) {
        return;
      }
      this.previewPDF(--this.pageNumber);
      this.$nextTick(() => {
        this.$refs["the_viewer"].scrollIntoView({
          block: "start",
          scrollBehavior: "smooth",
        });
      });
    },
    close: function () {
      this.dialog = false;
      this.$emit("close_pdf");
    },
    previewPDF: function (pageNumber) {
      //var pdfjsLib = window["pdfjs-dist/build/pdf"];
      //pdfjsLib.GlobalWorkerOptions.workerSrc = "https://mozilla.github.io/pdf.js/build/pdf.worker.js";

      var pdfjsLib = window["pdfjs-dist/build/pdf"];
      pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.0.269/pdf.worker.min.mjs";
      var pdfData = this.base64_data;
      var loadingTask = pdfjsLib.getDocument({ data: pdfData });
      let instance = this;
      var scale = "2.0";
      if (Util.isMobile()) {
        scale = "0.5";
      }
      loadingTask.promise.then(
        function (pdf) {
          console.log("PDF loaded");
          instance.numPages = pdf.numPages;
          pdf.getPage(pageNumber).then(function (page) {
            var viewport = page.getViewport({ scale: scale });
            var canvas = document.getElementById("the_viewer");
            var context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            var renderContext = {
              canvasContext: context,
              viewport: viewport,
            };
            var renderTask = page.render(renderContext);
            renderTask.promise.then(function () {
              console.log("Page rendered");
            });
          });
        },
        function (reason) {
          console.error(reason);
        }
      );
    },
  },
};
</script>
<style>
.dialog_class {
  overflow-y: hidden;
}
</style>
