import { CronJob } from "cron";
import DBHandler from "@/js/database/DBHandler.js";
import PostgresDB from "@/js/database/postgres_db.js";
import Util from "@/js/Util.js";
import WorkOrderDB from "@/js/database/workorder_db";
import * as XLSX from 'xlsx';
import axios from "axios";
import settings from "@/js/database/settings";
let cron_job = {
  run: function () {
    try {
      cron_job.db_cron();
      //cron_job.time_cron3();
      //cron_job.time_cron2();
     // cron_job.geolocation_cron();
    } catch (err) {
      console.log(err);
    }
  },
  db_cron: function () {
    let token_job = new CronJob("0 */45 * * * *", async function () {
      console.log(">>>>>>> calling PostgresDB.refreshToken() <<<<<<<< ");
      await PostgresDB.refreshToken();
      console.log(">>>>>>> calling DBHandler.init() <<<<<<<< ");
      await DBHandler.init();
    });
    console.log("45 min. cloudant cron job created.");
    token_job.start();
  },
  time_cron: function () {
    let time_job = new CronJob("0 */1 * * * *", async function () {
      console.log(">>>>>>> calling Time Cron <<<<<<<< ");
      Util.update_time();
    });
    console.log("1 min. time cron job created.");
    time_job.start();
  },
  time_cron2: function () {
    //let time_job = new CronJob("0 */1 * * * *", async function () {
    let time_job = new CronJob("0 15 0 * * *", async function () {
      console.log(">>>>>>> calling Time Cron <<<<<<<< ");
      //Util.update_time();
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      // Format the date as YYYY-MM-DD
      const year = yesterday.getFullYear();
      const month = String(yesterday.getMonth() + 1).padStart(2, '0'); // Add leading zero
      const day = String(yesterday.getDate()).padStart(2, '0'); // Add leading zero
      const this_date = this.order_date = year + '-' + month + '-' + day;
      console.log(this_date);
      this.work_orders = await WorkOrderDB.list(this.order_date);


      let exportData = this.work_orders.map((order, index) => ({
        No: index + 1,
        Name: order.name,
        Gender: order.gender,
        Age: order.age,
        Mobile: order.mobile,
        Date: order.appointment_date,
        Time: order.appointment_time,
        "Tech. Status": order.status,
        "Server Status": order.server_status,
        "Assigned To": order.assigned_to
      }));

      // Create a new workbook
      const wb = XLSX.utils.book_new();

      // Convert data to worksheet
      const ws = XLSX.utils.json_to_sheet(exportData);

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "Work Orders");

      // Trigger the file download
      //  XLSX.writeFile(wb, "WorkOrders.xlsx");
      XLSX.writeFile(wb, 'work_order_' + this_date + '.xlsx');

      // Handle the fetched work orders (if necessary)
      console.log("Work orders:", this.work_orders);
    });
    //console.log("1 min. time cron job created.");
    console.log("12:15 AM daily time cron job created.");
    time_job.start();
  },



  time_cron4: function () {
    //  let time_job = new CronJob("0 15 0 * * *", async function () {
    let time_job = new CronJob("0 */45 * * * *", async function () {
      console.log(">>>>>>> calling Time Cron <<<<<<<< ");

      //Util.update_time();
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      // Format the date as YYYY-MM-DD
      const year = yesterday.getFullYear();
      const month = String(yesterday.getMonth() + 1).padStart(2, '0'); // Add leading zero
      const day = String(yesterday.getDate()).padStart(2, '0'); // Add leading zero
      const this_date = year + '-' + month + '-' + day;
      console.log(this_date);
      this.work_orders = await WorkOrderDB.list(this_date);

      let exportData = this.work_orders.map((order, index) => {
        let createdat = "NA";
        let createdby = "NA";
        let editedby = "NA";
        let editedat = "NA";
        let assignedat = "NA";
        let assignedby = "NA";
        let copiedby = "NA";
        let copiedat = "NA";
        let startedby = "NA";
        let startedat = "NA";
        let completedat = "NA";
        let completedby = "NA";
        let billedat = "NA";
        let billedby = "NA";
        let cancelledat = "NA";
        let cancelledby = "NA";
        let remittancemadeby = "NA";
        let remittancemadeat = "NA";
        let remittancecancelat = "NA";
        let remittancecancelby = "NA";

        // Process the time_line if it exists
        if (order.time_line && order.time_line.length > 0) {
          order.time_line.forEach(entry => {
            if (entry.includes("Work Order Created")) {
              createdat = entry.split("-")[0].trim();
              createdby = entry.split("-")[1].trim();
            } else if (entry.includes("Work Order Edited")) {
              editedat = entry.split("-")[0].trim();
              editedby = entry.split("-")[1].trim();
            }
            else if (entry.includes("Work Order Assigned")) {
              assignedat = entry.split("-")[0].trim();
              assignedby = entry.split("-")[1].trim();
            }
            else if (entry.includes("Work Order Copied")) {
              copiedat = entry.split("-")[0].trim();
              copiedby = entry.split("-")[1].trim();
            } else if (entry.includes("Work Order Started")) {
              startedat = entry.split("-")[0].trim();
              startedby = entry.split("-")[1].trim();
            } else if (entry.includes("Work Order Completed")) {
              completedat = entry.split("-")[0].trim();
              completedby = entry.split("-")[1].trim();
            }
            else if (entry.includes("Work Order Billed")) {
              billedat = entry.split("-")[0].trim();
              billedby = entry.split("-")[1].trim();
            }
            else if (entry.includes("Work Order Cancelled")) {
              cancelledat = entry.split("-")[0].trim();
              cancelledby = entry.split("-")[1].trim();
            }
            else if (entry.includes("Remittance Made")) {
              remittancemadeat = entry.split("-")[0].trim();
              remittancemadeby = entry.split("-")[1].trim();
            }
            else if (entry.includes("Remittance Cancelled")) {
              remittancecancelat = entry.split("-")[0].trim();
              remittancecancelby = entry.split("-")[1].trim();
            }
          });
        }

        return {
          No: index + 1,
          Name: order.name,
          Gender: order.gender,
          Age: order.age,
          Mobile: order.mobile,
          Date: order.appointment_date,
          Time: order.appointment_time,
          "Tech. Status": order.status,
          "Server Status": order.server_status,
          "Assigned To": order.assigned_to,

          "Created By": createdby,
          "Created At": createdat,
          "Edited By": editedby,
          "Edited At": editedat,
          "Assigned By": assignedby,
          "Assigned At": assignedat,
          "Copied By": copiedby,
          "Copied At": copiedat,
          "Started By": startedby,
          "Started At": startedat,
          "Completed At": completedat,
          "Completed By": completedby,
          "Billed By": billedby,
          "Billed At": billedat,
          "Remittance Made By": remittancemadeby,
          "Remittance Made At": remittancemadeat,
          "Remittance Cancel By": remittancecancelby,
          "Remittance Cancel At": remittancecancelat,
          "Cancelled By": cancelledby,
          "Cancelled At": cancelledat
        };
      });

      // Create a new workbook
      const wb = XLSX.utils.book_new();

      // Convert data to worksheet
      const ws = XLSX.utils.json_to_sheet(exportData);

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "Work Orders");

      // Trigger the file download with the dynamic name
      XLSX.writeFile(wb, 'work_order_' + this_date + '.xlsx');

      // Handle the fetched work orders (if necessary)
      console.log("Work orders:", this.work_orders);
    });

    console.log("12:15 AM daily time cron job created.");
    time_job.start();
  },


  time_cron3: function () {
    //  let time_job = new CronJob("0 15 0 * * *", async function () {
    let time_job = new CronJob("0 */1 * * * *", async function () {
      console.log(">>>>>>> calling Time Cron <<<<<<<< ");

      //Util.update_time();
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      // Format the date as YYYY-MM-DD
      const year = yesterday.getFullYear();
      const month = String(yesterday.getMonth() + 1).padStart(2, '0'); // Add leading zero
      const day = String(yesterday.getDate()).padStart(2, '0'); // Add leading zero
      const this_date = year + '-' + month + '-' + day;
      console.log(this_date);
      this.work_orders = await WorkOrderDB.list(this_date);

      let exportData = this.work_orders.map((order, index) => {
        let createdat = "NA";
        let createdby = "NA";
        let editedby = "NA";
        let editedat = "NA";
        let assignedat = "NA";
        let assignedby = "NA";
        let copiedby = "NA";
        let copiedat = "NA";
        let startedby = "NA";
        let startedat = "NA";
        let completedat = "NA";
        let completedby = "NA";
        let billedat = "NA";
        let billedby = "NA";
        let cancelledat = "NA";
        let cancelledby = "NA";
        let remittancemadeby = "NA";
        let remittancemadeat = "NA";
        let remittancecancelat = "NA";
        let remittancecancelby = "NA";

        // Process the time_line if it exists
        if (order.time_line && order.time_line.length > 0) {
          order.time_line.forEach(entry => {
            if (entry.includes("Work Order Created")) {
              createdat = entry.split("-")[0].trim();
              createdby = entry.split("-")[1].trim();
            } else if (entry.includes("Work Order Edited")) {
              editedat = entry.split("-")[0].trim();
              editedby = entry.split("-")[1].trim();
            }
            else if (entry.includes("Work Order Assigned")) {
              assignedat = entry.split("-")[0].trim();
              assignedby = entry.split("-")[1].trim();
            }
            else if (entry.includes("Work Order Copied")) {
              copiedat = entry.split("-")[0].trim();
              copiedby = entry.split("-")[1].trim();
            } else if (entry.includes("Work Order Started")) {
              startedat = entry.split("-")[0].trim();
              startedby = entry.split("-")[1].trim();
            } else if (entry.includes("Work Order Completed")) {
              completedat = entry.split("-")[0].trim();
              completedby = entry.split("-")[1].trim();
            }
            else if (entry.includes("Work Order Billed")) {
              billedat = entry.split("-")[0].trim();
              billedby = entry.split("-")[1].trim();
            }
            else if (entry.includes("Work Order Cancelled")) {
              cancelledat = entry.split("-")[0].trim();
              cancelledby = entry.split("-")[1].trim();
            }
            else if (entry.includes("Remittance Made")) {
              remittancemadeat = entry.split("-")[0].trim();
              remittancemadeby = entry.split("-")[1].trim();
            }
            else if (entry.includes("Remittance Cancelled")) {
              remittancecancelat = entry.split("-")[0].trim();
              remittancecancelby = entry.split("-")[1].trim();
            }
          });
        }

        return {
          No: index + 1,
          Name: order.name,
          Gender: order.gender,
          Age: order.age,
          Mobile: order.mobile,
          Date: order.appointment_date,
          Time: order.appointment_time,
          "Tech. Status": order.status,
          "Server Status": order.server_status,
          "Assigned To": order.assigned_to,

          "Created By": createdby,
          "Created At": createdat,
          "Edited By": editedby,
          "Edited At": editedat,
          "Assigned By": assignedby,
          "Assigned At": assignedat,
          "Copied By": copiedby,
          "Copied At": copiedat,
          "Started By": startedby,
          "Started At": startedat,
          "Completed At": completedat,
          "Completed By": completedby,
          "Billed By": billedby,
          "Billed At": billedat,
          "Remittance Made By": remittancemadeby,
          "Remittance Made At": remittancemadeat,
          "Remittance Cancel By": remittancecancelby,
          "Remittance Cancel At": remittancecancelat,
          "Cancelled By": cancelledby,
          "Cancelled At": cancelledat
        };
      });

      // Create a new workbook
      const wb = XLSX.utils.book_new();

      // Convert data to worksheet
      const ws = XLSX.utils.json_to_sheet(exportData);

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "Work Orders");

      // Trigger the file download with the dynamic name
      //   XLSX.writeFile(wb, 'work_order_' + this_date + '.xlsx');
      // Write Excel to a buffer (for sending via email)
      //const excelsheet = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
      const excelsheet = XLSX.write(wb, { bookType: 'xlsx', type: 'base64' });
      axios.post(settings.node_url + "/sms/send-email", {
        to: 'jenipherjenipher666@gmail.com',
        subject: 'Daily Work Orders',
        text: 'Please find the attached work order report for yesterday.',
        excelsheet: excelsheet, // send the base64 encoded Excel sheet
      });
      // Handle the fetched work orders (if necessary)
      console.log("Work orders:", this.work_orders);
    });

    console.log("12:15 AM daily time cron job created.");
    time_job.start();
  },




  // New geolocation cron job that runs every 1 minute
  geolocation_cron: function () {
    let geo_job = new CronJob("0 */1 * * * *", async function () {
      console.log(">>>>>>> calling Geolocation Cron <<<<<<<< ");
      
      // Fetch geolocation
      try {
        let geolocation = await cron_job.getGeolocation();
        console.log("Geolocation Data: ", geolocation);

        // Example: You can store geolocation or use it as needed
        //await PostgresDB.updateGeolocation(geolocation);  // Modify as per your DB logic
        //console.log("Geolocation updated in DB.");
      } catch (error) {
        console.error("Error fetching geolocation: ", error);
      }
    });
    console.log("1 min. geolocation cron job created.");
    geo_job.start();
  },

  // Geolocation function
  getGeolocation: function () {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          let latitude = position.coords.latitude;
          let longitude = position.coords.longitude;
          resolve({ latitude, longitude });
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  

};
export default cron_job;
