<template>
  <v-container>
    <v-btn-toggle
        v-model="selectedComponent"
        tile
        color="deep-purple accent-3"
        group
      >
        <v-btn value="daily_report">
          Daily 
        </v-btn>

        <v-btn value="weekly_report">
          Weekly
        </v-btn>

        <v-btn value="monthly_report">
          Monthly
        </v-btn>

        <v-btn value="yearly_report">
          Yearly
        </v-btn>
      </v-btn-toggle>
      <component :is="selectedComponent"></component>
  </v-container>
</template>
<script>
import monthly_report from './monthly_report.vue';
import daily_report from './daily_report.vue';
import weekly_report from './weekly_report.vue';
import yearly_report from './yearly_report.vue';
export default {
  components:{monthly_report,daily_report,weekly_report,yearly_report},
  data () {
      return {
        selectedComponent: 'monthly_report',
      }
  }
}
</script>