<template>
   <div>
        <v-container v-if="isMobile()">
            <v-chip color="orange" text-color="white" label><h3>Notifications</h3></v-chip>
            <mobile_view :key="compKey"></mobile_view>
        </v-container>
        <v-container v-else>
            <v-chip color="orange" text-color="white" label><h3>Notifications</h3></v-chip>
            <desktop_view :key="compKey"></desktop_view>
        </v-container>
   </div> 
</template>
<script>
import mobile_view from '@/components/notifications/mobile_view.vue';
import desktop_view from '@/components/notifications/desktop_view.vue';
import Util from "@/js/Util";
export default {
  name: 'Notifications',
  components: { mobile_view,desktop_view },
  data : function() {
      return {
          compKey: 0,
      }
  },
  methods: {
    isMobile: function(){
      return Util.isMobile();
    },
    update: function(){
      this.compKey += 1;
    },
  }
};
</script>
<style>
</style>