<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <v-radio-group v-model="select" row dense @change="radio_change">
          <v-radio value="Mobile" label="Mobile" hide-details class="shrink mr-2 mt-0 my-checkbox"></v-radio>
          <v-radio value="Date" label="Date" hide-details class="shrink mr-2 mt-0 my-checkbox"></v-radio>
          <v-radio value="Name" label="Name" hide-details class="shrink mr-2 mt-0 my-checkbox"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="10">
        <v-text-field
          v-model="search"
          label="Mobile Search"
          single-line
          hide-details
          v-if="select == 'Mobile'"
          @keydown.enter="debounce_search()"
        ></v-text-field>
        <v-menu
          v-model="date_menu"
          :close-on-content-click="true"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
          v-else-if="select == 'Date'"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="search" label="Date Search" readonly v-bind="attrs" v-on="on" @keydown.enter="debounce_search()"></v-text-field>
          </template>
          <v-date-picker v-model="search" class="mt-4" min="2021-08-01" :max="max_date" @input="menu2 = false"></v-date-picker>
        </v-menu>
        <v-text-field
          v-model="search"
          label="Name Search"
          single-line
          hide-details
          v-else-if="select == 'Name'"
          @keydown.enter="debounce_search()"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-btn class="mx-1" fab dark small color="primary" @click.native="debounce_search()"><v-icon dark>mdi-magnify</v-icon></v-btn>
      </v-col>
    </v-row>
    <wo_mobile_view :search_result="search_result" mode="search" :key="compKey_12"></wo_mobile_view>
    <!-- 
      snackbar msg   
   -->
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackbar_text }}
    </v-snackbar>
    <v-overlay :value="overlay" :z-index="2000">
      <v-progress-circular absolute size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Util from "@/js/Util";
import moment from "moment";
import wo_mobile_view from "@/components/my_work_order/mobile_view.vue";
import PostgresDB from "@/js/database/postgres_db";
export default {
  name: "SearchHC",
  components: { wo_mobile_view },
  data() {
    return {
      select: "Mobile",
      search: "",
      date_menu: false,
      max_date: "",
      snackbar_text: "",
      snackbar: false,
      overlay: false,
      search_result: [],
      compKey_12: 0,
    };
  },
  mounted: function () {
    this.max_date = moment().format("YYYY-MM-DD");
  },
  methods: {
    radio_change: function () {
      this.search = "";
      this.search_result = [];
      this.compKey_12 = this.compKey_12 + 1;
    },
    debounce_search: Util.debounce(function () {
      this.search_details();
    }, 500),
    search_details: async function () {
      try {
        console.log("search..." + this.search + " mode:" + this.select);
        let items = [];
        if (this.select == "Mobile") {
          let str = this.search;
          if (str.length < 10) {
            this.snackbar_text = "Please Enter Valid Mobile Number";
            this.snackbar = true;
            return;
          }
          try {
            let mobile = parseInt(this.search);
            if (mobile <= 999999999 || mobile >= 10000000000) {
              this.snackbar_text = "Please Enter Valid Mobile Number";
              this.snackbar = true;
              return;
            }
          } catch (e) {
            this.snackbar_text = "Please Enter Valid Mobile Number:" + JSON.stringify(e);
            this.snackbar = true;
            return;
          }
          this.overlay = true;
          let search_result = await PostgresDB.search_patients(this.search, this.select);
          if (search_result instanceof String) {
            throw search_result;
          }
          for (let i = 0; i < search_result.length; i++) {
            let each_result = search_result[i];
            let multi_doc = each_result.hc_patient_visit_detail;
            for (let j = 0; j < multi_doc.length; j++) {
              let each_doc = multi_doc[j];
              let jdoc = JSON.parse(each_doc.doc);
              let app_dt_time = jdoc.appointment_date + " " + jdoc.appointment_time;
              let sort_time = Util.parseAppTime(app_dt_time).valueOf();
              jdoc.sort_time = sort_time;
              items.push(jdoc);
            }
          }
          //  sort latest first
          items.sort(function (a, b) {
            return b.sort_time - a.sort_time;
          });
          // items.sort(function (a, b) {
          //   let dt_a = moment(a.appointment_date, "DD-MM-YYYY");
          //   let dt_b = moment(b.appointment_date, "DD-MM-YYYY");
          //   let diff = dt_b.diff(dt_a, "days");
          //   return diff;
          // });
          this.overlay = false;
        } else if (this.select == "Date" || this.select == "Name") {
          let str = this.search;
          if (!str) {
            this.snackbar_text = "Please Enter Valid " + this.select + ".";
            this.snackbar = true;
            return;
          }
          this.overlay = true;
          let search_result = await PostgresDB.search_patients(this.search, this.select);
          if (search_result instanceof String) {
            throw search_result;
          }
          for (let i = 0; i < search_result.length; i++) {
            let each_result = search_result[i];
            let jdoc = JSON.parse(each_result.doc);
            let app_dt_time = jdoc.appointment_date + " " + jdoc.appointment_time;
            let sort_time = Util.parseAppTime(app_dt_time).valueOf();
            jdoc.sort_time = sort_time;
            items.push(jdoc);
          }
          //  sort latest first
          items.sort(function (a, b) {
            return b.sort_time - a.sort_time;
          });
          this.overlay = false;
        }
        console.log("total search items:" + items.length);
        this.search_result = items;
        this.compKey_12 = this.compKey_12 + 1;
        if (items.length == 0) {
          this.snackbar_text = "No Data Found For Your Search.";
          this.snackbar = true;
        }
      } catch (e) {
        console.log(e);
        this.snackbar_text = "Error Occurred:" + JSON.stringify(e);
        this.snackbar = true;
        this.overlay = false;
      }
    },
  },
};
</script>
