<template>
  <div>
    <v-card>
      <v-toolbar color="blue lighter-2" dark>
        <v-toolbar-title>Work Orders</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="canceled_work_order()">
          <v-icon>mdi-account-multiple-remove-outline</v-icon>
        </v-btn>
        <v-btn icon @click="show_price()">
          <v-icon>mdi-clipboard-list-outline</v-icon>
        </v-btn>
        <v-btn icon @click="show_tech_engagements()">
          <v-icon>mdi-account-details-outline</v-icon>
        </v-btn>
        <v-btn icon @click="show_add_screen()">
          <v-icon>mdi-playlist-plus</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs align-with-title center-active v-model="tab">
            <v-tabs-slider color="orange"></v-tabs-slider>
            <v-tab @click="update(1)" :key="0">
              {{ getDate(3) }}
            </v-tab>
            <v-tab @click="update(2)" :key="1">
              {{ getDate(2) }}
            </v-tab>
            <v-tab @click="update(3)" :key="2">
              Nextday<br />{{ getDate(1) }}
            </v-tab>
            <v-tab @click="update(4)" :key="3">
              Today<br />{{ getDate(0) }}
            </v-tab>
            <v-tab @click="update(5)" :key="4">
              Yesterday<br />{{ getDate(-1) }}
            </v-tab>
            <v-tab @click="update(6)" :key="5">
              {{ getDate(-2) }}
            </v-tab>
            <v-tab @click="update(7)" :key="6">
              {{ getDate(-3) }}
            </v-tab>
            <v-tab @click="update(8)" :key="7">
              {{ getDate(-4) }}
            </v-tab>
            <v-tab @click="update(9)" :key="8">
              {{ getDate(-5) }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat v-if="isMobile()">
            <mobile_view
              :this_date="getDatesInRange(3, 10)"
              :key="compKey_1"
            ></mobile_view>
          </v-card>
          <v-card flat v-else>
            <desktop_view
              :this_date="getDatesInRange(3, 10)"
              :key="compKey_1"
            ></desktop_view>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat v-if="isMobile()">
            <mobile_view :this_date="getDate(2)" :key="compKey_2"></mobile_view>
          </v-card>
          <v-card flat v-else>
            <desktop_view
              :this_date="getDate(2)"
              :key="compKey_2"
            ></desktop_view>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat v-if="isMobile()">
            <mobile_view :this_date="getDate(1)" :key="compKey_3"></mobile_view>
          </v-card>
          <v-card flat v-else>
            <desktop_view
              :this_date="getDate(1)"
              :key="compKey_3"
            ></desktop_view>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat v-if="isMobile()">
            <mobile_view :this_date="getDate(0)" :key="compKey_4"></mobile_view>
          </v-card>
          <v-card flat v-else>
            <desktop_view
              :this_date="getDate(0)"
              :key="compKey_4"
            ></desktop_view>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat v-if="isMobile()">
            <mobile_view
              :this_date="getDate(-1)"
              :key="compKey_5"
            ></mobile_view>
          </v-card>
          <v-card flat v-else>
            <desktop_view
              :this_date="getDate(-1)"
              :key="compKey_5"
            ></desktop_view>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat v-if="isMobile()">
            <mobile_view
              :this_date="getDate(-2)"
              :key="compKey_6"
            ></mobile_view>
          </v-card>
          <v-card flat v-else>
            <desktop_view
              :this_date="getDate(-2)"
              :key="compKey_6"
            ></desktop_view>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat v-if="isMobile()">
            <mobile_view
              :this_date="getDate(-3)"
              :key="compKey_7"
            ></mobile_view>
          </v-card>
          <v-card flat v-else>
            <desktop_view
              :this_date="getDate(-3)"
              :key="compKey_7"
            ></desktop_view>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat v-if="isMobile()">
            <mobile_view
              :this_date="getDate(-4)"
              :key="compKey_8"
            ></mobile_view>
          </v-card>
          <v-card flat v-else>
            <desktop_view
              :this_date="getDate(-4)"
              :key="compKey_8"
            ></desktop_view>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat v-if="isMobile()">
            <mobile_view
              :this_date="getDate(-5)"
              :key="compKey_9"
            ></mobile_view>
          </v-card>
          <v-card flat v-else>
            <desktop_view
              :this_date="getDate(-5)"
              :key="compKey_9"
            ></desktop_view>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-container v-if="type === 'add_work_order_dialog'">
      <add_work_order
        v-bind:show="show_add"
        @close_add_work_order="close_add_screen"
      ></add_work_order>
    </v-container>
    <v-container v-if="tech_engagement_dialog">
      <tech_engagement
        v-bind:show="show_engagement"
        @close_tech_engagements="close_tech_engagements"
      ></tech_engagement>
    </v-container>
    <v-container v-if="price_view_dialog">
      <price_view
        v-bind:show="show_price_view"
        @close_price_view="close_price_view"
      ></price_view>
    </v-container>
    <v-container v-if="price_view_manager_dialog">
      <price_view_manager
        v-bind:show="show_price_view"
        @close_price_view="close_price_view"
      ></price_view_manager>
    </v-container>
    <v-container v-if="canceled_work_order_dialog">
      <canceled_work_order
        v-bind:show="show_canceled_work_order"
        @close_canceled_work_order="close_canceled_work_order"
      ></canceled_work_order>
    </v-container>
    <!-- 
      Message Dialog   
  -->
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>
<script>
import moment from "moment";
import mobile_view from "@/components/work_order/mobile_view.vue";
import desktop_view from "@/components/work_order/desktop_view.vue";
import add_work_order from "@/components/work_order/add_work_order.vue";
import canceled_work_order from "@/components/work_order/canceled_work_order.vue";
import tech_engagement from "@/components/work_order/tech_engagement.vue";
import price_view from "@/components/work_order/price_view.vue";
import price_view_manager from "@/components/work_order/price_view_manager.vue";
import DBHandler from "@/js/database/DBHandler";
import store from "@/store";
import Util from "@/js/Util";
export default {
  components: {
    price_view_manager,
    mobile_view,
    desktop_view,
    add_work_order,
    tech_engagement,
    price_view,
    canceled_work_order,
  },
  data() {
    return {
      sheet: false,
      tab: 3,
      compKey_1: 0,
      compKey_2: 0,
      compKey_3: 0,
      compKey_4: 0,
      compKey_5: 0,
      compKey_6: 0,
      compKey_7: 0,
      compKey_8: 0,
      compKey_9: 0,
      compKey_10: 0,
      show_add: false,
      type: "",
      snackbar: false,
      snackbar_text: "",
      to_day: "",
      tech_engagement_dialog: false,
      show_engagement: false,
      show_price_view: false,
      price_view_dialog: false,
      price_view_manager_dialog: false,
      show_canceled_work_order: false,
      canceled_work_order_dialog: false,
    };
  },
  mounted: function() {},
  methods: {
    isMobile: function() {
      return Util.isMobile();
    },
    close_price_view: function() {
      this.show_price_view = false;
      this.price_view_dialog = false;
      this.price_view_manager_dialog = false;
    },
    show_price: function() {
      let role = Util.getFromSession("role_name");
      if (role == "MANAGER") {
        this.show_price_view = true;
        this.price_view_manager_dialog = true;
      } else {
        this.show_price_view = true;
        this.price_view_dialog = true;
      }
    },
    canceled_work_order: function() {
      this.show_canceled_work_order = true;
      this.canceled_work_order_dialog = true;
    },
    close_canceled_work_order: function() {
      this.show_canceled_work_order = false;
      this.canceled_work_order_dialog = false;
    },
    close_tech_engagements: function() {
      this.show_engagement = false;
      this.tech_engagement_dialog = false;
    },
    show_tech_engagements: function() {
      this.show_engagement = true;
      this.tech_engagement_dialog = true;
    },
    getTime: async function() {
      let timeDb = await DBHandler.getDb("last_updated_at");
      this.to_day = await timeDb
        .get("last:updated_time")
        .then(function(doc) {
          console.log("updating time here......" + doc.time);
          return doc.time;
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    update: function(index) {
      this["compKey_" + index] += 1;
    },
    getDate: function(days) {
      if (this.to_day == "") {
        this.to_day = store.getters.getToday;
      }
      //console.log('getDate set here....>>>>>>>>>>>'+this.to_day);
      let calendar = moment(this.to_day, "YYYY-MM-DD HH:mm:ss", true);
      if (days > 0) {
        calendar = calendar.add(days, "days");
      } else if (days < 0) {
        days *= -1;
        calendar = calendar.subtract(days, "days");
      }
      return calendar.format("YYYY-MM-DD");
    },
    getDatesInRange(start, end) {
      let dates = [];
      for (let i = start; i <= end; i++) {
        dates.push(this.getDate(i));
      }
      console.log("Dates in range:", dates); // Log output for debugging
      return dates;
    },
    close_add_screen: function(e) {
      if (typeof e === "string" || e instanceof String) {
        this.snackbar_text = e;
        this.snackbar = true;
      }
      this.show_add = false;
      this.type = "";
    },
    show_add_screen: function() {
      this.type = "add_work_order_dialog";
      this.show_add = true;
    },
  },
};
</script>
<style></style>
