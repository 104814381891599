import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import ChangePassword from "../views/ChangePassword.vue";
import WorkOrders from "../views/WorkOrders.vue";
import Users from "../views/Users.vue";
import MyWorkOrders from "../views/MyWorkOrders.vue";
import BillingWorkOrders from "../views/BillingWorkOrders.vue";
import Loading from "../views/Loading";
import Logout from "../views/Logout";
import Notifications from "../views/Notifications";
import HCProcess from "../views/HCProcess";
import lab_sample_receiving from "../views/lab_sample_receiving.vue";
import TechEditNameAge from "../views/TechEditNameAge.vue";
import GPayEdit from "../views/GPayEdit.vue";
import TechViewTests from "../views/TechViewTests.vue";
import RemarksEdit from "../views/RemarksEdit.vue";
import SearchHC from "../views/SearchHC.vue";
import Util from "@/js/Util";
import transaction_success from "@/components/work_order/transaction_success.vue";
import transaction_failure from "@/components/work_order/transaction_failure.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { isPublic: true },
  },
  {
    path: "/loading",
    name: "Loading",
    component: Loading,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/changepassword",
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/work_orders",
    name: "Orders",
    component: WorkOrders,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
  },
  {
    path: "/my_work_orders",
    name: "MyWorkOrders",
    component: MyWorkOrders,
  },
  {
    path: "/billing_work_orders",
    name: "BillingWorkOrders",
    component: BillingWorkOrders,
  },
  {
    path: "/hc_process/:id",
    name: "HCProcess",
    component: HCProcess,
  },
  {
    path: "/lab_sample_receiving",
    name: "lab_sample_receiving",
    component: lab_sample_receiving,
  },
  {
    path: "/tech_edit_name_age/:id",
    name: "TechEditNameAge",
    component: TechEditNameAge,
  },
  {
    path: "/tech_edit_gpay/:id",
    name: "GPayEdit",
    component: GPayEdit,
  },
  {
    path: "/tech_view_tests/:id",
    name: "TechViewTests",
    component: TechViewTests,
  },
  {
    path: "/tech_edit_remarks/:id",
    name: "RemarksEdit",
    component: RemarksEdit,
  },
  {
    path: "/search",
    name: "SearchHC",
    component: SearchHC,
  },
  {
    path: "/transcation_success",
    name: "transaction_success",
    component: transaction_success,
  },
  {
    path: "/transaction_failure",
    name: "transaction_failure",
    component: transaction_failure,
  },
];

const router = new VueRouter({ routes });

router.beforeEach((to, from, next) => {
  if (!to.meta.isPublic) {
    if (!Util.isLoggedIn()) {
      next({
        path: "/login",
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
