<template>
    <v-container fill-height>
      <v-row align="center" justify="center">
          <div class="loader"></div>
      </v-row>
      <v-row align="center" justify="center">
          Loading....Please Wait...
      </v-row>
    </v-container>
</template>
<script>
import DBHandler from "@/js/database/DBHandler";
import Util from '@/js/Util';
import { mapState } from "vuex";
import NotificationDB from "@/js/database/notification_db";
export default {
  name: 'Loading',
  data: () => ({
  }),
  computed: mapState(["to_day"]),
  watch: {
    to_day(newValue, oldValue) {
      console.log("Updating from "+oldValue+" to "+newValue);
      this.redirect_to();
    },
  },
  mounted: function(){
    console.log('loading....')
    let result = Util.getFromSession("logged_in_mobile");
    if(result){
        if(DBHandler.getDbCount() > 0){
          this.redirect_to();
        }
        else {
          DBHandler.set_up_db_with_time().then(function(result){
            if(result == 'OK'){
              console.log('DB created....')
            }
          }).catch(function(err){
            console.log(err);
          })
        } 
    } else {
      this.$router.push('/login').catch(err => {console.log('its ok:'+err)});
    }
  },
  methods: {
   redirect_to(){
     NotificationDB.handle_changes();
     this.$router.push('/notifications').catch(err => {console.log('its ok:'+err)});
   },
  }
};
</script>
<style scoped>
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>