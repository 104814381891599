<template>
  <div>
    <div>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="11" sm="5">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Choose a Month"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" type="month" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)"> OK </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </div>
    <v-container>
      <v-card>
        <v-toolbar color="blue lighter-1" dark dense>
          <v-toolbar-title>Monthly Cases Report</v-toolbar-title>
        </v-toolbar>
        <v-data-table
          :headers="tableHeaders"
          :items="tableData"
          item-key="_id"
          :loading="loading"
          loading-text="Loading... Please wait"
          class="elevation-1"
          hide-default-footer
          dense
        >
          <!-- eslint-disable-next-line -->
          <template v-slot:item.total_amount="{ item }"> RS. {{ item.total_amount }} </template>
        </v-data-table>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-toolbar color="blue lighter-1" dark dense>
          <v-toolbar-title>Monthly Cases Report</v-toolbar-title>
        </v-toolbar>
        <BarChart :item="monthly_report_chartData"></BarChart>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-toolbar color="blue lighter-1" dark dense>
          <v-toolbar-title>Monthly Collection</v-toolbar-title>
        </v-toolbar>
        <BarChart :item="monthly_collection_chartData"></BarChart>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import BarChart from "@/components/work_order/dashboard/bar_chat.vue";
export default {
  components: {
    BarChart,
  },
  name: "Dashboard",
  data: () => ({
    date: new Date().format().substr(0, 7),
    menu: false,
    loading: false,
    tableHeaders: [
      { text: "Weeks", value: "weeks" },
      { text: "Assigned", value: "total_assigned" },
      { text: "Cancelled", value: "total_cancelled" },
      { text: "Finished", value: "total_finished" },
      { text: "Pending", value: "total_pending" },
      { text: "Amount Collected", value: "total_amount" },
    ],
    tableData: [
      {
        Weeks: "Week-1",
        total_assigned: "40",
        total_cancelled: "3",
        total_finished: "35",
        total_pending: "2",
        total_amount: "10000",
      },
      {
        Weeks: "Week-2",
        total_assigned: "40",
        total_cancelled: "3",
        total_finished: "35",
        total_pending: "2",
        total_amount: "10000",
      },
      {
        Weeks: "Week-3",
        total_assigned: "40",
        total_cancelled: "3",
        total_finished: "35",
        total_pending: "2",
        total_amount: "10000",
      },
      {
        Weeks: "Week-4",
        total_assigned: "40",
        total_cancelled: "3",
        total_finished: "35",
        total_pending: "2",
        total_amount: "10000",
      },
    ],

    monthly_collection_chartData: {
      labels: ["Week-1", "Week-2", "Week-3", "Week-4"],
      datasets: [
        {
          label: "Total Amount",
          type: "bar",
          stack: "Total Amount",
          backgroundColor: "#9999ff",
          data: [30000, 31000, 32000, 33000],
        },
        {
          label: "Manager Handover",
          type: "bar",
          stack: "Manager Handover",
          backgroundColor: "#b3ffb3",
          data: [20000, 21000, 22000, 23000],
        },
      ],
    },

    monthly_report_chartData: {
      labels: ["Week-1", "Week-2", "Week-3", "Week-4"],
      datasets: [
        {
          label: "Total",
          type: "bar",
          stack: "Total",
          backgroundColor: "#9999ff",
          data: [30, 31, 32, 33],
        },
        {
          label: "Fineshed",
          type: "bar",
          stack: "Fineshed",
          backgroundColor: "#b3ffb3",
          data: [20, 21, 22, 23],
        },
        {
          label: "cancelled",
          type: "bar",
          stack: "cancelled",
          backgroundColor: " #cccccc",
          data: [5, 2, 6, 9],
        },
        {
          label: "Pending",
          type: "bar",
          stack: "Pending",
          backgroundColor: "#ffa500",
          data: [5, 2, 6, 9],
        },
      ],
    },
  }),
  mounted: function () {},
  methods: {},
};
</script>
