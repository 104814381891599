<template>
  <div>
    <v-card>
      <v-toolbar color="orange" dark>
        <v-toolbar-title><h5>Edit Name & Age</h5></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-card-text>
        <v-form ref="name_edit_form" lazy-validation>
          <v-row dense>
            <v-col cols="12" sm="6" md="2">
              <v-select
                :items="['Mr', 'Ms', 'Mrs', 'Child Of', 'Dr']"
                label="Salutation"
                v-model="salutation"
                :rules="salutationRules"
                required
                @change="salutationChange()"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field label="Name" hint="Name" v-model="edited_name" :rules="nameRules" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-text-field label="Age" hint="Age" v-model="edited_age" :rules="ageRules" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-select :items="['Male', 'Female', 'Other']" label="Gender" v-model="edited_gender" :rules="genderRules" required></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-text-field label="Mobile" hint="MObile" v-model="edited_mobile" :rules="mobileRules" required></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text @click="save_edit_name_dialog">OK</v-btn>
        <v-btn text @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
    <!-- 
      Message Dialog   
    -->
    <div v-if="snackbar">
      <v-snackbar v-model="snackbar" :timeout="2000">
        {{ snackbar_text }}
      </v-snackbar>
    </div>
  </div>
</template>
<script>
import WorkOrderDB from "@/js/database/workorder_db";
import Util from "@/js/Util";
export default {
  name: "TechEditNameAge",
  data() {
    return {
      doc_id: "",
      edit_item: {},
      salutation: "",
      salutationRules: [(v) => !!v || "Salutation is required"],
      edited_name: "",
      nameRules: [(v) => !!v || "Name is required", (v) => (v && v.length <= 30) || "Name must be less than 30 characters"],
      edited_age: "NA",
      ageRules: [(v) => !!v || "Age is required", (v) => (v && v.length <= 10) || "Age must be less than 10 characters"],
      edited_gender: "",
      genderRules: [(v) => !!v || "Gender is required"],
      mobileRules: [(v) => !!v || "Mobile is required", (v) => (v && v.length == 10) || "Mobile must have 10 digits."],
      edited_mobile: "",
      snackbar: false,
      snackbar_text: "",
    };
  },
  mounted: function () {
    if (Util.isLoggedIn()) {
      this.doc_id = this.$route.params.id;
      this.get_doc();
    } else {
      location.reload;
    }
  },
  methods: {
    close: function () {
      this.$router.push("/my_work_orders");
    },
    get_doc: async function () {
      let doc = await WorkOrderDB.getWithId(this.doc_id);
      this.edit_item = doc;
      let name = doc.name;
      let sal = "";
      if (name.indexOf(".") > -1) {
        sal = name.substring(0, name.indexOf("."));
        name = name.substring(name.indexOf(".") + 1).trim();
      }
      this.salutation = sal;
      this.edited_name = name;
      this.edited_age = doc.age;
      this.edited_gender = doc.gender;
      this.edited_mobile = doc.mobile;
    },
    salutationChange: function () {
      if (this.salutation == "Ms" || this.salutation == "Mrs") {
        this.edited_gender = "Female";
      } else if (this.salutation == "Mr") {
        this.edited_gender = "Male";
      } else {
        this.edited_gender = "";
      }
    },
    save_edit_name_dialog: async function () {
      let result = this.$refs.name_edit_form.validate();
      if (result) {
        let id = this.edit_item._id;
        let doc = await WorkOrderDB.getWithId(id);
        doc.name = this.salutation + ". " + Util.toTitleCase(this.edited_name);
        doc.age = this.edited_age;
        doc.gender = this.edited_gender;
        doc.mobile = this.edited_mobile;
        let result = await WorkOrderDB.doUpdate(doc);
        if (result == "OK") {
          this.snackbar_text = "Updated Successfully.";
          this.snackbar = true;
          Util.callThisAfter(this.close, 1000);
        } else {
          this.snackbar_text = result;
          this.snackbar = true;
        }
      }
    },
  },
};
</script>
