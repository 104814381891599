<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar color="white">
            <v-toolbar-title
              ><v-chip color="orange" text-color="white" label><h3>Technician Engagements</h3></v-chip></v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar>
          <v-row justify="center" dense>
            <v-col cols="12" sm="6" md="2" v-if="month_wise">
              <v-menu
                ref="month_menu"
                v-model="month_menu"
                :close-on-content-click="false"
                :return-value.sync="month"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="month" label="Choose a Month" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker
                  v-model="month"
                  type="month"
                  no-title
                  scrollable
                  @change="
                    $refs.month_menu.save(month);
                    month_change(month);
                    month_menu = false;
                  "
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="month_menu = false"> Cancel </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs.menu.save(month);
                      month_change(month);
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="2" v-else>
              <v-menu v-model="date_menu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="choosen_date" label="Choose Date" readonly v-bind="attrs" v-on="on" @change="date_change"></v-text-field>
                </template>
                <v-date-picker v-model="choosen_date" min="2021-08-01" :max="max_date" @input="menu2 = false" @change="date_change"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-switch v-model="month_wise" label="Month Wise" dense></v-switch>
            </v-col>
          </v-row>
          <v-container>
            <v-toolbar color="blue lighter-1" dark dense>
              <v-toolbar-title> Aggregates</v-toolbar-title>
            </v-toolbar>
            <v-data-table
              :headers="aggregate_tableHeaders"
              :items="aggregate_tableData"
              item-key="_id"
              :loading="loading"
              hide-default-footer
              loading-text="Loading... Please wait"
              class="elevation-1"
              dense
            >
            </v-data-table>
            <v-banner elevation="8" outlined single-line
              ><h5>
                Total Cases Excluding Glucose(PP)
                <v-chip color="blue" label outlined>{{ getAccounted() }}</v-chip>
              </h5>
            </v-banner>
          </v-container>
          <v-container>
            <v-data-table
              :headers="tableHeaders"
              :items="tableData"
              :items-per-page="-1"
              :single-expand="singleExpand"
              :expanded.sync="expanded"
              item-key="_id"
              show-expand
              :loading="loading"
              loading-text="Loading... Please wait"
              class="elevation-1"
              dense
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    @keyup.native="debounce_search()"
                  ></v-text-field>
                </v-toolbar>
              </template>

              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Patient Name</th>
                          <th class="text-left">Gender</th>
                          <th class="text-left">Age</th>
                          <th class="text-left">Mobile</th>
                          <th class="text-left">Time</th>
                          <th class="text-left">Status</th>
                          <th class="text-left">HC Charges</th>
                          <th class="text-left">Amount</th>
                          <th class="text-left">Accept Remittance</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(thing, i) in item.assigned_list" :key="i">
                          <td>{{ thing.name }}</td>
                          <td>{{ thing.gender }}</td>
                          <td>{{ thing.age }}</td>
                          <td>{{ thing.mobile }}</td>
                          <td>{{ get_date_or_time(thing) }}</td>
                          <td>
                            <v-chip :color="getColor(thing.status)" outlined x-small>
                              {{ thing.status }}
                            </v-chip>
                          </td>
                          <td>{{ thing.hc_charges }}</td>
                          <td>{{ getTotal(thing) }}</td>
                          <td v-if="thing.payment_method == 'gpay'">
                            <v-chip color="blue" outlined x-small> GPay {{ thing.gpay_ref }} </v-chip>
                          </td>
                          <td v-else-if="checkCashPayment(thing)">
                            <v-switch
                              :label="getLabelTitle(thing)"
                              v-model="thing.accept_remittance"
                              color="green"
                              v-if="thing.remittance"
                              @change="remit_change(thing)"
                              dense
                            ></v-switch>
                            <div v-else>Not Remitted</div>
                          </td>
                          <td v-else>
                            <div>Credit Client</div>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </td>
              </template>
            </v-data-table>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
    <v-overlay :value="overlay" :z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!-- 
      Message Dialog   
  -->
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>
<script>
import WorkOrderDB from "@/js/database/workorder_db";
import PostgresDB from "@/js/database/postgres_db";
import Util from "@/js/Util";
import moment from "moment";
export default {
  props: ["show"],
  data() {
    return {
      month_wise: false,
      month_menu: false,
      month: "",
      loading: false,
      search: "",
      expanded: [],
      singleExpand: true,
      dialog: this.show,
      choosen_date: "",
      overlay: false,
      technicianDetails: [],
      tableHeaders: [
        { text: "Technician", align: "start", sortable: true, value: "name" },
        { text: "Assigned", value: "assigned" },
        { text: "Cancelled", value: "cancelled" },
        { text: "Finished", value: "finished" },
        { text: "Pending", value: "pending" },
        { text: "Appointment Till", value: "time_till" },
        { text: "Cash", value: "cash" },
        { text: "GPay", value: "gpay" },
        { text: "HC Charges", value: "hc_charges" },
        { text: "Total", value: "amount" },
        { text: "Received", value: "amount_collected" },
        { text: "", value: "data-table-expand" },
      ],
      aggregate_tableHeaders: [
        { text: "Assigned", value: "total_assigned" },
        { text: "Cancelled", value: "total_cancelled" },
        { text: "Finished", value: "total_finished" },
        { text: "Pending", value: "total_pending" },
        { text: "Cash", value: "cash" },
        { text: "GPay", value: "gpay" },
        { text: "HC Charges", value: "hc_charges" },
        { text: "Total Collected", value: "total_amount" },
        { text: "Total Received", value: "amount_collected" },
      ],
      tableData: [],
      aggregate_tableData: [],
      techData: [],
      amount_deposited: [],
      snackbar: "",
      snackbar_text: "",
      max_date: "",
      date_menu: false,
    };
  },
  mounted: function () {
    this.max_date = moment().format("YYYY-MM-DD");
    this.choosen_date = this.max_date;
    this.fetch();
  },
  methods: {
    fetch: async function () {
      this.overlay = true;
      this.technicianDetails = await PostgresDB.getTechnicians();
      this.date_change();
      this.overlay = false;
    },
    month_change: async function (month) {
      console.log(month);
      try {
        this.overlay = true;
        let start_date = month + "-01";
        let end_date = month + "-31";
        this.techData = await WorkOrderDB.tech_engagements(start_date, end_date, this.technicianDetails, false);
        this.tableData = this.techData;
        this.aggregate_tableData = await WorkOrderDB.aggregate_view(start_date, end_date, false);
      } catch (e) {
        this.snackbar_text = "Error while getting data:" + JSON.stringify(e);
        this.snackbar = true;
      } finally {
        this.overlay = false;
      }
    },
    getAccounted: function () {
      try {
        let data = this.aggregate_tableData;
        let doc = data[0];
        return doc.total_accounted;
      } catch (e) {
        console.log("Error in getAccounted");
      }
      return "Error";
    },
    date_change: async function () {
      try {
        this.overlay = true;
        let local = true;
        let aggr_data = await WorkOrderDB.aggregate_view(this.choosen_date, "", local);
        if (aggr_data.length == 1) {
          let data = aggr_data[0];
          let assigned = Util.parseInt(data.total_assigned);
          console.log("total assigned=" + assigned);
          if (assigned == 0) {
            local = false;
          }
        }
        console.log("Fetching tech engagement from local:" + local);
        this.techData = await WorkOrderDB.tech_engagements(this.choosen_date, "", this.technicianDetails, local);
        this.tableData = this.techData;
        if (local) {
          this.aggregate_tableData = aggr_data;
        } else {
          this.aggregate_tableData = await WorkOrderDB.aggregate_view(this.choosen_date, "", local);
        }
      } catch (e) {
        this.snackbar_text = "Error while getting data:" + JSON.stringify(e);
        this.snackbar = true;
      } finally {
        this.overlay = false;
      }
    },
    getColor: function (status) {
      if (status) {
        let lStatus = status.toLowerCase().trim();
        if (lStatus.startsWith("un")) return "red";
        else if (lStatus == "assigned") return "blue";
        else if (lStatus == "cancelled") return "grey";
        else if (lStatus == "finished") return "green";
        else return "orange";
      } else {
        return "red";
      }
    },
    getTotal: function (thing) {
      if (Util.isNotEmpty(thing.amount_received)) {
        return thing.amount_received;
      } else {
        return "NA";
      }
    },
    checkCashPayment: function (item) {
      let status = item.status;
      if (status == "Finished") {
        if (item.payment_method) {
          if (item.payment_method == "cash") {
            if (item.amount_received > 0) {
              return true;
            }
          }
        }
      }
      return false;
    },
    debounce_search: Util.debounce(function () {
      this.search_details();
    }, 500),
    search_details: function () {
      this.tableData = Util.search(this.techData, this.search.toLowerCase());
    },
    close: function (e) {
      this.dialog = false;
      this.$emit("close_tech_engagements", e);
    },
    remit_change: async function (item) {
      //console.log('item here...'+JSON.stringify(item));
      let id = item._id;
      let doc = await WorkOrderDB.getWithId(id);
      doc.accept_remittance = item.accept_remittance;
      try {
        let time_stamp = Util.gettime();
        let user = Util.getFromSession("logged_in_emp_name");
        let new_tl = "";
        if (item.accept_remittance) {
          new_tl = time_stamp + " - " + user + " - Remittance Accepted.";
        } else {
          new_tl = time_stamp + " - " + user + " - Remittance Cancelled.";
        }
        let old_tl = doc.time_line;
        old_tl.push(new_tl);
        doc.time_line = old_tl;
      } catch (e) {
        console.log("error updating timeline:" + e);
      }
      let result = await WorkOrderDB.doUpdate(doc);
      if (result == "OK") {
        let collected = parseInt(item.amount_collected + "");
        let total = parseInt(item.total + "");
        let accepted = collected + total;
        let rejected = 0;
        if (collected >= total) {
          rejected = collected - total;
        }
        if (item.accept_remittance) {
          item.amount_collected = accepted;
          this.snackbar_text = "Remittance Accepted Successfully.";
        } else {
          item.amount_collected = rejected;
          this.snackbar_text = "Remittance Cancelled.";
        }
        this.snackbar = true;
      } else {
        this.snackbar_text = result;
        this.snackbar = true;
      }
      this.date_change();
    },
    get_date_or_time: function (thing) {
      if (this.month_wise) {
        return thing.appointment_date;
      } else {
        return thing.appointment_time;
      }
    },
    getLabelTitle: function (thing) {
      if (thing.accept_remittance) {
        return "Received";
      } else {
        return "Accept";
      }
    },
  },
};
</script>
