<template>
<div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
         <v-toolbar color="white">
          <v-toolbar-title><v-chip color="orange" text-color="white" label><h3>Price List</h3></v-chip></v-toolbar-title>
          <v-spacer></v-spacer>
            <v-text-field v-model="search" ref="spl_ref1" append-icon="mdi-magnify" label="Search" single-line hide-details @keyup.native="debounce_search()"></v-text-field>
            <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-sheet>
          <v-data-table
            :headers="headers"
            :items="price_list"
            :items-per-page="-1"
            class="elevation-1"
            dense
          ></v-data-table>
        </v-sheet>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay" :z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
</div>
</template>
<script>
import Util from "@/js/Util";
import PriceListDB from "@/js/database/price_list_db";
export default {
    props: ['show'],
    data () {
      return {
        headers: [
          { text: 'Department', value: 'dept_name' },
          { text: 'Investigation', value: 'invest_name' },
          { text: 'Price', value: 'base_cost' },
          { text: 'Min Price', value: 'min_cost' },
          { text: 'CGHS Price', value: 'cghs_price' },
        ],
        dialog: this.show,
        search: '',
        price_list : [],
        overlay: false,
      }
    },
    mounted: function(){
     this.search_price_list();
    },
    methods: {
      format: function(money){
        return Util.formatMoney(money);
      },
      close: function(e){
          this.dialog = false;
          this.$emit('close_price_view',e);
      },
      search_price_list: async function(){
        try{
          console.log('searching...'+this.search);
          this.price_list = [];
          this.overlay = true;
          let list = await PriceListDB.fetch_all_data(this.search);
          console.log('list size:'+list.length);
          this.price_list = list;
          this.overlay = false;
          this.$refs.spl_ref1.$refs.input.focus();
        } catch(e){
          console.log('Error:'+e);
        }
      },
      debounce_search: Util.debounce(function () {
        this.search_price_list();
      }, 500)
      ,
    }
}
</script>