<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar color="white">
            <v-toolbar-title>
              <v-chip color="orange" text-color="white" label><h3>View Image</h3></v-chip>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="close()"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar>
          <v-container v-if="show_error">
            <v-alert type="warning">{{ error_msg }}</v-alert>
          </v-container>
          <v-container>
            <v-img :src="img_src"></v-img>
          </v-container>
        </v-card>
        <v-bottom-navigation>
          <v-spacer></v-spacer>
          <v-btn icon @click="close()"><v-icon>mdi-close</v-icon></v-btn>
        </v-bottom-navigation>
      </v-dialog>
    </v-row>
    <v-overlay :value="overlay" :z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import axios from "axios";
import settings from "@/js/database/settings";
import Util from '@/js/Util';
export default {
  props: ["show_img", "s3_url"],
  data() {
    return {
      overlay: false,
      dialog: this.show_img,
      img_src: '',
      show_error: false,
      error_msg: '',
    };
  },
  mounted: function () {
    console.log(this.s3_url);
    this.getImage();
  },
  methods: {
    getImage: function () {
      let item = this.s3_url;
      let dArray = [];
      if(item.indexOf(' | ')>-1){
          dArray = item.split(" | ");
      } else {
          dArray[0] = 'homecollection';
          dArray[1] = item;
      }
      let jwt_token = Util.getFromSession('pg_admin');
      let data = {
        bucket_name: dArray[0],
        key: dArray[1],
        jwt_token: jwt_token
      }
      let name = dArray[1];
      if(name.indexOf('/') > -1){
        name = name.substring((name.lastIndexOf('/')+1));
      }
      this.overlay = true;
      let instance = this;
      axios({
        method: "post",
        responseType: "blob",
        url: settings.node_url + "/s3/get_file_v2",
        data: data,
      })
      .then(async function (response) {
          console.log(response.data);
          instance.overlay = false;
          if(response.data.size < 1000){
            instance.error_msg = 'Error Occurred. Please try later.';
            instance.show_error = true;
          } else {
            instance.img_src = URL.createObjectURL(response.data);
          }          
      })
      .catch(function (error) {
          instance.overlay = false;
          console.log("error:" + error);
          alert(error);
      });
    },
    close: function () {
      this.dialog = false;
      this.$emit("close_image");
    },
  },
};
</script>
<style>
.dialog_class {
  overflow-y: hidden;
}
</style>
