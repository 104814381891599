import moment from "moment";
import settings from "./settings.js";
import PouchDB from "pouchdb-browser";
import postgres_db from "./postgres_db.js";
import Util from "./../Util.js";

let couch_db = {
  db_map: {},
  get_db: async function (db_name) {
    try {
      if (couch_db.validate_token()) {
        if (couch_db.db_map) {
          if (couch_db.db_map[db_name]) {
            return couch_db.db_map[db_name];
          }
        }
      }
      let new_db = await couch_db.create_db(db_name);
      return new_db;
    } catch (e) {
      console.log("Exception in couch_db.get_db");
    }
    return "Error";
  },
  create_db: async function (db_name) {
    let remoteUrl = settings.remote_couch_url + "/" + db_name;
    let token = await couch_db.get_token();
    const remoteOptions = {
      fetch: function (url, opts) {
        opts.headers.set("Authorization", "Bearer " + token);
        return PouchDB.fetch(url, opts);
      },
    };
    const remoteDb = new PouchDB(remoteUrl, remoteOptions);
    couch_db.db_map[db_name] = remoteDb;
    console.log("created db for:" + db_name);
    return remoteDb;
  },
  get_token: async function () {
    let token = await couch_db.check_and_return_token();
    return token;
  },
  validate_token: function () {
    let exp_time = Util.getFromSession("exp_time");
    let calendar = moment(exp_time, "YYYY-MM-DD HH:mm:ss");
    calendar.subtract(20, "minutes");
    let minutesRemaining = calendar.diff(moment(), "minutes");
    if (minutesRemaining <= 0) {
      return false;
    } else {
      return true;
    }
  },
  check_and_return_token: function () {
    const check_promise = new Promise((resolve, reject) => {
      if (couch_db.validate_token()) {
        let token = Util.getFromSession("pg_admin");
        resolve(token);
      } else {
        couch_db.db_map = {};
        return postgres_db
          .refreshToken()
          .then(function (result) {
            if (result == "OK") {
              let token = Util.getFromSession("pg_admin");
              resolve(token);
            }
          })
          .catch(function (e) {
            reject(e);
          });
      }
    });
    return check_promise;
  },
};

export default couch_db;
