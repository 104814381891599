<template>
  <div>
    <v-data-table
      :headers="tableHeaders"
      :items="tableData"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :items-per-page="500"
      item-key="_id"
      show-expand
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1"
      dense
    >
      <template v-slot:top v-if="check_search_result() == false">
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @keyup.native="debounce_search()"
          ></v-text-field>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.name="{ item }">
        {{ item.name }} &nbsp;
        <v-chip color="red" outlined x-small v-if="getClientStatus(item)">
          {{ getClientStatus(item) }}
        </v-chip>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.serialnumber="{ item, index }">
        {{ index + 1 }}
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }" v-if="check_search_result()">
        <v-icon small @click="copyItem(item)"> mdi-content-copy </v-icon>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }" v-else>
        <v-icon small class="mr-1" @click="copyItem(item)"> mdi-content-copy </v-icon>
        <v-icon small class="mr-1" @click="editItem(item)" v-if="check_finished(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)" v-if="check_finished(item)"> mdi-delete </v-icon>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.status="{ item }">
        <v-chip :color="getColor(item.status)" outlined x-small @click="showAssign(item.status, item.pincode, item)">
          {{ item.status }}
        </v-chip>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.server_status="{ item }">
        <v-chip :color="getColor(item.server_status)" outlined x-small>
          {{ getStatus(item.server_status) }}
        </v-chip>
        <v-btn class="px-4" icon :color="getColor(item.report_status)" v-if="getReportStatus(item)" @click="download_files(item.report_path)" x-small
          ><v-icon>mdi-thumb-up</v-icon></v-btn
        >
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Address</th>
                  <th class="text-left">Pincode</th>
                  <th class="text-left">Additional Info</th>
                  <th class="text-left">Ref. By.</th>
                  <th class="text-left">Email</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ item.address }}</td>
                  <td>{{ item.pincode }}</td>
                  <td>{{ item.free_text }}</td>
                  <td v-if="item.b2b_client_id">B2B: {{ item.b2b_client_name }}</td>
                  <td v-else>Dr. {{ item.doctor_name }}</td>
                  <td>{{ item.email }}</td>
                </tr>
                <tr v-if="item.pres_photo">
                  <td colspan="5">
                    <v-chip color="red" outlined x-small> Prescription: </v-chip>
                    <v-chip color="primary" outlined x-small @click="download_files(item.pres_photo)">
                      {{ getName(item.pres_photo) }}
                    </v-chip>
                  </td>
                </tr>
                <tr v-if="getCancelStatus(item)">
                  <td colspan="5">
                    <v-chip color="red" outlined x-small> Cancellation Reason: {{ getReason(item) }} </v-chip>
                  </td>
                </tr>
                <tr v-if="item.process">
                  <td colspan="5" v-if="item.process.first_step">
                    <v-chip color="orange" outlined x-small> Step-1 </v-chip> Reason For Delay:
                    {{ item.process.first_step }}
                  </td>
                </tr>
                <tr v-if="item.process">
                  <td colspan="5" v-if="item.process.second_step">
                    <v-chip color="orange" outlined x-small> Step-2 </v-chip> Proforma Invoice:
                    <v-chip color="red" outlined x-small v-if="item.b2b_client_id">Not Sent For B2B Client</v-chip>
                    <v-chip color="red" outlined x-small v-else-if="item.credit == 1">Not Sent For Credit Client</v-chip>
                    <v-chip color="red" outlined x-small v-else-if="item.credit == 2">Not Sent For Trial Client</v-chip>
                    <v-chip color="green" outlined x-small v-else>Sent</v-chip>
                    <v-chip color="primary" outlined x-small @click="download_files(item.process.second_step)"> Proforma Invoice </v-chip>
                  </td>
                </tr>
                <tr v-if="item.process">
                  <td colspan="5" v-if="item.process.third_step">
                    <v-chip color="orange" outlined x-small> Step-3 </v-chip>
                    Bill Amount:
                    <v-chip color="primary" outlined x-small>
                      {{ item.process.third_step }}
                    </v-chip>
                  </td>
                </tr>
                <tr v-if="item.process">
                  <td colspan="5" v-if="item.process.fourth_step">
                    <v-chip color="orange" outlined x-small> Step-4 </v-chip> OTP:
                    <v-chip color="primary" outlined x-small>
                      {{ item.process.fourth_step }}
                    </v-chip>
                  </td>
                </tr>
                <tr v-if="item.process">
                  <td colspan="5" v-if="item.process.fifth_step">
                    <v-chip color="orange" outlined x-small> Step-5 </v-chip> Prescription Photo:
                    <v-chip color="primary" outlined x-small @click="download_files(item.process.fifth_step)">
                      {{ getPrescrptionFileNames(item.process.fifth_step) }}
                    </v-chip>
                  </td>
                </tr>
                <tr v-if="item.process">
                  <td colspan="5" v-if="item.process.sixth_step">
                    <v-chip color="orange" outlined x-small> Step-6 </v-chip> Transaction Id:
                   <v-chip color="primary" outlined x-small>
                        {{ item.process.sixth_step }}
                    </v-chip>
                  </td>
                </tr>
                <tr v-if="item.process">
                  <td colspan="5" v-if="item.process.sixth_step">
                    <v-chip color="orange" outlined x-small> Step-7 </v-chip> Payment Status:
                   <v-chip color="primary" outlined x-small>
                        {{ item.process.seventh_step }}
                    </v-chip>
                  </td>
                </tr>
                <tr v-if="item.remarks">
                  <td colspan="5">
                    <v-chip color="orange" outlined x-small> Remarks </v-chip>
                    {{ item.remarks }}
                  </td>
                </tr>
                <tr v-if="item.server_status == 'Billed'">
                  <td colspan="5">
                    <v-chip color="orange" outlined x-small> Bill No: </v-chip>
                    <v-chip color="primary" outlined x-small>
                      {{ item.bill_number }}
                    </v-chip>
                  </td>
                </tr>
                <tr v-if="item.server_status == 'Billed'">
                  <td colspan="5">
                    <v-chip color="orange" outlined x-small> Lab No: </v-chip>
                    <v-chip color="primary" outlined x-small>
                      {{ item.lab_number }}
                    </v-chip>
                  </td>
                </tr>
                <tr v-if="getReportStatus(item)">
                  <td colspan="5">
                    <v-chip color="orange" outlined x-small>Lab Result: </v-chip>
                    <v-chip :color="getColor(item.report_status)" outlined x-small>{{ item.status_in_number }} {{ item.report_status }}</v-chip>
                    <v-chip color="primary" outlined x-small @click="download_files(item.report_path)"> Report PDF </v-chip>
                  </td>
                </tr>
                <tr>
                  <td colspan="5">
                    <v-chip color="primary" outlined x-small @click="view_time_line(item)"> Time Line </v-chip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
    </v-data-table>

    <!-- 
      Choose Technician 
  -->

    <div>
      <v-bottom-sheet v-model="sheet" scrollable inset>
        <v-sheet height="300px">
          <v-toolbar color="white">
            <v-chip color="orange" text-color="white" label><h3>Choose Technician</h3></v-chip>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="technician_search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @keyup.native="debounce_technician_search"
            ></v-text-field>
            <v-btn icon @click="closeSheet()"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar>
          <v-simple-table dense fixed-header height="300px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Name</th>
                  <th class="text-left">Mobile</th>
                  <th class="text-left">Allocated Areas</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, j) in technician_details" :key="j">
                  <td>
                    <a @click="selected_tech(item._id, item.name)">{{ item.name }}</a>
                  </td>
                  <td>
                    <a @click="selected_tech(item._id, item.name)">{{ item.mobile }}</a>
                  </td>
                  <td>
                    <v-chip class="mx-1" x-small color="orange" label outlined v-for="(thing, i) in item.allocated_areas" :key="i">
                      {{ thing.pincode }} {{ thing.area }}
                    </v-chip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-sheet>
      </v-bottom-sheet>
    </div>

    <!-- 
      copy work order  
    -->

    <v-container v-if="type === 'copy_work_order_dialog'">
      <copy_work_order :show="show_copy" :item="copied_item" @close_copy_work_order="close_copy_screen"></copy_work_order>
    </v-container>

    <!-- 
      Edit work order  
    -->

    <v-container v-if="type === 'edit_work_order_dialog'">
      <edit_work_order :show="show_edit" :item="edited_item" @close_edit_work_order="close_edit_screen"></edit_work_order>
    </v-container>
    <!-- 
      time line work order  
    -->

    <v-container v-if="type === 'time_line_work_order_dialog'">
      <time_line :show="show_time_line" :item="time_line_item" @close_time_line="close_time_line"></time_line>
    </v-container>
    <!-- 
      Delete Dialog   
    -->

    <div>
      <v-dialog v-model="deleteDialog" max-width="400">
        <v-card>
          <v-card-title class="text-h6 orange lighter-4 white--text"> Do you want to delete this record? </v-card-title>
          <v-card-text>
            {{ delete_summary }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="orange darken-1" text @click="deleteDialog = false"> Cancel </v-btn>
            <v-btn color="orange darken-1" text @click="doDelete()"> Yes </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <!-- 
      snackbar msg   
   -->
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackbar_text }}
    </v-snackbar>
    <v-overlay :value="overlay" :z-index="2000">
      <v-progress-circular absolute size="64"></v-progress-circular>
    </v-overlay>
    <!--  
   --                         Dialog for msgs
   -->
    <div>
      <v-dialog persistent v-model="msg_dialog" transition="dialog-bottom-transition" max-width="600">
        <v-card>
          <v-toolbar color="orange" dark>{{ msg_head }}</v-toolbar>
          <v-card-text>
            <v-subheader class="pa-2">{{ msg_str }}</v-subheader>
            <v-switch v-model="sms" label="SMS" class="pa-0"></v-switch>
            <v-switch v-model="whatsapp" label="WhatsApp" class="pa-0"></v-switch>
            <v-switch v-model="email" label="Email" class="pa-0"></v-switch>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="ok_msg_dialog">OK</v-btn>
            <v-btn text @click="close_msg_dialog">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!--  
   --                         Dialog for view / download
   -->
    <div>
      <v-dialog persistent v-model="file_dialog" transition="dialog-bottom-transition" max-width="600">
        <v-card>
          <v-toolbar color="orange" dark>View / Download Files</v-toolbar>
          <v-card-text>
            <v-sheet v-for="url in pres_array" :key="url" color="orange lighten-5" elevation="7" outlined rounded shaped class="ma-2 pa-2">
              <v-btn text color="primary" x-small> {{ getName(url) }}</v-btn>
              <v-btn text color="primary" @click="download_id(url, 'view')" x-small>View </v-btn>
              <v-btn text color="primary" @click="download_id(url, 'download')" x-small>Download</v-btn>
            </v-sheet>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="close_download_dialog">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!--  
   --                         Dialog for view pdf
   -->
    <v-container v-if="show_report">
      <show_pdf v-bind:show_report="show_report" v-bind:s3_url="s3_url" @close_pdf="pdf_close()"></show_pdf>
    </v-container>
    <v-container v-if="show_img">
      <show_img v-bind:show_img="show_img" v-bind:s3_url="s3_url" @close_image="img_close()"></show_img>
    </v-container>

    <v-overlay :value="overlay_dk" :z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import fileDownload from "js-file-download";
import axios from "axios";
import WorkOrderDB from "@/js/database/workorder_db";
import PostgresDB from "@/js/database/postgres_db";
import Util from "@/js/Util";
import edit_work_order from "@/components/work_order/edit_work_order.vue";
import copy_work_order from "@/components/work_order/copy_work_order.vue";
import time_line from "@/components/work_order/time_line.vue";
import { mapState } from "vuex";
import sms_template from "@/js/sms_templates";
import settings from "@/js/database/settings";
import com_center from "@/js/com_center";
import DBHandler from "@/js/database/DBHandler";
import show_pdf from "@/components/show_pdf.vue";
import show_img from "@/components/show_img.vue";
export default {
  name: "desktop_view",
  props: ["this_date", "search_result", "mode"],
  components: { edit_work_order, show_pdf, show_img, time_line, copy_work_order },
  data() {
    return {
      sms: 1,
      whatsapp: 1,
      email: 0,
      loading: false,
      order_date: this.this_date,
      search: "",
      expanded: [],
      singleExpand: true,
      work_orders: [],
      tableHeaders: [
        { text: "No", value: "serialnumber", sortable: false },
        { text: "Name", align: "start", sortable: true, value: "_id" },
        { text: "Gender", value: "gender", sortable: false },
        { text: "Age", value: "age", sortable: false },
        { text: "Mobile", value: "mobile" },
        { text: "Date", value: "appointment_date" },
        { text: "Time", value: "appointment_time" },
        { text: "Tech. Status", value: "status" },
        { text: "Server Status", value: "server_status" },
        { text: "Assigned To", value: "assigned_to" },
        { text: "Actions", value: "actions", sortable: false },
        { text: "", value: "data-table-expand" },
      ],
      tableData: [],
      type: "",
      show_copy: false,
      copied_item: {},
      show_edit: false,
      time_line_item: [],
      show_time_line: false,
      sheet: false,
      technician_details: [],
      edited_item: null,
      deleteDialog: false,
      delete_item: null,
      delete_summary: "",
      snackbar_text: "",
      snackbar: false,
      technician_search: "",
      overlay: false,
      assign_item: null,
      msg_dialog: false,
      msg_head: "",
      msg_str: "",
      action: "",
      file_dialog: false,
      pres_array: [],
      show_report: false,
      s3_url: "",
      show_img: false,
      overlay_dk: false,
    };
  },
  computed: mapState(["work_order"]),
  watch: {
    work_order(newValue, oldValue) {
      if (this.check_search_result() == false) {
        console.log("Updating from " + oldValue + " to " + newValue);
        this.updateScreen();
      }
    },
  },
  mounted: async function () {
    if (this.check_search_result()) {
      this.updateSearchScreen();
    } else {
      await this.updateScreen();
      await this.loadRemoteData();
    }
  },
  methods: {
    getName: function (item) {
      return Util.getName(item);
    },
    updateSearchScreen: function () {
      try {
        this.work_orders = this.search_result;
        this.tableData = this.work_orders;
      } catch (e) {
        console.log(e);
      }
    },
   

    updateScreen : async function () {
    this.loading = true;
    console.log("loading desktop for date(s):", this.order_date);
    
    if (Array.isArray(this.order_date)) {
      let allWorkOrders = [];
      for (let date of this.order_date) {
        let workOrders = await WorkOrderDB.list(date);
        allWorkOrders = allWorkOrders.concat(workOrders);
      }
      this.work_orders = allWorkOrders;
    } else {
      this.work_orders = await WorkOrderDB.list(this.order_date);
    }

    if (this.work_orders.length > 0) {
      this.tableData = this.work_orders;
    }

    this.loading = false;
  },
loadRemoteData :async function () {
    if (this.work_orders.length === 0) {
      this.loading = true;

      if (Array.isArray(this.order_date)) {
        let allWorkOrders = [];
        for (let date of this.order_date) {
          let workOrders = await WorkOrderDB.list_remote_data(date);
          allWorkOrders = allWorkOrders.concat(workOrders);
        }
        this.work_orders = allWorkOrders;
      } else {
        this.work_orders = await WorkOrderDB.list_remote_data(this.order_date);
      }

      this.tableData = this.work_orders;
      this.loading = false;
    }
  },
    debounce_search: Util.debounce(function () {
      this.search_details();
    }, 500),
    search_details: function () {
      this.tableData = Util.search(this.work_orders, this.search.toLowerCase());
    },
    debounce_technician_search: Util.debounce(function () {
      this.tech_search();
    }, 500),
    tech_search: async function () {
      this.overlay = true;
      this.technician_details = await PostgresDB.getTechnicians(this.technician_search.toLowerCase());
      this.overlay = false;
    },
    getColor: function (status) {
      if (status) {
        let lStatus = status.toLowerCase().trim();
        if (lStatus.startsWith("un")) return "red";
        else if (lStatus == "assigned") return "blue";
        else if (lStatus == "cancelled") return "grey";
        else if (lStatus == "finished") return "green";
        else if (lStatus == "billed") return "green";
        else if (lStatus == "unbilled") return "red";
        else if (lStatus == "waiting") return "blue";
        else if (lStatus == "part") return "orange";
        else if (lStatus == "complete") return "green";
        else if (lStatus == "error") return "red";
        else return "orange";
      } else {
        return "red";
      }
    },
    close_msg_dialog: function () {
      this.msg_str = "";
      this.msg_head = "";
      this.msg_dialog = false;
      this.send_notification();
    },
    send_notification: async function () {
      let doc = this.assign_item;
      let msg_header = "Collection on " + doc.appointment_date + " " + doc.appointment_time + " assigned.";
      let msg_body = "Home collection for " + doc.name + "(" + doc.age + "/" + doc.gender + ") ";
      msg_body += "address:" + doc.address + " mobile:" + doc.mobile + " pincode:" + doc.pincode;
      msg_body += " " + doc.free_text;
      let notifications = {
        _id: "notifications:" + Util.getDateForId() + ":" + Util.uuidv4(),
        from_id: "0",
        from_name: Util.getFromSession("logged_in_emp_name"),
        to_id: doc.assigned_id,
        to_name: doc.assigned_to,
        msg_header: msg_header,
        msg_body: msg_body,
        msg_attachment: {},
        status: "New",
        msg_time: Util.getTodayWithTime(),
        updated_at: Util.getTimeStamp(),
      };
      let notification_db = await DBHandler.getDb("notifications");
      notification_db
        .put(notifications)
        .then(function (response) {
          if (response.ok) {
            console.log(response);
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    ok_msg_dialog: async function () {
      if (this.action == "Cancelled") {
        this.action = "";
        this.send_cancellation_sms();
        return;
      }
      this.send_notification();
      let tech_mobile = "";
      let s3_loc = "";
      let doc = this.assign_item;
      for (let i = 0; i < this.technician_details.length; i++) {
        let technician = this.technician_details[i];
        if (technician.emp_id == doc.assigned_id) {
          tech_mobile = technician.mobile;
          s3_loc = technician.id_card_location;
        }
      }
      this.msg_str = "";
      this.msg_head = "";
      this.msg_dialog = false;
      let id_part = Util.getRandomString(5);
      let msg_url = settings.msg_url + id_part;
      let message = {
        center_id: Util.getFromSession("logged_in_tenant_id"),
        center_name: Util.getFromSession("logged_in_tenant_name"),
        department_id: Util.getFromSession("department_id"),
        department_name: Util.getFromSession("department_name"),
        role_id: Util.getFromSession("role_id"),
        role_name: Util.getFromSession("role_name"),
        emp_id: Util.getFromSession("logged_in_emp_id"),
        emp_name: Util.getFromSession("logged_in_emp_name"),
        recipient_mobile: doc.mobile,
        recipient_name: doc.name,
        status: "0",
        s3_loc: s3_loc,
        msg_time: Util.getTodayWithTime(),
        updated_at: Util.getTimeStamp(),
      };

      if (this.sms) {
        let sms_msg = "";
        if (doc.prev_assigned_to) {
          sms_msg = sms_template.home_collection_tech_change(doc.assigned_to, tech_mobile, msg_url);
        } else {
          let app_time = doc.appointment_date + " " + doc.appointment_time;
          sms_msg = sms_template.sample_collection(doc.name, doc.assigned_to, app_time, tech_mobile, msg_url);
        }
        message._id = "sms_center:" + id_part + ":" + Util.uuidv4();
        message.message = sms_msg;
        let result = await com_center.send_msg(message);
        if (result == "OK") {
          this.snackbar_text = "SMS Sent Successfully.";
          this.snackbar = true;
        } else {
          this.snackbar_text = result;
          this.snackbar = true;
        }
      }
      if (this.whatsapp) {
        let whatsapp_msg = "";
        let whatsapp_template = "";
        if (doc.prev_assigned_to) {
          whatsapp_msg = [doc.assigned_to, tech_mobile, msg_url];
          whatsapp_template = "Technician_change_for_hc";
        } else {
          let app_time = doc.appointment_date + " " + doc.appointment_time;
          whatsapp_msg = [doc.name, doc.assigned_to, app_time, tech_mobile, msg_url];
          whatsapp_template = "hc_technician_allocation3";
        }
        message._id = "whatsapp_center:" + id_part + ":" + Util.uuidv4();
        message.message = whatsapp_msg;
        message.template = whatsapp_template;
        let result = await com_center.send_msg(message);
        if (result == "OK") {
          this.snackbar_text = "WhatsApp Msg. Sent Successfully.";
          this.snackbar = true;
        } else {
          this.snackbar_text = result;
          this.snackbar = true;
        }
      }
      if (this.email) {
        message._id = "email_center:" + id_part + ":" + Util.uuidv4();
        let result = await com_center.send_msg(message);
        if (result == "OK") {
          this.snackbar_text = "Email Msg. Sent Successfully.";
          this.snackbar = true;
        } else {
          this.snackbar_text = result;
          this.snackbar = true;
        }
      }
    },
    selected_tech: async function (tech_id, tech_name) {
      let result = this.validateAssign(tech_id, tech_name);
      if (result == false) {
        return;
      }
      let doc = this.assign_item;
      let time_stamp = Util.gettime();
      let workorder_created_by = Util.getFromSession("logged_in_emp_name");
      let workorder_assigned = time_stamp + "-" + workorder_created_by + "- Work Order Assigned To " + tech_name;
      if (doc.assigned_to) {
        doc.prev_assigned_to = doc.assigned_to;
        doc.prev_assigned_id = doc.assigned_id;
      }
      doc.status = "assigned";
      doc.assigned_to = tech_name;
      doc.assigned_id = tech_id;
      if (doc.time_line) {
        doc.time_line.push(workorder_assigned);
      }
      let update = await WorkOrderDB.doUpdate(doc);
      if (update == "OK") {
        this.snackbar_text = "Assigned Successfully.";
        this.snackbar = true;
        if (doc.email) {
          this.email = 1;
        }
        this.msg_head = "Assigned Successfully.";
        this.msg_str = "Do you wish to inform " + doc.name + " (Mob:" + doc.mobile + ") about the technician?";
        this.msg_dialog = true;
      } else {
        console.log(update);
        this.snackbar_text = "Error Occurred:" + update;
        this.snackbar = true;
      }
      this.sheet = false;
    },
    validateAssign: function (tech_id, tech_name) {
      if (this.assign_item) {
        let doc = this.assign_item;
        for (let i = 0; i < this.work_orders.length; i++) {
          let workorder = this.work_orders[i];
          if (workorder.status == "assigned") {
            if (workorder.assigned_id == tech_id) {
              if (workorder.appointment_date == doc.appointment_date) {
                let ap_time = doc.appointment_date + " " + doc.appointment_time;
                let wo_time = workorder.appointment_date + " " + workorder.appointment_time;
                console.log(ap_time + " >>><<< " + wo_time);
                let ap_moment = Util.parseAppTime(ap_time);
                let wo_moment = Util.parseAppTime(wo_time);
                let mins = wo_moment.diff(ap_moment, "minutes");
                //if((mins >= -29) && (mins <= 29)){
                if (mins >= -1 && mins <= 1) {
                  if (mins < 0) {
                    mins *= -1;
                  } else if (mins == 0) {
                    this.snackbar_text = tech_name + " has already got appointment at the same time.";
                    this.snackbar = true;
                    return false;
                  }
                  this.snackbar_text = tech_name + " has already got appointment within " + mins + " Mins from this time.";
                  this.snackbar = true;
                  return false;
                }
              }
            }
          }
        }
      } else {
        this.snackbar_text = "Assigning job order missing.";
        this.snackbar = true;
        return false;
      }
      return true;
    },
    showAssign: async function (status, pincode, item) {
      let wo_date = item.appointment_date;
      if (Util.isPassedDate(wo_date)) {
        this.snackbar_text = "Passed work orders can not be assigned.";
        this.snackbar = true;
        return false;
      }
      if (status) {
        if (status == "cancelled") {
          this.snackbar_text = "Cannot Assign Cancelled WorkOrder.";
          this.snackbar = true;
        } else if (status == "assigned" || status == "unassigned") {
          this.assign_item = item;
          let match_found = [];
          let match_notfound = [];
          this.overlay = true;
          let technicians = await PostgresDB.getTechnicians();
          this.overlay = false;
          for (let i = 0; i < technicians.length; i++) {
            let technician = technicians[i];
            //console.log('in showassign:'+JSON.stringify(technician));
            let allocArray = technician.allocated_areas;
            if (Util.isNotEmpty(allocArray)) {
              for (const val of allocArray) {
                if (val.pincode == pincode) {
                  if (this.isTechnicianAlreadyAdded(match_found, technician) == false) {
                    match_found.push(technician);
                  }
                } else {
                  if (this.isTechnicianAlreadyAdded(match_notfound, technician) == false) {
                    match_notfound.push(technician);
                  }
                }
              }
            } else {
              match_notfound.push(technician);
            }
          }
          this.technician_details = match_found.concat(match_notfound);
          this.technician_search = "";
          this.sheet = true;
        } else {
          this.snackbar_text = "Cannot Assign WorkOrder At This Stage.";
          this.snackbar = true;
        }
      } else {
        alert("no status");
      }
    },
    isTechnicianAlreadyAdded: function (matches, technician) {
      for (let i = 0; i < matches.length; i++) {
        let match = matches[i];
        if (match._id == technician._id) {
          return true;
        }
      }
      return false;
    },
    print: function (status) {
      if (status) {
        return status;
      } else {
        return "Not Ready!";
      }
    },
    deleteItem: function (item) {
      this.delete_summary = item.name + " " + item.age + " Yrs " + item.gender + " Mobile:" + item.mobile;
      this.delete_item = item;
      this.deleteDialog = true;
    },
    doDelete: async function () {
      let delete_doc = this.delete_item;
      delete_doc._deleted = true;
      let instance = this;
      WorkOrderDB.doUpdate(delete_doc)
        .then(function (result) {
          if (result == "OK") {
            instance.snackbar_text = "Deleted Successfully.";
            instance.snackbar = true;
          }
        })
        .catch(function (err) {
          instance.snackbar_text = "Error: " + err;
          instance.snackbar = true;
        });
      this.deleteDialog = false;
    },
    view_time_line: function (item) {
      this.type = "time_line_work_order_dialog";
      this.show_time_line = true;
      this.time_line_item = item;
    },
    close_time_line: function () {
      this.show_time_line = false;
      this.type = "";
    },
    editItem: function (item) {
      this.type = "edit_work_order_dialog";
      this.show_edit = true;
      this.edited_item = item;
    },

    close_edit_screen: function (e) {
      let ret_msg = "";
      if (typeof e === "string" || e instanceof String) {
        ret_msg = e;
        this.snackbar_text = ret_msg;
        this.snackbar = true;
      }
      //this.edited_item = null;
      this.show_edit = false;
      this.type = "";
      if (ret_msg.startsWith("Cancelled")) {
        this.action = "Cancelled";
        let doc = this.edited_item;
        this.msg_head = "Cancelled Successfully.";
        this.msg_str = "Do you wish to inform " + doc.name + " (Mob:" + doc.mobile + ") about the cancellation?";
        this.msg_dialog = true;
      }
    },
    closeSheet: function () {
      this.sheet = false;
    },
    send_cancellation_sms: async function () {
      this.msg_str = "";
      this.msg_head = "";
      this.msg_dialog = false;
      let doc = (doc = this.edited_item);
      let msg_header = "Cancelled Collection on " + doc.appointment_date + " " + doc.appointment_time + ".";
      let msg_body = "Cancelled home collection for " + doc.name + "(" + doc.age + "/" + doc.gender + ") ";
      msg_body += "address:" + doc.address + " mobile:" + doc.mobile + " pincode:" + doc.pincode;
      msg_body += " " + doc.free_text;
      let notifications = {
        _id: "notifications:" + Util.getDateForId() + ":" + Util.uuidv4(),
        from_id: "0",
        from_name: Util.getFromSession("logged_in_emp_name"),
        to_id: doc.assigned_id,
        to_name: doc.assigned_to,
        msg_header: msg_header,
        msg_body: msg_body,
        msg_attachment: {},
        status: "New",
        msg_time: Util.getTodayWithTime(),
        updated_at: Util.getTimeStamp(),
      };
      let notification_db = await DBHandler.getDb("notifications");
      notification_db
        .put(notifications)
        .then(function (response) {
          console.log(response);
          if (response.ok) {
            console.log(response);
          }
        })
        .catch(function (err) {
          console.log(err);
        });
      let reshedule_url = settings.msg_url + "reshedule";

      let message = {
        center_id: Util.getFromSession("logged_in_tenant_id"),
        center_name: Util.getFromSession("logged_in_tenant_name"),
        department_id: Util.getFromSession("department_id"),
        department_name: Util.getFromSession("department_name"),
        role_id: Util.getFromSession("role_id"),
        role_name: Util.getFromSession("role_name"),
        emp_id: Util.getFromSession("logged_in_emp_id"),
        emp_name: Util.getFromSession("logged_in_emp_name"),
        recipient_mobile: doc.mobile,
        recipient_name: doc.name,
        status: "0",
        msg_time: Util.getTodayWithTime(),
        updated_at: Util.getTimeStamp(),
      };
      if (this.sms) {
        let msg = sms_template.home_collection_cancellation(reshedule_url);
        message._id = "sms_center:" + Util.getDateForId() + ":" + Util.uuidv4();
        message.message = msg;
        let result = await com_center.send_msg(message);
        if (result == "OK") {
          this.snackbar_text = "SMS Sent Successfully.";
          this.snackbar = true;
        } else {
          this.snackbar_text = result;
          this.snackbar = true;
        }
      }
      if (this.whatsapp) {
        let whatsapp_msg = [reshedule_url];
        message._id = "whatsapp_center:" + Util.getDateForId() + ":" + Util.uuidv4();
        message.message = whatsapp_msg;
        message.template = "hc_cancellation";
        let result = await com_center.send_msg(message);
        if (result == "OK") {
          this.snackbar_text = "WhatsApp Msg. Sent Successfully.";
          this.snackbar = true;
        } else {
          this.snackbar_text = result;
          this.snackbar = true;
        }
      }
      if (this.email_msg) {
        message._id = "email_center:" + Util.getDateForId() + ":" + Util.uuidv4();
        let result = await com_center.send_msg(message);
        if (result == "OK") {
          this.snackbar_text = "Email Msg. Sent Successfully.";
          this.snackbar = true;
        } else {
          this.snackbar_text = result;
          this.snackbar = true;
        }
      }
    },
    download_id: function (id_loc, mode) {
      let item = id_loc + "";
      let dArray = [];
      if (item.indexOf(" | ") > -1) {
        dArray = item.split(" | ");
      } else {
        dArray[0] = "homecollection";
        dArray[1] = item;
      }
      let jwt_token = Util.getFromSession("pg_admin");
      let data = {
        bucket_name: dArray[0],
        key: dArray[1],
        jwt_token: jwt_token,
      };
      let name = dArray[1];
      if (name.indexOf("/") > -1) {
        name = name.substring(name.lastIndexOf("/") + 1);
      }
      if (mode == "view") {
        if (name.endsWith(".pdf")) {
          this.s3_url = id_loc;
          this.show_report = true;
        } else {
          this.s3_url = id_loc;
          this.show_img = true;
        }
        return;
      }
      this.overlay_dk = true;
      let instance = this;
      axios({
        method: "post",
        responseType: "blob",
        url: settings.node_url + "/s3/get_file_v2",
        data: data,
      })
        .then(async function (response) {
          //console.log(response.data);
          if (mode == "download") {
            fileDownload(response.data, name);
          }
          instance.overlay_dk = false;
        })
        .catch(function (error) {
          console.log("error:" + error);
          instance.overlay_dk = false;
          alert(error);
        });
    },
    getReason: function (item) {
      if (item.cancel_reason) {
        return item.cancel_reason;
      } else {
        return "Reason Not Given.";
      }
    },
    getCancelStatus: function (item) {
      if (item.status) {
        if (item.status == "cancelled") {
          return true;
        }
      }
      return false;
    },
    getReportStatus: function (item) {
      try {
        if (item.server_status == "Billed") {
          if (item.report_path) {
            return true;
          }
        }
        return false;
      } catch (e) {
        console.log(e);
      }
      return false;
    },
    getStatus: function (status) {
      if (status == "Received") {
        return "Unbilled";
      }
      return status;
    },
    getPrescrptionFileNames: function (name) {
      let aName = [];
      let retval = "";
      if (name.indexOf(",") > -1) {
        aName = name.split(",");
      } else {
        aName.push(name);
      }
      if (aName.length > 1) {
        retval = aName.length + " files. ";
        return retval;
      } else {
        return Util.getName(name);
      }
    },
    download_files: function (name) {
      let pres_array = [];
      let aName = [];
      if (name.indexOf(",") > -1) {
        aName = name.split(",");
      } else {
        aName.push(name);
      }
      for (let i = 0; i < aName.length; i++) {
        let fName = aName[i];
        pres_array.push(fName);
      }
      this.pres_array = pres_array;
      this.file_dialog = true;
    },
    close_download_dialog: function () {
      this.file_dialog = false;
    },
    pdf_close: function () {
      this.show_report = false;
    },
    img_close: function () {
      this.show_img = false;
    },
    getClientStatus: function (item) {
      let ret = "";
      if (item.urgent) {
        ret += "Urgent ";
      }
      if (item.vip_client) {
        ret += "VIP ";
      }
      if (item.credit == 1) {
        ret += "Credit ";
      } else if (item.credit == 2) {
        ret += "Trial ";
      }
      if (item.b2b_client_id) {
        ret += "B2B";
      }
      return ret;
    },
    copyItem: function (item) {
      this.type = "copy_work_order_dialog";
      this.show_copy = true;
      this.copied_item = item;
    },
    close_copy_screen: function (e) {
      let ret_msg = "";
      if (typeof e === "string" || e instanceof String) {
        ret_msg = e;
        this.snackbar_text = ret_msg;
        this.snackbar = true;
      }
      //this.edited_item = null;
      this.show_copy = false;
      this.type = "";
    },
    check_search_result: function () {
      try {
        if (this.mode == "search") {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    check_finished(item) {
      if (item.status == "Finished" || item.status == "cancelled") {
        return true;
      }
      return true;
    },
  },
};
</script>
