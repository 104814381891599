import DBHandler from "@/js/database/DBHandler";
import Util from "@/js/Util";
import store from "@/store";
import moment from "moment";

var NotificationDB = {
  handle_changes: Util.debounce(function () {
    NotificationDB.updateStore();
  }, 500),
  updateStore: function () {
    let notifications = store.getters.getNotifications;
    notifications = "updated: " + new Date().getTime();
    console.log(notifications);
    store.commit("setNotifications", notifications);
  },
  list: async function (status) {
    let to_day = store.getters.getToday;
    let calendar = moment(to_day, "YYYY-MM-DD HH:mm:ss", true);
    let end_date = calendar.format("YYYY-MM-DD");
    calendar = calendar.subtract(1, "days");
    let start_date = calendar.format("YYYY-MM-DD");
    console.log("Notification data > list() > " + start_date + " till " + end_date);
    let criteria = parseInt(Util.getFromSession("logged_in_emp_id"));
    if (Util.getFromSession("department_name") == "HOME COLLECTION" && Util.getFromSession("role_name") == "TECHNICIAN") {
      criteria = parseInt(Util.getFromSession("logged_in_emp_id"));
    }
    var localDb = await DBHandler.getDb("notifications");
    if (localDb) {
      var doc = await localDb.allDocs({
        include_docs: true,
        startkey: "notifications:" + start_date,
        endkey: "notifications:" + end_date + "\ufff0",
      });
      var new_items = [];
      var all_items = [];
      if (doc.total_rows > 0) {
        for (var i = 0; i < doc.rows.length; i++) {
          if (doc.rows[i].doc.from_name !== undefined) {
            if (doc.rows[i].doc.to_id == criteria) {
              let updated = Util.format_date(doc.rows[i].doc.updated_at);
              doc.rows[i].doc.updated = updated;
              if (doc.rows[i].doc.status == status) {
                // only new
                new_items.push(doc.rows[i].doc);
              }
              // all status
              all_items.push(doc.rows[i].doc);
            }
          }
        }
        all_items.sort(function (a, b) {
          return new Date(Date.parse(b.updated_at)) - new Date(Date.parse(a.updated_at));
        });
      }
      if (all_items.length == 0) {
        if (status != "New") {
          all_items.push({
            from_name: "No Notifications Found.",
            msg_header: "NA",
            status: "NA",
            msg_body: "NA",
          });
        }
      }
      console.log("items length:" + all_items.length);
      if (status == "New") {
        return new_items;
      }
      return all_items;
    } else {
      console.log("Db is null here....notifications db list ");
    }
  },
  doUpdate: async function (doc) {
    var localDb = await DBHandler.getDb("notifications");
    if (localDb) {
      let result = localDb
        .put(doc)
        .then(function (response) {
          console.log(response);
          if (response.ok) {
            console.log("updated doc successfully.");
            return "OK";
          }
        })
        .catch(function (err) {
          console.error(err);
          return "ERROR:" + err;
        });
      return result;
    } else {
      console.log("Db is null here....");
      return "ERROR";
    }
  },
};
export default NotificationDB;
