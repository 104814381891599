let settings = {
  production: true,
  type: "desktop",
  version: "2.8.4",
  //rdbms_db_url: "/v3",
  rdbms_db_url: "https://api.andrsn.in",
  remote_couch_url: "https://db-2.andrsn.in",
  //node_url: "https://hc.andrsn.in",
 node_url: "https://hcdev.andrsn.click/api",
 //node_url: "/sms",
 // msg_url: "https://hc.andrsn.in?s=",
  msg_url: "https://hcdev.andrsn.click/api?s=",
  app_user: "HOME_COLLECTION_APP",
  app_pass: "qMc7eNKhNOjDUJ",
  

  //  production: false,
  //  type: "desktop",
  //  version: "2.8.3",
  //  rdbms_db_url: "https://api.andrsn.in",
  //  remote_couch_url: "https://db-2.andrsn.in",
  //  node_url: "https://hc.andrsn.in",
  //  msg_url: "https://hc.andrsn.in?s=",
  //  app_user: "HOME_COLLECTION_APP",
  //  app_pass: "NidUEpIsTkQiSfddm",

  // production: false,
  // version: "2.3.3",
  // type: "desktop",
  // rdbms_db_url: "https://api.andrsn.click",
  // remote_couch_url: "https://db-2.andrsn.in",
  // remote_couch_direct_url: "https://webuser:cka0t20iwlxf@db-2.andrsn.in",
  // node_url: "https://hc.andrsn.click",
  // msg_url: "https://hc.andrsn.click?z=",
  // app_user: "HOME_COLLECTION_APP",
  // app_pass: "JpYXQiOjE2NjM3ODY",

  // production: true,
  // type: 'desktop',
  // version: '2.3.3',
  // rdbms_db_url: 'https://api.andrsn.in',
  // remote_cloudant_url: "https://309fe160-f01e-4797-81da-89a64322ebaa-bluemix.cloudantnosqldb.appdomain.cloud",
  // node_url:  'https://hc.andrsn.in',
  // msg_url:  'https://hc.andrsn.in?s=',
  // app_user: 'HOME_COLLECTION_APP',
  // app_pass: 'zY1LTQ3NTItOWZlZi0',

  // production: false,
  // version: '2.3',
  // type: 'desktop',
  // rdbms_db_url: 'https://api.andrsn.in',
  // remote_cloudant_url: "https://309fe160-f01e-4797-81da-89a64322ebaa-bluemix.cloudantnosqldb.appdomain.cloud",
  // node_url: 'https://hc.andrsn.in',
  // msg_url: 'https://hc.andrsn.in?z=',
  // app_user: 'HOME_COLLECTION_APP',
  // app_pass: 'BgXf_iHqCTmcZtYzrHUNu9KQU_khnqbEYd',

  //production: false,
  // version: '1.1.0',
  // type: 'desktop',
  // rdbms_db_url: 'https://api.andrsn.click',
  // remote_cloudant_url: "https://309fe160-f01e-4797-81da-89a64322ebaa-bluemix.cloudantnosqldb.appdomain.cloud",
  // node_url: 'https://hc.andrsn.click',
  // msg_url: 'https://hc.andrsn.click?z=',
  // app_user: 'HOME_COLLECTION_APP',
  // app_pass: 'qMc7eNKhNOjDUJ',
};
export default settings;

// 2.2    ib9jNg9YtjSiRc
// 2.3    BgXf_iHqCTmcZtYzrHUNu9KQU_khnqbEYd
// 2.3.1  BgXf_iHqCTmcZtYzrHUNu9KQU_khnqbEYd
// 2.3.2  zY1LTQ3NTItOWZlZi0
// 2.3.3  zY1LTQ3NTItOWZlZi0
// 2.4    W50X3R5cGUiOiJ1cm  -- couchdb version
// 2.5    W50X3R5cGUiOiJ1cm
// 2.6    W50X3R5cGUiOiJ1cm  -- bill and attune report
// 2.7    JpYXQiOjE2NjM3ODY  -- tech app changes
// 2.8    NidUEpIsTkQiSfddm  -- virtual scroll
// 2.8.1  NidUEpIsTkQiSfddm  -- tenant list visiblity
// 2.8.2  NidUEpIsTkQiSfddm  -- workorder online add
