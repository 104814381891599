<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar color="white">
            <v-toolbar-title
              ><v-chip color="orange" text-color="white" label><h3>Add Test</h3></v-chip></v-toolbar-title
            >
            <v-chip class="ma-2" color="orange" label outlined> {{ item.name }} ({{ item.age }} / {{ item.gender }}) </v-chip>
            <v-spacer></v-spacer>
            <v-btn icon @click="show_price_list_sheet()">
              <v-icon>mdi-selection-search</v-icon>
            </v-btn>
            <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar>
          <v-container>
            <v-form ref="add_test_order_form" v-model="valid" lazy-validation>
              <v-row justify="center" v-for="item in price_list_seleted_item" :key="item._id">
                <v-card elevation="2" outlined style="width: 100%" class="ma-2">
                  <v-card-title class="text-h6 blue--text">
                    {{ item.invest_name }}
                    <v-spacer></v-spacer>
                    <v-chip class="mx-2" color="orange" label outlined v-if="cghs">
                      {{ get_cghs(item) }}
                    </v-chip>
                    <v-chip class="mx-2" color="orange" label outlined v-else>
                      {{ format(item.base_cost) }}
                    </v-chip>
                    <v-btn icon @click="close_card(item._id)"><v-icon>mdi-close</v-icon></v-btn>
                  </v-card-title>
                  <v-card-subtitle>
                    {{ item.department_name }}
                  </v-card-subtitle>
                </v-card>
              </v-row>
              <v-row justify="center" v-if="price_list_seleted_item.length == 0">
                <v-card elevation="2" style="width: 100%" class="my-2">
                  <v-chip class="ma-2" color="orange" label outlined> Add tests by clicking on the search icon in the top right. </v-chip>
                </v-card>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
        <v-footer dense :fixed="footer_position" padless color="white">
          <!--  v-if="price_list_seleted_item.length > 0" -->
          <v-card elevation="2" style="width: 100%" class="ma-2">
            <v-card-title>
              <v-btn text color="primary" class="mx-2" @click="save">Save</v-btn>
              <v-btn text color="primary" class="mx-2" @click="close">Close</v-btn>
              <v-spacer></v-spacer>
              <v-chip class="ma-2" color="orange" label outlined> Total: {{ computed_total }} </v-chip>
            </v-card-title>
          </v-card>
        </v-footer>
      </v-dialog>
    </v-row>
    <v-bottom-sheet v-model="price_list_sheet" inset>
      <v-toolbar color="white">
        <v-chip color="orange" text-color="white" label><h3>Search Price List</h3></v-chip>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          ref="spl_ref"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          @keyup.native="debounce_search()"
        ></v-text-field>
        <v-btn icon @click="closeSheet()"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-sheet height="300px">
        <v-simple-table dense height="300px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left" width="20%">Department</th>
                <th class="text-left" width="60%">Investigation</th>
                <th class="text-left" width="10%">Price</th>
                <th class="text-left" width="10%" v-if="cghs">CGHS</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in price_list" :key="item._id">
                <td width="20%">
                  <a @click="selected_price_list(item)">{{ item.dept_name }}</a>
                </td>
                <td width="60%">
                  <a @click="selected_price_list(item)">{{ item.invest_name }}</a>
                </td>
                <td width="10%">
                  <a @click="selected_price_list(item)">{{ format(item.base_cost) }}</a>
                </td>
                <td width="10%" v-if="cghs">
                  <a @click="selected_price_list(item)">{{ format(item.cghs_price) }}</a>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-sheet>
    </v-bottom-sheet>
    <v-overlay :value="overlay" :z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!-- 
      Message Dialog   
  -->
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>
<script>
import Util from "@/js/Util";
import PriceListDB from "@/js/database/price_list_db";
export default {
  props: ["show", "item", "cghs"],
  data() {
    return {
      dialog: this.show,
      valid: true,
      search: "",
      overlay: false,
      price_list_sheet: false,
      price_list: [],
      price_list_seleted_item: [],
      footer_position: true,
      snackbar: false,
      snackbar_text: "",
      updated_item: this.item,
    };
  },
  mounted: function () {
    try {
      let selected_str = Util.getFromLocalStorage("selected_tests");
      let selected_array = JSON.parse(selected_str);
      if (selected_array.length > 0) {
        console.log("selected list:" + JSON.stringify(selected_array));
        this.price_list_seleted_item = selected_array;
      }
    } catch (e) {
      console.log(e);
    }
    this.show_price_list_sheet();
    console.log("cghs price:" + this.cghs);
  },
  computed: {
    computed_total: function () {
      let total = 0;
      for (let i = 0; i < this.price_list_seleted_item.length; i++) {
        let item = this.price_list_seleted_item[i];
        if (this.cghs) {
          let iCghs = parseInt(item.cghs_price);
          if (iCghs <= 0) {
            total += parseInt(item.base_cost);
          } else {
            total += iCghs;
          }
        } else {
          total += parseInt(item.base_cost);
        }
      }
      return total;
    },
    computed_total_for_pdf: function () {
      let total = 0;
      for (let i = 0; i < this.price_list_seleted_item.length; i++) {
        let item = this.price_list_seleted_item[i];
        if (this.cghs) {
          let iCghs = parseInt(item.cghs_price);
          if (iCghs <= 0) {
            total += parseInt(item.base_cost);
          } else {
            total += iCghs;
          }
        } else {
          total += parseInt(item.base_cost);
        }
      }
      return Util.formatMoneyForPDF(total);
    },
  },
  methods: {
    get_cghs: function (item) {
      let iCghs = parseInt(item.cghs_price);
      if (iCghs <= 0) {
        return this.format(item.base_cost);
      } else {
        return this.format(item.cghs_price);
      }
    },
    format: function (money) {
      return Util.formatMoney(money);
    },
    set_doc: function (doc) {
      Util.setSession("current_doc", JSON.stringify(doc));
    },
    save: async function () {
      let doc = this.updated_item;
      let app_time = this.updated_item.appointment_time.replace(":", "_");
      let fname = "proforma_" + this.updated_item.mobile + "_" + app_time + ".pdf";
      let flocation = "homecollection/proforma/" + Util.getTodayStringForFolderCreation() + "/" + fname;
      if (this.price_list_seleted_item.length > 0) {
        doc.test_items = this.price_list_seleted_item;
        doc.total = this.computed_total;
        doc.process.proforma_uploaded_at = "NOT_CREATED";
        doc.doc_dbs = Util.getFromSession("doc_dbs");
        this.set_doc(doc);
        this.close(flocation);
      } else {
        this.snackbar_text = "Add tests by clicking on the search icon in the top right.";
        this.snackbar = true;
      }
    },
    close: function (e) {
      this.dialog = false;
      this.$emit("close_add_test", e);
    },
    closeSheet: function () {
      this.price_list_sheet = false;
    },
    show_price_list_sheet: function () {
      this.price_list_sheet = true;
      this.search_price_list();
    },
    search_price_list: async function () {
      try {
        console.log("searching..." + this.search);
        this.price_list = [];
        this.overlay = true;
        let list = await PriceListDB.fetch_data(this.search);
        console.log("list size:" + list.length);
        this.price_list = list;
        this.overlay = false;
        this.$refs.spl_ref.$refs.input.focus();
      } catch (e) {
        console.log("Error:" + e);
      }
    },
    debounce_search: Util.debounce(function () {
      this.search_price_list();
    }, 500),
    selected_price_list: function (item) {
      if (item) {
        for (let i = 0; i < this.price_list_seleted_item.length; i++) {
          let pItem = this.price_list_seleted_item[i];
          if (pItem._id == item._id) {
            this.snackbar_text = "Test With The Same Name Already Added.";
            this.snackbar = true;
            return;
          }
        }
        this.price_list_seleted_item.push(item);
        if (this.price_list_seleted_item.length > 4) {
          this.footer_position = false;
        }
        try {
          Util.setLocalStorage("selected_tests", JSON.stringify(this.price_list_seleted_item));
        } catch (e) {
          console.log(e);
        }
      }
      //this.price_list_sheet = false;
      this.search = "";
      this.$refs.spl_ref.$refs.input.focus();
      //this.price_list_sheet = true;
    },
    close_card: function (id) {
      for (let i = 0; i < this.price_list_seleted_item.length; i++) {
        let item = this.price_list_seleted_item[i];
        if (item._id == id) {
          this.price_list_seleted_item.splice(i, 1);
        }
      }
      if (this.price_list_seleted_item.length <= 4) {
        this.footer_position = true;
      }
    },
  },
};
</script>
