import Util from "@/js/Util";

let authorize = {
  getMenus: function() {
   // let role_id = Util.getFromSession("role_id");
   let role_name = Util.getFromSession("role_name");

    let department = Util.getFromSession("department_name");
    if ((department == "HOME COLLECTION") || (department == "ALL DEPARTMENTS")){
      if ((role_name == "TECHNICIAN")) {
        let menu = [
          {
            icon: "mdi-order-bool-ascending",
            title: "My Work Orders",
            vertical: "MyWork",
            link: "my_work_orders",
          },
        ];
        return menu;
      } else if ((role_name == "BILLING")) {
        let menu = [
          {
            icon: "mdi-order-bool-ascending",
            title: "Work Orders",
            vertical: "billingWork",
            link: "billing_work_orders",
          },
        ];
        return menu;
      }
      else if ((role_name == "SAMPLE RECEIVING")) {
        let menu = [
          {
            icon: "mdi-test-tube",
            title: "Sample Receiving",
            vertical: "samplereceiving",
            link: "lab_sample_receiving",
          },
        ];
        return menu;
      }
      else if ((role_name == "MANAGER")){
        let menu = [
          {
            icon: "mdi-point-of-sale",
            title: "Work Orders",
            vertical: "Work Orders",
            link: "work_orders",
          },
          {
            icon: "mdi-motion-sensor",
            title: "Users",
            vertical: "Users",
            link: "users",
          },
        ];
        return menu;
      }

       else if ((role_name == "RECEPTIONIST")) {
        let menu = [
          {
            icon: "mdi-point-of-sale",
            title: "Work Orders",
            vertical: "Work Orders",
            link: "work_orders",
          },
          {
            icon: "mdi-motion-sensor",
            title: "Users",
            vertical: "Users",
            link: "users",
          },
        ];
        return menu;
      }
    }
  },
};
export default authorize;
