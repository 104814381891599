<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" sm="6" md="3">
        <v-select
          :items="suitable_dates"
          v-model="choosen_date"
          label="Choose Collection Date"
          @change="date_change"
        ></v-select>
      </v-col>
    </v-row>
    <v-container>
      <v-data-table
        :headers="tableHeaders"
        :items="tableData"
        :items-per-page="50"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="_id"
        show-expand
        :loading="loading"
        loading-text="Loading... Please wait"
        class="elevation-1"
        dense
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @keyup.native="debounce_search()"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Name
                    </th>
                    <th class="text-left">
                      Gender
                    </th>
                    <th class="text-left">
                      Age
                    </th>
                    <th class="text-left">
                      Mobile
                    </th>
                    <th class="text-left">
                      Status
                    </th>
                    <th class="text-left">
                      Sample Pics
                    </th>
                    <th class="text-left">
                      Accept
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(thing, i) in item.assigned_list" :key="i">
                    <td>{{ thing.name }}</td>
                    <td>{{ thing.gender }}</td>
                    <td>{{ thing.age }}</td>
                    <td>{{ thing.mobile }}</td>
                    <td>{{ thing.status }}</td>
                    <td>
                      {{ getPicCount(thing.lab_sample_pics) }}
                      <v-btn icon @click="view_download(thing)">
                        <v-icon color="primary">mdi-cloud-download</v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <div v-if="getPicCount(thing.lab_sample_pics)">
                        <v-switch v-model="thing.lab_sample_pics_accepted" label="Accept" @change="sample_change(thing)"></v-switch>
                      </div>
                      <div v-else>
                        No Image
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-container>

    <v-overlay :value="overlay" :z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!--  
   --                         Dialog for view / download
   -->
    <div>
      <v-dialog persistent v-model="file_dialog" transition="dialog-bottom-transition" max-width="600">
        <v-card>
            <v-toolbar color="orange" dark>View / Download Files</v-toolbar>
            <v-card-text>
                <v-sheet color="orange lighten-5" elevation="7" outlined rounded shaped class="ma-2 pa-2" v-for="url in pres_array" :key="url">
                  <v-btn text color="primary" x-small> {{ getName(url) }}</v-btn>
                  <v-btn text color="primary" @click="download_id(url,'view')" x-small>View </v-btn>
                  <v-btn text color="primary" @click="download_id(url,'download')" x-small>Download</v-btn>
                </v-sheet>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn  text  @click="close_download_dialog">OK</v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>
    </div>
    <!-- 
      Message Dialog   
  -->
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackbar_text }}
    </v-snackbar>
     <!--  
   --                         Dialog for view pdf
   -->
    <v-container v-if="show_report">
      <show_pdf v-bind:show_report="show_report" v-bind:s3_url="s3_url" @close_pdf="pdf_close()"></show_pdf>
    </v-container>
    <v-container v-if="show_img">
      <show_img v-bind:show_img="show_img" v-bind:s3_url="s3_url" @close_image="img_close()"></show_img>
    </v-container>

    <v-overlay :value="overlay_dk" :z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import fileDownload from "js-file-download";
import axios from "axios";
import settings from "@/js/database/settings";
import WorkOrderDB from "@/js/database/workorder_db";
import PostgresDB from "@/js/database/postgres_db";
import Util from "@/js/Util";
import moment from "moment";
import show_pdf from '@/components/show_pdf.vue';
import show_img from '@/components/show_img.vue';
export default {
  components: { show_pdf,show_img },
  props: ["show"],
  data() {
    return {
      loading: false,
      search: "",
      expanded: [],
      singleExpand: true,
      dialog: this.show,
      suitable_dates: [],
      choosen_date: "",
      overlay: false,
      technicianDetails: [],
      tableHeaders: [
        { text: "Technician", align: "start", sortable: true, value: "name" },
        { text: "Assigned", value: "assigned" },
        { text: "Cancelled", value: "cancelled" },
        { text: "Finished", value: "finished" },
        { text: "Pending", value: "pending" },
        { text: "Total Sample Accepted", value: "total_sample_accepted" },
        { text: "", value: "data-table-expand" },
      ],
      tableData: [],
      techData: [],
      amount_deposited: [],
      snackbar: "",
      snackbar_text: "",
      pres_array: [],
      file_dialog: false,
      sample_received_Dialog: false,
      tech_data: [],
      sample_index: "",
      edit_item: {},
      show_report: false,
      s3_url: '',
      show_img: false,
      overlay_dk: false,
    };
  },
  mounted: function () {
    this.suitable_dates.push(Util.getWeekDay(1));
    let to_day = Util.getWeekDay(0);
    this.suitable_dates.push(to_day);
    this.suitable_dates.push(Util.getWeekDay(-1));
    this.suitable_dates.push(Util.getWeekDay(-2));
    this.suitable_dates.push(Util.getWeekDay(-3));
    this.suitable_dates.push(Util.getWeekDay(-4));
    this.suitable_dates.push(Util.getWeekDay(-5));
    this.choosen_date = to_day;
    this.fetch();
    //this.dummy_data();
  },
  methods: {
    fetch: async function () {
      this.overlay = true;
      this.technicianDetails = await PostgresDB.getTechnicians();
      this.date_change();
      this.overlay = false;
    },
    date_change: async function () {
      let cal = moment(this.choosen_date, "ddd DD MMM YYYY");
      let col_date = cal.format("YYYY-MM-DD");
      this.techData = await WorkOrderDB.tech_sample_verification(
        col_date,
        this.technicianDetails
      );
      this.tableData = this.techData;
    },
    getName: function (name) {
      return Util.getName(name);
    },
    download_files: function (name) {
      let pres_array = [];
      let aName = [];
      if (name.indexOf(",") > -1) {
        aName = name.split(",");
      } else {
        aName.push(name);
      }
      for (let i = 0; i < aName.length; i++) {
        let fName = aName[i];
        pres_array.push(fName);
      }
      this.pres_array = pres_array;
      this.file_dialog = true;
    },
    close_download_dialog: function () {
      this.file_dialog = false;
    },
    download_id: function (id_loc, mode) {
      let item = id_loc + "";
      let dArray = [];
      if (item.indexOf(" | ") > -1) {
        dArray = item.split(" | ");
      } else {
        dArray[0] = "homecollection";
        dArray[1] = item;
      }
      let jwt_token = Util.getFromSession("pg_admin");
      let data = {
        bucket_name: dArray[0],
        key: dArray[1],
        jwt_token: jwt_token,
      };
      let name = dArray[1];
      if (name.indexOf("/") > -1) {
        name = name.substring(name.lastIndexOf("/") + 1);
      }
      if (mode == "view") {
        if (name.endsWith(".pdf")) {
          this.s3_url = id_loc;
          this.show_report = true;
        } else {
          this.s3_url = id_loc;
          this.show_img = true;
        }
        return;
      }
      this.overlay_dk = true;
      let instance = this;
      axios({
        method: "post",
        responseType: "blob",
        url: settings.node_url + "/s3/get_file_v2",
        data: data,
      })
        .then(async function (response) {
          //console.log(response.data);
          if (mode == "download") {
            fileDownload(response.data, name);
          }
          instance.overlay_dk = false;
        })
        .catch(function (error) {
          console.log("error:" + error);
          instance.overlay_dk = false;
          alert(error);
        });
    },
    getColor: function (status) {
      if (status) {
        let lStatus = status;
        if (lStatus == "true") return "green";
        else if (lStatus == "false") return "red";
        else return "green";
      } else {
        return "red";
      }
    },
    debounce_search: Util.debounce(function () {
      this.search_details();
    }, 500),
    search_details: function () {
      this.tableData = Util.search(this.techData, this.search.toLowerCase());
    },
    close: function (e) {
      this.dialog = false;
      this.$emit("close_tech_engagements", e);
    },
    getPicCount: function(str){
      if(str){
        let aPics = str.split(',');
        return aPics.length
      }
      return 0;
    },
    view_download: function(item){
      this.edit_item = item;
      if(item.lab_sample_pics){
        let files = item.lab_sample_pics.toString();
        this.download_files(files);
      } else {
        this.snackbar_text = 'No Files To View.';
        this.snackbar = true;
      }
    },
    pdf_close: function(){
        this.show_report = false;
    },
    img_close: function(){
      this.show_img = false;
    },
    sample_change: async function(item){
      let id = item._id;
      let doc = await WorkOrderDB.getWithId(id);
      doc.lab_sample_pics_accepted = item.lab_sample_pics_accepted;
      try{
          let time_stamp = Util.gettime();
          let user = Util.getFromSession("logged_in_emp_name");
          let new_tl = '';
          if(item.lab_sample_pics_accepted){
            new_tl = time_stamp + " - " + user + " - Lab Sample Accepted.";
          } else {
            new_tl = time_stamp + " - " + user + " - Lab Sample Cancelled.";
          }
          let old_tl = doc.time_line;
          old_tl.push(new_tl); 
          doc.time_line = old_tl;
      }catch(e){
          console.log('error updating timeline:'+e);
      }
      let result = await WorkOrderDB.doUpdate(doc);  
      if(result == 'OK'){
        if(item.lab_sample_pics_accepted){
          this.snackbar_text = 'Sample Accepted Successfully.';
        } else {
          this.snackbar_text = 'Sample Cancelled.';
        }
        this.snackbar = true;
      } else {
        this.snackbar_text = result;
        this.snackbar = true;
      }
      this.date_change();
    }
  },
};
</script>
