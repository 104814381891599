<template>
  <div v-if="check_tenant(tenant_id)">
    <v-container v-if="role_name == 'MANAGER'">
      <work_order_dashboard></work_order_dashboard>
    </v-container>
    <v-container v-else-if="role_name == 'TECHNICIAN'">
      <my_work_order_dashboard></my_work_order_dashboard>
    </v-container>
    <v-container v-else> Dashboard Yet To Come </v-container>
  </div>
  <div v-else>Dashboard Yet To Come</div>
</template>
<script>
import Util from "@/js/Util";
import work_order_dashboard from "@/components/work_order/dashboard/work_order_dashboard.vue";
import my_work_order_dashboard from "@/components/my_work_order/dashboard/my_work_order_dashboard.vue";
export default {
  components: { work_order_dashboard, my_work_order_dashboard },
  name: "Dashboard",
  data: () => ({
    role_name: Util.getFromSession("role_name"),
    department: Util.getFromSession("department_name"),
    tenant_id: Util.getFromSession("logged_in_tenant_id"),
  }),
  mounted: function () {},
  methods: {
    check_tenant: function (tenant_id) {
      let tenants_allowed = [1, 26, 70];
      for (let i = 0; i < tenants_allowed.length; i++) {
        let id = tenants_allowed[i];
        if (tenant_id == id) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
