<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar color="white">
            <v-toolbar-title
              ><v-chip color="orange" text-color="white" label
                ><h3>Technician Daily Summary</h3></v-chip
              ></v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar>
          <v-row justify="center">
            <v-col cols="12" sm="6" md="3">
              <v-select
                :items="suitable_dates"
                v-model="choosen_date"
                label="Choose Collection Date"
                @change="date_change"
              ></v-select>
            </v-col>
          </v-row>
          <v-card :loading="loading" v-for="(item, i) in tableData" :key="i">
            <v-card-subtitle>
              <v-sheet
                color="white"
                elevation="7"
                outlined
                rounded
                shaped
                class="ma-2 pa-2"
              >
                <div class="font-weight-black">Assigned: {{ item.total_assigned }} <br />
                Cancelled: {{ item.total_cancelled }} <br />
                Finished: {{ item.total_finished }} <br />
                Pending: {{ item.total_pending }}
                </div>
              </v-sheet>
              <v-sheet
                color="white"
                elevation="7"
                outlined
                rounded
                shaped
                class="ma-2 pa-2"
              >
              <div class="blue--text font-weight-bold">
                Collected: ₹{{ item.total_amount }}<br />
                Handed Over: ₹{{ item.amount_collected }}<br />
                Accepted: ₹{{ item.amount_accepted }}
              </div>
              </v-sheet>
            </v-card-subtitle>
          </v-card>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- 
      Message Dialog   
  -->
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>
<script>
import Util from "@/js/Util";
import moment from "moment";
import WorkOrderDB from "@/js/database/workorder_db";
export default {
  props: ["show"],
  data() {
    return {
      dialog: this.show,
      suitable_dates: [],
      choosen_date: "",
      tableData: [],
      work_orders: [],
      loading: false,
      amount_Remitted: "",
      amountRules: [
        (v) => !!v || "Received amount is required",
        (v) =>
          (v && v < 100000) ||
          "Received amount cannot be greater than Rs.100000",
        (v) => (v && v > 0) || "Received amount must be greater than 0",
      ],
      snackbar: "",
      snackbar_text: "",
      valid: true,
    };
  },
  mounted: function() {
    this.suitable_dates.push(Util.getWeekDay(1));
    let to_day = Util.getWeekDay(0);
    this.suitable_dates.push(to_day);
    this.suitable_dates.push(Util.getWeekDay(-1));
    this.suitable_dates.push(Util.getWeekDay(-2));
    this.suitable_dates.push(Util.getWeekDay(-3));
    this.suitable_dates.push(Util.getWeekDay(-4));
    this.suitable_dates.push(Util.getWeekDay(-5));
    this.choosen_date = to_day;
    this.date_change();
  },
  methods: {
    date_change: async function() {
      this.loading = true;
      let cal = moment(this.choosen_date, "ddd DD MMM YYYY");
      let col_date = cal.format("YYYY-MM-DD");
      let emp_id = Util.getFromSession("logged_in_emp_id");
      this.work_orders = await WorkOrderDB.tech_Aggregate_view(
        emp_id,
        col_date
      );
      this.tableData = this.work_orders;
      this.loading = false;
    },
    reset:async function() {
      this.$refs.amount_given_to_manager = "";
    },
    close: function(e) {
      this.dialog = false;
      this.$emit("close_tech_engagements", e);
    },
    validate: async function() {
      try{
        let value = parseInt(this.amount_Remitted)
        if (value > 0) {
          this.save();
        }
      } catch(e){
        this.snackbar_text = e.toString();
        this.snackbar = true;
      }
      
    },
    save: async function() {
      let item = this.work_orders;
      var data = item[0].assigned_list;
      var amount = this.amount_Remitted;
      let time_stamp = Util.gettime()
      let workorder_created_by = Util.getFromSession('logged_in_emp_name');
      let workorder_assigned = time_stamp +'-'+workorder_created_by +'- Amount Remitted ';
      console.log(JSON.stringify(data));
      data.forEach((d, i) => {
        data[i].amount_deposit = amount;
        data[i].amount_deposited_status = false;
        if(data[i]['time_line']){
          data[i]['time_line'].push(workorder_assigned);
        }
      });
      let update = await WorkOrderDB.Update_amount_deposit(data);
      console.log("updated" + update);
      if (update == "OK") {
        this.snackbar_text = "Remitted Successfully.";
        this.snackbar = true;

        this.msg_head = "Remitted Successfully.";
        this.msg_dialog = true;
      } else {
        console.log(update);
        this.snackbar_text = "Error Occurred:" + update;
        this.snackbar = true;
      }
      this.reset();
      console.log(data);
      this.date_change();
    },
  },
};
</script>
