<template>
  <v-app>
    <template v-if="!signedIn">
      <router-view></router-view>
    </template>
    <template v-if="signedIn">
      <v-navigation-drawer :class="navi_class" mini-variant-width="70" width="250" light :mini-variant.sync="mini" v-model="drawer" app>
        <v-list class="pa-0">
          <v-list-item tag="div">
            <v-list-item-content>
              <v-row justify="space-around">
                <v-avatar color="orange" size="35">
                  <span class="white--text text-h6">{{ initials }}</span>
                </v-avatar>
              </v-row>
              <v-row justify="space-around">
                <div class="text-xs-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on" :color="center_color" outlined x-small @click="show_center">
                        {{ center_name }}
                      </v-chip>
                    </template>
                    <span>Change Center</span>
                  </v-tooltip>
                </div>
              </v-row>
            </v-list-item-content>
            <!-- <v-spacer></v-spacer> -->
            <v-list-item-action style="max-width: 1em">
              <v-menu bottom right offset-y origin="bottom right" transition="v-slide-y-transition">
                <template v-slot:activator="{ on }">
                  <v-btn icon small light slot="activator" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="item in userMenus" :key="item.title" value="true" @click="handleUserActions(item)" router>
                    <v-list-item-content>
                      <v-list-item-title class="orange--text" v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
            <v-list-item-action style="max-width: 1em">
              <v-btn icon small @click.native.stop="mini = !mini">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-list>
          <v-list-item v-for="item in items" :key="item.title" @click="handleNavigation(item)" router>
            <v-list-item-action class="pr-1 pl-2 mr-1">
              <v-icon :class="activeMenuItem(item.title) ? 'blue--text' : 'orange--text'" :title="item.title">
                {{ item.icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content :class="activeMenuItem(item.title) ? 'blue--text' : 'orange--text'">
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar app elevation="2" :color="app_bar_color">
        <v-app-bar-nav-icon @click.native.stop="drawer = !drawer" light></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <v-checkbox
          class="pt-5"
          v-model="$vuetify.theme.dark"
          color="red"
          off-icon="mdi-theme-light-dark"
          on-icon="mdi-theme-light-dark"
          @change="theme_change"
        >
        </v-checkbox>
        <div class="text-xs-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-badge overlap>
                <span slot="badge">{{ notification_count }}</span>
                <v-btn v-bind="attrs" v-on="on" class="mx-2" fab dark small color="primary" @click.native.stop="notifi_drawer = !notifi_drawer">
                  <v-icon dark> mdi-bell-outline </v-icon>
                </v-btn>
              </v-badge>
            </template>
            <span>Notifications</span>
          </v-tooltip>
        </div>
        <img class="pl-4" alt="No Internet" src="https://hc.andrsn.in/img/anderson-logo.png" />
      </v-app-bar>
      <v-main>
        <v-container fluid>
          <router-view></router-view>
        </v-container>
      </v-main>
      <v-navigation-drawer :class="navi_class" v-model="notifi_drawer" right app>
        <template v-slot:prepend>
          <v-list-item-content>
            <v-list-item-title>
              <v-chip class="ma-2" color="orange" label text-color="white">
                <v-icon left> mdi-format-list-bulleted-square </v-icon>
                Notifications
              </v-chip>
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <v-divider></v-divider>
        <v-list dense>
          <v-card class="ma-1" v-for="(item, i) of notificationData" :key="i">
            <v-card-title class="subtitle-2">
              {{ item.from_name }}
              <v-spacer></v-spacer>
              <v-btn icon @click="change_status(item)">
                <v-icon color="green">mdi-check-outline</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-subtitle>
              Msg: {{ item.msg_header }} <br />
              Date: {{ item.updated }}
            </v-card-subtitle>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn icon @click="toggle(i)">
                <v-icon>{{ show[i] ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
              </v-btn>
            </v-card-actions>
            <v-expand-transition>
              <div v-show="show[i]">
                <v-divider></v-divider>
                <v-card-text>
                  <v-simple-table width="100%">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Message Detail</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ item.msg_body }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-list>
      </v-navigation-drawer>
      <v-footer :inset="true" :class="navi_class" style="justify-content: center; text-align: center" app>
        <span :style="theme_font_color"><b>&copy; Anderson-CRM</b></span>
      </v-footer>
    </template>

    <!--  
   --                         Bottom sheet for tenant
   -->
    <div>
      <v-bottom-sheet v-model="center_sheet" inset persistent scrollable>
        <v-sheet height="450px">
          <v-toolbar color="white">
            <v-chip color="orange" text-color="white" label><h3>Change Tenant</h3></v-chip>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              ref="center_ref"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @keyup.native="debounce_search()"
            ></v-text-field>
            <v-btn icon @click="closeSheet()"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar>
          <v-alert border="left" color="primary" type="info" dense>You are in {{ center_name }} </v-alert>
          <v-simple-table dense fixed-header height="300px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" width="10%">Tenant Id</th>
                  <th class="text-left" width="30%">Tenant Name</th>
                  <th class="text-left" width="30%">Region</th>
                  <th class="text-left" width="30%">State</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in centers" :key="item.center_id">
                  <td width="10%">
                    <a @click="selected_center(item.tenant_id, item.tenant_name)">{{ item.tenant_id }}</a>
                  </td>
                  <td width="30%">
                    <a @click="selected_center(item.tenant_id, item.tenant_name)">{{ item.tenant_name }}</a>
                  </td>
                  <td width="30%">
                    <a @click="selected_center(item.tenant_id, item.tenant_name)">{{ item.region }}</a>
                  </td>
                  <td width="30%">
                    <a @click="selected_center(item.tenant_id, item.tenant_name)">{{ item.state }}</a>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-sheet>
      </v-bottom-sheet>
      <!-- 
      Message Dialog   
   -->
      <div>
        <v-snackbar v-model="snackbar" :timeout="2000">
          {{ snackbar_text }}
        </v-snackbar>
        <v-overlay :value="overlay" :z-index="2000">
          <v-progress-circular absolute size="64"></v-progress-circular>
        </v-overlay>
      </div>
    </div>
  </v-app>
</template>
<script>
//import DBHandler from "@/js/database/DBHandler";
import PostgresDB from "@/js/database/postgres_db";
import Util from "@/js/Util";
import NotificationDB from "@/js/database/notification_db";
import { mapState } from "vuex";
import Auth from "@/js/authorize";
import DBHandler from "@/js/database/DBHandler";
import cron_job from "@/js/cron_job";
export default {
  name: "App",
  data: () => ({
    mini: false,
    drawer: false,
    notifi_drawer: false,
    firstname: "first name",
    lastname: "last name",
    menuItem: "Notifications",
    right: "",
    signedIn: false,
    initials: "",
    center_name: "",
    overlay: false,
    center_sheet: false,
    search: "",
    centers: [],
    snackbar_text: "",
    snackbar: false,
    notificationData: [],
    notification_count: 0,
    show: [],
    app_bar_color: "white",
    navi_class: "grey lighten-3",
    theme_font_color: "color:orange",
    center_color: "orange",
    userMenus: [
      {
        icon: "bubble_chart",
        title: "Logout",
        link: "logout",
      },
      {
        icon: "bubble_chart",
        title: "Change Password",
        link: "changepassword",
      },
    ],
    items: [
      {
        icon: "mdi-view-dashboard",
        title: "Dashboard",
        vertical: "Dashboard",
        link: "dashboard",
      },
      {
        icon: "mdi-file-find-outline",
        title: "Search",
        vertical: "search",
        link: "search",
      },
      {
        icon: "mdi-bell-ring",
        title: "Notifications",
        vertical: "Notifications",
        link: "notifications",
      },
    ],
  }),
  computed: mapState(["notifications"]),
  watch: {
    notifications(newValue, oldValue) {
      console.log("Updating from " + oldValue + " to " + newValue);
      this.updateNotifications();
    },
    menuItem(newValue, oldValue) {
      console.log("Menu item updating from " + oldValue + " to " + newValue);
      this.drawer = false;
      this.mini = false;
      this.notifi_drawer = false;
      if (newValue == "Notifications") {
        this.drawer = true;
        this.mini = true;
        this.notifi_drawer = true;
      } else if (newValue == "Work Orders") {
        this.drawer = false;
        this.mini = false;
        this.notifi_drawer = false;
      } else {
        this.drawer = true;
        this.mini = true;
      }
    },
  },
  mounted: function () {
    let result = Util.getFromSession("logged_in_mobile");
    if (result) {
      let menu = Auth.getMenus();
      cron_job.run();
      this.items = this.items.concat(menu);
      this.initials = Util.getFromSession("initials");
      this.center_name = Util.getFromSession("logged_in_tenant_name");
      this.load();
    } else {
      this.$router.push("/login");
    }
  },
  methods: {
    toggle: function (index) {
      let val = this.show[index];
      val = !val;
      this.$set(this.show, index, val);
    },
    theme_change: function () {
      //alert('OK');
      if (this.app_bar_color == "white") {
        this.app_bar_color = "orange";
        this.navi_class = "orange lighten-3";
        this.theme_font_color = "color:white";
        this.center_color = "white";
      } else {
        this.app_bar_color = "white";
        this.navi_class = "grey lighten-3";
        this.theme_font_color = "color:orange";
        this.center_color = "orange";
      }
    },
    updateNotifications: async function () {
      this.notificationData = await NotificationDB.list("New");
      let notification_len = this.notificationData.length;
      this.notification_count = notification_len;
      if (notification_len > 0) {
        this.snackbar_text = "Got New Notifications!";
        this.snackbar = true;
      }
    },
    load: function () {
      console.log(">>>>>>>>" + location.pathname);
      let result = Util.getFromSession("logged_in_mobile");
      if (result) {
        console.log("on app load");
        this.signedIn = true;
        if (DBHandler.getDbCount() > 0) {
          //this.updateNotifications();
          this.$router.push("/dashboard").catch((err) => {
            console.log("its ok:" + err);
          });
        } else {
          this.$router.push("/loading").catch((err) => {
            console.log("its ok:" + err);
          });
        }
      } else if (location.pathname == "/") {
        this.signedIn = false;
        console.log("login");
      } else {
        this.signedIn = false;
        this.$router.push("/login").catch((err) => {
          console.log("its ok:" + err);
        });
      }
    },
    handleNavigation: function (item) {
      if (this.menuItem != item.title) {
        this.menuItem = item.title;
        this.$router.push("/" + item.link); //.catch(()=>{});
      }
    },
    handleUserActions: function (item) {
      this.menuItem = item.title;
      if (item.title === "Logout") {
        sessionStorage.clear();
        location.reload();
      }
      this.$router.push("/" + item.link);
    },
    activeMenuItem: function (title) {
      if (this.menuItem == title) {
        return true;
      }
      return false;
    },
    show_center: async function () {
      this.center_sheet = true;
      // this.search = '';
      this.search_centers();
    },
    closeSheet: function () {
      this.center_sheet = false;
    },
    search_centers: async function () {
      console.log("searching..." + this.search);
      this.centers = [];
      this.overlay = true;
      let list = await PostgresDB.getAssignedTenantList(this.search);
      console.log("list size:" + list.length);
      this.centers = list;
      this.overlay = false;
      this.$refs.center_ref.$refs.input.focus();
    },
    debounce_search: Util.debounce(function () {
      this.search_centers();
    }, 500),
    selected_center: async function (id, name) {
      let log_id = Util.getFromSession("logged_in_tenant_id");
      let log_name = Util.getFromSession("logged_in_tenant_name");
      if (id == log_id) {
        this.snackbar_text = "You are already logged in " + log_name;
        this.snackbar = true;
        this.closeSheet();
      } else {
        this.overlay = true;
        console.log(id + " " + name);
        let result = await PostgresDB.change_tenant(id);
        console.log(result);
        this.overlay = false;
        if (result == 200) {
          location.reload();
        }
      }
    },
    change_status: async function (doc) {
      doc.status = "Seen";
      console.log(doc);
      let update = await NotificationDB.doUpdate(doc);
      if (update == "OK") {
        this.snackbar_text = "Successfully Updated";
        this.snackbar = true;
        this.updateNotifications();
      } else {
        console.log(update);
        this.snackbar_text = "Error Occurred:" + update;
        this.snackbar = true;
      }
    },
  },
};
</script>
<style>
.avatar {
  border-radius: 30%;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 12px !important;
  font-family: Tahoma, Verdana, sans-serif;
}
</style>
