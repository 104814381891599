<template>
<div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
         <v-toolbar color="white">
          <v-toolbar-title><v-chip color="orange" text-color="white" label><h3>Price List</h3></v-chip></v-toolbar-title>
          <v-spacer></v-spacer>
            <v-text-field v-model="search" ref="spl_ref1" append-icon="mdi-magnify" label="Search" single-line hide-details @keyup.native="debounce_search()"></v-text-field>
            <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-virtual-scroll bench="0" :items="price_list" :item-height="150" height="1200">
         <template v-slot:default="{ item }">
           <v-list-item dense>
            <v-list-item-content>
              <v-list-item-title>
                <v-sheet
                  color="white"
                  elevation="4"
                  class="ma-1"
                  rounded
                >
                <v-chip class="ma-1" color="primary" label outlined small>{{ item.dept_name }}</v-chip> 
                <v-chip color="orange" label outlined small>Invest. Id: {{ item.invest_id }}</v-chip>
                <br>
                <v-chip class="my-1" color="blue" label outlined small>{{ item.invest_name }}</v-chip>
                <br>
                <v-chip color="red" label outlined x-small>
                  Base Cost: ₹{{ item.base_cost }}
                </v-chip>
                <br>
                <v-chip label color="orange" outlined x-small>
                  Min. Cost: ₹{{ item.min_cost }}
                </v-chip>
                <br>
                <v-chip color="blue" label outlined x-small>
                  CGHS Cost: ₹{{ item.cghs_price }}
                </v-chip>
                <br>
                <v-chip class="my-1" color="primary" label outlined small v-if="check_history(item)" @click="show_history(item)">
                  History Found: {{ item.history.length }}
                </v-chip>
                <v-chip class="my-1"  color="grey" label outlined small v-else>
                  No History Found
                </v-chip>
                </v-sheet>
              </v-list-item-title>
            </v-list-item-content>
           </v-list-item>
         </template>
        </v-virtual-scroll>
      </v-card>  
    </v-dialog>
    <v-overlay :value="overlay" :z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!-- 
      History bottom sheet
     -->

  <div>
  <v-bottom-sheet v-model="sheet" scrollable inset>
    <v-sheet height="300px">
      <v-toolbar color="white">
            <v-chip color="orange" text-color="white" label><h3>History</h3></v-chip>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeSheet()"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-simple-table dense fixed-header height="300px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Action
                  </th>
                  <th class="text-left">
                    Summary
                  </th>
                  <th class="text-left">
                    User / Mobile
                  </th>
                  <th class="text-left">
                    Time
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item,j) in history"
                  :key="j"
                >
                  <td>{{ item.action }}</td>
                  <td>{{ item.summary }}</td>
                  <td>{{ item.emp_name }} / {{ item.emp_mobile }}</td>
                  <td>{{ item.time_stamp }}</td>
                </tr>
              </tbody>
            </template>
        </v-simple-table>
      </v-sheet>
    </v-bottom-sheet>
    </div>

</div>
</template>
<script>
import Util from "@/js/Util";
import PriceListDB from "@/js/database/price_list_db";
export default {
    props: ['show'],
    data () {
      return {
        headers: [
          { text: 'Department', value: 'dept_name' },
          { text: 'Investigation', value: 'invest_name' },
          { text: 'Price', value: 'base_cost' },
          { text: 'Min Price', value: 'min_cost' },
          { text: "CGHS", value: "cghs_price" },
          { text: '', value: 'data-table-expand' },
        ],
        expanded: [],
        loading: false,
        dialog: this.show,
        search: '',
        price_list : [],
        overlay: false,
        sheet: false,
        history: [],
      }
    },
    computed: {
      height: function(){
          console.log('name:'+this.$vuetify.breakpoint.name)
          switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 300
            case 'sm': return 300
            case 'md': return 300
            case 'lg': return 270
            case 'xl': return 600
          }
          return 400;
      }
    },
    mounted: function(){
     this.search_price_list();
    },
    methods: {
      format: function(money){
        return Util.formatMoney(money);
      },
      close: function(e){
          this.dialog = false;
          this.$emit('close_price_view',e);
      },
      search_price_list: async function(){
        try{
          console.log('searching...'+this.search);
          this.price_list = [];
          this.overlay = true;
          let list = await PriceListDB.fetch_all_data(this.search);
          console.log('list size:'+list.length);
          this.price_list = list;
          this.overlay = false;
          this.$refs.spl_ref1.$refs.input.focus();
        } catch(e){
          console.log('Error:'+e);
        }
      },
      debounce_search: Util.debounce(function () {
        this.search_price_list();
      }, 500)
      ,
      closeSheet: function(){
        this.sheet = false;
      },
      check_history: function(item){
        let history_array = item.history;
        if(history_array.length > 1){
          return true;
        }
        if(history_array.length == 1){
          let action = history_array[0].action;
          if(action == 'NA'){
            return false;
          } else {
            return true;
          }
        }
        return false;
      },
      show_history: function(item){
        let history_array = item.history;
        this.history = history_array;
        this.sheet = true;
      }
    }
  }
</script>