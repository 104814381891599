<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar color="white">
            <v-toolbar-title
              ><v-chip color="orange" text-color="white" label>
                <h3 v-if="edit_form">Edit User</h3>
                <h3 v-else>Add User</h3>
              </v-chip></v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn icon @click="close()"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar>
          <v-container>
            <v-form ref="add_technician_form" v-model="valid" lazy-validation>
              <v-row justify="center" dense>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field label="First Name" hint="First Name" v-model="first_name" :rules="nameRules" required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field label="Last Name" hint="Last Name" v-model="last_name"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field type="number" label="Mobile" hint="Mobile" prefix="+91" v-model="mobile" :rules="mobileRules" required></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" dense>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field label="Address" hint="Address" v-model="address"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field type="number" label="Pincode" hint="Pincode" v-model="pincode"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field label="Email Id" hint="Email Id" v-model="email"></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Choose Role Name"
                    hint="Choose Role Name"
                    v-model="role_name"
                    append-icon="mdi-selection-search"
                    @click:append="show_job"
                    :rules="popupRules"
                    readonly
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    label="Choose Department Name"
                    hint="Choose Department Name"
                    v-model="department_name"
                    :rules="popupRules"
                    readonly
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    label="Choose Tenant Name"
                    hint="Choose Tenant Name"
                    v-model="center_name"
                    :rules="popupRules2"
                    readonly
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

            <!-- <v-row justify="center" dense v-if="role_name == 'TECHNICIAN'"> -->   
                <v-row justify="center" dense v-if="isTechnicianRole()">
                <v-col cols="12" sm="6" md="9">
                  <v-card class="mx-auto" tile>
                    <v-system-bar color="white">
                      <v-subheader>Allocated Collection Areas</v-subheader>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="show_pincode()">
                        <v-icon>mdi-selection-search</v-icon>
                      </v-btn>
                    </v-system-bar>

                    <v-chip
                      class="ma-1"
                      close
                      close-icon="mdi-close-outline"
                      color="orange"
                      label
                      outlined
                      v-for="(item, i) in items"
                      :key="i"
                      @click:close="chip_close(item._id)"
                    >
                      {{ item.pincode }} {{ item.area }}
                    </v-chip>
                  </v-card>
                </v-col>
              </v-row>
              <v-row justify="center" dense>
                <v-col cols="12" sm="6" md="3" v-if="uploaded_file">
                  <v-chip small label color="primary"> Uploaded: {{ uploaded_file }} </v-chip>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-file-input
                    id="id_card_file"
                    v-model="files"
                    placeholder="Attach Id Card Copy"
                    label="Attach Id Card Copy"
                    multiple
                    prepend-icon="mdi-paperclip"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip small label color="primary">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-btn :loading="loading" :disabled="loading" color="primary" class="mx-4" @click="validate()" v-if="edit_form">Update</v-btn>
                <v-btn :loading="loading" :disabled="loading" color="primary" class="mx-4" @click="validate()" v-else>Save</v-btn>
                <v-btn color="primary" @click="close()" class="mx-4">Close</v-btn>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- Your existing code -->
  <v-bottom-sheet v-model="job_sheet" inset>
    <v-toolbar color="white">
      <v-chip color="orange" text-color="white" label>
        <h3>Search Role</h3>
      </v-chip>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="job_search"
        ref="job_ref"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        @keyup.native="debounce_job_search()"
      ></v-text-field>
      <v-btn icon @click="closeJobSheet()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-sheet>
      <v-simple-table dense height="300px">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left" width="5%">Select</th>
              <th class="text-left" width="10%">Role Id</th>
              <th class="text-left" width="85%">Role Name</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in roles" :key="item.role_id">
              <td width="5%">
                <v-checkbox
                  v-model="selectedRoles"
                  :value="item"
                  hide-details
                  @change="handleRoleSelect"
                ></v-checkbox>
              </td>
              <td width="10%">
                {{ item.role_id }}
              </td>
              <td width="85%">
                {{ item.role_name }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-sheet>
  </v-bottom-sheet>
    <!--  
   --                         Bottom sheet for pincode
   -->
    <v-bottom-sheet v-model="sheet" inset>
      <v-toolbar color="white">
        <v-chip color="orange" text-color="white" label><h3>Search Pincode</h3></v-chip>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          ref="sp_ref"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          @keyup.native="debounce_search()"
        ></v-text-field>
        <v-btn icon @click="closeSheet()"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-sheet height="300px">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Pincode</th>
                <th class="text-left">Area</th>
                <th class="text-left">City</th>
                <th class="text-left">District</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in pincodes" :key="item._id">
                <td>
                  <a @click="selected_pin(item.pincode, item.area, item._id)">{{ item.pincode }}</a>
                </td>
                <td>
                  <a @click="selected_pin(item.pincode, item.area, item._id)">{{ item.area }}</a>
                </td>
                <td>
                  <a @click="selected_pin(item.pincode, item.area, item._id)">{{ item.city }}</a>
                </td>
                <td>
                  <a @click="selected_pin(item.pincode, item.area, item._id)">{{ item.district }}</a>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-sheet>
    </v-bottom-sheet>
    <v-overlay :value="overlay" :z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!--  
   --                         Dialog for msgs
   -->
    <div>
      <v-dialog v-model="msg_dialog" transition="dialog-bottom-transition" max-width="600">
        <v-card>
          <v-toolbar color="orange" dark>{{ msg_head }}</v-toolbar>
          <v-card-text>
            <div class="text-h5 pa-4">{{ msg_str }}</div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="close_msg_dialog">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
//import Multiselect from 'vue-multiselect';
import axios from "axios";
import Util from "@/js/Util";
import PostgresDB from "@/js/database/postgres_db";
//import DBHandler from "@/js/database/DBHandler";
import settings from "@/js/database/settings";
import PincodeDB from "@/js/database/pincode_db";
//import TechnicianDB from '@/js/database/technician_db';
//import moment from 'moment';
export default {

  props: ["show", "item"],
  data() {
    return {
      msg_dialog: false,
      msg_str: "",
      msg_head: "",
      loading: false,
      dialog: this.show,
      valid: true,
      first_name: "",
      last_name: "",
      role_id: "",
      role_name: "",
      center_id: "",
      center_name: "",
      department_id: "64",
      department_name: "HOME COLLECTION",
      nameRules: [(v) => !!v || "Name is required", (v) => (v && v.length <= 20) || "Name must be less than 20 characters"],
      mobile: "",
      mobileRules: [
        (v) => !!v || "Mobile is required",
        (v) => (v && v.length == 10) || "Mobile number must be in 10 digits.",
        (v) => (v && v > 0) || "Mobile number must be greater than 0",
      ],
      address: "",
      addressRules: [(v) => !!v || "Address is required", (v) => (v && v.length <= 100) || "Address must be less than 100 characters."],
      pincode: "",
      pincodeRules: [
        (v) => !!v || "Pincode is required",
        (v) => (v && v.length == 6) || "Pincode must be in 6 digits.",
        (v) => (v && v > 0) || "Pincode must be greater than 0",
      ],
      popupRules: [
        (v) => !!v || "Value is required. Click icon to the right to choose.",
        (v) => (v && v.length <= 100) || "Value must be less than 100 characters",
      ],
      popupRules2: [(v) => !!v || "Value is required.", (v) => (v && v.length <= 50) || "Value must be less than 50 characters"],
      email: "",
      center_sheet: false,
      center_search: "",
      centers: [],
      dept_sheet: false,
      dept_search: "",
      depts: [],
       selectedRoles: [], // Array to store multiple selected roles
      job_sheet: false,
      job_search: "",
      roles: [],
      sheet: false,
      search: "",
      pincodes: [],
      overlay: false,
      items: [],
      files: [],
      edit_form: false,
      emp_id: "",
      uploaded_file: "",
      rolesModified: false,
    };
  },
  mounted: async function () {
    this.center_id = Util.getFromSession("logged_in_tenant_id");
    this.center_name = Util.getFromSession("logged_in_tenant_name");
    if (this.item.first_name) {
      this.edit_form = true;
      this.emp_id = this.item._id;
      this.first_name = this.item.first_name;
      this.last_name = this.item.last_name;
      this.mobile = this.item.mobile;
      this.address = this.item.address;
      this.pincode = this.item.pincode;
      this.email = this.item.email;
      //this.role_id = this.item.role_id;
      //this.role_name = this.item.role_name;
      if (this.item.employee_activities && this.item.employee_activities.role_list && this.item.employee_activities.role_list.length > 0) {
      this.role_id = this.item.employee_activities.role_list;
      // Fetch role names by role IDs
      this.role_name = await PostgresDB.getRoleNamesByIds(this.role_id);
    } else {
      // Fall back to this.item.role_id and this.item.role_name
      this.role_id = this.item.role_id;
      this.role_name = this.item.role_name;
    }

      this.department_id = this.item.department_id;
      this.department_name = this.item.department_name;
      this.items = this.item.allocated_areas;
      this.uploaded_file = this.item.photo_id_card;
    }
  },
 
  methods: {
    close_msg_dialog: function () {
      this.msg_str = "";
      this.msg_head = "";
      this.msg_dialog = false;
    },
    isTechnicianRole() {
    return this.role_name.toUpperCase().includes('TECHNICIAN');
  },
    validate: async function () {
      let result = this.$refs.add_technician_form.validate();
      if (result) {
        let result = await PostgresDB.getUserWithMobile(this.mobile);
        console.log(result);
        if (this.edit_form) {
          if (result.length > 0) {
            if (result[0].emp_id != this.emp_id) {
              this.msg_head = "Error";
              this.msg_str = this.mobile + " Mobile already exists";
              this.msg_dialog = true;
            } else {
              this.upload();
            }
          } else {
            this.upload();
          }
        } else {
          if (result.length > 0) {
            this.msg_head = "Error";
            this.msg_str = this.mobile + " Mobile already exists";
            this.msg_dialog = true;
          } else {
            this.upload();
          }
        }
      }
    },
handleRoleSelect() {
this.rolesModified = true;
  // Assign the first selected role's ID to this.role_id
  if (this.selectedRoles.length > 0) {
    this.role_id = this.selectedRoles[0].role_id;
  } 
  //else {
    //this.role_id = null; // or some default value
  //}

  // Prepare a comma-separated list of role names (if needed)
  this.role_name = this.selectedRoles
    .map((role) => role.role_name)
    .join(", ");
},
    upload: async function () {
      this.loading = true;
      let formData = new FormData();
      let upload = document.querySelector("#id_card_file");
      let instance = this;
      // let dept_name = this.department_name;
      if (upload.files[0]) {
        let jwt_token = Util.getFromSession("pg_admin");
        //let key = "andersonemployees/id_card/"+dept_name+"/"+upload.files[0].name;
        let key = "homecollection/id_card/" + upload.files[0].name;
        formData.append("upload_file", upload.files[0]);
        formData.append("key", key);
        formData.append("bucket_name", "andersonemployees");
        formData.append("jwt_token", jwt_token);
        await axios
          .post(settings.node_url + "/s3/upload_file_v3", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (response) {
            console.log(response.data);
            instance.save(key);
          })
          .catch(function (error) {
            console.log("error:" + error);
            instance.save("Error:" + error);
          });
      }
      if (this.edit_form) {
        instance.save("");
      } else {
        instance.save("No ID Card");
      }
    },
    save: async function (key) {
      if (key.startsWith("Error")) {
        this.msg_head = "Error";
        this.msg_str = key;
        this.msg_dialog = true;
         this.loading = false;
        return;
      }
  
      let doc = {
        first_name: Util.toTitleCase(this.first_name),
        last_name: Util.toTitleCase(this.last_name),
       
        department_id: this.department_id,
        default_tenant_id: this.center_id,
        mobile: this.mobile,
        email: this.email,
        address: Util.toTitleCase(this.address),
        pincode: this.pincode,
        created_by: Util.getFromSession("logged_in_emp_name"),
        last_updated_by: Util.getFromSession("logged_in_emp_name"),
       
      };
      if (this.rolesModified) {
        doc.role_id = this.role_id;
        doc.employee_activities = {
          role_list: this.selectedRoles.map((role) => role.role_id.toString())
        };
      }
      if (this.edit_form) {
        if (key) {
          doc.photo_id_card = key;
        }
        this.doUpdate(doc, this.emp_id);
      } else {
        doc.photo_id_card = key;
        this.doInsert(doc);
      }
    },
    doUpdate: async function (doc, emp_id) {
      let result = await PostgresDB.updateUser(emp_id, doc);
      if (result.status == 200 || result.status == 204) {
        if (this.department_name == "HOME COLLECTION") {
          let allocated = JSON.stringify(this.items);
          let alloc_doc = {
            emp_id: emp_id,
            allocated_areas: allocated,
            created_by: Util.getFromSession("logged_in_emp_name"),
        last_updated_by: Util.getFromSession("logged_in_emp_name"),
          };
          let result = await PostgresDB.upsertAllocatedAreas(emp_id, alloc_doc);
          console.log("allocated areas saved:" + result.status);

        }
       this.loading = false;
        this.msg_head = "Success";
        this.msg_str = "Successfully Updated.";
        this.msg_dialog = true; 
        //this.close();
      } else if (result.indexOf("409") > -1) {
        this.loading = false;
        this.msg_head = "Error";
        this.msg_str = "Mobile number found in deleted user. Please contact Admin.";
        this.msg_dialog = true;
      } else {
        this.loading = false;
        this.msg_head = "Error";
        this.msg_str = "Problem in updation";
        this.msg_dialog = true;
      }
    },
    doInsert: async function (doc) {
      let result = await PostgresDB.insertUser(doc);
      console.log(JSON.stringify(result));
      if (result.status == 201) {
       
      // let emp_str = result.data[0];
       // let emp_id = emp_str.emp_id;
        //console.log("emp_id=" + emp_id);
        let searchResult = await PostgresDB.search_employee(doc.mobile);
    console.log(JSON.stringify(searchResult));
     if (searchResult.length > 0 && searchResult[0].emp_id) {
      let emp_id = searchResult[0].emp_id;
      console.log("emp_id=" + emp_id);
      
     if (this.department_name == "HOME COLLECTION") {

        let allocated = JSON.stringify(this.items);
        let alloc_doc = {
          emp_id: emp_id,
          allocated_areas: allocated,
          created_by: Util.getFromSession("logged_in_emp_name"),
          last_updated_by: Util.getFromSession("logged_in_emp_name"),
        };
        let upsertResult = await PostgresDB.upsertAllocatedAreas(emp_id, alloc_doc);
        console.log("allocated areas saved: " + upsertResult.status);
      }
    }
         this.loading = false;
        this.msg_head = "Success";
        this.msg_str = "Successfully Inserted.";
        this.msg_dialog = true;
        this.close();
      } else if (result.indexOf("409") > -1) {
        this.loading = false;
        this.msg_head = "Error";
        this.msg_str = "Mobile number found in deleted user. Please contact Admin.";
        this.msg_dialog = true;
      } else {
        this.loading = false;
        this.msg_head = "Error";
        this.msg_str = "Problem while adding new record.";
        this.msg_dialog = true;
      }
    },
    close: function () {
      this.dialog = false;
      this.$emit("close_add_technician");
    },
    show_pincode: async function () {
      this.sheet = true;
      // this.search = '';
      this.search_pincodes();
    },
    closeSheet: function () {
      this.sheet = false;
    },
    search_pincodes: async function () {
      console.log("searching..." + this.search);
      this.pincodes = [];
      this.overlay = true;
      let list = await PincodeDB.fetch_data(this.search);
      console.log("list size:" + list.length);
      this.pincodes = list;
      this.overlay = false;
      this.$refs.sp_ref.$refs.input.focus();
    },
    debounce_search: Util.debounce(function () {
      this.search_pincodes();
    }, 500),
    selected_pin: function (pin, area, id) {
      let item_available = false;
      for (let i = 0; i < this.items.length; i++) {
        let item = this.items[i];
        if (item._id == id) {
          item_available = true;
        }
      }
      if (item_available) {
        this.msg_head = "Error";
        this.msg_str = "Pincode added already.";
        this.msg_dialog = true;
      } else {
        let data = {
          _id: id,
          pincode: pin,
          area: area,
        };
        this.items.push(data);
        this.sheet = false;
      }
    },
    chip_close: function (id) {
      let new_items = [];
      for (let i = 0; i < this.items.length; i++) {
        let item = this.items[i];
        if (item._id != id) {
          new_items.push(item);
        }
      }
      this.items = new_items;
    },
    show_job: async function () {
      this.job_sheet = true;
      this.search_jobs();
    },
    closeJobSheet: function () {
      this.job_sheet = false;
    },
    search_jobs: async function () {
      console.log("searching..." + this.job_search);
      this.roles = [];
      this.overlay = true;
      let list = await PostgresDB.getRoleList(this.job_search);

      console.log("list size:" + list.length);
      this.roles = list;
      this.overlay = false;
      this.$refs.job_ref.$refs.input.focus();
    },
    debounce_job_search: Util.debounce(function () {
      this.search_jobs();
    }, 500),
    selected_job: function (id, name) {
      this.role_name = name;
      this.role_id = id;
      this.job_sheet = false;
    },
  },
};
</script>
