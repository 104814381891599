<template>
  <div>
    <v-toolbar color="orange" dark>
      <v-toolbar-title><h5>View Tests</h5></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-row class="my-2" justify="center" v-for="item in price_list" :key="item._id">
      <v-card elevation="2" outlined style="width: 100%" class="ma-2">
        <v-card-title class="text-h6 blue--text">
          {{ item.invest_name }}
          <v-spacer></v-spacer>
          <v-chip class="mx-2" color="orange" label outlined v-if="cghs">
            {{ get_cghs(item) }}
          </v-chip>
          <v-chip class="mx-2" color="orange" label outlined v-else>
            {{ format(item.base_cost) }}
          </v-chip>
        </v-card-title>
        <v-card-subtitle>
          {{ item.department_name }}
        </v-card-subtitle>
      </v-card>
    </v-row>
    <v-footer dense :fixed="footer_position" padless color="white">
      <v-card elevation="2" style="width: 100%" class="my-4">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-chip color="orange" label outlined> Total: {{ computed_total }} </v-chip>
        </v-card-title>
      </v-card>
    </v-footer>
    <!-- 
      Message Dialog   
    -->
    <div v-if="snackbar">
      <v-snackbar v-model="snackbar" :timeout="2000">
        {{ snackbar_text }}
      </v-snackbar>
    </div>
  </div>
</template>
<script>
import WorkOrderDB from "@/js/database/workorder_db";
import Util from "@/js/Util";
export default {
  name: "TechViewTests",
  data() {
    return {
      doc_id: "",
      edit_item: {},
      price_list: [],
      footer_position: false,
      cghs: 0,
      snackbar: false,
      snackbar_text: "",
    };
  },
  computed: {
    computed_total: function () {
      let total = 0;
      for (let i = 0; i < this.price_list.length; i++) {
        let item = this.price_list[i];
        if (this.cghs) {
          let iCghs = parseInt(item.cghs_price);
          if (iCghs <= 0) {
            total += parseInt(item.base_cost);
          } else {
            total += iCghs;
          }
        } else {
          total += parseInt(item.base_cost);
        }
      }
      return this.format(total);
    },
  },
  mounted: function () {
    if (Util.isLoggedIn()) {
      this.doc_id = this.$route.params.id;
      this.get_doc();
    } else {
      location.reload;
    }
  },
  methods: {
    close: function () {
      this.$router.push("/my_work_orders");
    },
    get_doc: async function () {
      let doc = await WorkOrderDB.getWithId(this.doc_id);
      this.edit_item = doc;
      this.price_list = doc.test_items;
      this.cghs = doc.cghs;
      if (this.price_list.length <= 3) {
        this.footer_position = true;
      }
    },
    get_cghs: function (item) {
      let iCghs = parseInt(item.cghs_price);
      if (iCghs <= 0) {
        return this.format(item.base_cost);
      } else {
        return this.format(item.cghs_price);
      }
    },
    format: function (money) {
      return Util.formatMoney(money);
    },
  },
};
</script>
