<template>
  <div>
    <top_tab @unbilled="unbilled()" @billed="billed()"></top_tab>
    <v-container ref="unbilled" v-if="type === 'unbilled'">
      <v-container v-if="isMobile()">
        <v-chip color="orange" text-color="white" label><h3>Unbilled Work Orders</h3></v-chip>
        <mobile_view type="unbilled"  :key="compKey"></mobile_view>
      </v-container>
      <v-container v-else>
        <v-chip color="orange" text-color="white" label><h3>Unbilled Work Orders</h3></v-chip>
        <desktop_view type="unbilled" :key="compKey"></desktop_view>
      </v-container>
    </v-container>
    <v-container ref="unbilled" v-else-if="type === 'billed'">
      <v-container v-if="isMobile()">
        <v-chip color="orange" text-color="white" label><h3>Billed Work Orders</h3></v-chip>
        <mobile_view type="billed"  :key="compKey"></mobile_view>
      </v-container>
      <v-container v-else>
        <v-chip color="orange" text-color="white" label><h3>Billed Work Orders</h3></v-chip>
        <desktop_view type="billed" :key="compKey"></desktop_view>
      </v-container>
    </v-container>
  </div>
</template>
<script>
import mobile_view from "@/components/billing_work_order/mobile_view.vue";
import desktop_view from "@/components/billing_work_order/desktop_view.vue";
import top_tab from "@/components/billing_work_order/top_tab.vue";
import Util from "@/js/Util";
export default {
  name: "BillingWorkOrders",
  components: { mobile_view, desktop_view, top_tab },
  data: function() {
    return {
      compKey: 0,
      type: "unbilled",
    };
  },
  methods: {
    isMobile: function(){
      return Util.isMobile();
    },
    update: function() {
      this.compKey += 1;
    },
    unbilled: function() {
      this.update();
      this.type = "unbilled";
    },
    billed: function() {
      this.update();
      this.type = "billed";
    },
  },
};
</script>
<style></style>
