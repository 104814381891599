<template>
  <div>
    <div>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="11" sm="5">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="date" label="Choose a Day" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              type="date"
              no-title
              scrollable
              @change="
                $refs.menu.save(date);
                show_data(date);
                menu = false;
              "
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(date);
                  show_data(date);
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </div>
    <v-container>
      <v-card>
        <v-toolbar color="blue lighter-1" dark dense>
          <v-toolbar-title>Daily Cases {{ date }}</v-toolbar-title>
        </v-toolbar>
        <v-data-table
          :headers="tableHeaders"
          :items="tableData"
          item-key="_id"
          :loading="loading"
          loading-text="Loading... Please wait"
          class="elevation-1"
          hide-default-footer
          dense
        >
          <!-- eslint-disable-next-line -->
          <template v-slot:item.collection="{ item }">
            {{ format(item.collection) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.credit="{ item }">
            {{ format(item.credit) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.b2b="{ item }">
            {{ format(item.b2b) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.trial="{ item }">
            {{ format(item.trial) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.received="{ item }">
            {{ format(item.received) }}
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-toolbar color="blue lighter-1" dark dense>
          <v-toolbar-title>Daily Cases {{ date }}</v-toolbar-title>
        </v-toolbar>
        <BarChart :item="daily_report_chartData" :key="reload"></BarChart>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-toolbar color="blue lighter-1" dark dense>
          <v-toolbar-title>Daily Collection {{ date }}</v-toolbar-title>
        </v-toolbar>
        <BarChart :item="daily_collection_chartData" :key="reload2"></BarChart>
      </v-card>
    </v-container>
    <!-- 
      Message Dialog   
    -->
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>
<script>
import moment from "moment";
import DashboardDB from "@/js/database/dashboard_db";
import BarChart from "@/components/work_order/dashboard/bar_chat.vue";
import Util from "@/js/Util";
export default {
  components: {
    BarChart,
  },
  name: "Dashboard",
  data: () => ({
    date: "",
    menu: false,
    loading: false,
    doc: {},
    snackbar: false,
    snackbar_text: "",
    reload: 0,
    reload2: 0,
    tableHeaders: [
      { text: "Date", value: "date" },
      { text: "Assigned", value: "assigned" },
      { text: "Finished", value: "finished" },
      { text: "Cancelled", value: "cancelled" },
      { text: "Pending", value: "pending" },
      { text: "Total Collection", value: "collection" },
      { text: "Received", value: "received" },
      { text: "Credit", value: "credit" },
      { text: "B2B", value: "b2b" },
      { text: "Trial", value: "trial" },
    ],
    tableData: [],
    daily_collection_chartData: {
      labels: [],
      datasets: [
        {
          label: "Total Collection",
          type: "bar",
          stack: "Total Collection",
          backgroundColor: "#9999ff",
          data: [],
        },
        {
          label: "Received",
          type: "bar",
          stack: "Received",
          backgroundColor: "#b3ffb3",
          data: [],
        },
        {
          label: "Credit",
          type: "bar",
          stack: "Credit",
          backgroundColor: "#cccccc",
          data: [],
        },
        {
          label: "B2B",
          type: "bar",
          stack: "B2B",
          backgroundColor: "#ffa500",
          data: [],
        },
        {
          label: "Trial",
          type: "bar",
          stack: "Trial",
          backgroundColor: "#2db83d",
          data: [],
        },
      ],
    },

    daily_report_chartData: {
      labels: [],
      datasets: [
        {
          label: "Assigned",
          type: "bar",
          stack: "Assigned",
          backgroundColor: "#9999ff",
          data: [],
        },
        {
          label: "Fineshed",
          type: "bar",
          stack: "Fineshed",
          backgroundColor: "#b3ffb3",
          data: [],
        },
        {
          label: "Cancelled",
          type: "bar",
          stack: "Cancelled",
          backgroundColor: "#cccccc",
          data: [],
        },
        {
          label: "Pending",
          type: "bar",
          stack: "Pending",
          backgroundColor: "#ffa500",
          data: [],
        },
      ],
    },
  }),
  mounted: function () {
    let today = moment().subtract(1, "days");
    let this_date = today.format("YYYY-MM-DD");
    this.date = this_date;
    // console.log(this_date);
    this.load_data(this_date);
  },
  methods: {
    format: function (str) {
      return Util.formatMoney(str);
    },
    show_data: function (date) {
      this.menu = false;
      this.load_data(date);
    },
    load_data: async function (this_date) {
      try {
        let data = this.tableData;
        for (let i = 0; i < data.length; i++) {
          let each = data[i];
          if (this_date == each.date) {
            this.snackbar_text = "Date Already Selected.";
            this.snackbar = true;
            return;
          }
        }
      } catch (e) {
        console.log(e);
      }
      let today = moment(this_date, "YYYY-MM-DD");
      if (today.isAfter()) {
        this.snackbar_text = "Future Date May Not Have Data To Show.";
        this.snackbar = true;
        return;
      }
      let year = today.year();
      let doc_id = "daily_" + year;
      // console.log(doc_id + "  >" + this_date);
      this.doc = await DashboardDB.get_one(doc_id);
      let data = this.doc.data;
      let today_data = data[this_date];
      if (today_data) {
        today_data.date = this_date;
        this.tableData.push(today_data);
        this.chart_cases_data(this_date, today_data);
        this.chart_collection_data(this_date, today_data);
      } else {
        this.snackbar_text = "No Data Found For The Selected Date";
        this.snackbar = true;
        return;
      }
    },
    chart_cases_data: function (this_date, today_data) {
      this.daily_report_chartData.labels = [this_date];
      this.daily_report_chartData.datasets[0].data = [today_data.assigned];
      this.daily_report_chartData.datasets[1].data = [today_data.finished];
      this.daily_report_chartData.datasets[2].data = [today_data.cancelled];
      this.daily_report_chartData.datasets[3].data = [today_data.pending];
      this.reload += 1;
    },
    chart_collection_data: function (this_date, today_data) {
      this.daily_collection_chartData.labels = [this_date];
      this.daily_collection_chartData.datasets[0].data = [today_data.collection];
      this.daily_collection_chartData.datasets[1].data = [today_data.received];
      this.daily_collection_chartData.datasets[2].data = [today_data.credit];
      this.daily_collection_chartData.datasets[3].data = [today_data.b2b];
      this.daily_collection_chartData.datasets[4].data = [today_data.trial];
      this.reload2 += 1;
    },
  },
};
</script>
