var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"11","sm":"5"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.dates,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Choose a Week","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","range":""},on:{"change":function($event){_vm.$refs.menu.save(_vm.dates);
              _vm.load_data(_vm.dates[0]);
              _vm.menu = false;}},model:{value:(_vm.datesProxy),callback:function ($$v) {_vm.datesProxy=$$v},expression:"datesProxy"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.menu.save(_vm.dates);
                _vm.load_data(_vm.dates[0]);}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1),_c('v-container',[_c('v-card',[_c('v-toolbar',{attrs:{"color":"blue lighter-1","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Weekly Cases Report "+_vm._s(_vm.dateRangeText))])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.tableData,"item-key":"_id","loading":_vm.loading,"loading-text":"Loading... Please wait","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.collection",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format(item.collection))+" ")]}},{key:"item.credit",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format(item.credit))+" ")]}},{key:"item.b2b",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format(item.b2b))+" ")]}},{key:"item.trial",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format(item.trial))+" ")]}},{key:"item.received",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format(item.received))+" ")]}}])})],1)],1),_c('v-container',[_c('v-card',[_c('v-toolbar',{attrs:{"color":"blue lighter-1","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Weekly Cases Report "+_vm._s(_vm.dateRangeText))])],1),_c('BarChart',{key:_vm.reload,attrs:{"item":_vm.weekly_report_chartData}})],1)],1),_c('v-container',[_c('v-card',[_c('v-toolbar',{attrs:{"color":"blue lighter-1","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Weekly Collection "+_vm._s(_vm.dateRangeText))])],1),_c('BarChart',{key:_vm.reload2,attrs:{"item":_vm.weekly_collection_chartData}})],1)],1),_c('v-snackbar',{attrs:{"timeout":2000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }